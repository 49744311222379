import React from 'react'
import Layout from './../Layout/Layout'
import Contact from '../Contact/Contact'
import HeaderBanner from 'components/Header/HeaderBanner'
import ContactPage from 'components/Contact/ContactPage'
const PageContact = () => {
  return (
    <Layout>
      <HeaderBanner title='CONTACTO' />
      <ContactPage />
      <Contact />
    </Layout>
  )
}
export default PageContact
