import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ErrorMessage, Field } from 'formik'
import { GoogleMap, Autocomplete, Marker } from '@react-google-maps/api'
import CreatableSelect from 'react-select/creatable'
import Select from 'react-select'
import { Tooltip } from 'reactstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import img1 from 'assets/img/mark1.svg'
import {
  FormField,
  FormLabel,
  SelectForm
} from 'components/DragAndDropUploadFile/DragAndDropUploadFile'
function FormInput(props) {
  const { registerList, tooltip, color } = props
  const [lat, setLatitude] = useState(null)
  const [lng, setLongitude] = useState(null)
  const [street, setStreet] = useState(props.value)
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [autocomplete, setAutocomplete] = useState(null)
  const toggle = () => setTooltipOpen(!tooltipOpen)
  const animate = 'animate__animated animate__fadeIn animate__faster'

  switch (props.type) {
    //-- RADIO BUTTON ------------------------------------------------------------//
    case 'radioButton': {
      let optionsForm = []
      props.radioButton.map(e =>
        optionsForm.push(
          <label key={e.id}>
            <Field type='radio' className='form-radio' name={props.id} value={e.value} />
            {e.value}
          </label>
        )
      )
      return (
        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12'>
          <div className='form-group radio-button-gender'>
            <div id={props.id}>{props.label}</div>
            <div role='group' aria-labelledby={props.label}>
              {optionsForm}
            </div>
          </div>
          <ErrorMessage
            name={props.id}
            component='div'
            className='animate__animated animate__fadeIn animate__faster error-message'
          />
        </div>
      )
    }
    //-- RADIO BUTTON SMALL ------------------------------------------------------------//
    case 'radioButtonSmall': {
      // eslint-disable-next-line no-case-declarations

      let optionsForm = []
      props.radioButton.map(e =>
        optionsForm.push(
          <label key={e.id} className='radio-button-gender'>
            <Field type='radio' className='form-radio' name={props.id} value={e.value} />
            {e.value}
          </label>
        )
      )
      return (
        <div className={props.css || 'col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12'}>
          <div className='form-group'>
            <div id={props.id} style={{ fontWeight: 'normal' }}>
              {props.label}
            </div>
            <div role='group' aria-labelledby={props.label} style={{ marginBottom: '8px' }}>
              {optionsForm}
            </div>
            {props.other && (
              <Field
                type={'text'}
                name={props.other}
                placeholder={'Otr@'}
                className='auth__input form-input_other mt-4 form-control'
              />
            )}
          </div>
          <ErrorMessage
            name={props.id}
            component='div'
            className='animate__animated animate__fadeIn animate__faster error-message'
          />
        </div>
      )
    }
    //-- RADIO BUTTON SMALL ------------------------------------------------------------//
    case 'radioButtonSmallProfile': {
      let optionsForm = []
      props.radioButton.map(e =>
        optionsForm.push(
          <label key={e.id} className={`radio-button-gender form-control-edit-gender-label`}>
            <Field
              type='radio'
              className='form-radio form-control-edit-gender-radio'
              name={props.id}
              value={e.value}
            />
            {e.value}
          </label>
        )
      )
      return (
        <div
          className={
            props.css ||
            'col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 form-control-edit-gender-content'
          }>
          <div className='form-group'>
            <div
              id={props.id}
              style={{ color: '#1e3250', marginLeft: '41px', marginTop: '32px' }}
              className='profile__form form-control-edit-gender-title'>
              {props.label}
            </div>
            <div role='group' aria-labelledby={props.label} style={{ marginBottom: '8px' }}>
              {optionsForm}
            </div>
            {props.other && (
              <Field
                type={'text'}
                name={props.other}
                placeholder={'Otr@'}
                style={{ marginLeft: '45px', width: '361px', paddingLeft: '15px' }}
                className='auth__input form-input_other mt-4 form-control form-control-gender form-control-edit-gender-input'
              />
            )}
          </div>
          <ErrorMessage
            name={props.id}
            component='div'
            className='animate__animated animate__fadeIn animate__faster error-message'
          />
        </div>
      )
    }
    //-- RADIO BUTTON SMALL ------------------------------------------------------------//
    case 'yesNo': {
      const yesNo = [
        { id: true, value: 'Sí' },
        { id: false, value: 'No' }
      ]

      let optionsForm = []
      yesNo.map(e =>
        optionsForm.push(
          <label key={e.id} className='radio-button-gender'>
            <Field
              type='radio'
              style={{ marginRight: '5px' }}
              className='form-radio'
              name={props.id}
              value={e.value}
            />
            {e.value}
          </label>
        )
      )
      return (
        <div className={props.css || 'col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12'}>
          <div className='form-group'>
            <FormLabel id={props.id} style={{ marginBottom: '0px' }}>
              {props.label}
            </FormLabel>
            <div role='group' onChange={props.onChange} aria-labelledby={props.label}>
              {optionsForm}
            </div>
          </div>
          <ErrorMessage
            name={props.id}
            component='div'
            className='animate__animated animate__fadeIn animate__faster error-message'
          />
        </div>
      )
    }
    //-- SELECTION rado Button------------------------------------------------------------//
    case 'checkbox': {
      // eslint-disable-next-line no-case-declarations
      let optionsForm = []
      props.radioButton.map(e => {
        return optionsForm.push(
          <div>
            <label key={e.id || e._id} className='radio-button-gender'>
              <Field
                type='checkbox'
                className='form-radio'
                style={{ marginRight: '5px' }}
                name={props.id}
                onClick={() => props.onChange(e.value || e.objective || e.name)}
                value={e.value || e._id}
              />
              {e.value || e.objective || e.name}
            </label>
          </div>
        )
      })
      return (
        <div className={props.css || 'col-12 mb-5 mt-1'}>
          <div className='form-group '>
            <FormLabel style={{ margin: '11px 0 10px 0' }}>{props.label}</FormLabel>
            <div role='group' aria-labelledby={props.label}>
              {optionsForm}
              {props?.child}
            </div>
          </div>
          <ErrorMessage
            name={props.id}
            component='div'
            className='animate__animated animate__fadeIn animate__faster error-message'
          />
        </div>
      )
    }
    //-- SELECTION rado Button------------------------------------------------------------//
    case 'selection': {
      // eslint-disable-next-line no-case-declarations
      let optionsForm = []
      props.radioButton.map(e => {
        return optionsForm.push(
          <>
            <label key={e.id || e._id} className='radio-button-gender'>
              <Field type='radio' className='form-radio' name={props.id} value={e._id} />
              {e.value}
            </label>
          </>
        )
      })
      return (
        <div className={props.css || 'col-12 mb-5 mt-1'}>
          <div className='form-group '>
            <div className={props.cssLabel} style={{ fontWeight: '400' }} id={props.id}>
              {props.label}
            </div>
            <div role='group' aria-labelledby={props.label}>
              {optionsForm}
            </div>
          </div>
          <ErrorMessage
            name={props.id}
            component='div'
            className='animate__animated animate__fadeIn animate__faster error-message'
          />
        </div>
      )
    }
    //-- SELECT ------------------------------------------------------------------//
    case 'select':
      return (
        <div className={props.css}>
          <div className={`form-group`} style={{ marginBottom: '10px' }}>
            <label className={`${props.colorLabel} form-label auth__label`} style={{ color }}>
              {props.label}
            </label>
            <select
              name={props.id}
              className={'form-select custom-select-filter'}
              onChange={props.onChange}
              value={props.value}
              // onBlur={handleBlur}
              style={{ display: 'block' }}>
              <option key={'default'} value={''}>
                {'Seleccione'}
              </option>
              {props.dataOptions.map(({ value, text }) => (
                <option key={value} value={value}>
                  {text}
                </option>
              ))}
            </select>
          </div>
          <ErrorMessage
            name={props.id}
            component='div'
            className='animate__animated animate__fadeIn animate__faster error-message'
          />
        </div>
      )
    //-- SELECT FORM ------------------------------------------------------------------//
    case 'selectForm':
      return (
        <div className={props.css}>
          <div className={`form-group`} style={{ marginBottom: '10px' }}>
            <FormLabel>{props.label}</FormLabel>
            <SelectForm name={props.id} onChange={props.onChange} value={props.value}>
              <option key={'default'} value={''}>
                {'Seleccione'}
              </option>
              {props.dataOptions.map(({ value, text }) => (
                <option key={value} value={value}>
                  {text}
                </option>
              ))}
            </SelectForm>
          </div>
          <ErrorMessage
            name={props.id}
            component='div'
            className='animate__animated animate__fadeIn animate__faster error-message'
          />
        </div>
      )

    //-- NATIONALITY ------------------------------------------------------------------//
    case 'nationality':
      return (
        <div className='col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12'>
          <div className='form-group'>
            <label className='form-label auth__label'>{props.label}</label>
            <select
              name={props.id}
              id='nationality'
              className={'form-select'}
              onChange={props.onChange}
              value={props.value}
              style={{ display: 'block' }}>
              <option key='0' id=''>
                Seleccione
              </option>
              {registerList &&
                registerList.map(countries => (
                  <option key={countries.code} id={countries.name} value={countries.name}>
                    {countries.name}
                  </option>
                ))}
            </select>
          </div>
        </div>
      )

    //-- COMPANY ------------------------------------------------------------------//
    case 'multiselect':
      return (
        <div id='TooltipIdMessageDiv' className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12'>
          <div className='form-group'>
            <label className='form-label auth__label'>{props.label}</label>
            {tooltip && (
              <Tooltip
                placement='top'
                isOpen={tooltipOpen}
                target='TooltipIdMessageDiv'
                toggle={toggle}>
                {tooltip}
              </Tooltip>
            )}
            <CreatableSelect
              className={props.className}
              id={props.id}
              name={props.name}
              placeholder={props.placeholder}
              isClearable
              onChange={props.onChange}
              options={props.options}
              value={props.value}
            />
          </div>
        </div>
      )
    //-- COMPANY ------------------------------------------------------------------//
    case 'multiselectsmall':
      return (
        <div
          id='TooltipIdMessageDiv'
          className={`col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 ${props.css}`}>
          <div className='form-group'>
            <label className='form-label auth__label'>{props.label}</label>
            {tooltip && (
              <Tooltip
                placement='top'
                isOpen={tooltipOpen}
                target='TooltipIdMessageDiv'
                toggle={toggle}>
                {tooltip}
              </Tooltip>
            )}
            <CreatableSelect
              className={props.className}
              id={props.id}
              name={props.name}
              formatCreateLabel={() => 'Agregar nueva empresa...'}
              placeholder={props.placeholder}
              isClearable
              onChange={props.onChange}
              options={props.options}
              value={props.value}
            />
          </div>
        </div>
      )

    //-- SINGLESELECT ------------------------------------------------------------------//
    case 'singleselect':
      return (
        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12'>
          <div className='form-group'>
            <label className='form-label auth__label'>{props.label}</label>
            <Select
              isLoading={false}
              isSearchable={true}
              placeholder={props.placeholder}
              name={props.name}
              id={props.id}
              onChange={props.onChange}
              options={props.options}
              value={props.value}
            />
          </div>
        </div>
      )
    //-- RESIDENCE ------------------------------------------------------------------//
    case 'selectresidency':
      return (
        <div className={`col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 ${props.css}`}>
          <div className='form-group'>
            <label className='form-label auth__label'>{props.label}</label>
            <Select
              isLoading={false}
              isSearchable={true}
              placeholder={props.placeholder}
              name={props.name}
              id={props.id}
              onChange={props.onChange}
              options={props.options}
            />
          </div>
        </div>
      )
    //-- SECTOR ------------------------------------------------------------------//
    case 'sector':
      return (
        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12'>
          <div className='form-group'>
            <label className='form-label auth__label'>{props.label}</label>
            <select
              name={props.id}
              id={props.id}
              className={'form-select'}
              onChange={props.onChange}
              style={{ display: 'block' }}>
              <option key='0' id=''>
                Seleccione
              </option>
              {registerList &&
                registerList.docs?.map(countries => (
                  <option key={countries._id} id={countries.name}>
                    {countries.name}
                  </option>
                ))}
            </select>
          </div>
        </div>
      )

    //-- TEXTAREA ---------------------------------------------------------------//
    case 'textarea':
      return (
        <div className={props.css || 'col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12'}>
          <div className='form-group'>
            <FormLabel>{props.label}</FormLabel>
            <Field
              as='textarea'
              name={props.id}
              id={props.id}
              placeholder={props.placeholder}
              value={props.value}
              autoComplete='off'
              className='auth__input  form-control'
            />
            <ErrorMessage
              name={props.id}
              component='div'
              className='animate__animated animate__fadeIn animate__faster error-message'
            />
          </div>
        </div>
      )

    //-- DEFAULT ---------------------------------------------------------------//
    case 'fullwidth':
      return (
        <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'>
          <div className='form-group'>
            <label className='form-label auth__label'>{props.label}</label>
            <Field
              type={props.type}
              name={props.id}
              id={props.id}
              placeholder={props.placeholder}
              value={props.value}
              autoComplete='off'
              className='auth__input mt-4 form-control'
            />
            <ErrorMessage
              name={props.id}
              component='div'
              className='animate__animated animate__fadeIn animate__faster error-message'
            />
          </div>
        </div>
      )

    //-- SMALL ---------------------------------------------------------------//
    case 'small':
      return (
        <div className={props.css || 'col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12'}>
          <div className='form-group'>
            <label className='form-label auth__label'>{props.label}</label>
            <Field
              type={props.type}
              name={props.id}
              placeholder={props.placeholder}
              autoComplete='off'
              className={`auth__input mt-4  form-control`}
            />
            <ErrorMessage
              name={props.id}
              component='div'
              className='animate__animated animate__fadeIn animate__faster error-message'
            />
          </div>
        </div>
      )
    //-- SMALL ---------------------------------------------------------------//
    case 'inputForm':
      return (
        <div className={props.css || 'col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12'}>
          <div className='form-group'>
            <FormLabel>{props.label}</FormLabel>
            <FormField
              type={props.type}
              name={props.id}
              placeholder={props.placeholder}
              autoComplete='off'
            />
            <ErrorMessage
              name={props.id}
              component='div'
              className='animate__animated animate__fadeIn animate__faster error-message'
            />
          </div>
        </div>
      )
    //-- SMALL ---------------------------------------------------------------//
    case 'number':
      return (
        <div className={props.css || `col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 ${animate}`}>
          <div className='form-group'>
            <FormLabel style={{ marginBottom: '5px' }}>{props.label}</FormLabel>
            <FormField
              type={props.type}
              name={props.id}
              value={props.value}
              placeholder={props.placeholder}
              onChange={e => {
                e.preventDefault()
                const { value } = e.target
                const regex = /^(0*[0-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/
                if (regex.test(value.toString())) {
                  props.onChange(e)
                }
              }}
              style={{ width: '100px' }}
              autoComplete='off'
            />
            <ErrorMessage
              name={props.id}
              component='div'
              className='animate__animated animate__fadeIn animate__faster error-message'
            />
          </div>
        </div>
      )
    //-- SWITCH ---------------------------------------------------------------//
    case 'switch':
      return (
        <div className='toggle-switch'>
          <Field
            id={props.id}
            name={props.id}
            type='checkbox'
            onClick={props.onChange}
            className='toggle-switch-checkbox'
          />
          <label className='toggle-switch-label' htmlFor={props.id}>
            <span className='toggle-switch-inner' />
            <span className='toggle-switch-switch' />
          </label>
        </div>
      )
    //-- Google Maps Autocomplete ---------------------------------------------------------------//
    case 'autocomplete':
      const mapContainerStyle = {
        height: '300px',
        width: '100%'
      }

      const onLoad = autocompleted => setAutocomplete(autocompleted)

      const onPlaceChanged = () => {
        if (autocomplete !== null) {
          const { lat, lng } = autocomplete.getPlace().geometry.location
          const street = autocomplete.getPlace().formatted_address
          setLatitude(lat)
          setLongitude(lng)
          setStreet(street)
          props.onChanges({ street, lat, lng })
        }
      }
      return (
        <div className={props.css || 'col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12'}>
          <div className='form-group'>
            <FormLabel style={{ marginTop: '0px' }}>{props.label}</FormLabel>
            <img src={img1} className='form-icon-map-marker' alt='search' />
            <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
              <FormField
                type={props.type}
                name={props.id}
                id={props.id}
                placeholder={props.placeholder}
                autoComplete='off'
                onChange={e => {
                  setStreet(e.target.value)
                  props.onChanges({ street, lat, lng })
                }}
                value={street}
              />
            </Autocomplete>
            {lat && lng && (
              <GoogleMap
                id='searchbox-example'
                mapContainerStyle={mapContainerStyle}
                zoom={15}
                center={{ lat, lng }}>
                <Marker position={{ lat, lng }} />
              </GoogleMap>
            )}
          </div>
        </div>
      )
    //-- SMALL DATE---------------------------------------------------------------//

    case 'smalldate':
      return (
        <div className='col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12'>
          <div className='form-group'>
            <label className='form-label auth__label'>{props.label}</label>
            <Field
              type={'date'}
              name={props.id}
              placeholder={props.placeholder}
              autoComplete='off'
              className='auth__input mt-4 form-control'
            />
            <ErrorMessage
              name={props.id}
              component='div'
              className='animate__animated animate__fadeIn animate__faster error-message'
            />
          </div>
        </div>
      )

    //-- DEFAULT ---------------------------------------------------------------//
    default:
      return (
        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12'>
          <div className='form-group'>
            <label className='form-label auth__label'>{props.label}</label>
            <Field
              type={props.type}
              name={props.id}
              id={props.id}
              placeholder={props.placeholder}
              value={props.value}
              autoComplete='off'
              className='auth__input mt-4 form-control'
            />
            <ErrorMessage
              name={props.id}
              component='div'
              className='animate__animated animate__fadeIn animate__faster error-message'
            />
          </div>
        </div>
      )
  }
}

FormInput.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  css: PropTypes.string,
  style: PropTypes.string,
  cssLabel: PropTypes.string,
  color: PropTypes.string,
  other: PropTypes.string,
  radioButton: PropTypes.array,
  dataOptions: PropTypes.array,
  onChange: PropTypes.func
}

FormInput.defaultProps = {
  type: 'text',
  color: '#1e3250',
  placeholder: '',
  radioButton: [],
  dataOptions: [],
  css: undefined
}

export default FormInput
