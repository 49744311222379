import React from 'react'
import { Segmented } from '../../../node_modules/antd/lib/index'

export const listObjectives = [
  {
    title: 'Impulsar la estrategia de desarrollo sostenible global actuando localmente',
    description: (
      <>
        <p>
          Siguiendo el llamado de las Naciones Unidas a las empresas a través de los Diez
          Principios, Pacto Global México está listo para pasar a la acción.
        </p>
        <p>
          Esta plataforma permite visibilizar las acciones que ya realiza el sector privado en
          México a favor de los Objetivos de Desarrollo Sostenible de la Agenda 2030 y de los
          Diez Principios del Pacto Global.
        </p>
        <p>
          <a
            style={{ color: '#699CC6' }}
            href='https://www.un.org/sustainabledevelopment/es/objetivos-de-desarrollo-sostenible/'
            target='_blank'>
            www.un.org/sustainabledevelopment/es/objetivos-de-desarrollo-sostenible/
          </a>
        </p>
      </>
    ),
    img: require(`./../../assets/img/ourObjectives/pmm-logra-assets-iconos-22.svg`)
  },
  {
    title: 'Compartir Buenas Prácticas',
    description: (
      <p>
        Apoyar a las empresas para conocer lo que otros están haciendo
        <br />
        para superar los retos globales y así elevar el impacto.
      </p>
    ),
    img: require(`./../../assets/img/ourObjectives/pmm-logra-assets-iconos-23.svg`)
  },
  {
    title: 'Aprender Buenas Prácticas',
    description: (
      <p>
        Condensar en un solo lugar soluciones creativas e innovadoras que se están
        <br />
        implementando en el país para contribuir a la sostenibilidad empresarial.
      </p>
    ),
    img: require(`./../../assets/img/ourObjectives/pmm-logra-assets-iconos-24.svg`)
  },
  {
    title: 'Comunidad',
    description: (
      <p>
        Incentivar la conformación de redes de apoyo que faciliten el intercambio de
        <br />
        soluciones prácticas y la conformación de alianzas en pro de la sostenibilidad
        <br />
        empresarial en nuestro país.
      </p>
    ),
    img: require(`./../../assets/img/ourObjectives/pmm-logra-assets-iconos-25.svg`)
  }
]
