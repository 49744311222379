import { put, call, takeLatest, delay } from 'redux-saga/effects'

import {
  usersListError,
  usersListSuccess,
  USERS_CREATE_FAILURE,
  USERS_GET_ALL_FAILURE,
  USERS_GET_ALL_REQUEST,
  USERS_GET_ALL_SUCCESS,
  USERS_GET_ONE_FAILURE,
  USERS_GET_ONE_REQUEST,
  USERS_GET_ONE_SUCCESS,
  USERS_REMOVE_FAILURE,
  USERS_FILTER_CHANGE_FAILURE,
  USERS_FILTER_CHANGE_REQUEST,
  USERS_FILTER_CHANGE_SUCCESS,
  USERS_NEW_USER_REQUEST,
  typesUsers,
  USERS_CREATE_SUCCESS,
  USERS_CREATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAILURE,
  USER_UPDATE_REQUEST
} from './users.redux'
import axios from 'axios'
import Swal from 'sweetalert2'
import { push } from 'connected-react-router'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import { manageError } from '../../redux/helpers/handlers.js'

function* usersListRequest() {
  try {
    const { data } = yield call(axios.get, '/api/profileUser')
    yield delay(process.env.REACT_APP_DELAY)
    yield put(usersListSuccess(data.data))
  } catch (error) {
    yield put(usersListError(error.message))
  }
}

export function* getAllUserSaga({ page = 1 }) {
  try {
    const { data } = yield call(axios.get, `/api/users?page=${page}`)
    yield put({ type: USERS_GET_ALL_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: USERS_GET_ALL_FAILURE, error: error.response })
  }
}

export function* getOneUserSaga({ payload }) {
  const _id = payload._id

  try {
    const { data } = yield call(axios.get, `/api/users/${_id}`)
    yield put(showLoading())
    yield delay(process.env.REACT_APP_DELAY)
    yield put(hideLoading())
    yield put({ type: USERS_GET_ONE_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: USERS_GET_ONE_FAILURE, error: error.response })
  }
}

export function* getUserFiltersSaga({ filters }) {
  try {
    filters.page = 1
    yield put({ type: USERS_FILTER_CHANGE_SUCCESS, payload: filters })
    yield put({ type: typesUsers.usersListRequest, filters })
  } catch (error) {
    yield put({ type: USERS_FILTER_CHANGE_FAILURE, error: error.response })
  }
}

export function* createUser({ payload }) {
  try {
    const url = 'api/user/'
    const insert = yield call(axios.post, url, payload)
    yield put({ type: USERS_CREATE_SUCCESS, payload: insert })
  } catch (error) {
    Swal.fire('Error', error, 'error')
    yield put({ type: USERS_CREATE_FAILURE, error: error.message })
  }
}

export function* updateUsersSaga({ payload }) {
  try {
    let formData = new FormData()
    formData.append('name', payload.name)
    formData.append('lastName', payload.lastName)
    formData.append('email', payload.email)
    formData.append('gender', payload.gender)
    formData.append('telephone', payload.telephone)
    formData.append('otherGender', payload.otherGender)
    formData.append('company', payload.company)
    formData.append('sector', payload.sector)
    formData.append('country', payload.country)
    formData.append('state', payload.state)
    formData.append('thumbnail', payload.imageFile)
    formData.append('city', payload.city)
    const insert = yield call(axios.put, '/api/users/' + payload._id, formData)
    yield put(showLoading())
    yield put({ type: USER_UPDATE_SUCCESS, payload: insert })
    yield delay(process.env.REACT_APP_DELAY)
    if (payload.imageFile) {
      yield put(push('/profile/' + payload._id))
      Swal.fire('Perfil actualizado.', '', 'success')
      yield put(window.location.reload())
    }
    yield put(push('/profile/' + payload._id))
    Swal.fire('Perfil actualizado', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put(hideLoading())
    yield put({ type: USER_UPDATE_FAILURE, error: error.response })
  }
}

export function* addNewUserSaga({ payload }) {
  try {
    yield put(showLoading())

    yield axios
      .post(`/api/user`, payload)
      .then(async () => {
        await Swal.fire(
          'Registro guardado',
          'El usuario se guardó correctamente...',
          'success'
        )
        window.open('/profile', '_self')
      })
      .catch(error => {
        Swal.fire('Error', 'AL guardar en la Base de Datos: ' + error, 'error')
      })

    yield delay(process.env.REACT_APP_DELAY)
    put(hideLoading())
  } catch (error) {
    Swal.fire('Error', 'Al conectar con el servidor' + error, 'error')
  }
}

export default function* usersSagas() {
  yield takeLatest(typesUsers.userListRequest, usersListRequest)
  yield takeLatest(USERS_GET_ALL_REQUEST, getAllUserSaga)
  yield takeLatest(USERS_GET_ONE_REQUEST, getOneUserSaga)
  yield takeLatest(USERS_FILTER_CHANGE_REQUEST, getUserFiltersSaga)
  yield takeLatest(USERS_CREATE_REQUEST, createUser)
  yield takeLatest(USERS_NEW_USER_REQUEST, addNewUserSaga)
  yield takeLatest(USER_UPDATE_REQUEST, updateUsersSaga)
  yield takeLatest(USERS_GET_ONE_FAILURE, manageError)
  yield takeLatest(USERS_FILTER_CHANGE_FAILURE, manageError)
  yield takeLatest(USERS_GET_ALL_FAILURE, manageError)
  yield takeLatest(USERS_REMOVE_FAILURE, manageError)
}
