import HomeDraft from 'components/Home/HomeDraft'
import React from 'react'
import HomeContainer from './../Home/HomeContainer'
import Layout from './../Layout/Layout'
function Home() {
  return (
    <div className='home-page' style={{ background: '#fff' }}>
      <Layout>
        <HomeContainer />
        <HomeDraft />
      </Layout>
    </div>
  )
}
export default Home
