import { put, call, takeLatest, delay, select } from 'redux-saga/effects'
import { typesSavedCards } from './cardSaved.redux.js'
import Swal from 'sweetalert2'
import axios from 'axios'
import { hideLoading, showLoading } from 'react-redux-loading-bar'
import { getUserId, encodeFiltersData } from '../../redux/helpers/handlers.js'

function* savedCardsCreateRequest(flashcard) {
  try {
    yield put(showLoading())
    const user = yield select(u => getUserId(u))
    flashcard.user = user
    const insert = yield call(axios.post, '/api/savedCards', flashcard)
    yield delay(process.env.REACT_APP_DELAY)
    yield put(hideLoading())
    yield put({ type: typesSavedCards.savedCardsCreateSuccess, payload: insert.data.data })
    Swal.fire('Tarjeta Guardada', '', 'success')
  } catch (error) {
    Swal.fire('Error', error.message, 'error')
    yield put({ type: typesSavedCards.savedCardsCreateFailure, error: error.response })
  }
}

function* savedCardsRemoveRequest({ flashcard }) {
  try {
    yield put(showLoading())
    const insert = yield call(axios.delete, `/api/savedCards/${flashcard}`)
    yield delay(process.env.REACT_APP_DELAY)
    yield put(hideLoading())
    yield put({ type: typesSavedCards.savedCardsRemoveSuccess, payload: insert })
    Swal.fire('Tarjeta sin guardar', '', 'success')
  } catch (error) {
    Swal.fire('Error', error.message, 'error')
    yield put({ type: typesSavedCards.savedCardsRemoveFailure, error: error.response })
  }
}

function* savedCardsGetAllRequest({ filters = {} }) {
  try {
    const user = yield select(u => getUserId(u))
    filters.user = user
    const filterString = encodeFiltersData(filters)
    const { data } = user
      ? yield call(axios.get, `/api/savedCards?${filterString}`)
      : { data: [] }
    yield put({
      type: typesSavedCards.savedCardsGetAllSuccess,
      payload: user ? data.data.docs : []
    })
  } catch (error) {
    Swal.fire('Error', error.message, 'error')
    yield put({ type: typesSavedCards.savedCardsGetAllFailure, error: error.response })
  }
}

function* savedCardsGetOneRequest({ filters = {} }) {
  try {
    const filterString = encodeFiltersData(filters)
    const { data } = yield call(axios.get, `/api/savedCards/all?${filterString}`)
    data.data.length > 0
      ? yield put({ type: typesSavedCards.savedCardsGetOneSuccess, payload: data.data[0] })
      : yield put({ type: typesSavedCards.savedCardsGetOneRemove })
  } catch (error) {
    Swal.fire('Error', error.message, 'error')
    yield put({ type: typesSavedCards.savedCardsGetOneFailure, error: error.response })
  }
}

export default function* cardSavedSagas() {
  yield takeLatest(typesSavedCards.savedCardsCreateRequest, savedCardsCreateRequest)
  yield takeLatest(typesSavedCards.savedCardsRemoveRequest, savedCardsRemoveRequest)
  yield takeLatest(typesSavedCards.savedCardsGetAllRequest, savedCardsGetAllRequest)
  yield takeLatest(typesSavedCards.savedCardsGetOneRequest, savedCardsGetOneRequest)
}
