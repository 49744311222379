import React from 'react'
import PropTypes from 'prop-types'
import HomeHead from './../Home/HomeHead'

export const Post = ({ title, content }) => {
  return (
    <div className='container container-light '>
      <div className='row animate__animated animate__fadeIn light post-paragraph'>
        {!content ? (
          <>
            <HomeHead title={title} type={2} />
            <div itemProp='articleBody post-paragraph'>
              <p className=''>
                <strong>Valores Corporativos Softtek, S.A. de C.V.</strong>,Lorem ipsum dolor
                sit amet, consectetur adipiscing elit. Vivamus iaculis sapien a felis porta, at
                faucibus velit sodales. Vestibulum vel neque nibh. In ac nibh quis mi ultricies
                tristique ac condimentum quam. Fusce aliquet varius nulla ac euismod. Nam nec
                feugiat velit. Sed imperdiet luctus maximus. Vivamus rutrum tortor ut arcu
                dictum iaculis. Ut hendrerit neque aliquet blandit malesuada. Sed in
                sollicitudin orci, non molestie augue. Quisque a mi sit amet risus interdum
                accumsan quis non erat. Nunc gravida eros ac mauris ultrices imperdiet. In
                felis massa, tempus nec pharetra ut, porttitor non velit. Phasellus in pharetra
                massa, id faucibus orci. Suspendisse eget nunc non tortor fermentum malesuada
                ac in dolor. Vestibulum a turpis eu ipsum dictum iaculis. Aliquam vel velit
                congue, hendrerit arcu ac, mattis urna.
                <a href='https://www.softtek.com/legal-disclaimer#terms'>terms of service.</a>
              </p>
              <br />
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus iaculis sapien a
              felis porta, at faucibus velit sodales. Vestibulum vel neque nibh. In ac nibh
              quis mi ultricies tristique ac condimentum quam. Fusce aliquet varius nulla ac
              euismod. Nam nec feugiat velit. Sed imperdiet luctus maximus. Vivamus rutrum
              tortor ut arcu dictum iaculis. Ut hendrerit neque aliquet blandit malesuada. Sed
              in sollicitudin orci, non molestie augue. Quisque a mi sit amet risus interdum
              accumsan quis non erat. Nunc gravida eros ac mauris ultrices imperdiet. In felis
              massa, tempus nec pharetra ut, porttitor non velit. Phasellus in pharetra massa,
              id faucibus orci. Suspendisse eget nunc non tortor fermentum malesuada ac in
              dolor. Vestibulum a turpis eu ipsum dictum iaculis. Aliquam vel velit congue,
              hendrerit arcu ac, mattis urna.
              <br />
              <br />
              <br />
            </div>
          </>
        ) : (
          <>
            <HomeHead title={'AVISO DE PRIVACIDAD'} type={2} />
            <HomeHead
              title={'DE LA RED DEL PACTO GLOBAL DE Y EN MÉXICO, A.C.'}
              marginTop={'-30px'}
              type={2}
            />
            <div itemProp='articleBody post-paragraph' className='articleBody'>
              <p>
                El presente Aviso de Privacidad (aviso) se emite en cumplimiento por lo
                dispuesto por el artículo 15 de la Ley Federal de Protección de Datos
                Personales en Posesión de los Particulares (LFPDPPP), de los Lineamientos
                Generales previstos en el artículo 43, en su fracción III de esta Ley y del
                artículo 23 de su Reglamento.
                <br />
                <br />
                Red del Pacto Global de y en México, A.C. tiene el compromiso jurídico legal y
                social de cumplir con las medidas legales y de seguridad para proteger sus
                datos personales con el objetivo de que usted tenga el conocimiento, control y
                decisión sobre ellos y para las finalidades que en el presente Aviso de
                Privacidad serán descritas.
                <br />
                <br />
                Por ello, lo invitamos a que lea detenidamente la siguiente información.
                <br />
                <br />
                1.) Datos del Responsable En su artículo 3, fracción XIV, de la LFPDPPP
                establece que el Responsable es: la; “Persona física o moral de carácter
                privado que decide sobre el tratamiento de datos personales.” La Red del Pacto
                Mundial de y en México, A.C. como Responsable del tratamiento de Datos
                Personales le comunica que es una Asociación Civil debidamente constituida de
                conformidad con las leyes de los Estados Unidos Mexicanos con domicilio en la
                calle de Montes Urales #440, Col. Lomas de Chapultepec, Delegación Miguel
                Hidalgo C.P. 11000 en la Ciudad de México, teléfono 554000-9748.
                <br />
                <br />
                2.) Datos Personales De conformidad con el artículo 3, fracción V, de la
                LFPDPPP son: “Datos personales: Cualquier información concerniente a una
                persona física identificada o identificable.” La Red del Pacto Global de y en
                México, A.C. recabará de Usted los datos personales necesarios para la adecuada
                prestación de nuestros servicios, este procedimiento puede ser de manera
                personal, directa o indirecta a través de nuestros Embajadores Regionales.
                Estos Datos Personales podrán incluir todos o algunos de los siguientes:
                <br />
                <br />
                3.) Datos de identificación: Nombre completo, domicilio, correo electrónico,
                teléfonos de casa, de trabajo, número de celular, Registro Federal de
                Contribuyentes (RFC), Clave Única de Registro Poblacional (CURP), lugar y fecha
                de nacimiento, edad.
                <br />
                <br />
                4.) Datos laborales: Ocupación, nombre de la empresa, institución o
                dependencia, puesto, área o departamento, domicilio, teléfono, correo
                electrónico de trabajo, referencias laborales y referencias personales, giro de
                la empresa, institución o dependencia.
                <br />
                <br />
                5.) Datos Financieros: N° de cuenta bancaria, CLABE, domicilio fiscal, número
                de tarjeta de crédito, fecha de vencimiento, así como el número de seguridad de
                la misma y que Usted utilice para adquirir alguno de nuestros servicios, al
                proporcionar estos datos usted reconoce que está otorgándonos su consentimiento
                expreso para el tratamiento de estos datos.
                <br />
                <br />
                6.) Datos Sensibles, de conformidad con el artículo 3, fracción VI de la
                LFPDPPP, se entiende como Datos personales sensibles: “Aquellos datos
                personales que afecten a la esfera más íntima de su titular, o cuya utilización
                indebida pueda dar origen a discriminación o conlleve un riesgo grave para
                éste. En particular, se consideran sensibles aquellos que puedan revelar
                aspectos como origen racial o étnico, estado de salud presente y futuro,
                información genética, creencias religiosas, filosóficas y morales, afiliación
                sindical, opiniones políticas, preferencia sexual.” En este caso la Red del
                Pacto Global de y en México, A.C. no recabará ni tratará datos personales
                sensibles.
                <br />
                <br />
                7.) Finalidades del Tratamiento de Datos Los Datos Personales obtenidos por la
                Red del Pacto Global de y en México, A.C serán utilizados para la realización
                de actividades filantrópicas relacionadas con nuestro Objeto Social, dentro de
                ellas, las siguientes: Convocarlo a Reuniones, Foros, Congresos y eventos
                encaminados a fomentar las relaciones entre Gobierno, Individuos y
                Organizaciones de la Sociedad Civil (OSC). Previa autorización, la Red del
                Pacto Global de y en México, A.C utilizará sus datos personales en la
                elaboración de Bases de Datos y Directorios de adheridos; así como la
                integración de Programas de Capacitación, Desarrollo institucional y de
                Asesoría y fortalecimiento en actividades filantrópicas y de aquellas
                relacionadas con la Responsabilidad Social Empresarial, el Voluntariado y
                actividades relacionadas con la Sostenibilidad Corporativa en general. Trabajos
                de análisis estadísticos y de Investigación sobre el Tercer Sector. El
                desarrollo de alianzas y relaciones interinstitucionales y de vinculación con
                organizaciones empresariales y civiles sin fines de lucro que atienden causas
                sociales en común que coadyuvan con el bienestar del Estado a través de
                Encuentros, Foros, Redes y Portales y con la participación de los, *Aliados
                Regionales, Usuarios, OSC y Miembros adheridos a la Red del Pacto Global de y
                en México, A.C.
                <br />
                <br />
                8.) Finalidades secundarias. Además, si usted no manifiesta lo contrario, la
                Red del Pacto Global de y en México, A.C tratará sus Datos Personales para las
                siguientes finalidades secundarias: Contactarlo para cualquier tema relacionado
                con nuestros servicios o con el presente Aviso de Privacidad. Efectuar
                promociones, y publicidad respecto de los productos y servicios de la Red del
                Pacto Global de y en México, A.C. La atención y procesamiento de solicitudes,
                la administración en actividades de cobranza, aclaración, investigación y
                facturación de cargos relacionados con nuestros miembros adheridos, usuarios o
                terceros que soliciten nuestros servicios. Llevar a cabo consultas,
                investigaciones y revisiones necesarias para la prestación de nuestros
                servicios a sus usuarios y en relación a sus quejas o peticiones. Cumplir las
                obligaciones derivadas de cualquier relación jurídica que se genere entre la
                Red del Pacto Global de y en México, A.C y Usted por la prestación de nuestros
                servicios que ofrecemos para el cumplimiento de leyes, reglamentos y
                disposiciones jurídicas y legales aplicables.
                <br />
                <br />
                9.) Transferencia de Datos Personales Si usted no manifiesta lo contrario la
                Red del Pacto Global de y en México, A.C requiere transferir sus Datos
                Personales en la medida que sea necesario para la prestación de nuestros
                servicios. En este sentido la Red del Pacto Global de y en México, A.C le
                informa que para poder prestar nuestros servicios, requerimos transferir dichos
                Datos Personales a: Empresas de mensajería, así como a nuestros embajadores
                Regionales, empresas y Organizaciones de la Sociedad Civil con los que la Red
                del Pacto Global de y en México, A.C mantiene vínculos de trabajo y
                colaboración, y operan bajo los mismos procesos y políticas de respeto a la
                privacidad, así como la oficina sede de Pacto Global de las Naciones Unidas en
                Nueva York. La Red del Pacto Global de y en México, A.C se compromete a velar
                porque se cumplan todos los principios legales de protección en torno a la
                transferencia de sus datos personales
                <br />
                <br />
                10.) Limitación en el Uso o Divulgación de los Datos Personales Usted puede
                limitar el uso o divulgación de sus datos personales para dejar de recibir
                nuestros servicios así como las promociones, ofertas y publicidad respecto de
                la Red del Pacto Global de y en México, A.C llamando al teléfono 4000-9748.
                <br />
                <br />
                11.) Derechos ARCO Estos derechos consisten en lo siguiente: 1. Acceso.-
                Implica conocer en todo momento sus datos personales en posesión de la Red del
                Pacto Global de y en México, A.C, así como el Aviso de privacidad
                correspondiente. 2. Rectificación.- Si alguno de sus datos es inexacto o
                incompleto, podrá solicitar su modificación, a nuestra administrativa al
                teléfono 554000-9714, adjuntando la documentación que acredite dicha
                corrección. 3. Cancelación.- Podrá requerir, cuando así lo considere, la
                cancelación de sus datos y, en caso de ser procedente, su información personal
                entrará en un periodo de bloqueo para proceder posteriormente a su eliminación.
                Al entrar a dicho periodo, su información ya no podrá ser tratada por la Red
                del Pacto Global de y en México, A.C., 4. Oposición.- Podrá en todo momento y
                por causa legítima objetar el tratamiento de sus datos personales. Si su
                solicitud resulta procedente, la Red del Pacto Global de y en México, A.C ya no
                podrá hacer uso de los mismos.
                <br />
                <br />
                12.) Procedimiento para ejercer sus Derechos ARCO El titular de los Datos
                Personales, por sí o mediante representante legal, debiendo identificarse o
                acreditarse correctamente, podrá ejercer sus derechos de Acceso, Rectificación,
                Cancelación y Oposición, así como revocar su consentimiento llamando a los
                números telefónicos de nuestra área administrativa al teléfono 554000-9748, o
                enviado un e-mail a{' '}
                <a href='mailto:contacto@pactomundial.org.mx'>
                  contacto@pactomundial.org.mx
                </a>{' '}
                describiendo de manera clara y precisa los datos personales respecto de los que
                se busca ejercer alguno de estos Derechos. Es importante señalar que en la
                llamada para ejercer los derechos ARCO usted deberá proporcionar una dirección
                de correo electrónico y número de teléfono a efecto de que podamos notificarle
                la respuesta a su solicitud o poder contactarlo en caso de requerir información
                adicional en relación con su petición. Asimismo, deberá enviarnos copia de su
                identificación oficial para acreditar su identidad. Por último, le informamos
                que se le responderá en un plazo máximo de 08 (ocho) días hábiles, contados
                desde la fecha en que cumpla con todos los requisitos establecidos en la
                solicitud de acceso, rectificación, cancelación u oposición, si resulta
                procedente, se hará efectiva dentro de los 08(ocho) días hábiles siguientes a
                la fecha en que se le comunique respuesta.
                <br />
                <br />
                13.) Cambios al Aviso de Privacidad Cualquier cambio significativo o total que
                se realice al presente Aviso de Privacidad podrá ser realizado por la Red del
                Pacto Global de y en México, A.C y será comunicado a través de nuestra página,
                o mediante correo electrónico a la dirección del titular que para tales efectos
                mantenga la Asociación registrada.
                <br />
                <br />
                14.) Departamento de Datos Personales
                <br />
                <b>Teléfono:</b> Área Servicios 5540009714.
                <br />
                <b>e-mail:</b>{' '}
                <a href='mailto:contacto@pactomundial.org.mx'>contacto@pactomundial.org.mx</a>
                <br /> <b>Horario:</b> de Lunes a Viernes de 9:00 a 17:00 hrs
                <br />
                <br />
                15.) Web Beacons Los sitios de Internet y servicios en línea la Red del Pacto
                Mundial de y en México, A.C se basan en el manejo de: Web Beacons Imágenes
                insertadas en páginas o portales de Internet así como en correos electrónicos y
                que entre otros pueden almacenar información de la dirección IP del usuario,
                tiempo de interacción en los sitios web, entre otros, que permiten a la Red del
                Pacto Global de y en México, A.C recabe automáticamente datos personales. Al
                ingresar y continuar con el uso del Sitio de Internet, usted consiente que la
                Red del Pacto Global de y en México, A.C recabe y trate sus datos personales.
                El uso de estas tecnologías es necesario por motivos de operación, técnicos y
                de seguridad. Red del Pacto Global de y en México, A.C tratará sus Datos
                Personales única y exclusivamente conforme a los términos del presente Aviso de
                Privacidad que dan origen a la relación entre la Red del Pacto Global de y en
                México, en su carácter de Entidad Responsable y usted como Titular de los datos
                personales. Usted, al proporcionar sus datos personales, confirma y acepta que
                ha leído este Aviso de Privacidad y otorga su consentimiento para ajustarse a
                lo señalado en el mismo.
                <br />
                <br />
                16) Definición de embajadores Regionales: Organizaciones Empresariales o de la
                Sociedad Civil con y sin fines de lucro y donatarias autorizadas que promueven
                y replican los programas de la Red del Pacto Global de y en México, A.C en los
                Estados de la República Mexicana y que conjuntamente coadyuvan en la
                construcción del bien público.
                <br />
                <br />
                17) Este sitio web habilita espacios donde los usuarios ingresan y cargan
                documentos de conformidad para mostrar sus mejores prácticas respecto al
                desarrollo sostenible cumpliendo con los Objetivos de Desarrollo Sostenible
                promovidos por las Naciones Unidas, mismas que serán públicas (una vez
                aprobadas por los administradores del sitio). Esta información no es alterada
                ni sustituida, queda tal cual el usuario sube su información.
                <br />
                <br />
                La información que comparten los usuarios a nombre de las empresas que
                representan, es pública y podrá ser consultada y/o comentada por los otros
                participantes de la plataforma. También podrá ser retomada como ejemplo para
                otras acciones o iniciativas. Los nombres de las personas que suben esta
                información como nombre, empresa, correo o cualquier otro dato sensible, no
                serán públicos por parte de la Red del Pacto Global de y en México A. C.
                <br />
                <br />
                Cualquier inconveniente o anomalía presentada en estos espacios de mejores
                prácticas empresariales deberá ser reportado al área de Servicios 5540009714.
                <br />
                <b>e-mail:</b>{' '}
                <a href='mailto:contacto@pactomundial.org.mx'>contacto@pactomundial.org.mx</a>
                <br />
                <b>Horario:</b> de Lunes a Jueves de 9:00 a 17:00 hrs.
              </p>
              <br />
              <br />{' '}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

Post.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired
}

Post.defaultProps = {
  title: '',
  content: ''
}

export default Post
