import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Container, Row } from 'reactstrap'
import manita from 'assets/img/manita.svg'

const HomeStepMetodology = ({ number, children, url, background }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const toggle = () => setTooltipOpen(!tooltipOpen)
  return (
    <div>
      <Container
        className='home-metodology '
        style={{ marginBottom: '-100px', background: '#f4f4f4' }}>
        <Row xs='1'>
          <Link to={url} style={{ color: 'white' }}>
            <div style={{ height: '12px' }} />
            {/* <img src={manita} alt='iconSvg' className='form-metodology-handy' /> */}
            <div
              id={`cardMetodology${number}`}
              className='center-col home-card-metodology'
              style={{ background }}>
              <div className='home-card-metodology-number'>{number}</div>
              {children}
            </div>
          </Link>
        </Row>
      </Container>
    </div>
  )
}

export default HomeStepMetodology
