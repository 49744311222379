import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Layout from '../Layout/Layout'
import { JoinActionRegister } from '../Join/JoinActionRegister'
import HeaderBanner from 'components/Header/HeaderBanner'
import imgBanner from 'assets/img/banners/share.webp'

export const PageRegisterAction = props => {
  return (
    <div className='gta__page'>
      <Layout>
        <>
          <HeaderBanner 
            img={imgBanner}
            title='COMPARTE ACCIONES'
            second='¡Logra el cambio, comparte tu experiencia!'
            description='Contribuye al desarrollo de estrategias.'
            paragraph='Las acciones en tu empresa pueden ser inspiración para otras y así elevaremos la ambición empresarial.'
          />
          <JoinActionRegister />
        </>
      </Layout>
    </div>
  )
}

PageRegisterAction.propTypes = {
  props: PropTypes
}

const mapStateToProps = state => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(PageRegisterAction)
