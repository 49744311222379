import React from 'react'
import CountUp from 'react-countup'
import { Row, Col } from 'reactstrap'
import {
  ContainerGraphOds,
  ContainerODS,
  NumberCounter,
  RowCounter,
  SpaceText,
  SubtitleGraphOds,
  TitleGraphOds,
  TitleText
} from './GraphOdsStyle'
import moment from 'moment'
import { Link } from 'react-router-dom'

const GraphCounter = ({ totalImplemented, title }) => {
  const today = moment().format('DD/MM/YYYY')

  return (
    <ContainerODS>
      <ContainerGraphOds>
        <TitleGraphOds>{title}</TitleGraphOds>
        <SubtitleGraphOds>Actualizado el {today}</SubtitleGraphOds>
      </ContainerGraphOds>
      <Link
        className='link-to-join animate__animated animate__fadeIn animate__faster'
        to='/good-practices-map'>
        <RowCounter>
          <Col>
            <NumberCounter>
              <CountUp
                className='home-count-number'
                start={0}
                end={totalImplemented}
                duration={0.5}
              />
            </NumberCounter>
          </Col>
          <Col>
            <SpaceText>
              {' '}
              <TitleText>Acciones</TitleText>
              <br />
              en nuestro banco{' '}
            </SpaceText>
          </Col>
        </RowCounter>
      </Link>
    </ContainerODS>
  )
}

export default GraphCounter
