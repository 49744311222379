import Home from './../Pages/Home'
import RegisterPage from './../Pages/PageRegister'
import PageLogin from './../Pages/PageLogin'
import Priorization from './../Pages/Priorization'
import Ranking from './../Pages/Ranking'
import RankingCompany from '../Pages/RankingCompany'
import RestorePwdPage from './../Pages/PageRestore'
import PageLegalDisclaimer from './../Pages/PageLegalDisclaimer'
import PagePrivacyNotice from './../Pages/PagePrivacyNotice'
import PageContact from './../Pages/PageContact'
import PageProfile from './../Pages/PageProfile'
import Implementation from './../Pages/Implementation'
import PageAbout from '../Pages/PageAbout'
import RecoverPwdPage from './../Pages/PageRecoverPwd'
import PageSolutionMap from './../Pages/PageSolutionMap'
import SavedCardsPage from './../Pages/PageSavedCards'
import Glossary from '../Pages/Glossary'
import cardRoutes from '../Cards/card.routes'
import PageActivate from '../Pages/PageActivate'
import PageProfileEdit from '../Pages/PageProfileEdit'
import PageRegisterAction from '../Pages/PageRegisterAction'
import PageJoinAction from '../Pages/PageJoinAction'
import PageGoodPracticesMap from 'components/Pages/PageGoodPracticesMap'
import SavedJoinPage from 'components/Pages/PageSavedJoin'
import PageMetodology from 'components/Pages/PageMetodology'
import ImplementationGuides from 'components/Pages/ImplementationGuides'
import { LinkedInCallback } from 'react-linkedin-login-oauth2'
import PageOurObjectives from 'components/Pages/PageOurObjectives'
import PageOne from 'pages/PageOne'
import PageCatalogue from 'pages/PageCatalogue'
import PageTwo from 'pages/PageTwo'
import PageFAQ from 'pages/PageFAQ'
import Grid from 'components/StyledComponents/Grid'
import PageInNumbers from 'components/Pages/PageInNumbers'
import PageHowParticipate from 'components/Pages/PageHowParticipate'
import PageSelectGoodPractice from 'pages/PageSelectGoodPractice'
import PageLickedJoin from 'components/Pages/PageLickedJoin'

export const routes = [
  ...cardRoutes,
  {
    path: '/',
    component: Home
  },
  {
    path: '/metodology',
    component: PageMetodology
  },
  {
    path: '/privacy-notice',
    component: PagePrivacyNotice
  },
  {
    path: '/our-objectives',
    component: PageOurObjectives
  },
  {
    path: '/page-one',
    component: PageOne
  },
  {
    path: '/page-two',
    component: PageTwo
  },
  {
    path: '/in-numbers',
    component: PageInNumbers
  },
  {
    path: '/page-faq',
    component: PageFAQ
  },
  {
    path: '/page-selection',
    component: PageSelectGoodPractice
  },
  {
    path: '/catalogo',
    component: PageCatalogue
  },
  {
    path: '/solution-map',
    component: PageSolutionMap
  },
  {
    path: '/good-practices-map',
    component: PageGoodPracticesMap
  },
  {
    path: '/legal-disclaimer',
    component: PageLegalDisclaimer
  },
  {
    path: '/join',
    component: Home //PageJoin
  },
  {
    path: '/register-action',
    component: PageRegisterAction
  },
  {
    path: '/good-practice/:_id',
    component: PageJoinAction
  },
  {
    path: '/register',
    component: RegisterPage
  },
  {
    path: '/contact',
    component: PageContact
  },
  {
    path: '/user/updatepassword/:token',
    component: RestorePwdPage
  },
  {
    path: '/recover',
    component: RecoverPwdPage
  },
  {
    path: '/login',
    component: PageLogin
  },
  {
    path: '/linkedin',
    component: LinkedInCallback
  },
  {
    path: '/priorization',
    component: Priorization
  },
  {
    path: '/ranking',
    component: Ranking
  },
  {
    path: '/profile/:_id',
    component: PageProfile
  },
  {
    path: '/edit/:_id',
    component: PageProfileEdit
  },
  {
    path: '/implementation',
    component: Implementation
  },
  {
    path: '/implementation-guides',
    component: ImplementationGuides
  },
  {
    path: '/how-participate',
    component: PageHowParticipate
  },
  {
    path: '/about',
    component: PageAbout
  },
  { path: '/saved-cards/:_id', component: SavedCardsPage },
  { path: '/my-initiatives/:_id', component: SavedJoinPage },
  { path: '/my-actions/:_id', component: PageLickedJoin },
  {
    path: '/rankingCompany',
    component: RankingCompany
  },
  {
    path: '/glossary',
    component: Glossary
  },
  {
    path: '/user/activate/:token',
    component: PageActivate
  },
  {
    path: '/grid',
    component: Grid
  }
]
