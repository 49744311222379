import React from 'react'
import logo from 'assets/img/logos/logoNav.webp'

export const SplashScreen = () => {
  return (
    <div className='spinner'>
      <img src={logo} className='screen-logo' alt='logo' />
      <span>Cargando</span>
      <div className='half-spinner'></div>
    </div>
  )
}
