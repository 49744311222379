// TYPES
export const REGISTER_CREATE_REQUEST = 'REGISTER_CREATE_REQUEST'
export const REGISTER_CREATE_SUCCESS = 'REGISTER_CREATE_SUCCESS'
export const REGISTER_CREATE_FAILURE = 'REGISTER_CREATE_FAILURE'
export const REGISTER_GET_ALL_REQUEST = 'REGISTER_GET_ALL_REQUEST'
export const REGISTER_GET_ALL_SUCCESS = 'REGISTER_GET_ALL_SUCCESS'
export const REGISTER_GET_ALL_FAILURE = 'REGISTER_GET_ALL_FAILURE'
export const REGISTER_REMOVE_REQUEST = 'REGISTER_REMOVE_REQUEST'
export const REGISTER_REMOVE_SUCCESS = 'REGISTER_REMOVE_SUCCESS'
export const REGISTER_REMOVE_FAILURE = 'REGISTER_REMOVE_FAILURE'
export const REGISTER_EDIT_REQUEST = 'REGISTER_EDIT_REQUEST'
export const REGISTER_EDIT_SUCCESS = 'REGISTER_EDIT_SUCCESS'
export const REGISTER_EDIT_FAILURE = 'REGISTER_EDIT_FAILURE'
export const REGISTER_GET_ONE_REQUEST = 'REGISTER_GET_ONE_REQUEST'
export const REGISTER_GET_ONE_SUCCESS = 'REGISTER_GET_ONE_SUCCESS'
export const REGISTER_GET_ONE_FAILURE = 'REGISTER_GET_ONE_FAILURE'
export const REGISTER_GET_CITIES_REQUEST = 'REGISTER_GET_CITIES_REQUEST'
export const REGISTER_GET_CITIES_SUCCESS = 'REGISTER_GET_CITIES_SUCCESS'
export const REGISTER_GET_CITIES_FAILURE = 'REGISTER_GET_CITIES_FAILURE'

export const typesRegister = {
  countryListRequest: '[ REGISTER ] COUNTRY_LIST_REQUEST',
  countryListSuccess: '[ REGISTER ] COUNTRY_LIST_SUCESS',
  countryListError: '[ REGISTER ] COUNTRY_LIST_ERROR',
  stateListRequest: '[ REGISTER ] STATE_LIST_REQUEST',
  stateListSuccess: '[ REGISTER ] STATE_LIST_SUCESS',
  stateListError: '[ REGISTER ] STATE_LIST_ERROR',
  registerListSectorRequest: '[ REGISTER ] REGISTER_LIST_SECTOR_REQUEST',
  registerListSectorSuccess: '[ REGISTER ] REGISTER_LIST_SECTOR_SUCESS',
  registerListSectorError: '[ REGISTER ] REGISTER_LIST_SECTOR_ERROR',
  registerListCompanyRequest: '[ REGISTER ] REGISTER_LIST_COMPANY_REQUEST',
  registerListCompanySuccess: '[ REGISTER ] REGISTER_LIST_COMPANY_SUCCESS',
  registerListCompanyError: '[ REGISTER ] REGISTER_LIST_COMPANY_ERROR',
  registerCreateRequest: '[ REGISTER ] REGISTER_CREATE_REQUEST',
  registerCreateSuccess: '[ REGISTER ] REGISTER_CREATE_SUCCESS',
  registerCreateError: '[ REGISTER ] REGISTER_CREATE_ERROR'
}
export const registersCreateRequest = ({ values }) => ({
  type: typesRegister.registerCreateRequest,
  payload: values
})
export const registersCreateSuccess = payload => ({
  type: typesRegister.registerCreateSuccess,
  payload
})
export const registersCreateFailure = payload => ({
  type: typesRegister.registerCreateError,
  payload
})
export const countryListRequest = payload => ({
  type: typesRegister.countryListRequest,
  payload
})
export const countryListSuccess = payload => ({
  type: typesRegister.countryListSuccess,
  payload
})
export const countryListError = payload => ({
  type: typesRegister.countryListError,
  payload
})
export const stateListRequest = payload => ({
  type: typesRegister.stateListRequest,
  payload
})
export const stateListSuccess = payload => ({
  type: typesRegister.stateListSuccess,
  payload
})
export const registerGetCitiesRequest = (country, state) => ({
  type: REGISTER_GET_CITIES_REQUEST,
  country,
  state
})
export const registerListError = payload => ({
  type: typesRegister.registerListError,
  payload
})
export const registerListSectorRequest = filters => ({
  type: typesRegister.registerListSectorRequest,
  filters
})
export const registerListSectorSuccess = payload => ({
  type: typesRegister.registerListSectorSuccess,
  payload
})
export const registerListSectorError = payload => ({
  type: typesRegister.registerListSectorError,
  payload
})
export const registerListCompanyRequest = filters => ({
  type: typesRegister.registerListCompanyRequest,
  filters
})
export const registerListCompanySuccess = payload => ({
  type: typesRegister.registerListCompanySuccess,
  payload
})
export const registerListCompanyError = payload => ({
  type: typesRegister.registerListCompanyError,
  payload
})

const initialState = {
  registerList: {},
  countryList: [],
  stateList: [],
  sectorList: [],
  companyList: {},
  conectAPI: false,
  loading: true,
  loader: false,
  error: '',
  all: [],
  one: {},
  cities: []
}

export function register(state = initialState, action) {
  switch (action.type) {
    case typesRegister.countryListRequest:
    case typesRegister.stateListRequest:
    case typesRegister.registerListSectorRequest:
    case typesRegister.registerCreateRequest:
      return { ...state, loading: true }
    case typesRegister.registerListSectorSuccess:
      return { ...state, loading: false, sectorList: action.payload }
    case typesRegister.registerListCompanySuccess:
      return { ...state, loading: false, companyList: action.payload }
    case typesRegister.registerCreateSuccess:
      return { ...state, error: null, loading: false, one: [action.payload] }
    case typesRegister.countryListSuccess:
      return { ...state, loading: false, countryList: action.payload, conectAPI: true }
    case typesRegister.countryListError:
      return { ...state, loading: false, countryList: action.payload, conectAPI: false }
    case typesRegister.stateListSuccess:
      return { ...state, loading: false, stateList: { ...action.payload } }
    case typesRegister.registerListSectorError:
    case typesRegister.stateListError:
    case typesRegister.registerListCompanyError:
    case typesRegister.registerCreateError:
      return { ...state, loading: false, error: action.error }
    case REGISTER_GET_ALL_SUCCESS:
      return { ...state, error: null, loading: false, all: { ...action.payload } }
    case REGISTER_GET_ALL_REQUEST:
      return { ...state, error: null, loading: true }
    case REGISTER_GET_ALL_FAILURE:
      return { ...state, loading: false, error: action.error }
    case REGISTER_REMOVE_SUCCESS:
      return { ...state, error: null, loading: false, one: { ...action.payload } }
    case REGISTER_REMOVE_REQUEST:
      return { ...state, error: null, loading: true }
    case REGISTER_REMOVE_FAILURE:
      return { ...state, loading: false, error: action.error }
    case REGISTER_EDIT_SUCCESS:
      return { ...state, error: null, loading: false, one: { ...action.payload } }
    case REGISTER_EDIT_REQUEST:
      return { ...state, error: null, loading: true }
    case REGISTER_EDIT_FAILURE:
      return { ...state, loading: false, error: action.error }
    case REGISTER_GET_ONE_SUCCESS:
      return { ...state, error: null, loading: false, one: { ...action.payload } }
    case REGISTER_GET_ONE_REQUEST:
      return { ...state, error: null, loading: true }
    case REGISTER_GET_ONE_FAILURE:
      return { ...state, loading: false, error: action.error }
    case REGISTER_GET_CITIES_SUCCESS:
      return { ...state, error: null, loading: false, cities: { ...action.payload } }
    case REGISTER_GET_CITIES_REQUEST:
      return { ...state, error: null, loading: true }
    case REGISTER_GET_CITIES_FAILURE:
      return { ...state, loading: false, error: action.error }
    default:
      return state
  }
}
