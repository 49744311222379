export const urlImgODS = {
  1: 'https://bspmpbs.blob.core.windows.net/climb-qa/aa286f00-8229-11ed-935a-7d701452b8aa-ODS1.jpg',
  2: 'https://bspmpbs.blob.core.windows.net/climb-qa/c26348b0-8229-11ed-935a-7d701452b8aa-ODS2.jpg',
  3: 'https://bspmpbs.blob.core.windows.net/climb-qa/d2870f10-8229-11ed-935a-7d701452b8aa-ODS3.jpg',
  4: 'https://bspmpbs.blob.core.windows.net/climb-qa/e2faa320-8229-11ed-935a-7d701452b8aa-ODS4.jpg',
  5: 'https://bspmpbs.blob.core.windows.net/climb-qa/f7bbe670-8229-11ed-935a-7d701452b8aa-ODS5.jpg',
  6: 'https://bspmpbs.blob.core.windows.net/climb-qa/0542bad0-822a-11ed-935a-7d701452b8aa-ODS6.jpg',
  7: 'https://bspmpbs.blob.core.windows.net/climb-qa/30a2db10-822a-11ed-935a-7d701452b8aa-ODS7.jpg',
  8: 'https://bspmpbs.blob.core.windows.net/climb-qa/4ea24c90-822a-11ed-935a-7d701452b8aa-ODS8.jpg',
  9: 'https://bspmpbs.blob.core.windows.net/climb-qa/7f6fbce0-822a-11ed-935a-7d701452b8aa-ODS9.jpg',
  10: 'https://bspmpbs.blob.core.windows.net/climb-qa/93835b10-822a-11ed-935a-7d701452b8aa-ODS10.jpg',
  11: 'https://bspmpbs.blob.core.windows.net/climb-qa/a08c9b00-822a-11ed-935a-7d701452b8aa-ODS11.jpg',
  12: 'https://bspmpbs.blob.core.windows.net/climb-qa/aca27a40-822a-11ed-935a-7d701452b8aa-ODS12.jpg',
  13: 'https://bspmpbs.blob.core.windows.net/climb-qa/baa7a660-822a-11ed-935a-7d701452b8aa-ODS13.jpg',
  14: 'https://bspmpbs.blob.core.windows.net/climb-qa/c67e59c0-822a-11ed-935a-7d701452b8aa-ODS14.jpg',
  15: 'https://bspmpbs.blob.core.windows.net/climb-qa/d2a41780-822a-11ed-935a-7d701452b8aa-ODS15.jpg',
  16: 'https://bspmpbs.blob.core.windows.net/climb-qa/e5968e40-822a-11ed-935a-7d701452b8aa-ODS16.jpg',
  17: 'https://bspmpbs.blob.core.windows.net/climb-qa/f2c1d520-822a-11ed-935a-7d701452b8aa-ODS17.jpg'
}
