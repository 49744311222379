import React from 'react'
import { Container, Row } from 'reactstrap'
import styled from 'styled-components'
import logo from './../../assets/img/GANADORES-2024.jpg'

const ImgLogra = styled.img`
  display: block;
  height: 350px;
  margin: 79px 0 34px 0;

  @media (min-width: 56px) and (max-width: 574px) {
    height: 110px !important;
    margin: 22px 0 4px 0;
  }

  @media (min-width: 576px) and (max-width: 991px) {
    height: 180px !important;
    margin: 37px 0 19px 0;
  }
  @media (min-width: 992px) and (max-width: 1400px) {
    height: 280px !important;
    margin: 118px 0 70px 0;
  }
`
const HomeTitleColabora2024 = () => {
  return (
    <Container className=' animate__animated animate__fadeIn animate__faster'>
      <Row className='home-row-ods-img'>
        <a
          href='https://pactoglobal.org.mx/empresas-ganadoras-premios-logra-2024'
          target='_blank'>
          <div className='home-ods-svg-div2'>
            <ImgLogra src={logo} />
          </div>
        </a>
      </Row>
    </Container>
  )
}

export default HomeTitleColabora2024
