import PostLegacyDisclaimer from 'components/Post/PostLegacyDisclaimer'
import React from 'react'
import Layout from './../Layout/Layout'

const PageLegalDisclaimer = () => {
  return (
    <Layout>
      <PostLegacyDisclaimer title='Legal Disclaimer' content='Contenido' />
    </Layout>
  )
}
export default PageLegalDisclaimer
