// @ts-nocheck
import React, { useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { faStar, faBookmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { Container, Row, Col } from 'reactstrap'

import CardDetailBack from './CardDetailBack.js'
import CardDetailFront from './CardDetailFront.js'
import {
  cardGetOneRequest,
  cardsLikeRequest,
  cardsUnlikeRequest,
  cardsLikesRequest
} from './cards.redux.js'
// import CardMessages from './CardMessages'
import PriorizationDetail from './PriorizationDetail'
import { commentsGetAllRequest } from '../Comments/comments.redux.js'
import { priorizationGetAverageRequest } from './Priorization.redux'
import { SplashScreen } from '../SplashScreen/SplashScreen.js'
import { getRating } from '../../utils/priorization.js'
import {
  savedCardsCreateRequest,
  savedCardsGetOneRequest,
  savedCardsRemoveRequest
} from './cardSaved.redux.js'
import CardDetailBackTwo from './CardDetailBackTwo.js'
import {
  CardDetailContainer,
  CardDetailDivActions,
  CardDetailDivFavorite,
  CardDetailDivPriorization,
  CardDetailImageGta,
  CardDetailLine,
  CardDetailWorkGroup
} from './CardStyled.js'

function CardDetail({
  cardGetOneRequest,
  cardsLikesRequest,
  commentsGetAllRequest,
  savedCardsGetOneRequest,
  priorizationGetAverageRequest,
  ...props
}) {
  const { savedCardsCreateRequest, savedCardsRemoveRequest, savedCard, user, match, cards } =
    props
  const { _id } = match?.params
  const likes = cards?.likes

  useEffect(() => {
    cardGetOneRequest({ _id })
    cardsLikesRequest({ flashcard: _id, user: user?._id || '' })
    commentsGetAllRequest({ flashcard: _id })
    user?._id && savedCardsGetOneRequest({ flashcard: _id, user: user?._id })
    priorizationGetAverageRequest()
  }, [
    priorizationGetAverageRequest,
    cardGetOneRequest,
    cardsLikesRequest,
    commentsGetAllRequest,
    savedCardsGetOneRequest,
    user,
    _id
  ])

  likes.unlikeFunction = props.cardsUnlikeRequest
  likes.likeFunction = props.cardsLikeRequest
  likes.loading = cards.loading

  const card = props.one || {}
  const { gta, workAxis, name, number } = card

  const comments = props.comments.docs || []
  const state = useSelector(state => state)
  const { isLogin } = state?.auth
  const iconEye = (
    <FontAwesomeIcon
      style={{ fontSize: '22px' }}
      className='icon-save-card'
      color={gta?.color}
      icon={faStar}
    />
  )
  const icon_Star = <FontAwesomeIcon color='#ffe200' icon={faStar} />
  const iconBookmark = <FontAwesomeIcon size='3x' color='#aaa' icon={faBookmark} />
  const iconBookmarkActive = <FontAwesomeIcon size='3x' color={gta?.color} icon={faBookmark} />
  const priorizationAvg = props.average || {}
  const filterAverage = priorizationAvg?.docs
    ?.map(a => a)
    .filter(f => f._id.flashcardId === card._id)
  const getLength = filterAverage?.map(a => a).length
  return !name ? (
    <SplashScreen />
  ) : (
    <>
      <div
        className='container card__detail-container animate__animated animate__fadeIn'
        style={{ marginTop: '10px', backgroundColor: 'white' }}>
        <CardDetailContainer>
          <div className='row animate__animated animate__fadeIn'>
            <div className='col-12'>
              <div className='Rectngulo-141'>
                <span className='card-detail-number'>
                  #{gta?.nomenclature}
                  {number}
                </span>
                <span
                  className='Contratar-externosas-para-evaluar-los-procesos-de-reclutamiento-con-perspectiva-de-gnero'
                  style={{ color: gta?.color }}>
                  {name}
                </span>
              </div>
            </div>
          </div>

          <div className='row animate__animated animate__fadeIn animate__faster'>
            <div className='col-3'>
              <Container>
                <Row>
                  <CardDetailImageGta
                    src={
                      gta?.logoColor
                        ? gta?.logoColor
                        : require(`./../../assets/img/spinner.gif`)
                    }
                  />
                </Row>
                <Row>
                  <CardDetailWorkGroup color={gta?.color}>
                    Grupo de Trabajo
                  </CardDetailWorkGroup>
                  <CardDetailWorkGroup color={gta?.color}>{gta?.title}</CardDetailWorkGroup>
                </Row>
                <CardDetailLine color={gta?.color} />
                <CardDetailWorkGroup color={gta?.color}>
                  {workAxis?.objective}
                </CardDetailWorkGroup>
                <CardDetailDivPriorization>
                  <div className='Rectngulo-420'>
                    <span className='Priorizacin'>
                      {getLength === 0 ? 'Sin priorizar' : 'Priorización'}
                    </span>
                    {filterAverage?.map(a => getRating(a.total, icon_Star))}
                  </div>
                  <div className='card_see-more'>
                    <PriorizationDetail {...props} />
                  </div>
                </CardDetailDivPriorization>
                <Link to={`/saved-cards/${user?._id}`}>
                  <CardDetailDivFavorite>
                    <div className='Trazado-562'>{iconEye}</div>
                    <span className='ir-favoritos-tarjeta'>Ir a mis favoritas</span>
                  </CardDetailDivFavorite>
                </Link>
                {isLogin && (
                  <CardDetailDivActions>
                    {savedCard?._id ? (
                      <div onClick={() => savedCardsRemoveRequest(savedCard?._id)}>
                        <div className='Trazado-563'>
                          <button className='card-detail-button-save-card'>
                            {iconBookmarkActive}
                          </button>
                        </div>
                        <span className='Guardar-tarjeta'>Agragada a mis favoritas</span>
                      </div>
                    ) : (
                      <div onClick={() => savedCardsCreateRequest(_id)}>
                        <div className='Trazado-563'>
                          <button className='card-detail-button-save-card'>
                            {iconBookmark}
                          </button>
                        </div>
                        <span className='Guardar-tarjeta'>Agregar a mis favoritas</span>
                      </div>
                    )}
                  </CardDetailDivActions>
                )}
              </Container>
            </div>
            {/* FLASHCARD Y CHAT */}
            <div className='col-9 animate__animated animate__fadeIn animate__faster card-detail-priorization'>
              {/* FLASHCARD */}
              <div className='row card-full'>
                <CardDetailFront data={card} />
                <CardDetailBack data={card} />
                <CardDetailBackTwo data={card} />
              </div>
              {/* <CardMessages
                messages={comments}
                user={user?._id}
                idCard={_id}
                color={gta?.color}
                likes={likes}
              /> */}
            </div>
          </div>
        </CardDetailContainer>
      </div>
    </>
  )
}
const mapStateToProps = state => ({
  cards: state.cards,
  user: state.user,
  login: state.auth.login,
  auth: state.auth.user,
  one: state.cards.one,
  savedCard: state.savedCards.one,
  loading: state.cards.loading,
  average: state.priorizations.average,
  comments: state.comments.all
})
const mapDispatchToProps = {
  cardGetOneRequest,
  cardsLikesRequest,
  commentsGetAllRequest,
  priorizationGetAverageRequest,
  savedCardsCreateRequest,
  savedCardsGetOneRequest,
  savedCardsRemoveRequest,
  cardsLikeRequest,
  cardsUnlikeRequest
}
export default connect(mapStateToProps, mapDispatchToProps)(CardDetail)
