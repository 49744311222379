import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Layout from '../Layout/Layout'
import HeaderBanner from 'components/Header/HeaderBanner'
import cifras from 'assets/img/banners/cifras.webp'
import GraphOds from 'components/Numbers/GraphOds'
import GraphAxis from 'components/Numbers/GraphAxis'
import GraphCounter from 'components/Numbers/GraphCounter'
import { actionGetTotals } from 'components/Join/joinAction.redux'
import PodiumLikes from 'components/Numbers/PodiumLikes'

export const PageInNumbers = ({ totalImplemented, actionGetTotals, props }) => {
  useEffect(() => {
    actionGetTotals()
  }, [actionGetTotals])

  return (
    <div className='gta__page'>
      <Layout>
        <HeaderBanner title='EN CIFRAS' img={cifras} />
        <PodiumLikes title={'Podium'} />
        <GraphCounter totalImplemented={totalImplemented} title='CONTADOR DE ACCIONES' />
        <GraphOds title='ACCIONES POR OBJETIVO DE DESARROLLO SOSTENIBLE' />
        <GraphAxis title='ACCIONES POR EJES DE PACTO GLOBAL' />
      </Layout>
    </div>
  )
}

PageInNumbers.propTypes = {
  props: PropTypes
}

const mapStateToProps = ({ joinActions }) => ({
  totalImplemented: joinActions.totals.totalAction
})

const mapDispatchToProps = {
  actionGetTotals: actionGetTotals
}

export default connect(mapStateToProps, mapDispatchToProps)(PageInNumbers)
