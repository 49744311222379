import React from 'react'
import Layout from './../Layout/Layout'
import Profile from './../Users/Profile'
const PageProfile = () => {
  return (
    <Layout>
      <Profile />
    </Layout>
  )
}
export default PageProfile
