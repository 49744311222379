import React from 'react'

function GlossaryDescription() {
  return (
    <div className='row glossary-general_description'>
      <div className='col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12'>
        <h3 className='glossary-subtitle'>Mapeo de Soluciones</h3>
        <p className='glossary-description'>
          Una metodología para implementar acciones empresariales sobre los{' '}
          <i>Objetivos de Desarrollo Sostenible</i> de la Agenda 2030 a través del mapeo de
          soluciones (Flashcards CLIMB), su priorización y su implementación. <br />
          Esta metodología tiene 3 objetivos:
          <ul>
            <li>Sumar la inteligencia</li>
            <li>Ordenar la agenda</li>
            <li>Implementar acciones</li>
          </ul>
          Esta metodología plantea que para que exista un cambio sistémico dentro de las
          organizaciones se requieren 5 condiciones CLIMB* <br />
          <ul>
            <li>C – Compromiso del CEO y de la Alta Dirección</li>
            <li>L – Desarrollo de liderazgo de mandos medios y altos</li>
            <li>I – Infraestructura</li>
            <li>M – Métricas (Indicadores)</li>
            <li>B – Conducta y comportamiento</li>
          </ul>
          *McKinsey, One aspiration, two realities, promoting gender equality in Mexico, 2018
        </p>
      </div>
      <div className='col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12'>
        <h3 className='glossary-subtitle'>Priorización</h3>
        <p className='glossary-description'>
          A partir del compilado de soluciones, la priorización permite que el Grupo de Trabajo
          elija qué soluciones empresariales quiere implementar. <br />
          La priorización se realiza con base en dos criterios. Impacto:
          <ul>
            <li>Cobertura (mayor número de beneficiarios)</li>
            <li>Beneficio externo (beneficio a la comunidad)</li>
            <li>Beneficio interno (beneficio para la empresa en términos de reputación)</li>
            <li>Escalabilidad (qué tan replicable es)</li>
          </ul>
          Facilidad de implementación:
          <ul>
            <li>Costo (menor costo)</li>
            <li>Recursos humanos disponibles</li>
            <li>Infraestructura disponible (tanto material como intangible)</li>
          </ul>
        </p>
      </div>
      <div className='col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12'>
        <h3 className='glossary-subtitle'>Implementación</h3>
        <p className='glossary-description'>
          Implementar las Flashcards CLIMB que fueron priorizadas.
          <br />A través de un semáforo de implementación se muestra el estatus de
          implementación por empresa de las Flashcards priorizadas{' '}
        </p>
      </div>
    </div>
  )
}

export default GlossaryDescription
