import React from 'react'
import styled, { css } from 'styled-components'

export const Container = styled.div.attrs({
  className: 'container'
})`
  position: static;
  color: #1e3250;
  font-weight: normal !important;
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
  ${props =>
    props.marginTop &&
    css`
      margin-top: ${props.marginTop};
    `}
  ${props =>
    props.marginBottom &&
    css`
      margin-bottom: ${props.marginBottom};
    `}
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${props =>
    props.textAlign &&
    css`
      text-align: ${props.textAlign};
    `}
  ${props =>
    props.color &&
    css`
      color: ${props.color};
    `}
`

export const Circle = styled.div`
  width: 48px;
  height: 48px;
  line-height: 48px;
  border-radius: 50%;
  font-size: 28px;
  color: #0a121d;
  text-align: center;
  font-weight: 900;
  background: #699cc6;
  left: 8.5%;
  z-index: 1;
  ${props =>
    props.topCircle &&
    css`
      top: ${props.topCircle};
    `}
    @media only screen and (max-width: 640px) {
    position: relative;
    top: 45px;
    left: -19px;
  }
`

export const Rectangle = styled.div`
  width: 100%;
  height: auto;
  font-size: 20px;
  color: #1e3250;
  text-align: left;
  background: #dceaf4;
  margin-left: -80px;
  margin-top: 15px;
  left: 10%;
  z-index: 0;
  line-height: 1.25;
  padding: 10px 40px;
  @media only screen and (max-width: 640px) {
    margin: 15px 0;
  }
`
export const ParagraphSpace = styled.p`
  font-size: 28px;
  font-weight: normal;
  padding: 10px 20%;
  @media only screen and (max-width: 640px) {
    font-size: 20px;
    padding: 5px;
  }
`

export const Paragraph = styled.p`
  font-size: 28px;
  font-weight: normal;
  @media only screen and (max-width: 640px) {
    font-size: 20px;
  }
`

export const ParagraphNote = styled.p`
  font-size: 28px;
  font-weight: normal;
  @media only screen and (max-width: 640px) {
    text-align: center;
    font-size: 20px;
  }
`

export const DivLine = styled.div`
  width: 10px;
  height: 90%;
  background: #699cc6;
`
export const HowParticipateContainer = styled.section`
  background-color: #f2f2f2;
  @media only screen and (max-width: 640px) {
    padding:0 10px 0 20px;
  }
`

export const DarkContainer = styled.div`
  width: 100vw;
  height: auto;
  text-align: center;
  padding: 5% 17%;
  @media only screen and (max-width: 640px) {
    padding: 25px 20px 25px 40px;
  }
`
export const Title = styled.h4`
  color: #699cc6;
  margin-left: 15%;
  margin-top: 2%;
  @media only screen and (max-width: 640px) {
    margin: 10% 10% 0 10%;
  }
`
