import React from 'react'
import PropTypes from 'prop-types'
import {
  AboutLogo,
  AboutSecondContainer,
  AboutText,
  AboutSecondText,
  AboutLink,
  Columna
} from './aboutStyleComponents'
import logo from 'assets/img/about/logoGrupos.svg'

const AboutSection = ({ text, secondtext }) => {
  return (
    <AboutSecondContainer>
      <AboutText>{text}</AboutText>
      <div className='row'>
        <Columna md='12' sm='12'>
          <AboutSecondText>{secondtext}</AboutSecondText>
        </Columna>
        {/* <Columna md='6' sm='6'>
          <AboutLink href='https://www.pactomundial.org.mx/gta2030/' target='_blank'>
            <AboutLogo src={logo} />
          </AboutLink>
        </Columna> */}
      </div>
    </AboutSecondContainer>
  )
}

AboutSection.propTypes = {
  text: PropTypes.string.isRequired,
  secondtext: PropTypes.string
}

AboutSection.defaultProps = {
  text: 'LOGRA es un espacio en donde todas las empresas comparten y consultan acciones en favor de los Objetivos de Desarrollo Sostenible (ODS) para ampliar el impacto positivo del sector privado en la gente y en la naturaleza.',
  secondtext: (
    <p>
      En 2021, Pacto Global México publicó el estudio “Las Empresas Mexicanas por los ODS en la
      década de acción” con el objetivo de evaluar el nivel de avance de las empresas mexicanas
      a las metas de la Agenda 2030, así como dar visibilidad a los esfuerzos que el sector
      privado está realizando, y compartir el conocimiento entre sectores. <br />
      <br />
      LOGRA es una plataforma viva que permite mantenernos actualizados sobre los esfuerzos que
      la comunidad empresarial suma día con día por consolidar un país más justo, inclusivo,
      responsable y sostenible.
    </p>
  )
}

export default AboutSection
