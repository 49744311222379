import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { SplashScreen } from './../SplashScreen/SplashScreen.js'
import ReactElasticCarousel from 'react-elastic-carousel'
import { Card } from '../Cards/Card.js'
import RankingFilterBar from './RankingFilterBar'
import { rankingGetRankingList } from './ranking.redux'
import CircleLoader from './CircleLoader'

export const CardsSlider = ({
  filters,
  cardsList,
  loading,
  type,
  ranking,
  loader,
  rankingGetRankingList,
  ...props
}) => {
  const handleChange = values => {
    rankingGetRankingList({ values })
  }

  useEffect(
    values => {
      if (!rankingGetRankingList({ values })) {
        return rankingGetRankingList()
      }
    },
    [rankingGetRankingList]
  )

  const rankingFiltered = ranking?.data?.data || []
  const rankingLength = rankingFiltered.length

  const aproveList =
    cardsList && cardsList.docs?.filter(item => item.status === 2 && item.active === true)

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1000, itemsToShow: 4 },
    { width: 1500, itemsToShow: 5 },
    { width: 1800, itemsToShow: 6 },
    { width: 2100, itemsToShow: 7 },
    { width: 2500, itemsToShow: 8 },
    { width: 2800, itemsToShow: 9 },
    { width: 3100, itemsToShow: 10 }
  ]

  return (
    <>
      {loading ? (
        <SplashScreen />
      ) : (
        <div className='row container' style={{ marginLeft: '0px' }}>
          {type !== 'home' && (
            <RankingFilterBar
              handleChange={handleChange}
              type='rankingCardsCompany'
              theme='dark'
            />
          )}

          <div
            className='cards__slider-rows'
            style={{ marginTop: '25px', marginBottom: '215px' }}>
            <div className='cards__div-slider'>
              {loader ? (
                <CircleLoader />
              ) : (
                <ReactElasticCarousel breakPoints={breakPoints}>
                  {rankingLength === 0
                    ? aproveList && type === 'home'
                      ? aproveList?.map(card => (
                          <div style={{ paddingTop: '10px' }}>
                            <Card key={card._id} data={card} />
                          </div>
                        ))
                      : aproveList?.map(card => (
                          <div style={{ paddingTop: '10px' }}>
                            <Card key={card._id} data={card} />
                          </div>
                        ))
                    : rankingFiltered && type === 'home'
                    ? rankingFiltered.map(card => (
                        <div style={{ paddingTop: '10px' }}>
                          <Card key={card._id} data={card} />
                        </div>
                      ))
                    : rankingFiltered.map(card => (
                        <div style={{ paddingTop: '10px' }}>
                          <Card key={card._id} data={card} />
                        </div>
                      ))}
                </ReactElasticCarousel>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const mapStateToProps = ({ cards, rankings }) => ({
  cardsList: cards.cardsList,
  loading: cards.loading,
  error: cards.error,
  filters: cards.filters,
  ranking: rankings.ranking,
  loader: rankings.loader
})

const mapDispatchToProps = {
  rankingGetRankingList: rankingGetRankingList
}
export default connect(mapStateToProps, mapDispatchToProps)(CardsSlider)
