import { put, call, takeLatest, delay, select } from 'redux-saga/effects'
import Swal from 'sweetalert2'
import axios from 'axios'
import {
  COMMENTS_GET_ALL_REQUEST,
  COMMENTS_GET_ALL_FAILURE,
  COMMENTS_GET_ALL_SUCCESS,
  COMMENTS_CREATE_SUCCESS,
  COMMENTS_CREATE_FAILURE,
  COMMENTS_CREATE_REQUEST,
  COMMENTS_EDIT_SUCCESS,
  COMMENTS_EDIT_REQUEST,
  COMMENTS_REMOVE_SUCCESS,
  COMMENTS_REMOVE_FAILURE,
  COMMENTS_REMOVE_REQUEST
} from './comments.redux.js'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import { encodeFiltersData, getUserId, manageError } from './../../redux/helpers/handlers.js'

function* getAllCommentsSaga({ filters = { page: 1 } }) {
  try {
    const filterString = encodeFiltersData(filters)
    const { data } = yield call(axios.get, `/api/comments/?active=true&${filterString}`)
    yield delay(process.env.REACT_APP_DELAY)
    yield put({ type: COMMENTS_GET_ALL_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: COMMENTS_GET_ALL_FAILURE, error: error.response })
  }
}

function* createCommentsSaga({ payload }) {
  try {
   
    yield put(showLoading())
    const user = yield select(u => getUserId(u))
    payload.createdBy = user
    if (user === undefined)
    return Swal.fire(
      'Aviso',
      'Debes iniciar sesión para poder comentar una flashcard',
      'warning'
    )
    const insert = yield call(axios.post, '/api/comments/', payload)
    yield put({ type: COMMENTS_CREATE_SUCCESS, payload: insert })
    if (insert.data.code === 400) {
      Swal.fire('Alerta', insert.data.message, 'info')
    } else {
      Swal.fire('Comentario Guardado', '', 'success')
      yield put({ type: COMMENTS_GET_ALL_REQUEST, filters: { flashcard: payload.flashcard } })
    }
    yield put(hideLoading())
  } catch (error) {
    yield put(hideLoading())

    yield put({ type: COMMENTS_CREATE_FAILURE, error: error.response })
  }
}

function* editCommentsSaga({ payload }) {
  try {
    const insert = yield call(axios.put, '/api/comments/' + payload._id, payload)
    yield put({ type: COMMENTS_EDIT_SUCCESS, payload: insert })
    yield delay(process.env.REACT_APP_DELAY)
    // yield put(push('/'))
    Swal.fire('Registro actualizado', '', 'success')
    yield put(hideLoading())
  } catch (error) {
    yield put(hideLoading())
    yield put({ type: COMMENTS_CREATE_FAILURE, error: error.response })
  }
}

function* removeCommentsSaga({ payload }) {
  try {
    yield put(showLoading())
    const insert = yield call(axios.delete, '/api/comments/' + payload.id)
    yield put({ type: COMMENTS_REMOVE_SUCCESS, payload: insert })
    yield put({ type: COMMENTS_GET_ALL_REQUEST })
    yield delay(process.env.REACT_APP_DELAY)
    // yield put(push('/'))
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: COMMENTS_REMOVE_FAILURE, error: error.response })
  }
}

export default function* commentsSagas() {
  yield takeLatest(COMMENTS_GET_ALL_REQUEST, getAllCommentsSaga)
  yield takeLatest(COMMENTS_CREATE_REQUEST, createCommentsSaga)
  yield takeLatest(COMMENTS_EDIT_REQUEST, editCommentsSaga)
  yield takeLatest(COMMENTS_REMOVE_REQUEST, removeCommentsSaga)
  yield takeLatest(COMMENTS_GET_ALL_FAILURE, manageError)
  yield takeLatest(COMMENTS_REMOVE_FAILURE, manageError)
}
