import styled from 'styled-components'

export const ContainerJoin = styled.section.attrs({})`
  background-color: #f4f4f4;
  border-radius: 2px;
  padding: 55px 20vw;
  color: #000;
  width: 100%;
  margin-right: 0px;
  margin-top: 6px;
  display: flex;
  @media only screen and (max-width: 640px) {
    padding: 25px;
  }
`

export const ContainerJoinTitle = styled.section.attrs({})`
  padding: 1.8rem 4rem 1rem 4rem;
  @media (max-width: 768px) {
    padding-left: 0vw;
    max-width: 100vw;
  }
  h1 {
    font-family: 'Flame', 'Roboto', Arial, sans-serif;
    font-size: 40px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #709ec9;
  }
  h5 {
    font-family: 'Flame', 'Roboto', Arial, sans-serif;
    font-size: 20px;
    font-weight: 100;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
  }
`

// Tarjeta de banco de acciones
export const ImageActionCard = styled.img`
  height: 80px;
  width: 100%;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
`
// Tarjeta de banco de acciones
export const BannerImageJoin = styled.img`
  height: 250px;
  width: 100%;
  object-fit: cover;
`

export const JoinCardContainer = styled.section.attrs({
  className: 'col-md-3 cards__card-dimension animate__animated animate__fadeIn animate__faster'
})`
  max-width: 355px;
  min-width: 355px;
  margin-bottom: 28px;
  margin-right: 18px;
`

export const JoinCardHeader = styled.section`
  background: #141b28;
  box-shadow: 7px 6px 11px #2727273d;
  min-height: 80px;
  border-radius: 8px 8px 0 0;
`
export const JoinCardContent = styled.section`
  background: ${({ color }) => color || '#141b28'};
  color: white;
  font-weight: bold;
  text-align: center;
  box-shadow: 7px 6px 11px #2727273d;
  line-height: 18px;
  min-height: 75px;
  font-size: 16px;
  padding: 5px 10px;
  display: grid;
  align-items: center;
  justify-items: center;
  overflow: hidden;
`

export const JoinCardFooter = styled.section`
  background: #141b28;
  height: 81px;
  display: inline-flex;
  width: 100%;
  box-shadow: 7px 6px 11px #2727273d;
  border-radius: 0 0 8px 8px;
`

export const JoinCardFooterLeft = styled.section`
  float: left;
  padding: 6px 0px;
  width: 100px;
  color: white;
  text-transform: uppercase;
`

export const JoinCardFooterLeftImage = styled.img`
  height: 60px;
  width: 100%;
  margin: 5px 0 0 -8px;
  z-index: 1;
`

export const JoinCardFooterRightText = styled.text`
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
`
export const JoinCardFooterRightP = styled.p`
  padding-top: 10px;
`

export const JoinCardFooterRight = styled.section`
  float: right;
  font-size: 11px;
  font-weight: 400;
  width: 211px;
  display: grid;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: end;
  justify-items: end;
  text-align: right;
  color: white;
  text-transform: uppercase;
`
export const BannerNameJoin = styled.div`
  background: ${({ color }) => color || '#141b28'};
  padding: 10px;
  text-align: center;
  color: white;
  font-size: 24px;
  font-weight: 400;
`

export const TitleParrafJoin = styled.h5`
  color: ${({ color }) => color || '#141b28'};
  padding: 10px 0 0 0;
  font-weight: 700;
  margin: 0;
`

export const DataParrafJoin = styled.p`
  color: #29426b;
  font-weight: 400;
`
export const ContainerJoinFirst = styled.div`
  padding: 40px 30px;
`

export const ContainerJoinSecond = styled.div`
  padding: 80px;
  display: grid;
`

export const OdsLogoJoin = styled.img`
  width: 70%;
  margin: auto;
  margin-bottom: 25px;
`

export const LogoJoin = styled.img`
  width: 70%;
  margin: auto;
`
export const RowButtons = styled.div`
  display: flex;
  margin: 40px 60px 10px 60px;
  justify-content: space-between;
  @media (max-width: 576px) {
    margin: 10px 0;
  }
`

export const BtnVolunteers = styled.button`
  font-size: 17px;
  font-weight: 600;
  color: #f5f5f5;
  border-radius: 48px;
  background: #699cc6;
  border-color: #699cc6;
  padding: 5px 40px;
  &:hover {
    border-color: transparent;
    color: #699cc6;
    background: white;
  }
  @media (max-width: 576px) {
    font-size: 12px;
  }
`

export const BtnLike = styled.a`
  font-size: 17px;
  font-weight: 600;
  color: #f5f5f5;
  border-radius: 48px;
  background: #699cc6;
  border-color: #699cc6;
  padding: 5px 40px;
  &:hover {
    border-color: transparent;
    color: #699cc6;
    background: white;
  }
  @media (max-width: 576px) {
    font-size: 12px;
    padding: 5px;
  }
`

export const LabelVolunteers = styled.label`
  color: #699cc6;
  font-size: 17px;
  font-weight: 600;
  line-height: 23px;
  margin-top: 9px;
  @media (max-width: 576px) {
    font-size: 12px;
  }
`

// Podium Cards

export const JoinCardContainerPodium = styled.section.attrs({
  className: 'col-md-3 cards__card-dimension animate__animated animate__fadeIn animate__faster'
})`
  max-width: 355px;
  min-width: 320px;
  margin-bottom: 28px;
  margin-right: 18px;
  z-index: 1;
  @media only screen and (max-width: 640px) {
    max-width: 355px;
    min-width: 320px;
    margin: 0 10px;
  }
`

export const JoinCardHeaderPodium = styled.section`
  background: #141b28;
  box-shadow: 7px 6px 11px #2727273d;
  min-height: 80px;
  border-radius: 8px 8px 0 0;
`
export const JoinCardPlace = styled.img`
  height: 60px;
  width: 100%;
  z-index: 1;
  display: flex;
  position: relative;
  top: 30px;
  left: 2px;
`

export const JoinCardFooterPodium = styled.section`
  background: #141b28;
  height: 81px;
  display: inline-flex;
  box-shadow: 7px 6px 11px #2727273d;
  width: 100%;
`
export const JoinCardFooterRightPodium = styled.section`
  float: right;
  font-size: 11px;
  font-weight: 400;
  width: 178px;
  display: grid;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: end;
  justify-items: end;
  text-align: right;
  color: white;
  text-transform: uppercase;
`

export const JoinCardPodiumLikes = styled.section`
  background: #75a0cc;
  height: 30px;
  width: 100%;
  border-radius: 0 0 8px 8px;
  display: grid;
`

export const JoinCardContentLikes = styled.div`
  color: white;
  font-weight: bold;
  text-align: center;
  line-height: 18px;
  /* min-height: 75px; */
  font-size: 16px;
  padding: 5px 10px;
  display: inline-table;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-items: center;
  overflow: hidden;
`
