import React from 'react'
import { RestorePassword } from './../Auth/RestorePassword'
import Layout from './../Layout/Layout'

const RestorePwdPage = () => (
  <Layout>
    <RestorePassword />
  </Layout>
)
export default RestorePwdPage
