export const PROFILE_USER_CREATE_FAILURE = 'PROFILE_USER_CREATE_FAILURE'
export const PROFILE_USER_CREATE_IMAGE_REQUEST = 'PROFILE_USER_CREATE_IMAGE_REQUEST'
export const PROFILE_USER_CREATE_IMAGE_SUCCESS = 'PROFILE_USER_CREATE_IMAGE_SUCCESS'
export const PROFILE_USER_CREATE_IMAGE_FAILURE = 'PROFILE_USER_CREATE_IMAGE_FAILURE'
export const PROFILE_USER_GET_ALL_REQUEST = 'PROFILE_USER_GET_ALL_REQUEST'
export const PROFILE_USER_GET_ALL_SUCCESS = 'PROFILE_USER_GET_ALL_SUCCESS'
export const PROFILE_USER_GET_ALL_FAILURE = 'PROFILE_USER_GET_ALL_FAILURE'
export const PROFILE_USER_UPDATE_REQUEST = 'PROFILE_USER_UPDATE_REQUEST'
export const PROFILE_USER_UPDATE_SUCCESS = 'PROFILE_USER_UPDATE_SUCCESS'
export const PROFILE_USER_UPDATE_FAILURE = 'PROFILE_USER_UPDATE_FAILURE'
export const PROFILE_USER_GET_ONE_REQUEST = 'PROFILE_USER_GET_ONE_REQUEST'
export const PROFILE_USER_GET_ONE_SUCCESS = 'PROFILE_USER_GET_ONE_SUCCESS'
export const PROFILE_USER_GET_ONE_FAILURE = 'PROFILE_USER_GET_ONE_FAILURE'

export const typeProfileUser = {
  profileUsersUpdateRequest: '[ PROFILEUSER ] PROFILE_USER_UPDATE_REQUEST'
}
export const profileUsersUpdateRequest = ({ values }) => ({
  type: PROFILE_USER_UPDATE_REQUEST,
  payload: values
})
export const updatedItem = param => (list, item) => {
  const index = list.findIndex(i => i[param] === item[param])
  return [...list.slice(0, index), item, ...list.slice(index + 1)]
}

const initialState = {
  profileUsersList: {},
  loading: false,
  loader: false,
  error: '',
  all: [],
  one: {},
  filters: {}
}

export function profileUser(state = initialState, action) {
  switch (action.type) {
    case PROFILE_USER_UPDATE_REQUEST:
    case PROFILE_USER_GET_ONE_REQUEST:
    case PROFILE_USER_GET_ALL_REQUEST:
    case PROFILE_USER_CREATE_IMAGE_REQUEST:
    case typeProfileUser.profileUserListRequest:
      return { ...state, error: null, loading: true }
    case typeProfileUser.profileUserNewUserRequest:
      return { ...state, error: null, loading: false, all: [action.payload] }
    case PROFILE_USER_CREATE_IMAGE_SUCCESS:
      return { ...state, error: null, loading: false, one: [action.payload] }
    case PROFILE_USER_UPDATE_SUCCESS:
      return { ...state, error: null, loading: false, one: { ...action.payload } }
    case PROFILE_USER_GET_ALL_SUCCESS:
      return { ...state, error: null, loading: false, all: { ...action.payload } }
    case PROFILE_USER_GET_ALL_FAILURE:
      return { ...state, loading: false, error: action.error }
    case PROFILE_USER_GET_ONE_SUCCESS:
      return { ...state, error: null, loading: false, one: { ...action.payload } }
    case PROFILE_USER_UPDATE_FAILURE:
    case PROFILE_USER_GET_ONE_FAILURE:
    case PROFILE_USER_CREATE_IMAGE_FAILURE:
      return { ...state, loading: false, error: action.error }
    default:
      return state
  }
}
