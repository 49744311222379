import styled, { css } from 'styled-components'

// Create a <Title> react component that renders an <h1> which is
// centered, palevioletred and sized at 1.5em
export const Title = styled.h1`
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  color: withe;
  ${props =>
    props.goodPractices &&
    css`
      @media (max-width: 575.98px) {
        margin-top: 0px;
      }
    `}
  @media only screen and (max-width: 640px) {
    font-size: 29px;
  }
`
export const SubTitle = styled.h1`
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  color: yellow;
`

export const SecondTitle = styled.h1`
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  padding-top: 2%;
`

export const Description = styled.h1`
  font-size: 26px;
  text-align: center;
  color: white;
  padding: 0 15%;
  font-weight: 300;
`

export const Parrafo = styled.h1`
  font-size: 26px;
  font-weight: 300;
  text-align: center;
  padding: 2% 15% 0 15%;
  @media only screen and (max-width: 640px) {
    padding: 0em;
    font-size: 22px;
  }
`

export const InfoParrafo = styled.h1`
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  padding: 5% 0 0 0;
`

export const Boton = styled.div`
  text-align: center;
  padding: 2% 15% 0 15%;
`

// Create a <Wrapper> react component that renders a <section> with
// some padding and a papayawhip background
export const Banner = styled.section`
  padding: 7em 5em 4em 5em;
  background-size: cover;
  @media only screen and (max-width: 640px) {
    padding: 6em 4em 3em 4em;
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
  }
`

export const InfoBanner = styled.section`
  padding: 5% 17%;
  background-color: #131a27;
  @media only screen and (max-width: 640px) {
    padding: 45px;
  }
`

export const BannerLogo = styled.img`
  margin: 0 15%;
  height: auto;
  max-width: 250px;
  @media only screen and (max-width: 640px) {
    margin: 0 15%;
    height: 200px;

  }
`
export const BannerLink = styled.a`
  display: contents;
`

export const BannerButton = styled.div`
  padding: 1% 17%;
  background-color: #131a27;
  display: grid;
  @media only screen and (max-width: 640px) {
    padding: 12px 42px 12px 15px;

  }
`

export const BannerInfoButton = styled.div`
  font-size: 20px;
  font-weight: 400;
  text-align: center;
`

export const ButtonLink = styled.a`
  width: 100%;
  margin: 2%;
  font-size: 22px;
  color: #2d5e9a;
  padding: 5px 70px;
  border-radius: 48px;
  background: transparent;
  border-color: #a1c1d8;
  &:hover {
    color: #fff;
    background: lighten($color: #a1c1d8, $amount: 3);
    border-color: lighten($color: #a1c1d8, $amount: 3);
  }
  @media only screen and (max-width: 640px) {
    padding: 5px 20px;

  }
`

export const Container = styled.section`
  flex: 1;
  min-width: 100%;
  background: #f4f4f4;
  align-items: center;
  @media only screen and (max-width: 640px) {
    padding: 0 20px;
  }
`
