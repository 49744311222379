import React from 'react'
import PropTypes from 'prop-types'

function HomeTitle({ title, subtitle, line }) {
  return (
    <div>
      <span className='title-h1'>{title}</span>
      {line && <div className='line-h1'></div>}
      <span style={{}} className='text-h1'>
        {subtitle}
      </span>
    </div>
  )
}

HomeTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  line: PropTypes.bool.isRequired
}

HomeTitle.defaultProps = {
  title: '',
  subtitle: '',
  line: true
}

export default HomeTitle
