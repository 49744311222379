// TYPES
export const CONTACT_CREATE_REQUEST = 'CONTACT_CREATE_REQUEST'
export const CONTACT_CREATE_SUCCESS = 'CONTACT_CREATE_SUCCESS'
export const CONTACT_CREATE_FAILURE = 'CONTACT_CREATE_FAILURE'

export const CONTACT_GET_ALL_REQUEST = 'CONTACT_GET_ALL_REQUEST'
export const CONTACT_GET_ALL_SUCCESS = 'CONTACT_GET_ALL_SUCCESS'
export const CONTACT_GET_ALL_FAILURE = 'CONTACT_GET_ALL_FAILURE'

export const CONTACT_REMOVE_REQUEST = 'CONTACT_REMOVE_REQUEST'
export const CONTACT_REMOVE_SUCCESS = 'CONTACT_REMOVE_SUCCESS'
export const CONTACT_REMOVE_FAILURE = 'CONTACT_REMOVE_FAILURE'

export const CONTACT_EDIT_REQUEST = 'CONTACT_EDIT_REQUEST'
export const CONTACT_EDIT_SUCCESS = 'CONTACT_EDIT_SUCCESS'
export const CONTACT_EDIT_FAILURE = 'CONTACT_EDIT_FAILURE'

export const CONTACT_GET_ONE_REQUEST = 'CONTACT_GET_ONE_REQUEST'
export const CONTACT_GET_ONE_SUCCESS = 'CONTACT_GET_ONE_SUCCESS'
export const CONTACT_GET_ONE_FAILURE = 'CONTACT_GET_ONE_FAILURE'

export const typesContact = {
  contactListRequest: '[ CONTACT ] CONTACT_LIST_REQUEST',
  contactListSuccess: '[ CONTACT ] CONTACT_LIST_SUCESS',
  contactListError: '[ CONTACT ] CONTACT_LIST_ERROR'
}
// ACTIONS
export const contactCreateRequest = payload => ({ type: CONTACT_CREATE_REQUEST, payload })
export const contactListRequest = filters => ({
  type: typesContact.contactListRequest,
  filters
})
export const contactListSuccess = payload => ({
  type: typesContact.contactListSuccess,
  payload
})
export const contactListError = payload => ({ type: typesContact.contactListError, payload })

// REDUCER
const initialState = {
  contactList: {},
  loading: false,
  loader: false,
  error: '',
  all: [],
  one: {}
}

export function contact(state = initialState, action) {
  switch (action.type) {
    case typesContact.contactListRequest:
    case CONTACT_GET_ALL_REQUEST:
    case CONTACT_REMOVE_REQUEST:
    case CONTACT_EDIT_REQUEST:
    case CONTACT_GET_ONE_REQUEST:
      return { ...state, error: null, loading: true }
    case typesContact.contactListSuccess:
      return { ...state, loading: false, contactList: action.payload }
    case CONTACT_CREATE_REQUEST:
      return { ...state, error: null, loading: false, all: [action.payload] }
    case CONTACT_CREATE_SUCCESS:
      return { ...state, error: null, loading: false, one: [action.payload] }
    case CONTACT_GET_ALL_SUCCESS:
      return { ...state, error: null, loading: false, all: { ...action.payload } }
    case CONTACT_REMOVE_SUCCESS:
    case CONTACT_EDIT_SUCCESS:
    case CONTACT_GET_ONE_SUCCESS:
      return { ...state, error: null, loading: false, one: { ...action.payload } }
    case CONTACT_CREATE_FAILURE:
    case typesContact.contactListError:
    case CONTACT_GET_ALL_FAILURE:
    case CONTACT_EDIT_FAILURE:
    case CONTACT_REMOVE_FAILURE:
    case CONTACT_GET_ONE_FAILURE:
      return { ...state, loading: false, error: action.payload }
    default:
      return state
  }
}
