import { put, call, takeLatest, delay } from 'redux-saga/effects'
import Swal from 'sweetalert2'
import axios from 'axios'
import { push } from 'connected-react-router'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import {
  PROFILE_USER_CREATE_FAILURE,
  PROFILE_USER_CREATE_IMAGE_FAILURE,
  PROFILE_USER_CREATE_IMAGE_REQUEST,
  PROFILE_USER_CREATE_IMAGE_SUCCESS,
  PROFILE_USER_GET_ALL_FAILURE,
  PROFILE_USER_GET_ALL_REQUEST,
  PROFILE_USER_GET_ALL_SUCCESS,
  PROFILE_USER_GET_ONE_FAILURE,
  PROFILE_USER_GET_ONE_REQUEST,
  PROFILE_USER_GET_ONE_SUCCESS,
  PROFILE_USER_UPDATE_FAILURE,
  PROFILE_USER_UPDATE_REQUEST,
  PROFILE_USER_UPDATE_SUCCESS
} from './profile.redux'
import { encodeFiltersData, manageError } from '../../redux/helpers/handlers.js'

function* getOneProfileSaga({ id }) {
  try {
    const { data } = yield call(axios.get, `/api/profileUsers/${id}/user`)
    yield put(showLoading())
    yield delay(process.env.REACT_APP_DELAY)
    yield put(hideLoading())
    yield put({ type: PROFILE_USER_GET_ONE_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: PROFILE_USER_GET_ONE_FAILURE, error: error.response })
  }
}

function* createProfileImgSaga({ payload }) {
  try {
    let formData = new FormData()
    formData.append('image', payload.imageFile)
    yield put(showLoading())
    const insert = yield call(axios.post, '/api/profileUsers/picture', formData)
    yield put({ type: PROFILE_USER_CREATE_IMAGE_SUCCESS, payload: insert })
    yield delay(process.env.REACT_APP_DELAY)
    yield put(hideLoading())
    yield put(push('/profile'))
    Swal.fire({
      title: 'Success',
      text: 'Profile image has been created',
      icon: 'success',
      confirmButtonText: 'Ok'
    })
  } catch (error) {
    yield put({ type: PROFILE_USER_CREATE_IMAGE_FAILURE, error: error.response })
  }
}

function* updateProfileSaga({ payload }) {
  try {
    let formData = new FormData()
    formData.append('telephone', payload.telephone)

    const insert = yield call(axios.put, '/api/profileUsers/' + payload.profileId, formData)
    yield put(showLoading())
    yield put({ type: PROFILE_USER_UPDATE_SUCCESS, payload: insert })
    yield delay(process.env.REACT_APP_DELAY)
    yield put(push('/profile/' + payload._id))
    Swal.fire('Perfil actualizado...', '', 'success')
    yield delay(3000)
    yield put(hideLoading())
  } catch (error) {
    yield put(hideLoading())
    yield put({ type: PROFILE_USER_CREATE_FAILURE, error: error.response })
  }
}

export function* getAllProfileSaga({ filters = { page: 1, active: true } }) {
  try {
    const filterString = encodeFiltersData(filters)
    const { data } = yield call(axios.get, `/api/profileUsers/?${filterString}`)
    yield delay(800)
    yield put({ type: PROFILE_USER_GET_ALL_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: PROFILE_USER_GET_ALL_FAILURE, error: error.response })
  }
}

export default function* profileSagas() {
  yield takeLatest(PROFILE_USER_UPDATE_REQUEST, updateProfileSaga)
  yield takeLatest(PROFILE_USER_GET_ONE_REQUEST, getOneProfileSaga)
  yield takeLatest(PROFILE_USER_CREATE_IMAGE_REQUEST, createProfileImgSaga)
  yield takeLatest(PROFILE_USER_GET_ALL_REQUEST, getAllProfileSaga)
  yield takeLatest(PROFILE_USER_UPDATE_FAILURE, manageError)
  yield takeLatest(PROFILE_USER_GET_ONE_REQUEST, getOneProfileSaga)
}
