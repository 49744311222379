import {
  DraftCCE,
  DraftContainer,
  DraftImage,
  DraftImage2,
  DraftRow,
  DraftText
} from 'components/Draft/DraftStyledComponent'
import React from 'react'
import draft1 from './../../assets/img/draft/draft1-pacto.webp'
import draft2 from './../../assets/img/draft/logo-cce.png'

const HomeDraft = () => {
  return (
    <>
      {/* Impulsada por */}
      <DraftContainer>
        <DraftRow>
          <a target={'_blank'} href={`https://www.pactomundial.org.mx/`}>
            <DraftImage src={draft1} alt='draft1' />
          </a>
        </DraftRow>
        <DraftRow style={{ display: 'block', paddingLeft: '63px' }}>
          <DraftText>
            La iniciativa de los Grupos de Trabajo
            <br />
            Agenda 2030 es desarrollada en alianza con​
          </DraftText>
          <a target={'_blank'} href={`https://cce.org.mx`}>
            <DraftImage2 src={draft2} alt='draft2' />
          </a>
        </DraftRow>
      </DraftContainer>
    </>
  )
}

export default HomeDraft
