import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const AboutContainer = styled.section`
  flex: 1;
  min-width: 100%;
  background: #f2f2f2;
  align-items: center;
  padding: 5%;
`

export const AboutSecondContainer = styled.p`
  flex: 1;
  min-width: 100%;
  background: #131a27;
  align-items: center;
  padding: 5% 17%;
  @media only screen and (max-width: 640px) {
    padding: 45px;
  }
`

export const AboutText = styled.div`
  border-bottom: 2px solid #749bc2;
  color: white;
  font-size: 22px;
  text-align: center;
  padding: 5% 0%;
`

export const AboutSecondText = styled.div`
  color: white;
  font-size: 22px;
  text-align: left;
  padding: 10% 0 0 0;
  @media only screen and (max-width: 640px) {
    text-align: center;
  }
`

export const AboutLogo = styled.img`
  margin: 7% 20%;
  height: auto;
  @media only screen and (max-width: 640px) {
    margin: 4%;
    height: 200px;
  }
`

export const AboutLink = styled.a`
  display: contents;
`

export const Columna = styled.div`
  display: flex;
  float: left;
  /* // extra small screens */
  ${({ xs }) => (xs ? getWidth(xs) : 'width: 100%')}
  /* // small screens */
  @media only screen and (max-width: 640px) {
    ${({ sm }) => sm && getWidth(sm)}
  }
  /* // medium screens */
  @media only screen and (min-width: 992px) {
    ${({ md }) => md && getWidth(md)}
  }
  /* // everything bigger than that */
  @media only screen and (min-width: 1200px) {
    ${({ lg }) => lg && getWidth(lg)}
  }
`

export const AboutTitle = styled.h1`
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  color: black;
  @media only screen and (max-width: 640px) {
    font-size: 28px;
  }
`

export const LeftContainer = styled.div`
  flex: 25%;
`

export const RightContainer = styled.div`
  flex: 75%;
`

export const AboutNumber = styled.img`
  max-width: 135px;
  height: auto;
`

export const AboutItemTitle = styled.h1`
  font-size: 30px;
  font-weight: bold;
  text-align: left;
  color: #bfd3e2;
`

export const AboutItemDescription = styled.h1`
  font-size: 22px;
  text-align: left;
  color: #2c4b77;
`

function getWidth(span) {
  if (!span) return
  let width = (span / 12) * 100
  return `width: ${width}%`
}

export const Column = styled.div`
  display: flex;
  float: left;
  padding: 5%;
  // extra small screens
  ${({ xs }) => (xs ? getWidth(xs) : 'width: 100%')}
  // small screens
  @media only screen and (min-width: 768px) {
    ${({ sm }) => sm && getWidth(sm)}
  }
  // medium screens
  @media only screen and (min-width: 992px) {
    ${({ md }) => md && getWidth(md)}
  }
  // everything bigger than that
  @media only screen and (min-width: 1200px) {
    ${({ lg }) => lg && getWidth(lg)}
  }
`
