import React from 'react'
import PropTypes from 'prop-types'
import { AboutContainer, AboutTitle } from './aboutStyleComponents'
import AboutItem from './AboutItem'
import uno from 'assets/img/about/number1.svg'
import dos from 'assets/img/about/number2.svg'
import ButtonsContainer from 'components/Buttons/ButtonsContainer.js'
import ButtonCard from 'components/Buttons/ButtonCard.js'


const AboutPoints = ({title}) => {
  return (
    <AboutContainer>
      <AboutTitle>{title}</AboutTitle>  
      <AboutItem img={uno} title='Compartir' description='buenas prácticas sostenibles para el aprendizaje y mejora de todos y todas.'/>    
      <AboutItem img={dos} title='Aprender' description='de las buenas prácticas compartidas para resolver con éxito los principales retos en materia de sostenibilidad empresarial del país'/>    
      <ButtonsContainer>
          <ButtonCard url='/our-objectives' title={'NUESTROS OBJETIVOS'} />
          <ButtonCard url='/how-participate' title={'¿CÓMO PARTICIPAR?'} />
          <ButtonCard url='/page-faq' title={'PREGUNTAS FRECUENTES'} />
        </ButtonsContainer>
    </AboutContainer>
  )
}

AboutPoints.propTypes = {
  title: PropTypes.string.isRequired,
}

AboutPoints.defaultProps = {
  title: 'Título',
}


export default AboutPoints
