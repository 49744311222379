// TYPES
export const ACTION_CREATE_REQUEST = 'ACTION_CREATE_REQUEST'
export const ACTION_CREATE_SUCCESS = 'ACTION_CREATE_SUCCESS'
export const ACTION_CREATE_FAILURE = 'ACTION_CREATE_FAILURE'

export const ACTION_GET_TOTALS_REQUEST = 'ACTION_GET_TOTALS_REQUEST'
export const ACTION_GET_TOTALS_SUCCESS = 'ACTION_GET_TOTALS_SUCCESS'
export const ACTION_GET_TOTALS_FAILURE = 'ACTION_GET_TOTALS_FAILURE'

export const ACTION_GET_TOTALS_ODS_REQUEST = 'ACTION_GET_TOTALS_ODS_REQUEST'
export const ACTION_GET_TOTALS_ODS_SUCCESS = 'ACTION_GET_TOTALS_ODS_SUCCESS'
export const ACTION_GET_TOTALS_ODS_FAILURE = 'ACTION_GET_TOTALS_ODS_FAILURE'

export const ACTION_GET_TOTALS_AXES_REQUEST = 'ACTION_GET_TOTALS_AXES_REQUEST'
export const ACTION_GET_TOTALS_AXES_SUCCESS = 'ACTION_GET_TOTALS_AXES_SUCCESS'
export const ACTION_GET_TOTALS_AXES_FAILURE = 'ACTION_GET_TOTALS_AXES_FAILURE'

export const ACTION_GET_TOTALS_LIKES_REQUEST = 'ACTION_GET_TOTALS_LIKES_REQUEST'
export const ACTION_GET_TOTALS_LIKES_SUCCESS = 'ACTION_GET_TOTALS_LIKES_SUCCESS'
export const ACTION_GET_TOTALS_LIKES_FAILURE = 'ACTION_GET_TOTALS_LIKES_FAILURE'

export const ACTION_JOIN_USER_REQUEST = 'ACTION_JOIN_USER_REQUEST'
export const ACTION_JOIN_USER_SUCCESS = 'ACTION_JOIN_USER_SUCCESS'
export const ACTION_JOIN_USER_FAILURE = 'ACTION_JOIN_USER_FAILURE'

export const ACTION_FILTER_CHANGE_REQUEST = 'ACTION_FILTER_CHANGE_REQUEST'
export const ACTION_FILTER_CHANGE_SUCCESS = 'ACTION_FILTER_CHANGE_SUCCESS'
export const ACTION_FILTER_CHANGE_FAILURE = 'ACTION_FILTER_CHANGE_FAILURE'

export const ACTION_GET_REQUEST = 'ACTION_GET_REQUEST'
export const ACTION_GET_SUCCESS = 'ACTION_GET_SUCCESS'
export const ACTION_GET_FAILURE = 'ACTION_GET_FAILURE'

export const ACTION_GET_ONE_LIKE_REQUEST = 'ACTION_GET_ONE_LIKE_REQUEST'
export const ACTION_GET_ONE_LIKE_SUCCESS = 'ACTION_GET_ONE_LIKE_SUCCESS'
export const ACTION_GET_ONE_LIKE_FAILURE = 'ACTION_GET_ONE_LIKE_FAILURE'

export const ACTION_GET_ALL_REQUEST = 'ACTION_GET_ALL_REQUEST'
export const ACTION_GET_ALL_SUCCESS = 'ACTION_GET_ALL_SUCCESS'
export const ACTION_GET_ALL_FAILURE = 'ACTION_GET_ALL_FAILURE'

export const ACTION_GET_LIKED_REQUEST = 'ACTION_GET_LIKED_REQUEST'
export const ACTION_GET_LIKED_SUCCESS = 'ACTION_GET_LIKED_SUCCESS'
export const ACTION_GET_LIKED_FAILURE = 'ACTION_GET_LIKED_FAILURE'

export const ACTION_GET_MORE_REQUEST = 'ACTION_GET_MORE_REQUEST'
export const ACTION_GET_MORE_SUCCESS = 'ACTION_GET_MORE_SUCCESS'
export const ACTION_GET_MORE_FAILURE = 'ACTION_GET_MORE_FAILURE'

export const ACTION_GET_WORKAXIS_REQUEST = 'ACTION_GET_WORKAXIS_REQUEST'
export const ACTION_GET_WORKAXIS_SUCCESS = 'ACTION_GET_WORKAXIS_SUCCESS'
export const ACTION_GET_WORKAXIS_FAILURE = 'ACTION_GET_WORKAXIS_FAILURE'

export const actionFiltersChangeRequest = filters => ({
  type: ACTION_FILTER_CHANGE_REQUEST,
  filters
})
export const joinUserToAction = payload => ({
  type: ACTION_JOIN_USER_REQUEST,
  payload
})
export const actionGetOneLikeRequest = payload => ({
  type: ACTION_GET_ONE_LIKE_REQUEST,
  payload
})
export const actionGetWorkAxisRequest = payload => ({
  type: ACTION_GET_WORKAXIS_REQUEST,
  payload
})
export const actionCreateRequest = ({ values }) => ({
  type: ACTION_CREATE_REQUEST,
  payload: values
})
export const actionGetRequest = payload => ({
  type: ACTION_GET_REQUEST,
  payload
})
export const actionGetAllRequest = filters => ({
  type: ACTION_GET_ALL_REQUEST,
  payload: filters
})
export const actionGetLikedRequest = filters => ({
  type: ACTION_GET_LIKED_REQUEST,
  payload: filters
})
export const actionGetMoreRequest = filters => ({
  type: ACTION_GET_MORE_REQUEST,
  payload: filters
})
export const actionGetTotalsByOds = payload => ({
  type: ACTION_GET_TOTALS_ODS_REQUEST,
  payload
})

export const actionGetTotalsByAxes = payload => ({
  type: ACTION_GET_TOTALS_AXES_REQUEST,
  payload
})

export const actionGetTotalsByLikes = payload => ({
  type: ACTION_GET_TOTALS_LIKES_REQUEST,
  payload
})

export const actionGetTotals = payload => ({
  type: ACTION_GET_TOTALS_REQUEST,
  payload
})

const initialState = {
  loading: false,
  loadingLikes: false,
  loader: false,
  joinAction: false,
  error: '',
  list: [],
  workAxis: [],
  one: {},
  like: false,
  filters: {},
  totals: {
    totalImplemented: 5,
    totalAction: 0
  },
  totalsOds: [],
  totalsAxes: [],
  totalsLikes: []
}

export function joinActions(state = initialState, action) {
  switch (action.type) {
    case ACTION_CREATE_REQUEST:
    case ACTION_GET_REQUEST:
    case ACTION_GET_TOTALS_REQUEST:
    case ACTION_GET_TOTALS_ODS_REQUEST:
    case ACTION_GET_TOTALS_AXES_REQUEST:
    case ACTION_GET_TOTALS_LIKES_REQUEST:
    case ACTION_GET_WORKAXIS_REQUEST:
    case ACTION_FILTER_CHANGE_REQUEST:
    case ACTION_JOIN_USER_REQUEST:
      return { ...state, loading: true }
    case ACTION_GET_ONE_LIKE_REQUEST:
      return { ...state, loadingLikes: true }

    case ACTION_GET_ALL_REQUEST:
      return { ...state, loading: true, filters: action?.payload }

    case ACTION_GET_LIKED_REQUEST:
      return { ...state, loading: true, filters: {} }

    case ACTION_GET_MORE_REQUEST:
      return { ...state, loader: true }

    case ACTION_CREATE_SUCCESS:
    case ACTION_GET_SUCCESS:
      return { ...state, loading: false, one: action.payload }
    case ACTION_GET_WORKAXIS_SUCCESS:
      return { ...state, loading: false, workAxis: action.payload }
    case ACTION_GET_ONE_LIKE_SUCCESS:
      return { ...state, like: action.payload, loadingLikes: false }
    case ACTION_JOIN_USER_SUCCESS:
      return { ...state, loading: false, joinAction: true }
    case ACTION_GET_TOTALS_SUCCESS:
      return { ...state, loading: false, totals: action.payload }
    case ACTION_GET_TOTALS_ODS_SUCCESS:
      return { ...state, loading: false, totalsOds: action.payload }
    case ACTION_GET_TOTALS_AXES_SUCCESS:
      return { ...state, loading: false, totalsAxes: action.payload }
    case ACTION_GET_TOTALS_LIKES_SUCCESS:
      return { ...state, loading: false, totalsLikes: action.payload }
    case ACTION_GET_ALL_SUCCESS:
    case ACTION_GET_LIKED_SUCCESS:
      return { ...state, loading: false, list: action.payload }

    case ACTION_GET_MORE_SUCCESS:
      action.payload.docs = [...state.list?.docs, ...action.payload?.docs]
      return { ...state, loader: false, list: action.payload }

    case ACTION_FILTER_CHANGE_SUCCESS:
      return { ...state, error: null, loading: false, filters: { ...action.payload } }

    case ACTION_CREATE_FAILURE:
    case ACTION_GET_FAILURE:
    case ACTION_GET_ALL_FAILURE:
    case ACTION_GET_LIKED_FAILURE:
    case ACTION_GET_WORKAXIS_FAILURE:
    case ACTION_JOIN_USER_FAILURE:
      return { ...state, loading: false, error: action.error }

    case ACTION_GET_MORE_FAILURE:
      return { ...state, loader: false, error: action.error }

    case ACTION_GET_ONE_LIKE_FAILURE:
      return { ...state, loader: false, like: false, error: action.error }

    case ACTION_FILTER_CHANGE_FAILURE:
      return { ...state, loading: false, error: action.error, filters: {} }
    case ACTION_GET_TOTALS_FAILURE:
      return { ...state, loading: false, error: action.error, totals: {} }
    case ACTION_GET_TOTALS_ODS_FAILURE:
      return { ...state, loading: false, error: action.error, totalsOds: {} }
    case ACTION_GET_TOTALS_AXES_FAILURE:
      return { ...state, loading: false, error: action.error, totalsAxes: {} }
    case ACTION_GET_TOTALS_LIKES_FAILURE:
      return { ...state, loading: false, error: action.error, totalsLikes: {} }
    default:
      return state
  }
}
