import React from 'react'
import PropTypes from 'prop-types'
import LayoutTwo from 'layouts/LayoutTwo/LayoutTwo'
import HeaderBanner from 'components/Header/HeaderBanner'

const PageTwo = props => {
  return (
    <LayoutTwo>
      <HeaderBanner title='Página 2' />
    </LayoutTwo>
  )
}

PageTwo.propTypes = {}

export default PageTwo
