import React from 'react'

function HeaderImplementation() {
  return (
    <div className='container headerImpl-color animate__animated animate__fadeIn animate__faster'>
      <div className='row'>
        <div className='col-lg-12 headerImpl-grid'>
          <h2 className='headerImpl-title'>Implementación</h2>
          <span className='headerImpl-subtitle'>
            Consulta aquí las soluciones <strong>implementadas</strong> bajo la Metodología de
            acción.
          </span>
        </div>
      </div>
    </div>
  )
}

export default HeaderImplementation
