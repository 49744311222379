import React from 'react'
import { connect } from 'react-redux'
import profilePicture from './../../assets/img/profileavatar.svg'
import { actionGetAllRequest } from './joinAction.redux.js'
import JoinActionCard from './JoinActionCard.js'

export const SavedJoin = ({ data }) => {
  const { filters, user, one } = data

  const handleScroll = event => {
    const { target } = event
    if (target.scrollHeight - target.scrollTop <= target.clientHeight && data.hasNextPage) {
      filters.page = data.nextPage
      filters.active = true
      actionGetAllRequest(filters)
    }
  }

  return (
    <div className='user__pfl' style={{ paddingRight: 0 }}>
      <div className='user__profile'>
        <div className='row'>
          <div className='user__profile-header-cards' style={{ paddingTop: '15px' }}>
            <div className='user__profile-picture'>
              <img src={one?.thumbnail || profilePicture} alt='profilePicture' />
            </div>
            <div className='user__profile-description' style={{ paddingTop: '37px' }}>
              <h2>Iniciativas en las que participo</h2>
              <h3>{user?.name}</h3>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div
        className='container cards__row'
        style={{
          marginLeft: '0px',
          minHeight: '570px',
          maxWidth: '100%',
          backgroundColor: '#1b2a46',
          paddingRight: 0
        }}
        onScroll={handleScroll}>
        {/* {cards.catalogs?.workAxes.lenght > 0 && <JoinFilterBar />} */}
        <div
          className='row cards-mobile'
          style={{ marginTop: '100px', alignContent: 'start', paddingLeft: '5rem' }}>
          {data?.all?.map(key => {
            return <JoinActionCard data={key} key={key._id} />
          })}
        </div>
      </div>
    </div>
  )
}
const mapStateToProps = state => ({
  // all: state.cards.all,
  // loading: state.cards.loading,
  // error: state.cards.error,
  // filters: state.joinActions.filters,
  // user: state.user,
  // one: state.users.one
})
const mapDispatchToProps = {
  actionGetAllRequest
  // savedCardsGetAllRequest
}
export default connect(mapStateToProps, mapDispatchToProps)(SavedJoin)
