// Type's
export const COMMENTS_GET_ALL_REQUEST = 'COMMENTS_GET_ALL_REQUEST'
export const COMMENTS_GET_ALL_SUCCESS = 'COMMENTS_GET_ALL_SUCCESS'
export const COMMENTS_GET_ALL_FAILURE = 'COMMENTS_GET_ALL_FAILURE'

export const COMMENTS_CREATE_REQUEST = 'COMMENTS_CREATE_REQUEST'
export const COMMENTS_CREATE_SUCCESS = 'COMMENTS_CREATE_SUCCESS'
export const COMMENTS_CREATE_FAILURE = 'COMMENTS_CREATE_FAILURE'

export const COMMENTS_EDIT_REQUEST = 'COMMENTS_EDIT_REQUEST'
export const COMMENTS_EDIT_SUCCESS = 'COMMENTS_EDIT_SUCCESS'
export const COMMENTS_EDIT_FAILURE = 'COMMENTS_EDIT_FAILURE'

export const COMMENTS_REMOVE_REQUEST = 'COMMENTS_REMOVE_REQUEST'
export const COMMENTS_REMOVE_SUCCESS = 'COMMENTS_REMOVE_SUCCESS'
export const COMMENTS_REMOVE_FAILURE = 'COMMENTS_REMOVE_FAILURE'
// Action's
export const commentsGetAllRequest = filters => ({
  type: COMMENTS_GET_ALL_REQUEST,
  filters
})
export const commentsGetAllSuccess = payload => ({
  type: COMMENTS_GET_ALL_SUCCESS,
  payload
})
export const commentsGetAllFailure = error => ({
  type: COMMENTS_GET_ALL_REQUEST,
  error
})
export const commentsDeactivateRequest = payload => ({
  type: COMMENTS_REMOVE_REQUEST,
  payload
})
export const commentsCreateRequest = ({ values }) => ({
  type: COMMENTS_CREATE_REQUEST,
  payload: values
})
export const commentsEditRequest = ({ values }) => ({
  type: COMMENTS_EDIT_REQUEST,
  payload: values
})
export const commentsCreateSuccess = payload => ({
  type: COMMENTS_CREATE_SUCCESS,
  payload
})
export const commentsCreateFailure = payload => ({
  type: COMMENTS_CREATE_FAILURE,
  payload
})

// Reducer
const INITIAL_STATE = {
  one: {},
  all: [],
  loading: false,
  error: null
}

export function comments(state = INITIAL_STATE, action) {
  switch (action.type) {
    case COMMENTS_GET_ALL_REQUEST:
    case COMMENTS_CREATE_REQUEST:
    case COMMENTS_EDIT_REQUEST:
    case COMMENTS_REMOVE_REQUEST:
      return { ...state, error: null, loading: true }

    case COMMENTS_GET_ALL_SUCCESS:
      return { ...state, error: null, loading: false, all: { ...action.payload } }
    case COMMENTS_EDIT_SUCCESS:
    case COMMENTS_CREATE_SUCCESS:
      return { ...state, error: null, loading: false, one: [action.payload] }
    case COMMENTS_REMOVE_SUCCESS:
      return { ...state, error: null, loading: false, one: { ...action.payload } }

    case COMMENTS_REMOVE_FAILURE:
    case COMMENTS_CREATE_FAILURE:
    case COMMENTS_EDIT_FAILURE:
    case COMMENTS_GET_ALL_FAILURE:
      return { ...state, loading: false, error: action.error }
    default:
      return state
  }
}
