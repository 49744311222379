import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Bar } from 'react-chartjs-2'
import { actionGetTotalsByOds } from 'components/Join/joinAction.redux'
import { cardGetCatalogs } from 'components/Cards/cards.redux'
import { chartData, mergeArray, mergeById, splitName } from 'components/Helpers/exceptionUtils'
import {
  ContainerGraphOds,
  ContainerODS,
  SectionGraphOds,
  TitleGraphOds
} from './GraphOdsStyle'
import 'chartjs-plugin-datalabels'
import 'chartjs-plugin-labels'

const GraphOds = ({
  actionGetTotalsByOds,
  totalImplementedByOds,
  cardGetCatalogs,
  title,
  odsLabels
}) => {
  useEffect(() => {
    cardGetCatalogs()
    actionGetTotalsByOds()
  }, [cardGetCatalogs, actionGetTotalsByOds])

  const dataOdsLabels = odsLabels?.data
  const labels = dataOdsLabels?.map(l => splitName(l.title))
  const images = dataOdsLabels?.map(i => i.logo)
  const colors = dataOdsLabels?.map(c => c.color)

  const countLabels = dataOdsLabels?.map(i => {
    return {
      id: i._id,
      count: 0
    }
  })

  const countsPractice = totalImplementedByOds?.map(v => {
    return {
      id: v._id,
      count: v.count
    }
  })

  const counts = countLabels && mergeArray(countLabels, countsPractice)
  const data = counts?.map(c => c.count)
  const chart = labels && chartData('En cifras', labels, data, colors)
  const arrValuesChart = chart?.datasets[0]?.data
  const maxValueArray = arrValuesChart?.reduce((a, b) => Math.max(a, b))

  const barOptions = {
    scales: {
      yAxes: [
        {
          ticks: {
            display: false,
            min: 0,
            max: maxValueArray + maxValueArray * 0.4,
            stepSize: 25
          }
        }
      ],
      xAxes: [
        {
          ticks: {
            display: false
          }
        }
      ]
    },
    legend: { display: false },
    responsive: true,
    plugins: {
      datalabels: {
        display: true,
        color: 'black',
        font: { size: '14' },
        formatter: function (value) {
          if (value == 0) {
            value = ''
            return value
          }
        }
      },
      labels: {
        render: 'image',
        images: images && [
          ...images.map((image, i) => {
            return {
              src: image,
              height: 45,
              width: 45
            }
          })
        ]
      }
    }
  }

  return (
    <ContainerODS>
      <ContainerGraphOds>
        <TitleGraphOds>{title}</TitleGraphOds>
      </ContainerGraphOds>
      {images && (
        <SectionGraphOds>
          {maxValueArray !== undefined && (
            <Bar data={chart} width={100} height={25} options={barOptions} />
          )}
        </SectionGraphOds>
      )}
    </ContainerODS>
  )
}

GraphOds.propTypes = {
  totalImplementedByOds: PropTypes.array.isRequired
}

GraphOds.defaultProps = {
  totalImplementedByOds: []
}

const mapStateToProps = ({ joinActions, cards }) => ({
  odsLabels: cards?.catalogs.objectives,
  totalImplementedByOds: joinActions?.totalsOds
})

const mapDispatchToProps = {
  cardGetCatalogs: cardGetCatalogs,
  actionGetTotalsByOds: actionGetTotalsByOds
}

export default connect(mapStateToProps, mapDispatchToProps)(GraphOds)
