import React from 'react'
import FormInput from '../Helpers/FormInput.js'
import { Formik, Form } from 'formik'
import { connect, useDispatch } from 'react-redux'
import { contactCreateRequest } from './contact.redux'
import LoadingBar from 'react-redux-loading-bar'

const ContactForm = () => {
  const dispatch = useDispatch()

  const handleContact = values => dispatch(contactCreateRequest(values))

  return (
    <>
      <LoadingBar
        showFastActions
        maxProgress={100}
        progressIncrease={50}
        style={{ backgroundColor: '#7a9ec6', height: '5px', top: 0, left: 0, zIndex: 999999 }}
      />
      <div className='contact__form'>
        <Formik
          initialValues={{ name: '', email: '', subject: '', message: '' }}
          validate={values => {
            const errors = {}
            for (const key in values) if (!values[key]) errors[key] = 'Requerido'
            if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email))
              errors.email = 'Ingresa un correo válido'
            return errors
          }}
          // Submit
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              handleContact(values)
              setSubmitting(false)
            }, 400)
          }}>
          {({ isSubmitting }) => (
            <Form className='form contact__form'>
              <div className='row gutters contact__form-info'>
                <FormInput id='name' label='Nombre' />
                <FormInput id='email' type='email' label='Correo' />
              </div>
              <div className='row gutters contact__form-subject'>
                <FormInput id='subject' label='Asunto' />
              </div>
              <div className='row gutters'>
                <FormInput id='message' type='textarea' label='Mensaje' />
              </div>
              <div className='row gutters'>
                <button
                  type='submit'
                  className='btn btn-primary btn-block mt-3'
                  disabled={isSubmitting}>
                  {'Enviar'}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  )
}

const mapStateToProps = ({ contact }) => ({
  loading: contact.loading,
  error: contact.error
})

const createContact = payload => ({
  type: 'CONTACT_CREATE_REQUEST',
  payload: payload.values
})

const eventDispatchCreateContact = {
  createContact
}

export default connect(
  (createContact, mapStateToProps),
  eventDispatchCreateContact
)(ContactForm)
