import React from "react";
import ContentLoader from "react-content-loader";

const ListItemLoader = () => {
  return (
    <ContentLoader
      height={"100vh"}
      width={"100vw"}
      speed={2}
      backgroundColor={"#a5a5a5a8"}
      style={{marginTop: "148px", marginLeft:"60px", paddingRight:"140px"}}
    >
      
      <rect x="30"   y="20"  rx="8" ry="8" width="180" height="287" />
      <rect x="232"  y="20"  rx="8" ry="8" width="180" height="287" />
      <rect x="434"  y="20"  rx="8" ry="8" width="180" height="287" />
      <rect x="636"  y="20"  rx="8" ry="8" width="180" height="287" />
      <rect x="838"  y="20"  rx="8" ry="8" width="180" height="287" />
      <rect x="1040" y="20"  rx="8" ry="8" width="180" height="287" />
      <rect x="1242" y="20"  rx="8" ry="8" width="180" height="287" />
      <rect x="1444" y="20"  rx="8" ry="8" width="180" height="287" />
      <rect x="1646" y="20"  rx="8" ry="8" width="180" height="287" />
      <rect x="1848" y="20"  rx="8" ry="8" width="180" height="287" />
      <rect x="2050" y="20"  rx="8" ry="8" width="180" height="287" />
      <rect x="2252" y="20"  rx="8" ry="8" width="180" height="287" />
      <rect x="2454" y="20"  rx="8" ry="8" width="180" height="287" />
      
      <rect x="30"   y="340"  rx="8" ry="8" width="180" height="287" />
      <rect x="232"  y="340"  rx="8" ry="8" width="180" height="287" />
      <rect x="434"  y="340"  rx="8" ry="8" width="180" height="287" />
      <rect x="636"  y="340"  rx="8" ry="8" width="180" height="287" />
      <rect x="838"  y="340"  rx="8" ry="8" width="180" height="287" />
      <rect x="1040" y="340"  rx="8" ry="8" width="180" height="287" />
      <rect x="1242" y="340"  rx="8" ry="8" width="180" height="287" />
      <rect x="1444" y="340"  rx="8" ry="8" width="180" height="287" />
      <rect x="1646" y="340"  rx="8" ry="8" width="180" height="287" />
      <rect x="1848" y="340"  rx="8" ry="8" width="180" height="287" />
      <rect x="2050" y="340"  rx="8" ry="8" width="180" height="287" />
      <rect x="2252" y="340"  rx="8" ry="8" width="180" height="287" />
      <rect x="2454" y="340"  rx="8" ry="8" width="180" height="287" />
      
      <rect x="30"   y="660"  rx="8" ry="8" width="180" height="287" />
      <rect x="232"  y="660"  rx="8" ry="8" width="180" height="287" />
      <rect x="434"  y="660"  rx="8" ry="8" width="180" height="287" />
      <rect x="636"  y="660"  rx="8" ry="8" width="180" height="287" />
      <rect x="838"  y="660"  rx="8" ry="8" width="180" height="287" />
      <rect x="1040" y="660"  rx="8" ry="8" width="180" height="287" />
      <rect x="1242" y="660"  rx="8" ry="8" width="180" height="287" />
      <rect x="1444" y="660"  rx="8" ry="8" width="180" height="287" />
      <rect x="1646" y="660"  rx="8" ry="8" width="180" height="287" />
      <rect x="1848" y="660"  rx="8" ry="8" width="180" height="287" />
      <rect x="2050" y="660"  rx="8" ry="8" width="180" height="287" />
      <rect x="2252" y="660"  rx="8" ry="8" width="180" height="287" />
      <rect x="2454" y="660"  rx="8" ry="8" width="180" height="287" />
      
      <rect x="30"   y="980"  rx="8" ry="8" width="180" height="287" />
      <rect x="232"  y="980"  rx="8" ry="8" width="180" height="287" />
      <rect x="434"  y="980"  rx="8" ry="8" width="180" height="287" />
      <rect x="636"  y="980"  rx="8" ry="8" width="180" height="287" />
      <rect x="838"  y="980"  rx="8" ry="8" width="180" height="287" />
      <rect x="1040" y="980"  rx="8" ry="8" width="180" height="287" />
      <rect x="1242" y="980"  rx="8" ry="8" width="180" height="287" />
      <rect x="1444" y="980"  rx="8" ry="8" width="180" height="287" />
      <rect x="1646" y="980"  rx="8" ry="8" width="180" height="287" />
      <rect x="1848" y="980"  rx="8" ry="8" width="180" height="287" />
      <rect x="2050" y="980"  rx="8" ry="8" width="180" height="287" />
      <rect x="2252" y="980"  rx="8" ry="8" width="180" height="287" />
      <rect x="2454" y="980"  rx="8" ry="8" width="180" height="287" />
      
      <rect x="30"   y="1300"  rx="8" ry="8" width="180" height="287" />
      <rect x="232"  y="1300"  rx="8" ry="8" width="180" height="287" />
      <rect x="434"  y="1300"  rx="8" ry="8" width="180" height="287" />
      <rect x="636"  y="1300"  rx="8" ry="8" width="180" height="287" />
      <rect x="838"  y="1300"  rx="8" ry="8" width="180" height="287" />
      <rect x="1040" y="1300"  rx="8" ry="8" width="180" height="287" />
      <rect x="1242" y="1300"  rx="8" ry="8" width="180" height="287" />
      <rect x="1444" y="1300"  rx="8" ry="8" width="180" height="287" />
      <rect x="1646" y="1300"  rx="8" ry="8" width="180" height="287" />
      <rect x="1848" y="1300"  rx="8" ry="8" width="180" height="287" />
      <rect x="2050" y="1300"  rx="8" ry="8" width="180" height="287" />
      <rect x="2252" y="1300"  rx="8" ry="8" width="180" height="287" />
      <rect x="2454" y="1300"  rx="8" ry="8" width="180" height="287" />
      
    </ContentLoader>
  );
};

export default ListItemLoader;