import React from 'react'
function HeaderRanking() {
  return (
    <div className='ranking-grid'>
      <div className='ranking-contain'>
        <h2 className='ranking-title'>Top 5 Soluciones</h2>
        <span className='ranking-subtitle'>Conoce nuestro ranking de mejores soluciones</span>
      </div>
    </div>
  )
}
export default HeaderRanking
