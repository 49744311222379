import React from 'react'
import RecoverPasswordForm from './RecoverPasswordForm.js'
import recoverPicture from './../../assets/img/FC_Foto_CambioContrasena.jpg'

export const RecoverPassword = () => {
  return (
    <div>
      <div className='auth__recover'>
        <div
          className='auth__box-recover-container animate__animated animate__fadeIn animate__faster'
          style={{ maxWidth: '502px' }}>
          <RecoverPasswordForm mode={undefined} />
          <img className='recoverPwd__sideimg' src={recoverPicture} alt='' />
        </div>
      </div>
    </div>
  )
}

export default RecoverPassword
