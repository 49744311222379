import HeaderBanner from 'components/Header/HeaderBanner'
import React from 'react'
import Layout from './../Layout/Layout'
import Register from './../Users/Register'
const RegisterPage = () => (
  <Layout>
    <HeaderBanner title='REGISTRO' />
    <Register />
  </Layout>
)
export default RegisterPage
