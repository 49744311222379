import { put, call, takeLatest, delay } from 'redux-saga/effects'
import {
  registerListError,
  registerListSectorError,
  registerListSectorSuccess,
  REGISTER_CREATE_FAILURE,
  REGISTER_CREATE_SUCCESS,
  REGISTER_EDIT_FAILURE,
  REGISTER_EDIT_REQUEST,
  REGISTER_GET_ALL_FAILURE,
  REGISTER_GET_ALL_REQUEST,
  REGISTER_GET_ALL_SUCCESS,
  REGISTER_GET_ONE_FAILURE,
  REGISTER_GET_ONE_REQUEST,
  REGISTER_GET_ONE_SUCCESS,
  REGISTER_REMOVE_FAILURE,
  REGISTER_REMOVE_REQUEST,
  REGISTER_REMOVE_SUCCESS,
  REGISTER_GET_CITIES_SUCCESS,
  REGISTER_GET_CITIES_FAILURE,
  REGISTER_GET_CITIES_REQUEST,
  typesRegister,
  registerListCompanySuccess,
  registerListCompanyError,
  registersCreateSuccess,
  registersCreateFailure,
  countryListSuccess,
  stateListSuccess,
  countryListError
} from './register.redux'
import axios from 'axios'
import Swal from 'sweetalert2'
import { push } from 'connected-react-router'
import { hideLoading, showLoading } from 'react-redux-loading-bar'
import { manageError } from '../../redux/helpers/handlers.js'
import { loginRequest } from 'components/Auth/auth.redux'

function* countryListRequestSaga() {
  try {
    const { data } = yield call(axios.get, `https://countriesnow.space/api/v0.1/countries/iso`)
    yield delay(process.env.REACT_APP_DELAY)
    yield put(countryListSuccess(data))
  } catch (error) {
    const countries = yield require('assets/countries.json')
    yield put(countryListError(countries))
  }
}

function* stateListRequestSaga({ payload }) {
  try {
    const body = {
      country: payload
    }
    const { data } = yield call(
      axios.post,
      `https://countriesnow.space/api/v0.1/countries/states`,
      body
    )
    yield delay(process.env.REACT_APP_DELAY)
    yield put(stateListSuccess(data))
  } catch (error) {
    Swal.fire('Error', error, 'error')
    yield put(registerListError(error.message))
  }
}

function* getCitiesSaga({ country, state }) {
  try {
    const body = {
      country: country,
      state: state
    }
    const { data } = yield call(
      axios.post,
      `https://countriesnow.space/api/v0.1/countries/state/cities`,
      body
    )
    yield put({ type: REGISTER_GET_CITIES_SUCCESS, payload: data })
  } catch (error) {
    yield put({ type: REGISTER_GET_CITIES_FAILURE, error: error.response })
  }
}
function* registerListSectorRequest({ page = 1 }) {
  try {
    const { data } = yield call(axios.get, `/api/sectors?page=${page}&limit=242&active=true`)
    yield delay(process.env.REACT_APP_DELAY)
    yield put(registerListSectorSuccess(data.data))
  } catch (error) {
    Swal.fire('Error', error, 'error')
    yield put(registerListSectorError(error.message))
  }
}

function* registerListCompanyRequest({ page = 1 }) {
  try {
    // const filterString = encodeFiltersData(filters)
    const { data } = yield call(axios.get, `/api/companies?page=${page}&limit=242`)
    yield delay(process.env.REACT_APP_DELAY)
    yield put(registerListCompanySuccess(data.data))
  } catch (error) {
    Swal.fire('Error', error, 'error')
    yield put(registerListCompanyError(error.message))
  }
}

export function* getAllRegisterSaga({ page = 1 }) {
  try {
    const { data } = yield call(axios.get, `/api/register?page=${page}`)
    yield delay(process.env.REACT_APP_DELAY)
    yield put({ type: REGISTER_GET_ALL_SUCCESS, payload: data?.data })
  } catch (error) {
    Swal.fire('Error', error, 'error')
    yield put({ type: REGISTER_GET_ALL_FAILURE, error: error.response })
  }
}

export function* createRegisterSaga({ payload }) {
  try {
    yield put(showLoading())
    const insert = yield call(axios.post, '/api/users', payload)
    yield put(hideLoading())
    if (!payload.provider) {
      yield put(registersCreateSuccess(insert))
      yield put(push('/login'))
      Swal.fire(
        'Registro exitoso',
        'Se le ha mandado un email a su cuenta, favor de verificarlo para poder realizar el ingreso a la plataforma',
        'info'
      )
    }
    // En caso de que sea una cuenta de google y el registro sea exitoso, mandar mensaje
    // e iniciar sesion automaticamente
    else {
      if (insert.status === 201) {
        Swal.fire(
          'Registro exitoso',
          'Edite su perfil para completar su información y poder brindarle un mejor servicio.',
          'success'
        )
        yield put(
          loginRequest({ email: payload.email, password: payload.password, provider: true })
        )
      }
    }
  } catch (error) {
    yield put(hideLoading())
    if (!payload.provider) yield put(error.response)
   // Si ya existe un usuario con el mismo email, y ya se ha iniciado  sesion con google, loguearse en el sistema
    else
    yield put(
    loginRequest({ email: payload.email, password: payload.password, provider: true })
    )
  }
}

export function* removeRegisterSaga({ id }) {
  try {
    const insert = yield call(axios.delete, '/api/register/' + id)
    yield delay(process.env.REACT_APP_DELAY)
    yield put({ type: REGISTER_REMOVE_SUCCESS, payload: insert })
    yield put({ type: REGISTER_GET_ALL_REQUEST })
    yield put(push('/admin/card'))
  } catch (error) {
    Swal.fire('Error', error, 'error')
    yield put({ type: REGISTER_REMOVE_FAILURE, error: error.response })
  }
}
export function* getOneRegisterSaga({ id = 1 }) {
  try {
    const { data } = yield call(axios.get, `/api/register/${id}`)
    yield delay(process.env.REACT_APP_DELAY)
    yield put({ type: REGISTER_GET_ONE_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: REGISTER_GET_ONE_FAILURE, error: error.response })
  }
}
export function* editRegisterSaga({ payload }) {
  try {
    const insert = yield call(axios.put, '/api/register/' + payload._id, payload)
    yield put({ type: REGISTER_CREATE_SUCCESS, payload: insert })
    yield put(push('/admin/register'))
  } catch (error) {
    Swal.fire('Error', error, 'error')
    yield put({ type: REGISTER_CREATE_FAILURE, error: error.response })
  }
}

function errorRegister({ payload }) {
  try {
    Swal.fire('Alerta', payload?.data.message, 'info')
  } catch (error) {
    Swal.fire('Error', 'error', 'error')
  }
}

export default function* RegisterSagas() {
  yield takeLatest(typesRegister.registerListSectorRequest, registerListSectorRequest)
  yield takeLatest(typesRegister.registerListCompanyRequest, registerListCompanyRequest)
  yield takeLatest(typesRegister.countryListRequest, countryListRequestSaga)
  yield takeLatest(typesRegister.stateListRequest, stateListRequestSaga)
  yield takeLatest(typesRegister.registerCreateRequest, createRegisterSaga)
  yield takeLatest(typesRegister.registerCreateError, errorRegister)
  yield takeLatest(REGISTER_CREATE_FAILURE, manageError)
  yield takeLatest(REGISTER_GET_ALL_REQUEST, getAllRegisterSaga)
  yield takeLatest(REGISTER_GET_ALL_FAILURE, manageError)
  yield takeLatest(REGISTER_REMOVE_REQUEST, removeRegisterSaga)
  yield takeLatest(REGISTER_REMOVE_FAILURE, manageError)
  yield takeLatest(REGISTER_EDIT_REQUEST, editRegisterSaga)
  yield takeLatest(REGISTER_EDIT_FAILURE, manageError)
  yield takeLatest(REGISTER_GET_ONE_REQUEST, getOneRegisterSaga)
  yield takeLatest(REGISTER_GET_ONE_FAILURE, manageError)
  yield takeLatest(REGISTER_GET_CITIES_REQUEST, getCitiesSaga)
  yield takeLatest(REGISTER_GET_CITIES_FAILURE, manageError)
}
