import React from 'react'
import PuffLoader from 'react-spinners/PuffLoader'

const override = `
  display: block;
  margin: 0 auto;
  border-color: #58bde5;
`

export const Loading = () => {
  return (
    <div className='sweet-loading'>
      <PuffLoader color={'#5cabdf'} loading={'loading'} css={override} size={230} />
    </div>
  )
}
