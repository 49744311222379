import { Row } from 'reactstrap'
import React from 'react'
import { ContainerButtons } from './ButtonsStyledComponents'

const ButtonsContainer = ({ children, marginBottom }) => {
  return (
    <ContainerButtons>
      <Row style={{ marginBottom: marginBottom }}>{children}</Row>
    </ContainerButtons>
  )
}

export default ButtonsContainer
