import React from 'react'
import PropTypes from 'prop-types'
function Company({ name, thumbnail }) {
  return (
    <img
      src={thumbnail}
      alt={name}
      className='rounded-circle p-1 bg-primary home-company-circle'
      width='170'></img>
  )
}

Company.propTypes = {
  name: PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired
}

Company.defaultProps = {
  thumbnail: ''
}

export default Company
