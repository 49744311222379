import { put, call, takeLatest, delay } from 'redux-saga/effects'

import {
  contactListError,
  contactListSuccess,
  CONTACT_CREATE_FAILURE,
  CONTACT_CREATE_REQUEST,
  CONTACT_CREATE_SUCCESS,
  CONTACT_EDIT_REQUEST,
  CONTACT_GET_ALL_FAILURE,
  CONTACT_GET_ALL_REQUEST,
  CONTACT_GET_ALL_SUCCESS,
  CONTACT_GET_ONE_FAILURE,
  CONTACT_GET_ONE_REQUEST,
  CONTACT_GET_ONE_SUCCESS,
  CONTACT_REMOVE_FAILURE,
  CONTACT_REMOVE_REQUEST,
  CONTACT_REMOVE_SUCCESS,
  typesContact
} from './contact.redux'
import axios from 'axios'
import Swal from 'sweetalert2'
import { push } from 'connected-react-router'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import { manageError } from './../../redux/helpers/handlers.js'

function* contactListRequest({ page = 1 }) {
  try {
    const { data } = yield call(axios.get, `/api/contacts?page=${page}`)
    yield delay(process.env.REACT_APP_DELAY)
    yield put(contactListSuccess(data.data))
  } catch (error) {
    Swal.fire('Error', error, 'error')
    yield put(contactListError(error.message))
  }
}

export function* getAllContactSaga({ page = 1 }) {
  try {
    const { data } = yield call(axios.get, `/api/contacts?page=${page}`)
    yield delay(process.env.REACT_APP_DELAY)
    yield put({ type: CONTACT_GET_ALL_SUCCESS, payload: data?.data })
  } catch (error) {
    Swal.fire('Error', error, 'error')
    yield put({ type: CONTACT_GET_ALL_FAILURE, error: error.response })
  }
}

export function* createContactSaga({ payload }) {
  try {
    yield put(showLoading())
    yield delay(process.env.REACT_APP_DELAY)
    yield axios
      .post(`/api/contacts`, payload)
      .then(async () => {
        await Swal.fire('Muchas gracias', 'Hemos recibido tu mensaje...', 'success')
      })
      .catch(error => {
        Swal.fire('Error', 'AL guardar en la Base de Datos: ' + error, 'error')
      })
    yield put(push('/'))
    yield delay(process.env.REACT_APP_DELAY)
    yield put(hideLoading())
  } catch (error) {
    Swal.fire('Error', 'Al conectar con el servidor' + error, 'error')
  }
}

export function* removeContactSaga({ id }) {
  try {
    const insert = yield call(axios.delete, '/api/contacts/' + id)
    yield delay(process.env.REACT_APP_DELAY)
    yield put({ type: CONTACT_REMOVE_SUCCESS, payload: insert })
    yield put({ type: CONTACT_GET_ALL_REQUEST })
    yield put(push('/admin/card'))
  } catch (error) {
    Swal.fire('Error', error, 'error')
    yield put({ type: CONTACT_REMOVE_FAILURE, error: error.response })
  }
}
export function* getOneContactSaga({ id = 1 }) {
  try {
    const { data } = yield call(axios.get, `/api/contacts/${id}`)
    yield delay(process.env.REACT_APP_DELAY)
    yield put({ type: CONTACT_GET_ONE_SUCCESS, payload: data?.data })
  } catch (error) {
    Swal.fire('Error', error, 'error')
    yield put({ type: CONTACT_GET_ONE_FAILURE, error: error.response })
  }
}
export function* editContactSaga({ payload }) {
  try {
    const insert = yield call(axios.put, '/api/contacts/' + payload._id, payload)
    yield put({ type: CONTACT_CREATE_SUCCESS, payload: insert })
    yield put(push('/admin/contact'))
  } catch (error) {
    Swal.fire('Error', error, 'error')
    yield put({ type: CONTACT_CREATE_FAILURE, error: error.response })
  }
}

export default function* contactSagas() {
  yield takeLatest(typesContact.contactListRequest, contactListRequest)
  yield takeLatest(CONTACT_CREATE_REQUEST, createContactSaga)
  yield takeLatest(CONTACT_GET_ALL_REQUEST, getAllContactSaga)
  yield takeLatest(CONTACT_REMOVE_REQUEST, removeContactSaga)
  yield takeLatest(CONTACT_EDIT_REQUEST, editContactSaga)
  yield takeLatest(CONTACT_GET_ONE_REQUEST, getOneContactSaga)
  yield takeLatest(CONTACT_GET_ALL_FAILURE, manageError)
  yield takeLatest(CONTACT_REMOVE_FAILURE, manageError)
  yield takeLatest(CONTACT_GET_ONE_FAILURE, manageError)
}
