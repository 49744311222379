import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Row, Col } from 'reactstrap'
import styled from 'styled-components'
import CardDetailClimb from './CardDetailClimb.js'
import CardDetailFrontSmall from './CardDetailFrontSmall.js'

const NumberList = styled.span`
  font-weight: 400;
`

const DivContainer = styled.section`
  background: #f5f5f5;
  margin: 20px 5px 4px 5px;
  padding: 2px 0 0 0;
`

export const Card = ({ data, areas }) => {
  const { _id, gta, what, climb, indicator, how1, how2, how3, area } = data
  return (
    <div
      className='col-md-3 cards__card-dimension animate__animated animate__fadeIn animate__faster'
      style={{ maxWidth: '298px', minWidth: '290px' }}>
      <Link to={`/card/${_id}`}>
        <div className='flashcard-container'>
          <div className='flashcard'>
            {/* ----- FRONT ---- */}
            <CardDetailFrontSmall data={data} />
            {/* ----- BACK ---- */}
            <div className='flashcard-back'>
              <div className='flashcard-main-back'>
                <div className='flashcard-div-border'>
                  <h7
                    className='flashcard-text-center'
                    style={{ color: gta?.color, fontSize: '14px', fontWeight: '400' }}>
                    ¿Qué?
                  </h7>
                  <p className='flashcard-text-solution'>{what}</p>
                  <h7
                    className='flashcard-text-center'
                    style={{ color: gta?.color, fontSize: '13px', fontWeight: '400' }}>
                    ¿Cómo implementar la propuesta?
                  </h7>
                  {how1 ? (
                    <p className='flashcard-text-solution' style={{ marginBottom: '-3px' }}>
                      <Row>
                        <Col style={{ maxWidth: '14px', paddingRight: '0px' }}>
                          <NumberList style={{ color: gta?.color }}>1. </NumberList>
                        </Col>
                        <Col>{how1}</Col>
                      </Row>
                    </p>
                  ) : (
                    ''
                  )}
                  {how2 ? (
                    <p className='flashcard-text-solution' style={{ marginBottom: '-3px' }}>
                      <Row>
                        <Col style={{ maxWidth: '14px', paddingRight: '0px' }}>
                          <NumberList style={{ color: gta?.color }}>2. </NumberList>
                        </Col>
                        <Col>{how2}</Col>
                      </Row>
                    </p>
                  ) : (
                    ''
                  )}
                  {how3 ? (
                    <p className='flashcard-text-solution' style={{ marginBottom: '-3px' }}>
                      <Row>
                        <Col style={{ maxWidth: '14px', paddingRight: '0px' }}>
                          <NumberList style={{ color: gta?.color }}>3. </NumberList>
                        </Col>
                        <Col>{how3}</Col>
                      </Row>
                    </p>
                  ) : (
                    ''
                  )}
                  <DivContainer>
                    <Row>
                      <Col style={{ maxWidth: '65px' }}>
                        <h7
                          className='flashcard-text-center'
                          style={{ color: gta?.color, fontSize: '13px', fontWeight: '400' }}>
                          Indicador
                        </h7>
                      </Col>
                      <Col>
                        <p className='flashcard-text-solution'>
                          {indicator === '' ? 'N/A' : indicator}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col style={{ maxWidth: '65px' }}>
                        <h7
                          className='flashcard-text-center'
                          style={{ color: gta?.color, fontSize: '13px', fontWeight: '400' }}>
                          Ownership
                        </h7>
                      </Col>
                      <Col>
                        <p className='flashcard-text-solution'>
                          {area !== undefined && area !== null && area.length > 0 ? (
                            <ul>
                              {area?.map(a => {
                                const areaItem = areas?.find(ar => ar._id === a)
                                if (areaItem?.name)
                                  return <li key={areaItem?._id}> {areaItem?.name}</li>
                              })}
                            </ul>
                          ) : (
                            'N/A'
                          )}
                        </p>
                      </Col>
                    </Row>
                  </DivContainer>
                  <div
                    className='flashcard-footer-back-indicador'
                    style={{ color: gta?.color }}>
                    Ver más →
                  </div>
                </div>
                <div className='flashcard-footer-back justify-content-center'>
                  <div className='flashcard-footer-back-climb justify-content-center '>
                    {climb?.map(c => (
                      <CardDetailClimb
                        key={c._id}
                        letter={c.nomenclature}
                        color={gta?.color}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}
const mapStateToProps = state => {
  return {
    data: state.cardsList,
    stateA: state
  }
}
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(Card)
