import React, { useEffect } from 'react'
import profilePicture from './../../assets/img/profileavatar.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEnvelope,
  faMapMarkerAlt,
  faPhoneAlt,
  faSuitcase,
  faHeart,
  faClone,
  faStar,
  faUser
} from '@fortawesome/free-solid-svg-icons'
import { Link, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { usersGetOneRequest } from './users.redux'
import ProfilePicture from './ProfilePicture'

function Profile({ one, usersGetOneRequest, ...props }) {
  const iconEye = (
    <FontAwesomeIcon style={{ margin: '0px 5px 0px 0px', color: '#AAD4C8' }} icon={faStar} />
  )
  const iconHeart = (
    <FontAwesomeIcon style={{ margin: '0px 5px 0px 1px', color: '#AAD4C8' }} icon={faHeart} />
  )
  const { _id } = useParams()

  useEffect(() => {
    usersGetOneRequest({ _id })
  }, [usersGetOneRequest, _id])

  const iconSuitcase = <FontAwesomeIcon icon={faSuitcase} />
  const iconFileAlt = (
    <FontAwesomeIcon style={{ margin: '6px 5px 0 1px', color: '#AAD4C8' }} icon={faClone} />
  )
  const iconMarker = <FontAwesomeIcon icon={faMapMarkerAlt} />
  const iconMail = <FontAwesomeIcon icon={faEnvelope} />
  const iconPhone = <FontAwesomeIcon icon={faPhoneAlt} />
  const iconUser = <FontAwesomeIcon icon={faUser} />

  return (
    <div className='user__pfl'>
      <div className='user__profile'>
        <div className='row'>
          <div
            className='user__profile-header'
            style={{ marginTop: '30px', marginBottom: '30px' }}>
            <div className='user__profile-picture'>
              <img src={one?.thumbnail || profilePicture} alt='profilePicture' />
              <div className='user__icon-plus'>
                <ProfilePicture profileuser={one} />
              </div>
            </div>
            <div className='user__profile-description' style={{ marginTop: '30px' }}>
              <h3>
                {one?.name} {one?.lastName}
              </h3>
              <Link to={`/my-actions/${_id}`}>
                <span className='saved-cards'>{iconHeart} Mis acciones favoritas</span>
              </Link>
              <Link to={`/my-initiatives/${_id}`}>
                <span className='saved-cards'>
                  {iconFileAlt} Iniciativas en las que participo
                </span>
              </Link>
              <Link to={`/saved-cards/${_id}`}>
                <span className='saved-cards'>
                  {iconEye} Ver mis Tarjetas GTA2030 favoritas
                </span>
              </Link>
            </div>
            <div className='user__profile-description'></div>
          </div>
        </div>
      </div>
      <div className='row user__profile-details'>
        <div className='col-lg-12'>
          <div className='profile__form form container' style={{ paddingTop: '40px' }}>
            <div className='row '>
              <div className='col-6'>
                <div className='form-group'>
                  <label className='form-label auth__label form-profile-space'>
                    Empresa u organización
                  </label>
                  <div className='form-profile-space'>
                    <div className='profile__icons'>{iconSuitcase}</div>
                    <input
                      disabled
                      className='select-input__profile'
                      value={one.company?.name}
                    />
                  </div>
                </div>
                <div className='form-group'>
                  <label className='form-label auth__label'>Sector</label>
                  <div className='form-profile-space'>
                    <div className='profile__icons'>{iconSuitcase}</div>
                    <input disabled id='sector' label='Sector' value={one.sector?.name} />
                  </div>
                </div>
                <div className='form-group'>
                  <label className='form-label auth__label'>País</label>
                  <div className='form-profile-space'>
                    <div className='profile__icons'>{iconMarker}</div>
                    <input disabled label='País' defaultValue={one?.country} />
                  </div>
                </div>
                <div className='form-group'>
                  <label className='form-label auth__label'>Estado</label>
                  <div className='form-profile-space'>
                    <div className='profile__icons'>{iconMarker}</div>
                    <input disabled id='residence' label='Estado' value={one?.state} />
                  </div>
                </div>
              </div>
              <div className='col-6'>
                <div className='form-group'>
                  <label className='form-label auth__label'>Ciudad</label>
                  <div className='form-profile-space'>
                    <div className='profile__icons'>{iconMarker}</div>
                    <input disabled label='Ciudad' defaultValue={one?.city} />
                  </div>
                </div>
                <div className='form-group'>
                  <label className='form-label auth__label'>Correo electrónico</label>
                  <div className='form-profile-space'>
                    <div className='profile__icons icon-right'>{iconMail}</div>
                    <input disabled id='email' label='Correo electrónico' value={one?.email} />
                  </div>
                </div>
                <div className='form-group'>
                  <label className='form-label auth__label'>Teléfono</label>
                  <div className='form-profile-space'>
                    <div className='profile__icons icon-right'>{iconPhone}</div>
                    <input
                      disabled
                      id='telephone'
                      label='Teléfono'
                      value={one.profileUser?.telephone}
                    />
                  </div>
                </div>
                <div className='form-group'>
                  <label className='form-label auth__label'>Género</label>
                  <div className='form-profile-space'>
                    <div className='profile__icons icon-right'>{iconUser}</div>
                    <input
                      disabled
                      id='email'
                      label='Género'
                      value={one?.gender || one?.otherGender}
                    />
                  </div>
                </div>
                <div className='profile_buttons'>
                  <div className='user-profile-div-button'>
                    <Link to={`/edit/${one._id}`}>
                      <button className='btn btn-primary btn-block user-profile-button'>
                        {'Editar perfil'}
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    one: state.users.one
  }
}

const mapDispatchToProps = {
  usersGetOneRequest: usersGetOneRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
