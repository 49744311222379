import React from 'react'
import { ButtonCardContent, ButtonLink } from './ButtonsStyledComponents'

function ButtonCard({ title, img, url }) {
  return (
    <ButtonLink to={url}>
      <ButtonCardContent>{title}</ButtonCardContent>
    </ButtonLink>
  )
}

export default ButtonCard
