import React, { useEffect } from 'react'
import { Formik, Form } from 'formik'
import FormInput from './../Helpers/FormInput.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faMapMarkerAlt,
  faPhoneAlt,
  faSuitcase,
  faUser
} from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'
import profilePicture from './../../assets/img/profileavatar.svg'
import LoadingBar from 'react-redux-loading-bar'
import { userUpdateRequest } from './users.redux'
import { profileUsersUpdateRequest } from './profile.redux'
import {
  countryListRequest,
  stateListRequest,
  registerListCompanyRequest,
  registerListSectorRequest,
  registerGetCitiesRequest
} from './../Users/register.redux'
import { genders } from 'utils/auth.js'

function ProfileForm({
  user,
  profileuser,
  sectorList,
  companyList,
  countryListRequest,
  countryList,
  stateList,
  stateListRequest,
  registerListSectorRequest,
  registerListCompanyRequest,
  registerGetCitiesRequest,
  cities,
  isEmpty,
  ...props
}) {
  const handleEditProfile = values => {
    props.userUpdateRequest({ values })
    props.profileUsersUpdateRequest({ values })
  }

  useEffect(() => {
    registerListSectorRequest()
    registerListCompanyRequest()
    countryListRequest()
  }, [registerListSectorRequest, registerListCompanyRequest, countryListRequest])

  const companies = companyList && companyList.docs?.map(c => c)
  const sectors = sectorList && sectorList.docs?.map(c => c)

  let initialValuesData = {
    _id: profileuser._id,
    name: profileuser.name,
    lastName: profileuser.lastName,
    company: profileuser.company?._id,
    sector: profileuser.sector?._id,
    country: profileuser?.country,
    state: profileuser?.state,
    city: profileuser?.city,
    gender: profileuser?.gender,
    otherGender: profileuser?.otherGender,
    profileId: profileuser.profileUser?._id,
    telephone: profileuser?.profileUser?.telephone,
    email: profileuser?.email
  }

  const iconSuitcase = <FontAwesomeIcon icon={faSuitcase} />
  const iconUser = <FontAwesomeIcon icon={faUser} />
  const iconMarker = <FontAwesomeIcon icon={faMapMarkerAlt} />
  const iconPhone = <FontAwesomeIcon icon={faPhoneAlt} />

  return (
    <>
      <LoadingBar
        showFastActions
        maxProgress={100}
        progressIncrease={50}
        style={{ backgroundColor: '#7a9ec6', height: '5px', top: 0, left: 0, zIndex: 999999 }}
      />
      <div className='user__pfl'></div>
      <div className='user__profile'>
        <div className='row'>
          <div
            className='user__profile-header-cards'
            style={{ height: '188px', paddingTop: '8px' }}>
            <div className='user__profile-picture'>
              <img src={profileuser?.thumbnail || profilePicture} alt='profilePicture' />
            </div>
            <div className='user__profile-description' style={{ paddingTop: '37px' }}>
              <h2>{'Editar Perfil'}</h2>
              <h3>
                {profileuser?.name} {profileuser?.lastName}
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className='user__profile-details'>
        <div className='profile__form'>
          <Formik
            initialValues={initialValuesData}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                handleEditProfile(values)
                setSubmitting(false)
              }, 400)
            }}>
            {({ values }) => (
              <Form className='form profile__form'>
                <div className='row'>
                  <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6'>
                    <div className='field-input__profile profile__icons__edit'>{iconUser}</div>
                    <FormInput id='name' label='Nombre' value={values.name} />
                    <div className='field-input__profile profile__icons__edit'>{iconUser}</div>
                    <FormInput id='lastName' label='Apellidos' value={values.lastName} />

                    <div className='profile__icons__edit'>{iconSuitcase}</div>
                    <FormInput
                      className='select-input__profile'
                      label='Empresa u organización'
                      type='multiselect'
                      placeholder={profileuser.company?.name}
                      name='company'
                      id='company'
                      onChange={e => {
                        values.company = e._name || e.value
                        registerListCompanyRequest(e.value)
                      }}
                      options={
                        companies &&
                        companies.map(({ _id, name }) => ({
                          value: name,
                          label: name,
                          _id
                        }))
                      }
                    />
                    <div className='profile__icons__edit'>{iconSuitcase}</div>
                    <FormInput
                      label='Sector'
                      placeholder={profileuser.sector?.name}
                      onChange={e => {
                        values.sector = e._id
                        registerListSectorRequest(e.value)
                      }}
                      type='singleselect'
                      id='sector'
                      name='sector'
                      options={
                        sectors &&
                        sectors.map(({ _id, name }) => ({
                          value: name,
                          label: name,
                          _id
                        }))
                      }
                    />
                    <div className='profile__icons__edit'>{iconMarker}</div>
                    <FormInput
                      label='País'
                      type='selectresidency'
                      value={values.country}
                      placeholder={values.country}
                      id='country'
                      onChange={e => {
                        values.country = e.value
                        stateListRequest(e.value)
                      }}
                      options={
                        countryList &&
                        countryList?.data?.map(({ name, Iso3 }) => ({
                          value: name,
                          name: name,
                          label: name,
                          id: name,
                          key: Iso3
                        }))
                      }
                    />
                    <div className='profile__icons__edit'>{iconMarker}</div>
                    <FormInput
                      id='state'
                      label='Estado'
                      placeholder={values.state}
                      type='selectresidency'
                      onChange={e => {
                        values.state = e.value
                        registerGetCitiesRequest(values.country, e.value)
                      }}
                      options={
                        stateList &&
                        stateList?.data?.states.map(({ name }) => ({
                          value: name,
                          label: name,
                          name: name,
                          id: name,
                          key: name
                        }))
                      }
                    />
                  </div>
                  <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6'>
                    <div className='profile__icons__edit'>{iconMarker}</div>
                    <FormInput
                      id='city'
                      label='Ciudad'
                      type='selectresidency'
                      placeholder={values.city}
                      onChange={e => {
                        values.city = e.value
                      }}
                      options={
                        cities &&
                        cities.data?.map(city => ({
                          value: city,
                          label: city,
                          id: city,
                          key: city
                        }))
                      }
                    />
                    <div className='field-input__profile profile__icons__edit'>
                      {iconPhone}
                    </div>
                    <FormInput id='telephone' label='Teléfono' value={values.telephone} />

                    <FormInput
                      id='gender'
                      label='Género'
                      other='otherGender'
                      type='radioButtonSmallProfile'
                      radioButton={genders}
                    />
                    <div className='profile_buttons' style={{ display: 'flex' }}>
                      <div className='user-profile-div-button'>
                        <button
                          type='submit'
                          className='btn btn-primary btn-block user-profile-button'>
                          {'Guardar'}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}
const mapStateToProps = state => {
  return {
    error: state.profileUser.error,
    countryList: state.register.countryList,
    stateList: state.register.stateList,
    companyList: state.register.companyList,
    sectorList: state.register.sectorList,
    cities: state.register.cities
  }
}

const mapDispatchToProps = {
  userUpdateRequest: userUpdateRequest,
  profileUsersUpdateRequest: profileUsersUpdateRequest,
  registerListCompanyRequest,
  countryListRequest,
  stateListRequest,
  registerListSectorRequest,
  registerGetCitiesRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileForm)
