import React from 'react'
import {
  ColStyled,
  Container,
  Division,
  Paragraph,
  RowStyled
} from 'components/Contact/contactStyleComponents'
import FooterContactForm from 'components/Contact/FooterContactForm'

const FooterContact = ({ title, subtitle }) => {
  return (
    <>
      <Container>
        <RowStyled>
          <ColStyled margin lg='4'>
            <h3>{title}</h3>
            <Paragraph>{subtitle}</Paragraph>
          </ColStyled>
          <ColStyled lg='1'>
            <Division />
          </ColStyled>
          <ColStyled lg='7'>
            <FooterContactForm />
          </ColStyled>
        </RowStyled>
      </Container>
    </>
  )
}

export default FooterContact
