export const implementationTime = [
  {
    id: 0,
    text: 'Corto Plazo',
    value: 0
  },
  {
    id: 1,
    text: 'Mediano Plazo',
    value: 1
  },
  {
    id: 2,
    text: 'Largo Plazo',
    value: 2
  }
]

export const typesAmbientGoodPractices = [
  {
    id: 0,
    value: 'En el interior de la empresa'
  },
  {
    id: 1,
    value: 'En el territorio'
  }
]

export const mapContainerStyle = {
  height: '300px',
  width: '100%'
}