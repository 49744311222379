import { Container } from 'reactstrap'
import styled from 'styled-components'

export const CardDetailContainer = styled(Container)`
  max-width: 1650px;
  @media (max-width: 768px) {
    margin-left: -12px;
  }
`
export const CardDetailDivFavorite = styled.div`
  margin: 8px 0px 0px 10px;
  display: flex;
  @media (max-width: 768px) {
    margin: 8px 0px 0px 17px;
  }
`
export const CardDetailDivActions = styled.div`
  margin: 8px 0px 0px 25px;
  display: flex;
  @media (max-width: 768px) {
    margin: 8px 0px 0px 32px;
  }
`
export const CardDetailDivPriorization = styled.div`
  margin-top: 23px;
  min-width: 263px;
  padding-right: 2px;
  padding-left: 10px;
  display: flex;
  @media (max-width: 768px) {
    padding-left: 17px;
  }
`
export const CardDetailLine = styled.div`
  width: 210px;
  height: 1px;
  background-color: ${({ color }) => color};
  margin: -3px 4px 5.5px 10px;
  @media (max-width: 768px) {
    margin: 0px 4px 7.5px 15px;
  }
`

export const CardDetailWorkGroup = styled.span`
  font-weight: 500;
  font-size: 18px;
  width: 100%;
  margin-left: 10px;
  margin-top: -13px;
  margin-bottom: 11px;
  line-height: normal;
  font-family: 'Flame', 'Roboto', Arial, sans-serif;
  color: ${({ color }) => color};
  @media (max-width: 768px) {
    margin-left: 19px;
  }
`

export const CardDetailImageGta = styled.img.attrs({
  alt: 'google button'
})`
  width: auto;
  justify-content: left;
  align-items: left;
  height: 130px;
  margin-left: -12px;
  @media (max-width: 768px) {
    margin-left: 0px;
    margin-top: -30px;
  }
`
