import React, { useEffect } from 'react'
import { SavedJoin } from 'components/Join/JoinSaved'
import Layout from '../Layout/Layout'
import { actionGetAllRequest } from 'components/Join/joinAction.redux'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { SplashScreen } from 'components/SplashScreen/SplashScreen'
import { usersGetOneRequest } from 'components/Users/users.redux'
import { cardGetCatalogs } from 'components/Cards/cards.redux'

export const SavedJoinPage = props => {
  const { loading, actionGetAllRequest, cardGetCatalogs, cards } = props
  const { _id } = useParams()
  useEffect(() => {
    cardGetCatalogs()
    actionGetAllRequest({
      status: 2,
      active: true,
      limit: 20,
      volunteers: _id
    })
    usersGetOneRequest({ _id })
  }, [cardGetCatalogs, actionGetAllRequest, _id])
  return <Layout>{loading && cards ? <SplashScreen /> : <SavedJoin data={props} />}</Layout>
}

const mapStateToProps = ({ cards, joinActions, user, users }) => ({
  all: joinActions.list.docs,
  loading: joinActions.loading,
  loader: joinActions.loader,
  error: joinActions.error,
  filters: joinActions.filters,
  cards,
  user,
  one: users.one
})

const mapDispatchToProps = {
  actionGetAllRequest,
  usersGetOneRequest,
  cardGetCatalogs
}

export default connect(mapStateToProps, mapDispatchToProps)(SavedJoinPage)
