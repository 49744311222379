// TYPES
export const PRIORIZATION_GET_ONE_REQUEST = 'PRIORIZATION_GET_ONE_REQUEST'
export const PRIORIZATION_GET_ONE_SUCCESS = 'PRIORIZATION_GET_ONE_SUCCESS'
export const PRIORIZATION_GET_ONE_FAILURE = 'PRIORIZATION_GET_ONE_FAILURE'

export const PRIORIZATION_GET_AVERAGE_REQUEST = 'PRIORIZATION_GET_AVERAGE_REQUEST'
export const PRIORIZATION_GET_AVERAGE_SUCCESS = 'PRIORIZATION_GET_AVERAGE_SUCCESS'
export const PRIORIZATION_GET_AVERAGE_FAILURE = 'PRIORIZATION_GET_AVERAGE_FAILURE'

export const PRIORIZATION_GET_ALL_REQUEST = 'PRIORIZATION_GET_ALL_REQUEST'
export const PRIORIZATION_GET_ALL_SUCCESS = 'PRIORIZATION_GET_ALL_SUCCESS'
export const PRIORIZATION_GET_ALL_FAILURE = 'PRIORIZATION_GET_ALL_FAILURE'

export const typesPriorizations = {
  priorizationsListRequest: '[ PRIORIZATIONS ] PRIORIZATIONS_LIST_REQUEST',
  priorizationsListSuccess: '[ PRIORIZATIONS ] PRIORIZATIONS_LIST_SUCESS',
  priorizationsListError: '[ PRIORIZATIONS ] PRIORIZATIONS_LIST_ERROR'
}
// ACTIONS

export const priorizationGetOneRequest = payload => ({
  type: PRIORIZATION_GET_ONE_REQUEST,
  payload
})
export const priorizationGetOneSuccess = payload => ({
  type: PRIORIZATION_GET_ONE_SUCCESS,
  payload
})
export const priorizationGetAverageRequest = payload => ({
  type: PRIORIZATION_GET_AVERAGE_REQUEST,
  payload
})
export const priorizationGetAverageSuccess = payload => ({
  type: PRIORIZATION_GET_AVERAGE_SUCCESS,
  payload
})
export const priorizationGetOneFailure = payload => ({
  type: PRIORIZATION_GET_ONE_FAILURE,
  payload
})

export const priorizationsListRequest = filters => ({
  type: typesPriorizations.priorizationsListRequest,
  filters
})
export const priorizationsListSuccess = payload => ({
  type: typesPriorizations.priorizationsListSuccess,
  payload
})
export const priorizationsListError = payload => ({
  type: typesPriorizations.priorizationsListError,
  payload
})

// REDUCER
const initialState = {
  priorizationList: {},
  loading: false,
  loader: false,
  error: '',
  all: [],
  one: {},
  cardSelected: '',
  filters: {},
  average: {}
}

export function priorizations(state = initialState, action) {
  switch (action.type) {
    case typesPriorizations.priorizationsListSuccess:
      return { ...state, loading: false, priorizationList: action.payload }

    case PRIORIZATION_GET_ONE_SUCCESS:
      return { ...state, error: null, loading: false, one: { ...action.payload } }
    case PRIORIZATION_GET_AVERAGE_SUCCESS:
      return { ...state, error: null, loading: false, average: { ...action.payload } }

    case typesPriorizations.priorizationsListRequest:
    case PRIORIZATION_GET_ONE_REQUEST:
    case PRIORIZATION_GET_AVERAGE_REQUEST:
      return { ...state, error: null, loading: true }

    case typesPriorizations.priorizationsListError:
    case PRIORIZATION_GET_ONE_FAILURE:
    case PRIORIZATION_GET_AVERAGE_FAILURE:
      return { ...state, loading: false, error: action.error }
    default:
      return state
  }
}
