import React from 'react'
import logoGta from './../../assets/img/GTA2030-09.svg'

function GlossaryHeader() {
  return (
    <div className='ranking-grid'>
      <div className='ranking-contain'>
        <img
          src={logoGta}
          alt='logo gta'
          style={{ position: 'absolute', left: 0, width: '206px' }}
        />
        <h2 className='ranking-title'>
          Glosario y <br /> Simbología
        </h2>
      </div>
    </div>
  )
}

export default GlossaryHeader
