import React, { useState } from 'react'
import { Form, Formik } from 'formik'
import { Modal, ModalBody } from 'reactstrap'
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import JoinParraf from './JoinParraf'
import { GoogleMap, Marker } from '@react-google-maps/api'
import { concatArray } from '../../utils/arrays'
import { joinUserToAction } from './joinAction.redux'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'
import imgDefault from 'assets/img/defaults/defaultEdit.jpg'
import { Container, Row, Col } from 'reactstrap'
import { mapContainerStyle } from 'utils/goodPractices'
import {
  BannerImageJoin,
  BannerNameJoin,
  BtnLike,
  BtnVolunteers,
  ContainerJoinFirst,
  ContainerJoinSecond,
  LabelVolunteers,
  LogoJoin,
  OdsLogoJoin,
  RowButtons
} from './JoinStyledComponents'
import { cardsLikeRequest, cardsUnlikeRequest } from 'components/Cards/cards.redux'
import { urlImgODS } from 'helpers/imageDefaultsODS'

const JoinUserForm = ({
  joinUserToAction,
  user,
  joinAction,
  cardsUnlikeRequest,
  cardsLikeRequest,
  like
}) => {
  const iconThumbsUp = <FontAwesomeIcon style={{ marginRight: '5px' }} icon={faThumbsUp} />
  const {
    _id,
    aceptVolunteers,
    axisGlobalPact,
    name,
    objective,
    description,
    otherAmbit,
    ubication,
    lat,
    lng,
    promoterInstitution,
    implementationPeriod,
    implementationTime,
    indicator,
    alliedInstitutions,
    businessJoinType,
    businessJoinSubType,
    createdBy,
    shareName,
    numberOfVolunteers,
    information,
    email,
    volunteers,
    ods,
    image,
    logo
  } = joinAction

  const numStrOds = ods ? ods[0]?.objective || '0' : '0'
  const numOds = numStrOds.split(' ')[0]
  const payload = {
    user,
    goodPractice: _id
  }
  const numVolunteersRegister = volunteers?.length || 0
  const numVolunteers = typeof numberOfVolunteers === 'number' ? numberOfVolunteers : 0
  const totVolunteers = numVolunteersRegister + numVolunteers

  const handleRegister = () => {
    volunteers?.filter(val => val._id === user).length > 0
      ? Swal.fire({
          title: 'Ya te has unido a esta iniciativa, gracias por participar',
          icon: 'info',
          confirmButtonText: 'Aceptar'
        })
      : joinUserToAction({ _id })
  }

  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)

  const [isLiked, setIsLiked] = useState(like)
  const likeAction = () => {
    setIsLiked(!isLiked)
    !isLiked ? cardsLikeRequest(payload) : cardsUnlikeRequest(payload)
  }

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} className={'join-modal-content'}>
        <ModalBody>
          <Container className='home-main' style={{ justifyContent: 'center' }}>
            <Row xs='1'>
              <Col className='center-col  '>
                <h3 className='join-detail-title-popup'>
                  Conoce quien está
                  <br />
                  implementando esta iniciativa
                </h3>
              </Col>
            </Row>
          </Container>
          <Container className='home-main' style={{ justifyContent: 'center' }}>
            <Row xs='1' sm='2' md='2' lg='2' xl='2' xxl='2'>
              <Col className='center-col join-more-email-img'>
                {logo ? (
                  <img
                    src={logo}
                    className='footer-im img-modal-detail-join'
                    alt='logo'
                    style={{ width: 'auto' }}
                  />
                ) : (
                  <h3 className='join-more-email-modal'>{createdBy?.company?.name}</h3>
                )}
              </Col>
              <Col className='center-col'>
                <a href={`mailto:${email}`}>
                  <h3 className='join-more-email-modal'>{email}</h3>
                </a>
              </Col>
            </Row>
          </Container>
          <Container
            className='home-main'
            style={{ padding: '0 0 10px 0', height: '80px', justifyContent: 'center' }}>
            <a href={`mailto:${email}`} className='btn btn-block mt-5 join-more-modal'>
              {'Enviar'}
            </a>
          </Container>
        </ModalBody>
      </Modal>

      <Formik
        initialValues={{
          _id,
          status: 2,
          ubication,
          promoterInstitution,
          implementationPeriod,
          implementationTime: implementationTime?.name,
          name,
          objective,
          description,
          alliedInstitutions,
          information,
          email,
          ods: ods?.map(i => i.title),
          axisGlobalPact: axisGlobalPact?.map(i => i?.name),
          axisGlobalPactLogo: axisGlobalPact?.map(i => i?.logo).join(''),
          odsColor: ods?.map(c => c.color),
          odsLogo: ods?.map(l => l.logo),
          indicator,
          otherAmbit,
          logo,
          image,
          businessJoinType: concatArray(businessJoinType?.map(item => item.name)),
          businessJoinSubType: concatArray(businessJoinSubType?.map(item => item.name)),
          personWhoEmits: createdBy?.name + ' ' + createdBy?.lastName
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            handleRegister()
            setSubmitting(false)
          }, 0)
        }}>
        {({ values, isSubmitting, handleChange }) => {
          return (
            <Form className='form m-0'>
              <BannerNameJoin color={values.odsColor}>{values.name}</BannerNameJoin>
              <BannerImageJoin
                src={image || urlImgODS[numOds] || imgDefault}
                alt='BannerImageAction'
              />
              <div className='row pt-0'>
                <div className='col-md-9'>
                  <ContainerJoinFirst>
                    <JoinParraf
                      title={'Objetivos de la acción'}
                      text={values.objective}
                      colorParraf={values.odsColor}
                    />
                    <JoinParraf
                      title={'Principal ODS impactado'}
                      text={values.ods}
                      colorParraf={values.odsColor}
                    />
                    <JoinParraf
                      title={'Principal eje de Pacto Global de las Naciones Unidas'}
                      text={values.axisGlobalPact}
                      colorParraf={values.odsColor}
                    />

                    <JoinParraf
                      title={'Descripción de la acción'}
                      text={values.description}
                      colorParraf={values.odsColor}
                    />
                    <JoinParraf
                      title={'Ámbito de implementación'}
                      text={`${
                        values.otherAmbit !== 'undefined' &&
                        values.otherAmbit !== undefined &&
                        values.otherAmbit !== '' &&
                        values.otherAmbit !== null
                          ? values.businessJoinType === ' y '
                            ? values.otherAmbit
                            : values.otherAmbit + ', '
                          : ''
                      } ${values.businessJoinType === ' y ' ? '' : values.businessJoinType} `}
                      colorParraf={values.odsColor}
                    />
                    <JoinParraf
                      title={'Ubicación de la acción'}
                      text={values.ubication}
                      colorParraf={values.odsColor}></JoinParraf>
                    {lat && lng && (
                      <div>
                        <GoogleMap
                          id='searchbox-example'
                          mapContainerStyle={mapContainerStyle}
                          zoom={15}
                          center={{ lat: lat * 1, lng: lng * 1 }}>
                          <Marker position={{ lat: lat * 1, lng: lng * 1 }} />
                        </GoogleMap>
                      </div>
                    )}
                    <br />
                    <JoinParraf
                      title={'Nombre de la empresa que implementó la acción'}
                      text={values.promoterInstitution}
                      colorParraf={values.odsColor}
                    />
                    <JoinParraf
                      title={'Tamaño de empresa que implementa'}
                      text={values.businessJoinSubType}
                      colorParraf={values.odsColor}
                    />
                    <JoinParraf
                      title={'Otras organizaciones participantes'}
                      text={values.alliedInstitutions}
                      colorParraf={values.odsColor}
                    />
                    <JoinParraf
                      title={'Fecha de implementación de la acción'}
                      text={values.implementationPeriod}
                      colorParraf={values.odsColor}
                    />
                    <JoinParraf
                      title={'Duración de la iniciativa'}
                      text={values.implementationTime}
                      colorParraf={values.odsColor}
                    />
                    <JoinParraf
                      title={'¿Qué indicador se utiliza para medir el impacto de esta acción?'}
                      text={
                        values.indicator !== 'undefined' &&
                        values.indicator !== undefined &&
                        values.indicator !== ''
                          ? values.indicator
                          : 'Sin indicador'
                      }
                      colorParraf={values.odsColor}
                    />
                    <JoinParraf
                      title={'¿Dónde se puede encontrar más información sobre esta acción?'}
                      text={values.information}
                      colorParraf={values.odsColor}
                    />
                    <JoinParraf
                      title={'Correo de contacto para saber más sobre la iniciativa '}
                      text={values.email}
                      colorParraf={values.odsColor}
                    />
                    <JoinParraf
                      title={'Número de voluntarios en la iniciativa'}
                      text={totVolunteers}
                      colorParraf={values.odsColor}
                    />

                    {shareName && (
                      <JoinParraf
                        title={'Nombre de la persona que emite esta ficha técnica'}
                        text={createdBy ? values.personWhoEmits : 'Anónimo'}
                        colorParraf={values.odsColor}
                      />
                    )}

                    <RowButtons>
                      <div>
                        {aceptVolunteers && (
                          <>
                            {volunteers?.filter(val => val._id === user).length > 0 ? (
                              <LabelVolunteers>
                                Ya te has unido a esta iniciativa, gracias por participar.
                              </LabelVolunteers>
                            ) : (
                              <BtnVolunteers
                                type='submit'
                                className='btn'
                                disabled={isSubmitting}>
                                Unirme como voluntario de la iniciativa
                              </BtnVolunteers>
                            )}
                          </>
                        )}
                      </div>
                      <div>
                        <BtnLike onClick={likeAction} href={() => false} className='btn'>
                          {!isLiked ? (
                            <label>{iconThumbsUp} Like a la acción</label>
                          ) : (
                            <label>{iconThumbsUp} Te gusta esta acción</label>
                          )}
                        </BtnLike>
                      </div>
                    </RowButtons>
                  </ContainerJoinFirst>
                </div>
                <div className='col-md-3' style={{ backgroundColor: '#e5e5e5', padding: 0 }}>
                  <ContainerJoinSecond>
                    <OdsLogoJoin src={values.odsLogo} alt='odsLogo' />
                    {values?.axisGlobalPactLogo && (
                      <LogoJoin
                        title={values.axisGlobalPact}
                        src={values.axisGlobalPactLogo}
                        alt={'axisGlobalPactLogo'}
                      />
                    )}
                    {values.logo && <LogoJoin src={values.logo} alt='logoGood' />}
                  </ContainerJoinSecond>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

const mapStateToProps = ({ user, joinActions }) => ({
  user: user._id,
  joinAction: joinActions.one,
  loading: joinActions.loading,
  like: joinActions.like
})

const mapDispatchToProps = {
  joinUserToAction,
  cardsUnlikeRequest,
  cardsLikeRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(JoinUserForm)
