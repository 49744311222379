import HeaderBanner from 'components/Header/HeaderBanner'
import React from 'react'
import imgBanner from 'assets/img/banners/actions.webp'
import JoinUserForm from './JoinUserForm'

export const JoinUserToAction = () => {
  return (
    <div>
      <div>
        <HeaderBanner
          title='BANCO DE ACCIONES'
          img={imgBanner}
          second='¡Conoce las buenas prácticas!'
          description='Soluciones innovadoras en las que trabajan las empresas en México en su camino hacia la sostenibilidad.'
        />
        <div className='users__main-join mb-5'>
          <div className='users__box-container-user'>
            <JoinUserForm />
          </div>
        </div>
      </div>
    </div>
  )
}
