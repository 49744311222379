import { put, call, takeLatest, delay, select } from 'redux-saga/effects'

import {
  cardsListError,
  workAxisError,
  workAxisSuccess,
  CARDS_CREATE_FAILURE,
  CARDS_CREATE_REQUEST,
  CARDS_CREATE_SUCCESS,
  CARDS_EDIT_REQUEST,
  CARDS_FILTER_CHANGE_FAILURE,
  CARDS_FILTER_CHANGE_REQUEST,
  CARDS_FILTER_CHANGE_SUCCESS,
  CARDS_GET_ALL_FAILURE,
  CARDS_GET_ALL_REQUEST,
  CARDS_GET_ALL_SUCCESS,
  CARD_GET_ONE_FAILURE,
  CARD_GET_ONE_REQUEST,
  CARD_GET_ONE_SUCCESS,
  CARDS_REMOVE_FAILURE,
  CARDS_REMOVE_REQUEST,
  CARDS_REMOVE_SUCCESS,
  loadMoreCardsSuccess,
  typesCards,
  cardDetailSuccess,
  cardDetailError,
  CARDS_RANKING_FILTER_CHANGE_FAILURE,
  CARDS_RANKING_FILTER_CHANGE_REQUEST,
  CARDS_GET_CATALOGS_SUCCESS,
  CARDS_GET_CATALOGS_FAILURE,
  CARDS_GET_CATALOGS_REQUEST,
  CARDS_GET_MORE_REQUEST,
  CARDS_GET_MORE_FAILURE,
  CARDS_GET_MORE_SUCCESS,
  CARDS_CREATE_DOC_REQUEST,
  CARDS_CREATE_DOC_FAILURE,
  CARDS_CREATE_DOC_SUCCESS
} from './cards.redux.js'
import axios from 'axios'
import { serviceUrl } from './../../utils/serviceUrl.js'
import Swal from 'sweetalert2'
import { push } from 'connected-react-router'
import { encodeFiltersData, manageError } from '../../redux/helpers/handlers.js'
import { typesSavedCards } from './cardSaved.redux.js'
import { getUserId } from './../../redux/helpers/handlers.js'

function* cardsListRequest({ filters = {} }) {
  try {
    const filterString = encodeFiltersData(filters)
  } catch (error) {
    Swal.fire('Error', error, 'error')
    yield put(cardsListError(error.message))
  }
}

function* cardDetailRequest({ card }) {
  try {
    const { data } = card
      ? yield call(axios.get, `/api/card`)
      : yield call(axios.get, `/api/card`)
    yield delay(process.env.REACT_APP_DELAY)
    yield put(cardDetailSuccess(data.data))
  } catch (error) {
    Swal.fire('Error', error, 'error')
    yield put(cardDetailError(error.message))
  }
}

function* workAxisRequest() {
  try {
    const { data } = yield call(axios.get, `/api/workAxis`)
    yield delay(process.env.REACT_APP_DELAY)
    yield put(workAxisSuccess(data.data))
  } catch (error) {
    Swal.fire('Error', error, 'error')
    yield put(workAxisError(error.message))
  }
}

function* loadMoreCardsRequest({ filters = {} }) {
  try {
    const filterString = encodeFiltersData(filters)
    const { data } = filterString
      ? yield call(axios.get, `/api/flashcards/all?${filterString}`)
      : yield call(axios.get, `/api/flashcards/all`)
    yield delay(process.env.REACT_APP_DELAY)
    yield put(loadMoreCardsSuccess({ data: data.data }))
  } catch (error) {
    Swal.fire('Error', error, 'error')
    yield put(cardsListError(error.message))
  }
}

export function* getAllCardsSaga({ filters = { status: 2, active: true, limit: 30 } }) {
  try {
    const { user, ...filtersWithOutUser } = filters
    const filterString = encodeFiltersData(filtersWithOutUser)
    const { data } = yield call(axios.get, `/api/flashcards/?${filterString}`)
    yield put({ type: CARDS_GET_ALL_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: CARDS_GET_ALL_FAILURE, error: error.response })
  }
}

export function* getMoreCardsSaga({ filters = { status: 2, active: true, limit: 30 } }) {
  try {
    const { user, ...filtersWithOutUser } = filters
    const filterString = encodeFiltersData(filtersWithOutUser)
    const { data } = yield call(axios.get, `/api/flashcards/?${filterString}`)
    yield delay(800)
    yield put({ type: CARDS_GET_MORE_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: CARDS_GET_MORE_FAILURE, error: error.response })
  }
}

export function* createCardSaga({ payload }) {
  try {
    const insert = yield call(axios.post, serviceUrl.cards, payload)
    yield delay(process.env.REACT_APP_DELAY)
    yield put({ type: CARDS_CREATE_SUCCESS, payload: insert })
    yield put(push('/admin/card'))
  } catch (error) {
    Swal.fire('Error', error, 'error')
    yield put({ type: CARDS_CREATE_FAILURE, error: error.response })
  }
}
export function* createCardDocSaga({ payload }) {
  try {
    const user = yield select(u => getUserId(u))
    payload.user = user
    if (!user)
      return Swal.fire(
        'Aviso',
        'Debes iniciar sesión para poder realizar una descarga',
        'warning'
      )
    yield window.open(payload.document, '_blank')
    const insert = yield call(axios.post, '/api/gtasDocsDownloads/', payload)
    yield put({ type: CARDS_CREATE_DOC_SUCCESS, payload: insert })
  } catch (error) {
    Swal.fire('Error', error, 'error')
    yield put({ type: CARDS_CREATE_DOC_FAILURE, error: error.response })
  }
}

export function* removeCardsSaga({ id }) {
  try {
    const insert = yield call(axios.delete, '/api/flashcards/' + id)
    yield delay(process.env.REACT_APP_DELAY)
    yield put({ type: CARDS_REMOVE_SUCCESS, payload: insert })
    yield put({ type: CARDS_GET_ALL_REQUEST })
    yield put(push('/admin/card'))
  } catch (error) {
    Swal.fire('Error', error, 'error')
    yield put({ type: CARDS_REMOVE_FAILURE, error: error.response })
  }
}
export function* getOneCardSaga({ payload }) {
  const _id = payload._id
  try {
    const { data } = yield call(axios.get, `/api/flashcards/${_id}`)
    yield delay(process.env.REACT_APP_DELAY)
    yield put({ type: CARD_GET_ONE_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: CARD_GET_ONE_FAILURE, error: error.response })
  }
}
export function* cardsLikesRequestSaga({ filters = {} }) {
  try {
    const filterString = encodeFiltersData(filters)
    const { data } = yield call(axios.get, `/api/likes/count?${filterString}`)
    yield delay(process.env.REACT_APP_DELAY)
    yield put({
      type: typesCards.cardsLikesSuccess,
      payload: data.data
    })
  } catch (error) {
    Swal.fire('Error', error.message, 'error')
    yield put({ type: typesCards.cardsLikesError, error: error.response })
  }
}
export function* cardsLikeRequestSaga({ payload }) {
  try {
    if (payload.user === undefined) {
      yield put(push('/login'))
      return Swal.fire('Aviso', 'Debes iniciar sesión para poder dar like ', 'warning')
    }
    const { data } = yield call(axios.post, `/api/likes`, payload)
    // yield put({ type: typesCards.cardsLikeSuccess, payload: data?.data })
  } catch (error) {
    yield put({ type: typesCards.cardsLikeError, error: error.response })
  }
}
export function* cardsUnlikeRequestSaga({ payload }) {
  try {
    if (payload.user === undefined)
      return Swal.fire(
        'Aviso',
        'Debes iniciar sesión para poder dar like a una tarjeta',
        'warning'
      )
    if (payload.goodPractice === undefined)
      return Swal.fire(
        'Aviso',
        'Información de acción incompleta, favor de recargar la página e intentar de nuevo',
        'warning'
      )

    const { data } = yield call(axios.post, `/api/likes/delete`, payload)
    // yield put({ type: typesCards.cardsLikesRequest, filters })
    // yield delay(process.env.REACT_APP_DELAY)
    yield put({ type: typesCards.cardsLikeSuccess, payload: data?.data })
  } catch (error) {
    yield put({ type: typesCards.cardsLikeError, error: error.response })
  }
}
export function* editCardsSaga({ payload }) {
  try {
    const insert = yield call(axios.put, '/api/flashcards//' + payload._id, payload)
    yield delay(process.env.REACT_APP_DELAY)
    yield put({ type: CARDS_CREATE_SUCCESS, payload: insert })
    yield put(push('/admin/card'))
  } catch (error) {
    Swal.fire('Error', error, 'error')
    yield put({ type: CARDS_CREATE_FAILURE, error: error.response })
  }
}

export function* getCardFiltersSaga({ filters = {} }) {
  try {
    filters.page = 1
    filters.status = 2
    yield put({ type: CARDS_FILTER_CHANGE_SUCCESS, payload: filters })
    yield put({ type: typesSavedCards.savedCardsGetAllRequest, filters })
    yield put({ type: CARDS_GET_ALL_REQUEST, filters })
  } catch (error) {
    Swal.fire('Error', error, 'error')
    yield put({ type: CARDS_FILTER_CHANGE_FAILURE, error: error.response })
  }
}

export function* getRankingCardsFiltersSaga({ filters }) {
  try {
    filters.page = 1
    yield put({ type: CARDS_FILTER_CHANGE_SUCCESS, payload: filters })
    yield put({ type: typesCards.cardsListRequest, filters })
  } catch (error) {
    Swal.fire('Error', error, 'error')
    yield put({ type: CARDS_RANKING_FILTER_CHANGE_FAILURE, error: error.response })
  }
}

export function* getCatalogsSaga({ filters = { active: true } }) {
  try {
    const filterString = encodeFiltersData(filters)
    const { data: areas } = yield call(axios.get, `/api/areas/all?${filterString}`)
    const { data: workAxes } = yield call(axios.get, `/api/workAxes/all?${filterString}`)
    const { data: axesGlobalPact } = yield call(
      axios.get,
      `/api/axes-global-pact/all?${filterString}`
    )
    const { data: gtas } = yield call(axios.get, `/api/gtas/all?${filterString}`)
    const { data: timeInitiatives } = yield call(
      axios.get,
      `/api/timeInitiatives/all?${filterString}`
    )
    const { data: climbs } = yield call(axios.get, `/api/climbs/all?${filterString}`)
    const { data: businessTypes } = yield call(
      axios.get,
      `/api/business-types/all?${filterString}`
    )
    const { data: businessJoinTypes } = yield call(
      axios.get,
      `/api/business-join-types/all?${filterString}`
    )
    const { data: businessJoinSubtypes } = yield call(
      axios.get,
      `/api/business-joinsub-types/all?${filterString}`
    )
    const { data: objectives } = yield call(axios.get, `/api/objectives/all?${filterString}`)

    const payload = {
      workAxes,
      axesGlobalPact,
      gtas,
      climbs,
      businessTypes,
      businessJoinTypes,
      businessJoinSubtypes,
      objectives,
      areas,
      timeInitiatives
    }
    yield put({ type: CARDS_GET_CATALOGS_SUCCESS, payload: payload })
  } catch (error) {
    yield put({ type: CARDS_GET_CATALOGS_FAILURE, error: error.response })
  }
}

export default function* cardsSagas() {
  yield takeLatest(typesCards.cardsListRequest, cardsListRequest)
  yield takeLatest(typesCards.workAxisRequest, workAxisRequest)
  yield takeLatest(typesCards.cardDetailRequest, cardDetailRequest)
  yield takeLatest(typesCards.loadMoreCardsRequest, loadMoreCardsRequest)
  yield takeLatest(CARDS_CREATE_REQUEST, createCardSaga)
  yield takeLatest(CARDS_CREATE_DOC_REQUEST, createCardDocSaga)
  yield takeLatest(CARDS_GET_ALL_REQUEST, getAllCardsSaga)
  yield takeLatest(CARDS_GET_MORE_REQUEST, getMoreCardsSaga)
  yield takeLatest(CARDS_REMOVE_REQUEST, removeCardsSaga)
  yield takeLatest(CARDS_EDIT_REQUEST, editCardsSaga)
  yield takeLatest(CARD_GET_ONE_REQUEST, getOneCardSaga)
  yield takeLatest(typesCards.cardsLikesRequest, cardsLikesRequestSaga)
  yield takeLatest(typesCards.cardsLikeRequest, cardsLikeRequestSaga)
  yield takeLatest(typesCards.cardsUnlikeRequest, cardsUnlikeRequestSaga)
  yield takeLatest(CARDS_FILTER_CHANGE_REQUEST, getCardFiltersSaga)
  yield takeLatest(CARDS_RANKING_FILTER_CHANGE_REQUEST, getRankingCardsFiltersSaga)
  yield takeLatest(CARDS_GET_CATALOGS_REQUEST, getCatalogsSaga)
  yield takeLatest(CARDS_REMOVE_FAILURE, manageError)
  yield takeLatest(CARD_GET_ONE_FAILURE, manageError)
  yield takeLatest(CARDS_FILTER_CHANGE_FAILURE, manageError)
  yield takeLatest(CARDS_GET_CATALOGS_FAILURE, manageError)
  yield takeLatest(CARDS_GET_ALL_FAILURE, manageError)
}
