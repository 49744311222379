import React from 'react'
import { Link } from 'react-router-dom'

const LoginRegister = () => {
  return (
    <div className='text-center login_register-quest'>
      <hr />
      <p>¿No tienes una cuenta?</p>
      <Link to='/register' className='login_register-link'>
        Regístrate
      </Link>
    </div>
  )
}

export default LoginRegister
