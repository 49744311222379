import React from 'react'

import googleLogo from './../../assets/img/loading-ods.png'

const SpinnerODS = () => {
  return (
    <img
      className='spinner-ods rotate-ods animate__animated animate__fadeIn animate__fadeOut '
      src={googleLogo}
      alt=''
    />
  )
}

export default SpinnerODS
