import React, { useState, useEffect, useCallback } from 'react'
import ReactDOM from 'react-dom'
import { faTimes, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'animate.css'

export default function SnackBar({ messageType, timer, title, message }) {
  const [closeTimeout, setCloseTimeout] = useState(null)
  const closeSnackBar = useCallback(() => {
    clearTimeout(closeTimeout)
    ReactDOM.unmountComponentAtNode(document.getElementById('snackbar-fixed-container'))
  }, [closeTimeout])

  const beginCloseTimeout = useCallback(() => {
    timer && setCloseTimeout(setTimeout(closeSnackBar, timer))
  }, [closeSnackBar, timer])

  useEffect(() => {
    beginCloseTimeout()
  }, [beginCloseTimeout])

  return (
    <div
      className={`snackbar-container ${messageType}-container animate__fadeIn animate__animated animate__faster`}
      onMouseEnter={() => clearTimeout(closeTimeout)}
      onMouseLeave={() => beginCloseTimeout()}>
      <div>
        <div className='snackbar-info-container'>
          <FontAwesomeIcon
            className={`${messageType}-snackbar-icon snackbar-icon-alert`}
            icon={faExclamationCircle}
          />
          <div>
            <h5 className='rubik-text'>{title}</h5>
            {message?.errors ? (
              <>
                <ol>{rowsMessagesSnackBar(message)}</ol>
              </>
            ) : (
              message
            )}
          </div>
        </div>
        <div onClick={() => closeSnackBar()}>
          <FontAwesomeIcon className='close-snackbar-icon' icon={faTimes} />
        </div>
      </div>
    </div>
  )
}

const rowsMessagesSnackBar = messages =>
  messages?.errors.map((error, index) => (
    // @ts-ignore
    <li type='square' key={index} className='muted-rubik-text-tr'>
      <span style={{ color: '#1d1d1d' }}> {error.msg}</span>
    </li>
  ))
