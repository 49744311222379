import { put, call, takeLatest, select, delay } from 'redux-saga/effects'
import axios from 'axios'
import jwt_decode from 'jwt-decode'
import Swal from 'sweetalert2'
import { push } from 'connected-react-router'
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  CHECK_LOGIN_REQUEST,
  RECOVER_REQUEST_REQUEST,
  RECOVER_REQUEST_FAILURE,
  RECOVER_REQUEST_SUCCESS,
  RECOVER_SUCCESS,
  RECOVER_FAILURE,
  RECOVER_REQUEST,
  ACTIVATE_REQUEST,
  ACTIVATE_FAILURE,
  ACTIVATE_SUCCESS,
  LOGOUT_REQUEST,
  LOGOUT_FAILURE,
  LOGOUT_SUCCESS,
  LOGIN_LINKEDIN_REQUEST
} from './auth.redux.js'
import { USER_LOGIN_GET_SUCCESS } from './../Users/users.redux.js'
import { isExpire, setAuthToken } from '../../utils/auth.js'
import { getUserId } from './../../redux/helpers/handlers.js'
import { registersCreateRequest } from 'components/Users/register.redux.js'

export function* loginRequest({ payload }) {
  try {
    const res = yield call(axios.post, '/api/auth/user/login', payload)
    if (res?.status === 200) {
      const { data } = res
      axios.defaults.headers.common['Authorization'] = `Bearer ${data?.data}`
      localStorage.setItem('auth_token', data?.data)
      const token = data.data
      // decoded token to get user data
      const decoded = jwt_decode(token)
      // Guardar en reducer
      yield put({ type: LOGIN_SUCCESS, payload: decoded })

      // Aquí se carga la información del usuario que viene del decode, para llenar la información del nodo del user
      yield put({ type: USER_LOGIN_GET_SUCCESS, payload: decoded?.user })
      const _id = decoded.user?._id
      if (payload.provider) {
        yield put(push('/edit/' + _id))
      } else {
        yield put(push('/profile/' + _id))
      }
    } else {
      yield put({ type: LOGIN_FAILURE, error: res })
    }
  } catch (error) {
    yield put({ type: LOGIN_FAILURE, error: error?.response })
  }
}

// Login with linkedin
function* loginLinkedinRequest({ payload }) {
  try {
    const { data } = yield call(axios.post, `/api/auth/user/login-linkedin`, { code: payload })
    const { data: values } = data
    yield put(registersCreateRequest({ values }))
  } catch (error) {
    console.log(error)
  }
}

export function* checkLogin() {
  try {
    const token = localStorage.getItem('auth_token')
    if (token) {
      setAuthToken(token) // Set token to Auth header
      const decoded = jwt_decode(token) // Decode token to get user data
      if (decoded && isExpire(decoded.exp)) {
        return yield put({ type: LOGOUT_SUCCESS })
      } else {
        yield put({ type: LOGIN_SUCCESS, payload: decoded })
        // Aquí se carga la información del usuario que viene del decode, para llenar la información del nodo del user
        yield put({ type: USER_LOGIN_GET_SUCCESS, payload: decoded?.user })
      }
    }
  } catch (error) {
    Swal.fire('Error', error, 'error')
    yield put({ type: LOGIN_FAILURE, error: error?.response })
  }
}

export function* logoutRequest() {
  try {
    yield jwt_decode(localStorage.getItem('auth_token'))
    const user = yield select(u => getUserId(u))
    yield call(axios.put, `/api/auth/user/logout/${user}`, { login: false })
    // Remove token from localStorage
    localStorage.removeItem('auth_token')
    // Remove the auth header for future requests
    setAuthToken(false)
    // Unset the user on the redux store
    Swal.fire('', 'Se ha cerrado su sesión', 'success')
    yield put({ type: LOGOUT_SUCCESS })
    yield delay(process.env.REACT_APP_DELAY)
    yield put(push('/'))
    window.location.reload()
  } catch (error) {
    yield put({ type: LOGOUT_FAILURE })
  }
}

export function* recoverRequestRequest({ payload }) {
  try {
    const res = yield call(axios.post, '/api/auth/user/request-recover', payload)
    if (res?.status === 200) {
      yield put({ type: RECOVER_REQUEST_SUCCESS, payload: res })
      Swal.fire(
        '',
        'Se ha enviado un correo con instrucciones, es posible que se encuentre en la carpeta Spam o correo no deseado.',
        'info'
      )
      yield put(push('/'))
    } else {
      yield put({ type: RECOVER_REQUEST_FAILURE, error: res })
    }
  } catch (error) {
    yield put({ type: RECOVER_REQUEST_FAILURE, error: error?.response })
  }
}

export function* recoverRequest({ payload }) {
  try {
    const res = yield call(axios.post, '/api/auth/user/recover', payload)
    if (res?.status === 200) {
      yield put({ type: RECOVER_SUCCESS, payload: res })
      Swal.fire('', 'Se ha actualizado su contraseña', 'success')
      yield put(push('/login'))
    } else {
      yield put({ type: RECOVER_FAILURE, error: res })
    }
  } catch (error) {
    Swal.fire('Error', error, 'error')
    yield put({ type: RECOVER_FAILURE, error: error?.response })
  }
}

export function* activateRequest({ payload }) {
  try {
    const res = yield call(axios.post, '/api/auth/user/activate', payload)
    if (res?.status === 200) {
      yield put({ type: ACTIVATE_SUCCESS, payload: res })
      yield put(push('/login'))
      Swal.fire('', 'Se ha activado su cuenta', 'success')
    } else {
      yield put({ type: ACTIVATE_FAILURE, error: res })
      yield put(push('/login'))
    }
  } catch (error) {
    Swal.fire('Error', error, 'error')
    yield put({ type: ACTIVATE_FAILURE, error: error?.response })
    yield put(push('/'))
  }
}

export function* requestFail({ error }) {
  try {
    if (error?.error?.status !== 400 && error?.error?.code !== 401) {
      //  'Alerta', error?.error?.data.message, 'info')
    }
  } catch (error) {
    yield Swal.fire('Error', 'error', 'error')
  }
}

export default function* authSagas() {
  yield takeLatest(LOGIN_REQUEST, loginRequest)
  yield takeLatest(LOGIN_LINKEDIN_REQUEST, loginLinkedinRequest)
  yield takeLatest(CHECK_LOGIN_REQUEST, checkLogin)
  yield takeLatest(LOGOUT_REQUEST, logoutRequest)
  yield takeLatest(RECOVER_REQUEST_REQUEST, recoverRequestRequest)
  yield takeLatest(RECOVER_REQUEST, recoverRequest)
  yield takeLatest(ACTIVATE_REQUEST, activateRequest)
  yield takeLatest(LOGIN_FAILURE, requestFail)
  yield takeLatest(RECOVER_REQUEST_FAILURE, requestFail)
  yield takeLatest(RECOVER_FAILURE, requestFail)
  yield takeLatest(ACTIVATE_FAILURE, requestFail)
}
