import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { gtaGetAllRequest } from '../Gta/gta.redux.js'

function CompanyCardPodium({ gtas, data, position }) {
  const { logo, gta, gtaNum, companyType, company, points } = data
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(gtaGetAllRequest())
  }, [dispatch])
  let colorMedal = '#ddc942'
  switch (position) {
    case 2:
      colorMedal = '#b4b4b4'
      break
    case 3:
      colorMedal = '#ba865a'
      break
    default:
      break
  }
  return (
    <div className='company-card-body'>
      <div className='company-card-square-up'>
        <div
          className='company-card-top-bar'
          style={{
            backgroundColor: gtas?.find(element => element?._id === gtaNum).color || '#ccc'
          }}>
          <div className='company-header-front'>
            <div className='flashcard-header-gta'>
              <img
                className='company-header-gta-img'
                width='100'
                src={require(`./../../assets/img/gtas/blanco/GTA2030_IconosColor_${gtaNum}.svg`)}
                alt='icon gta2030'
              />
            </div>
            <div className='company-header-number'>
              <div className='company-header-gta-title'>Grupo de trabajo Agenda 2030</div>
              <div className='flashcard-header-gta-name'>{gta}</div>
            </div>
          </div>
        </div>
        <div className='row justify-content-center'>
          <img
            className='company-logo-company-img justify-content-center'
            src={require(`./../../assets/img/${logo}`)}
            alt='icon gta2030'
          />
        </div>
        <div className='company-square-bottom'>
          <div className='company-card-medal' style={{ backgroundColor: colorMedal }}>
            <span className='company-card-medal-number'>{position}º</span>
          </div>
          <span className='company-card-company-name bold'>{company}</span>
          <span className='company-card-company-name font-size-2'>{companyType}</span>
          <span className='company-card-company-name company-puntos'>{points}</span>
          <span className='company-card-company-name font-size-2'>Puntos</span>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ gta }) => ({
  gtas: gta.all.docs
})

export default connect(mapStateToProps, null)(CompanyCardPodium)
