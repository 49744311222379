import { Container, Row, Col } from 'reactstrap'
import React from 'react'
import PropTypes from 'prop-types'

import image from './../../assets/img/en_construccion.svg'

const UnderConstruction = ({ title, text }) => {
  return (
    <>
      <Container className='underconstruction-container animate__animated animate__fadeInDown animate__faster'>
        <Row xs='1'>
          <Col style={{ fontWeight: '500' }}>
            <img src={image} alt='' className='underconstruction-img' />
            En construcción
          </Col>
        </Row>
      </Container>
    </>
  )
}

UnderConstruction.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string
}

UnderConstruction.defaultProps = {
  title: 'Sitio en construcción',
  text: 'Algunas de las funcionalidades estarán disponibles próximamente.'
}

export default UnderConstruction
