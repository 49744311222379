import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { slide as Menu } from 'react-burger-menu'
import { Link } from 'react-router-dom'
import { faBars, faTimes, faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { loginLogoutRequest } from '../Auth/auth.redux.js'

function MenuFullPage({ userId, isLogin, loginLogoutRequest}) {
  const handleLogout = () => {
    loginLogoutRequest()
  }

  const iconMenu = <FontAwesomeIcon icon={faBars} />
  const iconCross = <FontAwesomeIcon icon={faTimes} />
  const iconHome = <FontAwesomeIcon icon={faHome} className='bm-btn-home' />

  const text = (
    <Link className='navbar-brand' to='/'>
      {iconCross}
      <i className='icon-align-left'></i>
      {iconHome}
    </Link>
  )

  return (
    <div  className='class-mobile'>
    <Menu customCrossIcon={text} customBurgerIcon={iconMenu}> 
      <div className='full-page-menu row'>
        <div className='menu-column col-sm'>
          <Link to='/good-practices-map' className='fp-item'>
            Banco de acciones
          </Link>
          <Link to='/metodology' className='fp-item'>
            Metodología de acción
          </Link>
          <Link to='/register-action' className='fp-item'>
            Comparte acciones
          </Link>
          <Link to='/solution-map' className='fp-item'>
            Mapeo de soluciones (Flashcards CLIMB)
          </Link>
          <Link to='/priorization' className='fp-item'>
            Priorización
          </Link>
          <Link to='/implementation' className='fp-item'>
            Implementación
          </Link>
        </div>

        <div className='menu-column col-sm'>
          <Link to='/ranking' className='fullpage-item'>
            <i>Ranking</i>
          </Link>
          <Link to='/about' className='fullpage-item'>
            Acerca de
          </Link>
          <Link to='/contact' className='fullpage-item'>
            Contacto
          </Link>
          {/* <Link to='/glossary' className='fullpage-item'>
            Glosario
          </Link> */}
          {isLogin && (
            <Link to={`/profile/${userId}`} className='fullpage-item'>
              Perfil
            </Link>
          )}
          {!isLogin && (
            <>
              <Link to='/register' className='fullpage-item'>
                Registro
              </Link>
            </>
          )}
          {!isLogin ? (
            <Link to='/login' className='fullpage-item'>
              Iniciar sesión
            </Link>
          ) : (
            <Link to='' onClick={handleLogout} className='fullpage-item'>
              Cerrar Sesión
            </Link>
          )}
        </div>
      </div>
    </Menu>
    </div>
  )
}

MenuFullPage.propTypes = {
  props: PropTypes
}

const mapStateToProps = state => ({ userId: state.user._id, isLogin: state.auth.isLogin })

const mapDispatchToProps = {
  loginLogoutRequest: loginLogoutRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuFullPage)
