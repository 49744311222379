import React from 'react'
import { connect } from 'react-redux'
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from 'jwt-decode'
import { registersCreateRequest } from 'components/Users/register.redux'

const LoginGoogle = ({ registersCreateRequest }) => {
  const responseSuccess = response => {
    if (response.credential) {
      const decoded = jwt_decode(response.credential)
      const { email, given_name, family_name, googleId, picture } = decoded
      const values = {
        email,
        name: given_name,
        lastName: family_name,
        providerId: response.clientId,
        thumbnail: picture,
        validated: true,
        provider: 'google'
      }
      registersCreateRequest({ values })
    }
  }
  const responseFailure = response => {
    console.log(response)
  }

  return (
    <div className='social-media-logo-google'>
    <GoogleLogin
      type='icon'
      shape={'circle'}
      onSuccess={responseSuccess}
      onFailure={responseFailure}
      alt=''
    />
    </div>
  )
}

const mapDispatchToProps = {
  registersCreateRequest
}

export default connect(null, mapDispatchToProps)(LoginGoogle)
