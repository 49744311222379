import React from 'react'
import { Link } from 'react-router-dom'

function JoinCardButton({ title, img, url, text, onClick }) {
  return (
    <div
      onClick={onClick}
      className='link-to-join animate__animated animate__fadeIn animate__faster'
      to={url}>
      <div className='company-card-body animate__animated animate__fadeIn animate__faster'>
        <div className='company-card-square-up'>
          <div className='company-card-top-bar'>
            <div className='company-header-front'>
              <div className='flashcard-header-gta'></div>
              <div className='company-header-number'>
                <div className='company-header-gta-title-2 animate__animated animate__fadeIn animate__faster'>
                  {title}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default JoinCardButton
