import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { activateRequest } from '../Auth/auth.redux.js'
import { useParams } from 'react-router'

export const PageActivate = ({ activateRequest, ...props }) => {
  const { token } = useParams()
  const params = token.split('&')
  const activationToken = params[0]
  const email = params[1]
  useEffect(() => {
    activateRequest({ activationToken, email })
  }, [activateRequest, activationToken, email])
  return <></>
}
PageActivate.propTypes = {
  props: PropTypes
}
const mapDispatchToProps = {
  activateRequest
}
export default connect(null, mapDispatchToProps)(PageActivate)
