import styled from 'styled-components'

export const DraftSection = styled.section`
  padding: 6em 5em 5em 5em;
  background-size: cover;
`
export const DraftImage = styled.img`
  width: inherit;
  @media only screen and (max-width: 640px) {
    width: 409px;
    margin-left: 23px;
  }
`
export const DraftImage2 = styled.img`
  width: inherit;
  height: 70px;
  margin: 26px 0 85px 0;
  @media only screen and (max-width: 640px) {
    width: 265px;
    height: auto;
  }
`

export const DraftText = styled.p`
  color: #201e1e;
  font-weight: 600;
  line-height: 28px;
  margin: 0;
  display: block;
  @media only screen and (max-width: 640px) {
    font-size: 14px;
    line-height: 17px;
  }
`

export const DraftCCE = styled.div`
  height: 72px;
  width: 235px;
  background: green;
  position: absolute;
  margin: -124px 0px 0px 495px;
`

export const DraftContainer = styled.section`
  padding: 0em 26%;
  width: 100%;
  background: ${({ background }) => background || '#e6e6e6'};
  margin-top: ${({ marginTop }) => marginTop || '#0px'};
  align-items: center;
  @media only screen and (max-width: 640px) {
    padding: 0 1%;
  }

  @media only screen and (min-width: 1800px) {
    padding: 0em 18%;
  }
`

export const DraftRow = styled.div`
  display: flex;
  justify-content: space-between;
`
