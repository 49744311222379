import React from 'react'
import { Formik, Form } from 'formik'
import FormInput from '../Helpers/FormInput.js'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './_auth.scss'
import { recoverRequest } from './auth.redux.js'
import { connect } from 'react-redux'
import { useParams } from 'react-router'

const RestorePasswordForm = ({ loading, recoverRequest }) => {
  const { token } = useParams()
  const params = token.split('&')
  const recoveryToken = params[0]
  const email = params[1]
  const iconQuestion = <FontAwesomeIcon icon={faQuestionCircle} />
  return (
    <div className='recover__password'>
      <h3 className='auth__title'>Cambiar contraseña</h3>
      <Formik
        initialValues={{
          email: email,
          recoveryToken: recoveryToken,
          password: '',
          cfrmPassword: ''
        }}
        validate={values => {
          const errors = {}
          for (const key in values) if (!values[key]) errors[key] = 'Requerido'
          return errors
        }}
        // Submit
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            recoverRequest(values, recoveryToken, email)
            setSubmitting(false)
          }, 400)
        }}>
        {({ handleSubmit, values, handleChange }) => (
          <Form onSubmit={handleSubmit} className='form recover__form'>
            <div className='row gutters recover__input'>
              <div className='recover__questioni'>{iconQuestion}</div>
              <FormInput
                id='password'
                type='password'
                label='Contraseña nueva'
                onChange={handleChange}
                value={values.newPassword}
              />
            </div>
            <div className='row gutters recover__input'>
              <div className='recover__questioni'>{iconQuestion}</div>
              <FormInput
                id='cfrmPassword'
                type='password'
                label='Confirmar contraseña'
                onChange={handleChange}
                value={values.cfrmPassword}
              />
            </div>
            <div className='row gutters'>
              <button
                type='submit'
                className='btn btn-primary btn-block mt-3 mobile-btn'
                disabled={loading}>
                Cambiar contraseña
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    error: state.auth.error,
    loading: state.auth.loading
  }
}

const mapDispatchToProps = {
  recoverRequest: recoverRequest
}
export default connect(mapStateToProps, mapDispatchToProps)(RestorePasswordForm)
