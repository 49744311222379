import React from 'react'
import PropTypes from 'prop-types'
import LoginGoogle from 'components/Auth/LoginGoogle'
import LoginFacebook from 'components/Auth/LoginFacebook'
import LoginLinkedin from 'components/Auth/LoginLinkedin'

const SocialMediaBar = ({ css }) => {
  return (
    <div className={`social-media-bar ${css}`}>
      <LoginGoogle />
      <LoginFacebook />
      <LoginLinkedin />
    </div>
  )
}

SocialMediaBar.propTypes = {
  css: PropTypes.string
}

SocialMediaBar.defaultProps = {
  css: ''
}

export default SocialMediaBar
