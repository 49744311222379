import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import Layout from '../Layout/Layout'
import { SplashScreen } from '../SplashScreen/SplashScreen.js'
import { JoinGoodPracticesMap } from 'components/Join/JoinGoodPracticesMap.js'
import { actionGetAllRequest, actionGetMoreRequest } from 'components/Join/joinAction.redux.js'
import SpinnerODS from 'components/Helpers/SpinnerODS'

export const PageGoodPracticesMap = ({
  all,
  loading,
  loader,
  filters,
  actionGetAllRequest,
  actionGetMoreRequest
}) => {
  useEffect(() => {
    actionGetAllRequest({ status: 2, active: true, limit: 30 })
  }, [actionGetAllRequest])

  return (
      <Layout hideScroll={true}>
        {loading && !all?.docs ? (
          <SplashScreen />
        ) : (
          <>
            <JoinGoodPracticesMap
              data={all}
              filters={filters}
              actionGetMoreRequest={actionGetMoreRequest}
            />
          </>
        )}
        {loader && <SpinnerODS />}
      </Layout>
  )
}

const mapStateToProps = ({ joinActions }) => ({
  all: joinActions.list,
  loading: joinActions.loading,
  loader: joinActions.loader,
  error: joinActions.error,
  filters: joinActions.filters
})

const mapDispatchToProps = {
  actionGetAllRequest,
  actionGetMoreRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(PageGoodPracticesMap)
