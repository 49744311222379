import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Footer from 'components/Layout/Footer'
import Navbar from 'components/Layout/Navbar'

const LayoutTwo = ({ children, hideScroll }) => {
  const html = document.getElementsByTagName('html')[0]
  const body = document.body
  const main = document.getElementById('root')
  const divR = document.getElementById('div-root')
  html.classList.add('h-100')
  body.classList.add('h-90')
  main.classList.add('d-flex')
  main.classList.add('flex-column')
  main.classList.add('h-90')

  divR.classList.remove('overflow-hide')
  divR.classList.remove('overflow-show')
  hideScroll ? divR.classList.add('overflow-hide') : divR.classList.add('overflow-show')

  return (
    <Fragment>
      <Navbar />
      <div className='row paddingRowContainer'>{children}</div>
      {!hideScroll && <Footer />}
    </Fragment>
  )
}

LayoutTwo.propTypes = {
  hideScroll: PropTypes.bool.isRequired
}

LayoutTwo.defaultProps = {
  hideScroll: false
}

export default LayoutTwo
