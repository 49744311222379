import { Container, Row, Col, Modal, ModalBody } from 'reactstrap'
import React, { useState } from 'react'
import logoPacto from './../../assets/img/help-card.svg'

function HeaderSolutionMap() {
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} className={'map-info-card-modal-content'}>
        <ModalBody>
          <Container className='' style={{ justifyContent: 'center' }}>
            <Row xs='2' style={{ width: '100%' }}>
              <Col className=''>
                <h3 className='map-info-title-popup-left'>Componentes de tarjeta de acción</h3>
              </Col>
            </Row>
          </Container>
          <Container className='' style={{ justifyContent: 'center' }}>
            <Row xs='2' style={{ width: '100%' }}>
              <Col className=''>
                <img src={logoPacto} className='map-img-help-card' alt='logoPacto' />
              </Col>
            </Row>
          </Container>          
        </ModalBody>
      </Modal>
      {/* <div className='container headerImpl-color animate__animated animate__fadeIn animate__faster'>
        <div className='row'>
          <div className='col-lg-9 headerImpl-grid'>
            <h2 className='headerImpl-title'>Mapeo de soluciones (Flashcards CLIMB)</h2>
            <span className='headerImpl-subtitle'>
              Consulta aquí <strong>todas</strong> las soluciones propuestas bajo la
              Metodología de acción.
            </span>
          </div>
          <div className='col-lg-3'> */}
            <a
              onClick={toggle}
              href={() => false}
              className='btn btn-block map-modal-info-btn'>
              {'Componentes de tarjeta de acción'}
            </a>
          {/* </div>
        </div>
      </div> */}
    </>
  )
}

export default HeaderSolutionMap
