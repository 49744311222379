import React from 'react'
import Layout from './../Layout/Layout'
import { Post } from './../Post/Post'
const PagePrivacyNotice = () => {
  return (
    <Layout>
      <Post
        title='AVISO DE PRIVACIDAD
        DE LA RED DEL PACTO GLOBAL DE Y EN MÉXICO, A.C.'
        content='Contenido'
      />
    </Layout>
  )
}
export default PagePrivacyNotice
