import React from 'react'

function CardDetailBack(props) {
  const p = { ...props?.data }

  const { gta, what, how1, how2, how3 } = p

  return (
    <div className='card-detail-content-back'>
      {/* Marco fondo */}
      <div className='flashcard-detail-back-div flashcard-detail-back-div-solution'>
        {/* --- ¿Qué? ---------------------------------------------------------------- */}
        {what && (
          <>
            <span className='flashcard-detail-back-text' style={{ color: gta?.color }}>
              <span className='flashcard-detail-back-colortext' style={{ color: gta?.color }}>
                ¿Qué?
              </span>
            </span>
            <p className='flashcard-detail-back-text'>{what}</p>
            <br />
          </>
        )}
        {/* --- ¿Cómo? ---------------------------------------------------------------- */}
        {how1 && (
          <>
            <span className='flashcard-detail-back-text' style={{ color: gta?.color }}>
              <span className='flashcard-detail-back-colortext' style={{ color: gta?.color }}>
                ¿Cómo?
              </span>
            </span>
            <p className='flashcard-detail-back-text'>
              <ol> 
                {how1 ? <li>{how1}</li>: ''}
                {how2 ? <li>{how2}</li>: ''}
                {how3 ? <li>{how3}</li>: ''}                
              </ol>
            </p>
          </>
        )}
      </div>
    </div>
  )
}

export default CardDetailBack
