// Type's
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'

export const LOGIN_LINKEDIN_REQUEST = 'LOGIN_LINKEDIN_REQUEST'
export const LOGIN_LINKEDIN_SUCCESS = 'LOGIN_LINKEDIN_SUCCESS'
export const LOGIN_LINKEDIN_FAILURE = 'LOGIN_LINKEDIN_FAILURE'

export const CHECK_LOGIN_REQUEST = 'CHECK_LOGIN_REQUEST'
export const CHECK_LOGIN_SUCCESS = 'CHECK_LOGIN_SUCCESS'
export const CHECK_LOGIN_FAILURE = 'CHECK_LOGIN_FAILURE'

export const LOGOUT_REQUEST = '[ AUTH ] LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = '[ AUTH ] LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = '[ AUTH ] LOGOUT_FAILURE'

export const RECOVER_REQUEST_REQUEST = 'RECOVER_REQUEST_REQUEST'
export const RECOVER_REQUEST_SUCCESS = 'RECOVER_REQUEST_SUCCESS'
export const RECOVER_REQUEST_FAILURE = 'RECOVER_REQUEST_FAILURE'

export const RECOVER_REQUEST = 'RECOVER_REQUEST'
export const RECOVER_SUCCESS = 'RECOVER_SUCCESS'
export const RECOVER_FAILURE = 'RECOVER_FAILURE'

export const ACTIVATE_REQUEST = 'ACTIVATE_REQUEST'
export const ACTIVATE_SUCCESS = 'ACTIVATE_SUCCESS'
export const ACTIVATE_FAILURE = 'ACTIVATE_FAILURE'

// Action's
export const loginRequest = payload => ({
  type: LOGIN_REQUEST,
  payload: payload
})

export const loginLinkedinRequest = payload => ({
  type: LOGIN_LINKEDIN_REQUEST,
  payload: payload
})

export const checkloginRequest = () => ({
  type: CHECK_LOGIN_REQUEST
})

export const recoverRequestRequest = payload => ({
  type: RECOVER_REQUEST_REQUEST,
  payload: payload
})

export const recoverRequest = payload => ({
  type: RECOVER_REQUEST,
  payload: payload
})
export const activateRequest = payload => ({
  type: ACTIVATE_REQUEST,
  payload: payload
})

export const loginLogoutRequest = payload => ({
  type: LOGOUT_REQUEST
})
// Reducer
const INITIAL_STATE = {
  loading: false,
  isLogin: false,
  error: null
}

export function auth(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      const { user, ...newPayload } = action.payload
      return {
        ...state,
        error: null,
        loading: false,
        ...newPayload,
        isLogin: true
      }
    case LOGIN_REQUEST:
    case RECOVER_REQUEST_REQUEST:
    case RECOVER_REQUEST:
    case ACTIVATE_REQUEST:
      return { ...state, error: null, loading: true }

    case LOGOUT_SUCCESS:
      return { ...state, loading: false, error: action.error, isLogin: false }
    case RECOVER_REQUEST_SUCCESS:
    case RECOVER_SUCCESS:
    case ACTIVATE_SUCCESS:
      return { ...state, error: null, loading: false, user: { ...action.payload } }

    case ACTIVATE_FAILURE:
    case RECOVER_REQUEST_FAILURE:
    case RECOVER_FAILURE:
    case LOGIN_FAILURE:
      return { ...state, loading: false, error: action.error }
    default:
      return state
  }
}
