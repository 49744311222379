import React, { useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'
import { routes } from './routes'
import { ConnectedRouter } from 'connected-react-router'
import { history } from './../../redux/configureStore'
import ScrollTop from './../ScrollTop/ScrollTop'
import { useDispatch } from 'react-redux'
import { checkloginRequest } from '../Auth/auth.redux.js'

function Router() {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(checkloginRequest())
  }, [dispatch])
  return (
    <ConnectedRouter history={history}>
      <ScrollTop />
      <Switch>
        {routes.map((route, index) => (
          <Route key={index} exact path={route.path} component={route.component} />
        ))}
      </Switch>
      <ScrollTop />
    </ConnectedRouter>
  )
}

export default Router
