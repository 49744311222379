import React, { Fragment } from 'react'
import LayoutOne from 'layouts/LayoutOne/LayoutOne'
import HeaderBanner from 'components/Header/HeaderBanner'

import Navbar from 'components/Layout/Navbar'
import Footer from 'components/Layout/Footer'

import Grid, { Main, TopBlueSection, Container } from 'components/StyledComponents/Grid'

// start working //
const PageCatalogue = props => {
  return (
    <Main>
      <Navbar />
      <HeaderBanner title='Catálogo' />
      <TopBlueSection>
        <h3>
          La plataforma LOGRA es de acceso gratuito para cualquier persona que visite el sitio
          web, pudiendo ver la totalidad de las acciones publicadas en ella.
        </h3>
        <br />
        <h3>
          Sin embargo, para poder participar activamente compartiendo acciones deberás
          registrarte en la plataforma.
        </h3>
      </TopBlueSection>
      <Container>
        <Grid />
      </Container>
      <Footer />
    </Main>
  )
}

PageCatalogue.propTypes = {}

export default PageCatalogue
