import React from 'react'
import HeaderBanner from 'components/Header/HeaderBanner'
import { listSelection } from 'components/FAQ/ContentSelection'
import ListDropDown from 'components/ListDropDown/ListDropDown'
import Layout from 'components/Layout/Layout'
import ButtonsContainer from 'components/Buttons/ButtonsContainer'
import ButtonCard from 'components/Buttons/ButtonCard'
import FooterContact from 'components/Contact/FooterContact'
import { Container } from 'components/FAQ/listFAQStyle'

const PageSelectGoodPractice = props => {
  return (
    <Layout>
      <HeaderBanner 
      title='Criterios de aprobación de Acciones para ser publicadas en la plataforma LOGRA'  
      paragraph='El término Acciones utilizado en la plataforma LOGRA hace referencia a lo que comúnmente conocemos como buenas prácticas en desarrollo sostenible.'
      />
      <Container>
        <ListDropDown list={listSelection} listType={'faq'} />
        {/* Botónes */}
        <ButtonsContainer>
          <ButtonCard url='/about' title={'ACERCA DE'} />
          <ButtonCard url='/our-objectives' title={'NUESTROS OBJETIVOS'} />
          <ButtonCard url='/how-participate' title={'¿CÓMO PARTICIPAR?'} />
        </ButtonsContainer>
      </Container>
      <FooterContact title='¿Tienes alguna duda?' subtitle='Contáctanos' />
    </Layout>
  )
}

PageSelectGoodPractice.propTypes = {}

export default PageSelectGoodPractice
