import styled, { css } from 'styled-components'
import { Container } from 'reactstrap'
import { Link } from 'react-router-dom'

// Botón
export const ButtonCardContent = styled.div`
  width: 300px;
  margin: 2px 5px 17px 0px;
  border: 2px solid #699cc6;
  border-radius: 5px;
  color: #699cc6;
  height: 100px;
  font-weight: bold;
  background-color: #daeaf5;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  display: grid;
  align-items: center;
  justify-items: center;
  transition: background-color 0.5s ease;
  &:hover {
    background-color: #e8f2f9;
  }
  @media only screen and (max-width: 640px) {
    width: 325px;
    margin: 15px 0;
  }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    margin: 39px 0px 65px 0px;
  }
`

export const ButtonLink = styled(Link).attrs({
  className: 'col animate__animated animate__fadeIn animate__faster'
})`
  color: #fff !important;
  font-weight: 100 !important;
`

// Fila de botones
export const ContainerButtons = styled(Container)`
  padding: 0 0 0 6%;
  justify-content: center !important;
  ${props =>
    props.marginBottom &&
    css`
      margin-bottom: ${props.marginBottom};
    `}
    @media only screen and (max-width: 640px) {
    padding: 0 23px;
  }
`
