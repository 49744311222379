import styled, { css } from 'styled-components'
import { Field, ErrorMessage } from 'formik'
import { Row, Col } from 'reactstrap'

export const RowStyled = styled(Row)`
  margin-bottom: 100px;
`
export const ColStyled = styled(Col)`
  ${props =>
    props.margin &&
    css`
      margin-top: 55px;
    `}
  @media (max-width: 700px) {
    width: 100%;
  }
`

export const ErrorMessageStyled = styled(ErrorMessage)`
  color: red;
  font-size: 0.8em;
`

export const Input = styled(Field)`
  background-color: white;
  border: 1px solid lightgrey;
  border-radius: 1px;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  color: #fff !important;
  font-weight: 400;
  display: flex;
  /* min-width: 100%; */
  width: 100%;
  margin-right: 11px;
  margin-top: 0.5rem;
  padding: -0.25rem 6.75rem;
  height: 2rem !important;

  &:focus,
  &:active {
    box-shadow: rgb(210, 213, 217) 0px 0px 2px 1px, rgb(227, 230, 232) 0px 0px 0px 3px;
    border: 1px solid rgb(26, 33, 43);
    outline: none;
  }

  /* Autocomplete styles in Chrome*/
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    background-color: white;
    border: 1px solid lightgrey;
    box-shadow: 0 0 0px 1000px #fff inset;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: black;
  }

  ${({ valid }) =>
    valid &&
    css`
      border: 1px solid rgb(0, 156, 38);

      &:focus,
      &:active {
        border: 1px solid rgb(0, 156, 38);
        box-shadow: rgb(106, 237, 97) 0px 0px 2px 1px, rgb(177, 247, 160) 0px 0px 0px 3px;
        outline: none;
      }

      /* Autocomplete styles in Chrome*/
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        border: 1px solid rgb(0, 156, 38);
      }
    `}

  ${({ error }) =>
    error &&
    css`
      border: 1px solid rgb(191, 49, 12);
      outline: none;
      color: #fff !important;

      &:focus,
      &:active {
        box-shadow: rgb(244, 129, 116) 0px 0px 2px 1px, rgb(251, 178, 174) 0px 0px 0px 3px;
        border: 1px solid rgb(191, 49, 12);
        outline: none;
      }

      /* Autocomplete styles in Chrome*/
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        border: 1px solid rgb(191, 49, 12);
      }
    `}
`

export const Container = styled.section`
  flex: 1;
  margin: 0 15%;
  align-items: center;
  @media only screen and (max-width: 640px) {
    margin: 0 0 0 40px;
  }
`
export const Label = styled.label`
  color: #fff !important;
  margin-top: 1.5rem;
  width: 100%;
`
export const Flex = styled.div`
  align-content: ${({ alignContent }) => alignContent || 'stretch'};
  align-items: ${({ alignItems }) => alignItems || 'stretch'};
  align-self: ${({ alignSelf }) => alignSelf || 'auto'};
  display: flex;
  flex-grow: ${({ flexGrow }) => flexGrow || 0};
  flex-shrink: ${({ flexShrink }) => flexShrink || 1};
  flex-basis: ${({ flexBasis }) => flexBasis || 'auto'};
  flex-direction: ${({ direction }) => direction || 'row'};
  flex-wrap: ${({ wrap }) => wrap || 'nowrap'};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
`

export const ActualLabel = styled.label`
  display: block;
  ${props =>
    props.width &&
    css`
      width: 44%;
    `}
  ${props =>
    props.fullwidth &&
    css`
      color: #fff !important;
      width: 90%;
    `}

    @media only screen and (max-width: 640px) {
      width: 90%;
    }
`
export const PrimaryButton = styled.button.attrs({
  className: 'btn btn-primary btn-block mt-3'
})`
  width: 90% !important;
  margin-left: 5px !important;
  box-shadow: none !important;
`

export const Division = styled.div`
  margin-top: 40px;
  border-left: 1px solid #749bc2;
  height: 350px;
  @media (max-width: 990px) {
    display: none;
  }
`
export const Paragraph = styled.p`
  font-size: 25px;
`
