import * as Yup from 'yup'

export const JoinValidationSchema = Yup.object().shape({
  name: Yup.string().min(2).max(220).required('Debe ingresar el nombre de la iniciativa'),
  objective: Yup.string().required('Debe ingresar el objetivo de la iniciativa'),
  ods: Yup.string().required('Debe seleccionar un Objetivo de Desarrollo Sostenible'),
  axisGlobalPact: Yup.string().required('Debe seleccionar un Eje del Pacto Global'),
  description: Yup.string().required('Debe ingresar una descripción de la iniciativa'),
  // businessJoinType: Yup.array().required(
  //   'Debe seleccionar al menos un ámbito de la implementación'
  // ),
  businessJoinSubType: Yup.string().required('Debe seleccionar un tamaño de empresa'),
  indicator: Yup.string().required(
    'Debe indicar el indicador que utilizas para medir el impacto de la acción'
  ),
  // ubication: Yup.string().required('Debe ingresar una ubicación de la iniciativa'),
  promoterInstitution: Yup.string().required('Debe ingresar una institución promotora'),
  email: Yup.string()
    .email('Formato invalido')
    .required('Debe ingresar un correo de contacto'),
  information: Yup.string().required(
    'Ingrese fuentes de información sobre esta buena práctica.'
  )
})
