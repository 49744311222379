/* PLOP_INJECT_IMPORT */
import { auth } from './../../components/Auth/auth.redux.js'
import { cards } from './../../components/Cards/cards.redux.js'
import { company } from './../../components/Company/company.redux'
import { gta } from './../../components/Gta/gta.redux.js'
import { register } from './../../components/Users/register.redux'
import { combineReducers } from 'redux'
import { app } from './app.reducer'
import { router } from './router.reducer'
import { users, user } from './../../components/Users/users.redux.js'
import { loadingBarReducer } from 'react-redux-loading-bar'
import { contact } from '../../components/Contact/contact.redux'
import { profileUser } from '../../components/Users/profile.redux'
import { priorizations } from '../../components/Cards/Priorization.redux'
import { comments } from './../../components/Comments/comments.redux.js'
import { rankings } from './../../components/Ranking/ranking.redux'
import { savedCards } from '../../components/Cards/cardSaved.redux.js'
import { joinActions } from '../../components/Join/joinAction.redux'

export default combineReducers({
  /* PLOP_INJECT_EXPORT */
  auth,
  user,
  cards,
  users,
  company,
  gta,
  app,
  router,
  joinActions,
  register,
  loadingBar: loadingBarReducer,
  contact,
  profileUser,
  priorizations,
  comments,
  rankings,
  savedCards
})
