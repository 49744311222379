import React from 'react'

// @ts-ignore
import logoPacto from './../../assets/img/logoPacto.svg'
import logoStk from './../../assets/img/StkLogo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarker, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { FooterContent, FooterSection, FooterSectionLogo } from './LayoutStyled'

function Footer() {
  const iconLocation = <FontAwesomeIcon icon={faMapMarker} className='footer-icons' />
  const iconEmail = <FontAwesomeIcon icon={faEnvelope} className='footer-icons' />

  return (
    <FooterContent id='pageFooter'>
      <FooterSection>
        <div className='col-lg-3 footer-contact'>
          <FooterSectionLogo>
            <img src={logoPacto} className='footer-logo' alt='logoPacto' />
          </FooterSectionLogo>
          <div className='contact-mobile'>
            <span className='footer-pacto'>Pacto Global México</span>
            <br />

            <a
              href='mailto:contacto@pactomundial.org.mx'
              target='_blank'
              className='footer-street-link'>
              <div style={{ marginBottom: '12px' }}>
                {iconEmail}contacto@pactomundial.org.mx
              </div>
            </a>
          </div>
          <div></div>
        </div>
        <div className='col-lg-3 '>
          <div className='logo-mobile'> </div>
        </div>
        <div className='col-lg-3 footer-location-square'>
          <a
            href='https://goo.gl/maps/6MFRDPk5EMTLhc9x5'
            target='_blank'
            className='footer-street-link'>
            <div>{iconLocation}</div>
            Montes Urales 440, Lomas de Chapultepec México, CDMX C.P. 11000
          </a>
          <br />
        </div>
        <div className='row legaly'>
          <div className='col-lg-11 footer-legal footer-border'>
            <Link to='/legal-disclaimer'>
              <span className='footer-condiciones'>Términos y condiciones</span>
            </Link>
            <Link to='/privacy-notice'>
              <span className='footer-privacidad'>Aviso de privacidad</span>
            </Link>
            <div className='softtek-info' style={{ marginTop: '-11px' }}>
              <div className='row' style={{ margin: '0px' }}>
                <div className='col'>
                  <a href='https://www.softtek.com/' target='_blank'>
                    <img src={logoStk} className='footer-logo-stk' alt='logoStk' />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FooterSection>
    </FooterContent>
  )
}

export default Footer
