import axios from 'axios'
import { showErrorMessage } from '../components/Helpers/exceptionUtils'

// Interceptamos todos los códigos 400 en llamadas de la API
const request = axios
request.interceptors.response.use(
  response => {
    // console.table(response)
    // showErrorMessage(data.message, data)
    return response
  },
  error => {
    const { response } = error
    const { data, status } = response
    const { message, code, data: errors } = data
    if (message === 'Sin autorización') return Promise.reject(error)
    // si la url es /api/users, la data contiene un provider,el status es 400 y el mensaje es 'Ya existe un registro con ese email' entonces rechazar la promesa
    const { url, data: dataRequest } = response.config
    const parsedData = JSON.parse(dataRequest)
     if (url === '/api/users' && parsedData.provider &&  status === 400 && data.errors[0]?.msg === 'Ya existe un registro con ese email') return Promise.reject(error)
     
    // Error de validación en el backend
    code === 400 && showErrorMessage(message, errors)
    code === 401 && showErrorMessage(message, errors)
    // Sin conexión a la API
    status === 504 &&
      showErrorMessage(
        'Sin conexión con el servidor',
        'Por favor verifique que tiene conexión a internet, y vuelva a intentarlo.'
      )
    return Promise.reject(error)
  }
)
