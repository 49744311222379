import React from 'react'

const CircleLoader = () => {
  return (
    <div className='circle-loader'>
      <div className='circle'></div>
      <div className='circle'></div>
      <div className='circle'></div>
      <div className='circle'></div>
      <div className='circle'></div>
    </div>
  )
}

export default CircleLoader
