import React from 'react'
import {
  LiItemList,
  LinkItemList,
  OlItemList,
  ParraphListItem,
  TitleListItem
} from './listFAQStyle'

export const listContentFAQ = [
  {
    title: '¿Qué es LOGRA?',
    description: (
      <ParraphListItem>
        Un espacio empresarial para compartir tus mejores prácticas en desarrollo sostenible,
        mostrar tu mejora continua y progreso e inspirar a otras empresas.
      </ParraphListItem>
    )
  },
  {
    title: '¿Qué es el Banco de Acciones?',
    description: (
      <>
        <TitleListItem>
          Impulsar la estrategia de desarrollo sostenible global actuando localmente.
        </TitleListItem>
        <ParraphListItem>
          Siguiendo el llamado de las Naciones Unidas a las empresas a través de los Diez
          Principios, Pacto Global México está listo para pasar a la acción.
        </ParraphListItem>
        <ParraphListItem>
          Esta plataforma permite visibilizar las acciones que ya realiza el sector privado en
          México a favor de los Objetivos de Desarrollo Sostenible de la Agenda 2030 y de los
          Diez Principios del Pacto Global.
        </ParraphListItem>
        <TitleListItem>Compartir Buenas Prácticas</TitleListItem>
        <ParraphListItem>
          Apoyar a las empresas para conocer lo que otros están haciendo para superar los retos
          globales y así elevar el impacto.
        </ParraphListItem>
        <TitleListItem>Aprender Buenas Prácticas</TitleListItem>
        <ParraphListItem>
          Condensar en un solo lugar soluciones creativas e innovadoras que se están
          implementando en el país para contribuir a la sostenibilidad empresarial.
        </ParraphListItem>
        <TitleListItem>Generar Comunidad</TitleListItem>
        <ParraphListItem>
          Incentivar la conformación de redes de apoyo que faciliten el intercambio de
          soluciones prácticas y la conformación de alianzas en pro de la sostenibilidad
          empresarial en nuestro país.
        </ParraphListItem>
      </>
    )
  },
  {
    title: '¿Cómo participar?',
    description: (
      <>
        <ParraphListItem>
          La plataforma LOGRA es de acceso gratuito para cualquier persona que visite el sitio
          web, pudiendo ver la totalidad de las acciones publicadas en ella.
        </ParraphListItem>
        <ParraphListItem>
          Sin embargo, para poder participar activamente compartiendo acciones deberás
          registrarte en la plataforma.
        </ParraphListItem>
        <ParraphListItem>Comparte tus acciones en 4 sencillos pasos:</ParraphListItem>
        <ParraphListItem>
          <OlItemList>
            <LiItemList>
              <LinkItemList to='/register'>Regístrate</LinkItemList> en la plataforma.
            </LiItemList>
            <LiItemList>
              Lee atentamente nuestro{' '}
              <LinkItemList to='page-selection'>
                Procedimiento de Selección de Buenas Prácticas
              </LinkItemList>{' '}
              para conocer los requisitos necesarios para que tu buena práctica sea validada
              por Pacto Global México y sea publicada.
            </LiItemList>
            <LiItemList>
              Comparte tu acción completando el formulario, adjuntando una fotografía y el logo
              de tu organización en caso de que quieras personalizarla.
            </LiItemList>
            <LiItemList>
              Espera a que el equipo de Pacto Global México valide tu acción y sea publicada en
              la plataforma. Recibirás un correo de confirmación.
            </LiItemList>
          </OlItemList>
        </ParraphListItem>
      </>
    )
  },
  {
    title: '¿Quién puede compartir acciones en la plataforma?',
    description: (
      <ParraphListItem>
        Cualquier empresa puede compartir sus buenas prácticas. Recuerda que esta es una
        plataforma para compartir acciones empresariales por los ODS.
      </ParraphListItem>
    )
  },
  {
    title: '¿Quién puede consultar la información disponible en la plataforma?',
    description: (
      <ParraphListItem>
        LOGRA es de acceso gratuito, todas las personas interesadas, pueden acceder a su
        contenido, pero aquellas que quieran subir Buenas Prácticas, sí deberán registrarse
        para poder hacer el seguimiento de su propuesta, progreso y mejora.
      </ParraphListItem>
    )
  },
  {
    title: '¿Qué es el Banco de Acciones?',
    description: (
      <ParraphListItem>
        Son soluciones innovadoras en las que trabajan las empresas en México en su camino
        hacia la sostenibilidad.
      </ParraphListItem>
    )
  }
]
