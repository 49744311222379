import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import ListItemLoader from './../Cards/CardsItemLoader.js'
import CardsList from './../Cards/CardsList.js'
import { Loading } from './../Helpers/Loading.js'
import profilePicture from './../../assets/img/profileavatar.svg'
import { useParams } from 'react-router-dom'
import { savedCardsGetAllRequest } from './cardSaved.redux.js'
import { usersGetOneRequest } from '../Users/users.redux.js'

export const SavedCards = ({
  all,
  savedCardsGetAllRequest,
  usersGetOneRequest,
  loading,
  loader,
  filters,
  one
}) => {
  const { _id } = useParams()
  useEffect(() => {
    savedCardsGetAllRequest(filters)
    usersGetOneRequest({ _id })
  }, [savedCardsGetAllRequest, usersGetOneRequest, _id, filters])

  return (
    <div className='user__pfl'>
      <div className='user__profile'>
        <div className='row'>
          <div className='user__profile-header-cards' style={{ paddingTop: '15px' }}>
            <div className='user__profile-picture'>
              <img src={one?.thumbnail || profilePicture} alt='profilePicture' />
            </div>
            <div className='user__profile-description' style={{ paddingTop: '37px' }}>
              <h2>Flashcards favoritas</h2>
              <h3>
                {one?.name} {one?.lastName}
              </h3>
            </div>
          </div>
        </div>
      </div>
      {/* Loader para la carga al llegar al final del scroll */}
      {loader && <Loading />}
      {/* Loading inicial */}
      <br />
      <div className='profile_saved_cards'>
        {loading ? <ListItemLoader /> : <CardsList data={all} />}
      </div>
    </div>
  )
}
const mapStateToProps = state => ({
  all: state.savedCards.all,
  loading: state.cards.loading,
  loader: state.cards.loader,
  error: state.cards.error,
  filters: state.cards.filters,
  user: state.user,
  one: state.users.one
})
const mapDispatchToProps = {
  usersGetOneRequest,
  savedCardsGetAllRequest
}
export default connect(mapStateToProps, mapDispatchToProps)(SavedCards)
