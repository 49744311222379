import { push } from 'connected-react-router'
import { all, takeLatest, put } from 'redux-saga/effects'
import cardsSagas from './../../components/Cards/cards.saga'
import usersSagas from './../../components/Users/users.saga'
import companySagas from './../../components/Company/company.sagas'
import authSagas from './../../components/Auth/auth.sagas'
import gtaSagas from './../../components/Gta/gta.saga'
import registerSagas from './../../components/Users/register.saga'
import contactSagas from '../../components/Contact/contact.saga'
import profileSagas from '../../components/Users/profile.saga'
import priorizationsSagas from '../../components/Cards/Priorization.saga'
import commentsSagas from '../../components/Comments/comments.saga'
import rankingSagas from '../../components/Ranking/ranking.saga'
import cardSavedSagas from './../../components/Cards/cardSaved.saga'
import joinActionsSagas from '../../components/Join/joinAction.saga'

// import 'config'

export function* initAppSaga(action) {
  const lang = navigator.language
  yield alert('SAGAS RUNING')
}

export function* checkRouterSaga(action) {
  yield alert('YOU WILL BE REDIRECT')
  yield put(push('/about'))
}

export function* appSagas() {
  yield all([
    takeLatest('INIT_APP', initAppSaga),
    takeLatest('CHEK_ROUTER', checkRouterSaga),
    authSagas(),
    cardsSagas(),
    usersSagas(),
    companySagas(),
    companySagas(),
    gtaSagas(),
    registerSagas(),
    contactSagas(),
    profileSagas(),
    priorizationsSagas(),
    joinActionsSagas(),
    commentsSagas(),
    rankingSagas(),
    cardSavedSagas()
  ])
}
