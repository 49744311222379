import styled from 'styled-components'

// Create a <Title> react component that renders an <h1> which is
// centered, palevioletred and sized at 1.5em
export const TitleItemList = styled.h1`
  font-size: 28px;
  font-weight: medium;
  text-align: left;
  color: #1e3250;
  margin-bottom: 20px;
  max-width: 574px;
`

export const ImgItemList = styled.img`
  height: auto;
  width: 80px;
  margin: 15px 0 auto 0;
`

export const DescriptionItemList = styled.p`
  font-size: 18px;
  font-weight: regular;
  text-align: left;
  color: #0a121d;
`

// Create a <Wrapper> react component that renders a <section> with
// some padding and a papayawhip background
export const ContainerList = styled.section`
  padding: 5em 10% 5em 10%;

  @media only screen and (max-width: 640px) {
    padding: 1em 10% 0 10%;

  }
`

export const ContentDescriptionItem = styled.section`
  padding: 1em 2em 2em 4em;
  @media screen and (max-width: 575px) {
    max-width: 250px;
  }
`

export const ContainerItemList = styled.section`
  flex: 1;
  display: flex;
  background: #f4f4f4;
  border-top: 3px solid #749bc2;
  /* max-width: 900px; */
`
