import React from 'react'
import { Label, Container, Row, Col } from 'reactstrap'

import row from 'assets/img/row.png'
import HomeStepMetodology from './HomeStepMetodology'
const HomeMetodologyActions = () => {
  return (
    <>
      <Container
        className='home-metodology'
        style={{ paddingBottom: '0px', marginLeft: '32px', background: '#f4f4f4' }}>
        <Row className='justify-content-left' style={{ width: '100%', paddingLeft: '103px' }}>
          <Col>
            <Label className='text-metodology'>
              Pasos
            </Label>
          </Col>
        </Row>
      </Container>
      <Container
        className='home-metodology '
        style={{
          paddingBottom: '0px',
          marginLeft: '32px',
          background: '#f4f4f4',
          marginTop: '-17px'
        }}>
        <Row xs='3 justify-content-center'>
          <Col className='center-col animate__animated animate__fadeIn animate__faster animate__delay-1s center-col-card'>
            <HomeStepMetodology
              tooltip={'Ir al Mapeo de soluciones'}
              number='1'
              url='/solution-map'>
              <p>Mapeo de soluciones</p>
            </HomeStepMetodology>
          </Col>
          <img
            className='home-row-metodology animate__animated  animate__faster animate__flipInY animate__delay-2s'
            src={row}
            alt='row'
          />
          <Col
            className='center-col animate__animated animate__fadeIn animate__faster animate__delay-2s center-col-card'
            style={{ minWidth: '418px' }}>
            <HomeStepMetodology
              tooltip={'Ir a página de Priorización'}
              url='/priorization'
              number='2'
              background='#6191bf'>
              Priorización
            </HomeStepMetodology>
          </Col>
          <img
            className='home-row-metodology-2 animate__animated animate__flipInY animate__faster animate__delay-3s'
            src={row}
            alt='row'
          />
          <Col
            className='center-col animate__animated animate__fadeIn animate__faster animate__delay-3s center-col-card'
            style={{ minWidth: '418px' }}>
            <HomeStepMetodology
              tooltip={'Ir a página de Implementación'}
              url='/implementation'
              background='#56a4e3'
              number='3'>
              Implementación
            </HomeStepMetodology>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default HomeMetodologyActions
