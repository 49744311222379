import React from 'react'
import { connect } from 'react-redux'
import Company from './Company'
import ReactElasticCarousel from 'react-elastic-carousel'
import { SplashScreen } from './../SplashScreen/SplashScreen.js'

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2 },
  { width: 768, itemsToShow: 4 },
  { width: 1000, itemsToShow: 5 },
  { width: 1500, itemsToShow: 6 },
  { width: 1800, itemsToShow: 7 },
  { width: 2100, itemsToShow: 8 },
  { width: 2500, itemsToShow: 9 },
  { width: 2800, itemsToShow: 10 },
  { width: 3100, itemsToShow: 11 }
]

export const CompanyContainer = ({ data, loading }) => {
  return (
    <>
      {loading ? (
        <SplashScreen />
      ) : (
        <div
          className='container home-company-container'
          style={{ backgroundColor: '#131A27' }}>
          <div className='row justify-content-center mb-10'>
            <div className='Grupo-938'>
              <ReactElasticCarousel breakPoints={breakPoints}>
                {data &&
                  data.map(company => (
                    <div style={{ height: '12rem' }}>
                      <Company name={company.name} thumbnail={company.thumbnail} />
                    </div>
                  ))}
              </ReactElasticCarousel>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const mapStateToProps = ({ company }) => ({
  loading: company.loading
})

export default connect(mapStateToProps, null)(CompanyContainer)
