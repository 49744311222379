import React from 'react'

function HeaderPriorization() {
  return (
    <div className='container header-color animate__animated animate__fadeIn animate__faster'>
      <div className='row'>
        <div className='col-lg-12 header-grid'>
          <h2 className='header-title'>Priorización</h2>
          <span className='header-subtitle'>
            Consulta aquí las soluciones <strong>priorizadas</strong> bajo la Metodología de
            acción.
            <br />
            <br />A partir del compilado de soluciones, la priorización permitió que los Grupos
            de Trabajo Agenda 2030 eligieran qué soluciones empresariales se recomendaría
            implementar. La priorización se realizó tomando en cuenta dos dimensiones:
          </span>
        </div>
      </div>
      <div className='row header-grid-list'>
        <div className='col-lg-6'>
          <span> 1. Impacto </span>
          <ul>
            <li>Cobertura (mayor número de beneficiarios)</li>
            <li>Beneficio externo (beneficio a la comunidad)</li>
            <li>Beneficio interno (beneficio para la empresa en términos de reputación)</li>
            <li>Escalabilidad (qué tan replicable es)</li>
          </ul>
        </div>
        <div className='col-lg-6'>
          <span> 2. Facilidad de implementación </span>
          <ul>
            <li>Costo (menor costo)</li>
            <li>Recursos humanos disponibles</li>
            <li>Infraestructura disponible (tanto material como intangible)</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default HeaderPriorization
