import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Bar } from 'react-chartjs-2'
import { actionGetTotalsByLikes } from 'components/Join/joinAction.redux'
import {
  ContainerPodium,
  ContainerFirst,
  FirstPlaceContainer,
  SecondFloorContainer,
  ThirdPlaceContainer,
  ValidatePodium,
} from './GraphOdsStyle'
import "chartjs-plugin-datalabels"
import "chartjs-plugin-labels"
import JoinActionPodiumCard from 'components/Join/JoinActionPodiumCard'


const PodiumLikes = ({ actionGetTotalsByLikes, totalImplementedByLikes  }) => {

	useEffect(() => {
    actionGetTotalsByLikes()
  }, [ actionGetTotalsByLikes]) 
  
  const validate = totalImplementedByLikes.length
  const data = totalImplementedByLikes?.map((e, i) => ({ ...e, position: i }))

  const firstPlace = data[0]
  const secondPlace = data[1]
  const thirdPlace = data[2]


	return (
		<ContainerFirst>
			<ContainerPodium>
       {validate != 0 ? (
         <>
        <FirstPlaceContainer first={!secondPlace && '100vh'}>                 
          {firstPlace &&
            <JoinActionPodiumCard data={firstPlace} key={firstPlace?._id} />             
          }
        </FirstPlaceContainer>
        <SecondFloorContainer>                 
          {secondPlace &&
            <div>                 
              <JoinActionPodiumCard data={secondPlace} key={secondPlace?._id} />             
            </div>
          }
          {thirdPlace &&
            <ThirdPlaceContainer>                                
              <JoinActionPodiumCard data={thirdPlace} key={thirdPlace?._id} />             
            </ThirdPlaceContainer>
          }
        </SecondFloorContainer>
         </>
       ) : (
         <ValidatePodium>No hay iniciativas con me gusta</ValidatePodium>
       )}
			</ContainerPodium>
		</ContainerFirst>
	)
}

PodiumLikes.propTypes = {
	totalImplementedByLikes: PropTypes.array.isRequired
}

PodiumLikes.defaultProps = {
	totalImplementedByLikes: []
}

const mapStateToProps = ({ joinActions }) => ({
  totalImplementedByLikes: joinActions?.totalsLikes,
})

const mapDispatchToProps = {
  actionGetTotalsByLikes: actionGetTotalsByLikes,
}

export default connect(mapStateToProps, mapDispatchToProps)(PodiumLikes)


