import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { cardsGetAllRequest, loadMoreCardsRequest } from './../Cards/cards.redux.js'
import { SplashScreen } from './../SplashScreen/SplashScreen.js'
import { CardsAll } from '../Cards/CardsAll'
import Layout from 'components/Layout/Layout.js'
import HeaderPriorization from 'components/Header/HeaderPriorization.js'
import SpinnerODS from 'components/Helpers/SpinnerODS.js'

export const Priorization = ({
  all,
  loading,
  loader,
  filters,
  cardsGetAllRequest,
  loadMoreCardsRequest
}) => {
  const handleScroll = event => {
    const { target } = event
    if (
      Math.round(target.scrollHeight - target.scrollTop) <= target.clientHeight &&
      all?.hasNextPage
    ) {
      filters.page = all?.nextPage
      all?.totalPages >= filters.page &&
        loadMoreCardsRequest({ status: 2, active: true, limit: 10, ...filters })
    }
  }

  useEffect(() => {
    cardsGetAllRequest({ status: 2, active: true, limit: 10 })
  }, [cardsGetAllRequest])

  const headerParam = <HeaderPriorization />

  return (
    <>
      <Layout className='solution-map-scroll' hideScroll={true}>
        {loading && !all?.docs ? (
          <SplashScreen />
        ) : (
          <div onScroll={handleScroll} style={{ paddingRight: '0px' }}>
            <CardsAll data={all} footer={true} header={headerParam} />
          </div>
        )}
        {loader && <SpinnerODS />}
      </Layout>
    </>
  )
}

const mapStateToProps = ({ cards }) => ({
  all: cards.list,
  loading: cards.loading,
  loader: cards.loader,
  error: cards.error,
  filters: cards.filters
})

const mapDispatchToProps = {
  cardsGetAllRequest,
  loadMoreCardsRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(Priorization)
