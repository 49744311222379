import React from 'react'
import { Link } from 'react-router-dom'
import {
  ImageActionCard,
  JoinCardContainer,
  JoinCardContent,
  JoinCardFooter,
  JoinCardFooterLeft,
  JoinCardFooterLeftImage,
  JoinCardFooterRight,
  JoinCardFooterRightP,
  JoinCardFooterRightText,
  JoinCardHeader
} from './JoinStyledComponents'
import imgDefault from 'assets/img/defaults/defaultEdit.jpg'
import { urlImgODS } from 'helpers/imageDefaultsODS'

function JoinActionCard({ data }) {
  const { _id, name, number, ods, promoterInstitution, image } = data
  const colorOds = ods.map(i => i.color)
  const logoOds = ods.map(i => i.logo)
  const numStrOds = { ...ods.map(i => i.objective) }[0] || '0'
  const numOds = numStrOds.split(' ')[0]
  return (
    <JoinCardContainer>
      <Link to={`/good-practice/${_id}`}>
        <JoinCardHeader>
          {image ? (
            <ImageActionCard src={image} alt='joinImage' />
          ) : (
            <ImageActionCard src={urlImgODS[numOds] || imgDefault} alt='joinImage' />
          )}
        </JoinCardHeader>
        <JoinCardContent color={colorOds}>{name}</JoinCardContent>
        <JoinCardFooter>
          <JoinCardFooterLeft>
            <JoinCardFooterLeftImage src={logoOds} alt='gta-img' />
          </JoinCardFooterLeft>
          <JoinCardFooterRight>
            <JoinCardFooterRightP>
              <JoinCardFooterRightText> {promoterInstitution}</JoinCardFooterRightText>
              <br />
              Acción implementada #{number}
            </JoinCardFooterRightP>
          </JoinCardFooterRight>
        </JoinCardFooter>
      </Link>
    </JoinCardContainer>
  )
}

JoinActionCard.propTypes = {}

export default JoinActionCard
