import React from 'react'
import { connect } from 'react-redux'
import FilterBar from './../Helpers/FilterBar.js'
import { Card } from './Card.js'
import { savedCardsGetAllRequest } from './cardSaved.redux.js'

export const CardsList = ({ filters, data, savedCardsGetAllRequest }) => {
  const handleScroll = event => {
    const { target } = event
    if (target.scrollHeight - target.scrollTop <= target.clientHeight && data.hasNextPage) {
      filters.page = data.nextPage
      savedCardsGetAllRequest(filters)
    }
  }
  return (
    <>
      <div
        className='cards__row'
        style={{ marginLeft: '0px', backgroundColor: 'white' }}
        onScroll={handleScroll}>
        <FilterBar />
        <div
          className='row cards-mobile'
          style={{
            minHeight: 'calc(100vh - 500px)',
            height: '1280px',
            alignContent: 'start'
          }}>
          {data?.map(key => {
            return <Card data={key.flashcard} key={key.flashcard._id} />
          })}
        </div>
      </div>
    </>
  )
}

const mapStateToProps = ({ savedCards }) => ({
  filters: savedCards.filters
})

const mapDispatchToProps = {
  savedCardsGetAllRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(CardsList)
