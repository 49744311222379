import { ContainerMet } from 'components/FAQ/listFAQStyle'
import HeaderBanner from 'components/Header/HeaderBanner'
import imgBanner from 'assets/img/banners/implement.webp'
import HomeLinks from 'components/Home/HomeLinks'
import HomeMetodology from 'components/Home/HomeMetodology'
import React from 'react'
import Layout from '../Layout/Layout'
import InformationBanner from 'components/Header/InformationBanner'

function PageMetodology() {
  return (
    <div className='home-page'>
      <Layout>
        <HeaderBanner
          title='TARJETAS GTA2030'
          img={imgBanner}

          // btn={<HeaderSolutionMap />}
        />
        <InformationBanner
          paragraph={
            <p style={{ textAlign: 'justify', paddingRight: '31px', fontSize: '21px' }}>
              En este espacio podrás consultar propuestas de acciones de sostenibilidad
              empresarial que pueden ayudar a tu empresa a responder al reto global. <br />
              <br />
              Estas propuestas fueron generadas en la iniciativa Grupos de Trabajo Agenda 2030,
              un trabajo conjunto entre Pacto Global México y el Consejo Coordinador
              Empresarial.
            </p>
          }
        />
        <ContainerMet>
          {/* <HomeMetodology /> */}
          <HomeMetodology />
          {/* Botónes */}
          <HomeLinks deactivateIdeas={true} />
        </ContainerMet>
      </Layout>
    </div>
  )
}
export default PageMetodology
