import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { cardGetCatalogs } from '../Cards/cards.redux.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Formik, Form } from 'formik'
import { Button, Row } from 'reactstrap'
import FormInput from './../Helpers/FormInput'
import { actionFiltersChangeRequest } from './joinAction.redux.js'

export const JoinFilterBar = ({
  filters,
  catalogs,
  cardGetCatalogs,
  color,
  actionFiltersChangeRequest
}) => {
  useEffect(() => {
    cardGetCatalogs()
  }, [cardGetCatalogs])

  const objectives = catalogs?.objectives?.data

  const changeObjective = event => {
    filters.objectives = event.target.value
  }
  const handleFilter = filters => {
    actionFiltersChangeRequest(filters)
  }

  const iconSearch = (
    <FontAwesomeIcon color={color} icon={faSearch} className='join-inline-search' />
  )

  let initialValuesData = {
    ods: ''
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValuesData}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            handleFilter(values)
            setSubmitting(false)
          }, 400)
        }}>
        {({ values, isSubmitting, handleChange }) => (
          <Form className='form '>
            <Row style={{ marginBottom: '10px' }}>
              <div className='goodpractice-filter-div-content'>
                <FormInput
                  id='ods'
                  color={color}
                  label={'Objetivo de Desarrollo Sostenible'}
                  type='select'
                  onChange={e => {
                    changeObjective(e)
                    handleChange(e)
                  }}
                  dataOptions={objectives?.map(({ title, _id }) => ({
                    value: _id,
                    text: title,
                    id: _id,
                    key: _id
                  }))}
                  value={values.ods}
                />
              </div>
              <Button className='mb-1 search' type='submit' disabled={isSubmitting}>
                <span>{iconSearch}</span>
              </Button>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  )
}

JoinFilterBar.propTypes = {
  color: PropTypes.string.isRequired
}

JoinFilterBar.defaultProps = {
  color: 'white'
}

const mapStateToProps = state => {
  return {
    loading: state.cards.loading,
    filters: state.cards.filters,
    catalogs: state.cards.catalogs
  }
}

const mapDispatchToProps = {
  actionFiltersChangeRequest,
  cardGetCatalogs
}

export default connect(mapStateToProps, mapDispatchToProps)(JoinFilterBar)
