import React, { useState } from 'react'
import { connect } from 'react-redux'
import {
  NavbarContainer,
  LeftContainer,
  FirstContainer,
  SecondContainer,
  ThirdContainer,
  RightContainer,
  NavbarExtendedContainer,
  NavbarInnerContainer,
  NavbarLinkContainer,
  NavbarLinkLogo,
  NavbarLinkProfile,
  NavbarLink,
  Logo,
  OpenLinksButton,
  NavbarLinkExtended,
  NavbarDropdown,
  NavbarDropdownContent,
  NavbarItemDropdown,
  NavbarLinkDropdown,
  LinkMenu,
  NavbarLabel
} from './navbarStyleComponents'
import { loginLogoutRequest } from './../Auth/auth.redux.js'
import profileDefault from 'assets/img/profileavatar.svg'
import LogoImg from '../../assets/img/logos/logoNav.webp'
import Swal from 'sweetalert2'

function Navbar2({ loginLogoutRequest, isLogin, userId, profile }) {
  const handleLogout = () => {
    loginLogoutRequest()
  }
  const [extendNavbar, setExtendNavbar] = useState(false)

  const handleLogin = () => {
    Swal.fire({
      title: 'Inicio de sesión requerido',
      text: 'Para poder compartir una acción es necesario que inicies sesión.',
      showCancelButton: true,
      confirmButtonText: 'Iniciar sesión',
      cancelButtonText: 'Cerrar'
    }).then(result => {
      if (result.isConfirmed) {
        window.location.href = '/login'
      }
    })
  }

  return (
    <NavbarContainer extendNavbar={extendNavbar}>
      <NavbarInnerContainer>
        <LeftContainer>
          <NavbarLinkLogo to='/'>
            <Logo src={LogoImg}></Logo>
          </NavbarLinkLogo>
        </LeftContainer>
        <FirstContainer>
          <NavbarLinkContainer>
            <NavbarLink to='/good-practices-map'> Banco de acciones</NavbarLink>
            {isLogin && <NavbarLink to='/register-action'> Comparte acciones</NavbarLink>}
          </NavbarLinkContainer>
          {!isLogin && <NavbarLabel onClick={handleLogin}> Comparte acciones</NavbarLabel>}
        </FirstContainer>
        <SecondContainer>
          <NavbarLinkContainer>
            {/* dropdown */}
            <NavbarDropdown>
              <NavbarItemDropdown>Acerca de</NavbarItemDropdown>
              <NavbarDropdownContent>
                <LinkMenu>
                  <NavbarLinkDropdown to='/about'> Acerca de</NavbarLinkDropdown>
                </LinkMenu>
                <LinkMenu>
                  <NavbarLinkDropdown to='/our-objectives'>
                    {' '}
                    Nuestros objetivos
                  </NavbarLinkDropdown>
                </LinkMenu>
                <LinkMenu>
                  <NavbarLinkDropdown to='/how-participate'>
                    {' '}
                    ¿CÓMO PARTICIPAR?
                  </NavbarLinkDropdown>
                </LinkMenu>
                <LinkMenu>
                  <NavbarLinkDropdown to='/page-faq'> Faqs</NavbarLinkDropdown>
                </LinkMenu>
              </NavbarDropdownContent>
            </NavbarDropdown>
          </NavbarLinkContainer>
        </SecondContainer>
        <ThirdContainer>
          <NavbarLinkContainer>
            {/* dropdown */}
            <NavbarLink to='/in-numbers'> En cifras</NavbarLink>
          </NavbarLinkContainer>
        </ThirdContainer>
        <SecondContainer>
          <NavbarLinkContainer>
            {/* dropdown */}
            <NavbarDropdown>
              <NavbarItemDropdown>Recursos</NavbarItemDropdown>
              <NavbarDropdownContent>
                <LinkMenu>
                  <NavbarLinkDropdown to='/implementation-guides'>
                    {' '}
                    Publicaciones GTA2030
                  </NavbarLinkDropdown>
                </LinkMenu>
                <LinkMenu>
                  <NavbarLinkDropdown to='/metodology'>Tarjetas GTA2030</NavbarLinkDropdown>
                </LinkMenu>
              </NavbarDropdownContent>
            </NavbarDropdown>
          </NavbarLinkContainer>
        </SecondContainer>
        <RightContainer>
          <NavbarLinkContainer>
            <NavbarLink to='/contact'> Contacto </NavbarLink>
            {!isLogin && <NavbarLink to='/register'> Registro</NavbarLink>}
            {!isLogin ? (
              <NavbarLink to='/login'> iniciar sesión</NavbarLink>
            ) : (
              <NavbarLink onClick={handleLogout} to='/!'>
                {' '}
                Cerrar sesión
              </NavbarLink>
            )}
            {isLogin && (
              <NavbarLinkProfile to={`/profile/${userId}`}>
                <img
                  className='img-profile rounded-circle'
                  src={profile || profileDefault}
                  alt='profile'
                />
              </NavbarLinkProfile>
            )}
            <OpenLinksButton
              onClick={() => {
                setExtendNavbar(curr => !curr)
              }}>
              {extendNavbar ? <>&#10005;</> : <> &#8801;</>}
            </OpenLinksButton>
          </NavbarLinkContainer>
        </RightContainer>
      </NavbarInnerContainer>
      {extendNavbar && (
        <NavbarExtendedContainer>
          <NavbarLinkExtended to='/good-practices-map'> Banco de acciones</NavbarLinkExtended>
          <NavbarLinkExtended to='/register-action'> Comparte acciones</NavbarLinkExtended>
          <NavbarLinkExtended to='/metodology'>Tarjetas GTA2030 </NavbarLinkExtended>
          <NavbarLinkExtended to='/about'> Acerca de</NavbarLinkExtended>
          <NavbarLinkExtended to='/our-objectives'> Nuestros objetivos</NavbarLinkExtended>
          <NavbarLinkExtended to='/how-participate'> ¿CÓMO PARTICIPAR?</NavbarLinkExtended>
          <NavbarLinkExtended to='/page-faq'> Faqs</NavbarLinkExtended>
          <NavbarLinkExtended to='/in-numbers'> En cifras</NavbarLinkExtended>
          <NavbarLinkExtended to='/implementation-guides'> Recursos</NavbarLinkExtended>
          <NavbarLinkExtended to='/contact'> Contacto </NavbarLinkExtended>
          {!isLogin && <NavbarLinkExtended to='/register'> Registro</NavbarLinkExtended>}
          {!isLogin ? (
            <NavbarLinkExtended to='/login'> iniciar sesión</NavbarLinkExtended>
          ) : (
            <NavbarLinkExtended onClick={handleLogout} to='/!'>
              {' '}
              Cerrar sesión
            </NavbarLinkExtended>
          )}
        </NavbarExtendedContainer>
      )}
    </NavbarContainer>
  )
}

const mapStateToProps = state => {
  return {
    isLogin: state.auth.isLogin,
    userId: state.user._id,
    profile: state.user.thumbnail
  }
}
const mapDispatchToProps = {
  loginLogoutRequest: loginLogoutRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar2)
