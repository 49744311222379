import React from 'react'
import { Link } from 'react-router-dom'
import LoginFormEmailPassword from './LoginFormEmailPassword'

export default function LoginFormInputs(props) {
  return (
    <div>
      <LoginFormEmailPassword
        iconQuestion={props.iconQuestion}
        handleChange={props.handleChange}
        values={props.values}
      />
      <div className='row gutters checkbox'>
        <label>
          <div className='login__forgot'>
            <Link to='/recover'>Olvidé mi contraseña</Link>
          </div>
        </label>
      </div>
      <div className='row gutters'>
        <button
          type='submit'
          id='submit'
          className='btn btn-primary btn-block mt-3'
          disabled={props.loading}>
          {'Ingresar'}
        </button>
      </div>
    </div>
  )
}
