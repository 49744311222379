import React from 'react'
import imgBanner from 'assets/img/banners/actions.webp'
import HeaderBanner from './HeaderBanner'

function HeaderGoodPractice({ children }) {
  return (
    <div className='container headerImpl-color animate__animated animate__fadeIn animate__faster'>
      <HeaderBanner
        title='BANCO DE ACCIONES'
        goodPractices
        img={imgBanner}
        second='¡Conoce las buenas prácticas!'
        description='Soluciones innovadoras en las que trabajan las empresas en México en su camino hacia la sostenibilidad.'
      />
      <div className='row'>
        <div className='col-lg-12 headerImpl-grid'>{children}</div>
      </div>
    </div>
  )
}

export default HeaderGoodPractice
