import React from 'react'
import { Row, Col } from 'reactstrap'
import HomeTitleBanner from './HomeTitleBanner'
import HomeTitleContent from './HomeTitleContent'
import HomeMetodologyActions from './HomeMetodologyActions'
import QuoteContent from 'components/FAQ/QuoteContent'
import {
  BoldText,
  ButtonLightBlue,
  Container,
  ContainerCenter,
  ContentDescriptionItem,
  LinkItemList,
  ParraphListItem,
  QuoteSmall,
  TitleListItem
} from 'components/FAQ/listFAQStyle'
import { Link } from 'react-router-dom'

const HomeMetodology = () => {
  return (
    <>
      {/* Texto */}
      <ContainerCenter center>
        <ButtonLightBlue to='solution-map'>
          Conoce aquí <b> TARJETAS GTA2030 </b> para cada uno de los ODS.
        </ButtonLightBlue>
      </ContainerCenter>

      <QuoteContent>
        <TitleListItem>Metodología de Acción Empresarial</TitleListItem>
        <ParraphListItem>
          En este espacio te compartimos CLIMB, Metodología que plantea que para que exista un
          cambio sistémico dentro de las organizaciones se requieren cinco condiciones:
        </ParraphListItem>
        <ParraphListItem>
          <BoldText>C</BoldText> – Compromiso del CEO <br />
          <BoldText>L</BoldText> – Desarrollo de liderazgo de mandos medios y altos <br />
          <BoldText>I</BoldText> – Infraestructura <br />
          <BoldText>M</BoldText> – Métricas (Indicadores) <br />
          <BoldText>B</BoldText> – Behavior (Conducta)
        </ParraphListItem>
        <QuoteSmall>
          (McKinsey, One aspiration, two realities, promoting gender equality in Mexico, 2018.)
        </QuoteSmall>
      </QuoteContent>

      {/* Animación de los pasos */}
      <HomeMetodologyActions />

      <QuoteContent>
        <TitleListItem>1. Mapeo de soluciones</TitleListItem>
        <ParraphListItem>
          En esta etapa se compilan las propuestas de soluciones, para atender cada uno de los
          objetivos planteados.
        </ParraphListItem>
        <ParraphListItem>
          Requiere de la participación del mayor número de expertos, con el fin de contar con
          una plataforma de inteligencia colectiva.
        </ParraphListItem>
        <ParraphListItem>
          Las propuestas de soluciones son nuevas IDEAS PARA IMPLEMENTAR.
          <br />
          Estas ideas se presentan en{' '}
          <LinkItemList to='/solution-map'>tarjetas de acción</LinkItemList> tipo naipes que
          muestran de manera sintética el detalle de las soluciones.
        </ParraphListItem>
        <ParraphListItem style={{ marginTop: '35px', paddingBottom: '15px' }}>
          <ContainerCenter>
            <ButtonLightBlue to='solution-map'>
              Conoce aquí <b> TARJETAS GTA2030 </b> para cada uno de los ODS.
            </ButtonLightBlue>
          </ContainerCenter>
        </ParraphListItem>
      </QuoteContent>

      <QuoteContent>
        <TitleListItem>2. Priorización</TitleListItem>
        <ParraphListItem>
          A partir del mapeo de soluciones, se realiza un ejercicio de priorización que asigna
          una calificación a cada acción propuesta bajo dos criterios:
        </ParraphListItem>

        <Row xs='1' sm='2' md='2' lg='2' xl='2' xxl='2'>
          <Col>
            <ParraphListItem>
              <BoldText>Impacto </BoldText>
              <ul className='listaguion'>
                <li>Cobertura (mayor número de beneficiarios)</li>
                <li>Beneficio externo (beneficio a la comunidad)</li>
                <li>
                  Beneficio interno (beneficio para la empresa en términos de reputación)
                </li>
                <li>Escalabilidad (qué tan replicable es)</li>
              </ul>
            </ParraphListItem>
          </Col>
          <Col>
            <ParraphListItem>
              <BoldText>Facilidad de implementación</BoldText>
              <ul>
                <li>Costo (menor costo)</li>
                <li>Recursos humanos disponibles</li>
                <li> Infraestructura disponible (tanto material como intangible)</li>
              </ul>
            </ParraphListItem>
          </Col>
        </Row>
      </QuoteContent>

      <QuoteContent>
        <TitleListItem>3. Implementación</TitleListItem>
        <ParraphListItem>
          En esta etapa las empresas se comprometen a implementar las acciones.
        </ParraphListItem>
        <ParraphListItem>
          Una vez implementada la propuesta de solución, se transferirá al banco de acciones
          empresariales.
        </ParraphListItem>
        <ParraphListItem style={{ marginTop: '35px', paddingBottom: '15px' }}>
          <ContainerCenter>
            <ButtonLightBlue to='good-practices-map'>
              Si implementas alguna de estas ideas, compártela aquí en el{' '}
              <b>BANCO DE ACCIONES</b>
            </ButtonLightBlue>
          </ContainerCenter>
        </ParraphListItem>
      </QuoteContent>
    </>
  )
}

export default HomeMetodology
