import { put, call, takeLatest, delay } from 'redux-saga/effects'

import {
  companyListError,
  companyListSuccess,
  companyRankingListError,
  companyRankingListSuccess,
  COMPANY_CREATE_FAILURE,
  COMPANY_CREATE_REQUEST,
  COMPANY_CREATE_SUCCESS,
  COMPANY_EDIT_REQUEST,
  COMPANY_GET_ALL_FAILURE,
  COMPANY_GET_ALL_REQUEST,
  COMPANY_GET_ALL_SUCCESS,
  COMPANY_GET_ONE_FAILURE,
  COMPANY_GET_ONE_REQUEST,
  COMPANY_GET_ONE_SUCCESS,
  COMPANY_REMOVE_FAILURE,
  COMPANY_REMOVE_REQUEST,
  COMPANY_REMOVE_SUCCESS,
  typesCompany
} from './company.redux.js'
import axios from 'axios'
import Swal from 'sweetalert2'
import { push } from 'connected-react-router'
import { hideLoading, showLoading } from 'react-redux-loading-bar'
import { encodeFiltersData, manageError } from './../../redux/helpers/handlers.js'

function* companyListRequest({ page = 1 }) {
  try {
    const { data } = yield call(axios.get, `/api/company?page=${page}`)
    yield delay(process.env.REACT_APP_DELAY)
    yield put(companyListSuccess(data.data))
  } catch (error) {
    Swal.fire('Error', error, 'error')
    yield put(companyListError(error.message))
  }
}

function* companyRankingListRequest({ filters = {} }) {
  try {
    const filterString = encodeFiltersData(filters)
    yield put(showLoading())
    const { data } = filterString
      ? yield call(axios.get, `/api/comapan?${filterString}`)
      : yield call(axios.get, `/api/comapan`)
    yield put(companyRankingListSuccess(data.data))
    yield put(hideLoading())
  } catch (error) {
    Swal.fire('Error', error, 'error')
    yield put(companyRankingListError(error.message))
  }
}

export function* companyFiltersChangRequest({ filters }) {
  try {
    filters.page = 1
    yield delay(800)
    yield put({ type: typesCompany.companyFilterChangeSuccess, payload: filters })
    yield put({ type: typesCompany.companyRankingListRequest, filters })
  } catch (error) {
    Swal.fire('Error', error, 'error')
    yield put({ type: typesCompany.companyFilterChangeError, error: error.response })
  }
}

export function* getAllCompanySaga({ page = 1 }) {
  try {
    const { data } = yield call(axios.get, `/api/company?page=${page}`)
    yield delay(process.env.REACT_APP_DELAY)
    yield put({ type: COMPANY_GET_ALL_SUCCESS, payload: data?.data })
  } catch (error) {
    Swal.fire('Error', error, 'error')
    yield put({ type: COMPANY_GET_ALL_FAILURE, error: error.response })
  }
}

export function* createCompanySaga({ payload }) {
  try {
    const insert = yield call(axios.post, '/api/company', payload)
    yield delay(process.env.REACT_APP_DELAY)
    yield put({ type: COMPANY_CREATE_SUCCESS, payload: insert })
    yield put(push('/admin/card'))
  } catch (error) {
    Swal.fire('Error', error, 'error')
    yield put({ type: COMPANY_CREATE_FAILURE, error: error.response })
  }
}

export function* removeCompanySaga({ id }) {
  try {
    const insert = yield call(axios.delete, '/api/company/' + id)
    yield delay(process.env.REACT_APP_DELAY)
    yield put({ type: COMPANY_REMOVE_SUCCESS, payload: insert })
    yield put({ type: COMPANY_GET_ALL_REQUEST })
    yield put(push('/admin/card'))
  } catch (error) {
    Swal.fire('Error', error, 'error')
    yield put({ type: COMPANY_REMOVE_FAILURE, error: error.response })
  }
}
export function* getOneCompanySaga({ id = 1 }) {
  try {
    const { data } = yield call(axios.get, `/api/company/${id}`)
    yield delay(process.env.REACT_APP_DELAY)
    yield put({ type: COMPANY_GET_ONE_SUCCESS, payload: data?.data })
  } catch (error) {
    Swal.fire('Error', error, 'error')
    yield put({ type: COMPANY_GET_ONE_FAILURE, error: error.response })
  }
}
export function* editCompanySaga({ payload }) {
  try {
    const insert = yield call(axios.put, '/api/company/' + payload._id, payload)
    yield put({ type: COMPANY_CREATE_SUCCESS, payload: insert })
    yield put(push('/admin/company'))
  } catch (error) {
    Swal.fire('Error', error, 'error')
    yield put({ type: COMPANY_CREATE_FAILURE, error: error.response })
  }
}

export default function* companySagas() {
  yield takeLatest(typesCompany.companyListRequest, companyListRequest)
  yield takeLatest(typesCompany.companyRankingListRequest, companyRankingListRequest)
  yield takeLatest(typesCompany.companyFilterChangeRequest, companyFiltersChangRequest)
  yield takeLatest(COMPANY_CREATE_REQUEST, createCompanySaga)
  yield takeLatest(COMPANY_GET_ALL_REQUEST, getAllCompanySaga)
  yield takeLatest(COMPANY_REMOVE_REQUEST, removeCompanySaga)
  yield takeLatest(COMPANY_GET_ONE_REQUEST, getOneCompanySaga)
  yield takeLatest(COMPANY_EDIT_REQUEST, editCompanySaga)
  yield takeLatest(COMPANY_GET_ALL_FAILURE, manageError)
  yield takeLatest(COMPANY_REMOVE_FAILURE, manageError)
  yield takeLatest(COMPANY_GET_ONE_FAILURE, manageError)
}
