// TYPES
export const GTA_CREATE_REQUEST = 'GTA_CREATE_REQUEST'
export const GTA_CREATE_SUCCESS = 'GTA_CREATE_SUCCESS'
export const GTA_CREATE_FAILURE = 'GTA_CREATE_FAILURE'

export const GTA_GET_ALL_REQUEST = 'GTA_GET_ALL_REQUEST'
export const GTA_GET_ALL_SUCCESS = 'GTA_GET_ALL_SUCCESS'
export const GTA_GET_ALL_FAILURE = 'GTA_GET_ALL_FAILURE'

export const GTA_REMOVE_REQUEST = 'GTA_REMOVE_REQUEST'
export const GTA_REMOVE_SUCCESS = 'GTA_REMOVE_SUCCESS'
export const GTA_REMOVE_FAILURE = 'GTA_REMOVE_FAILURE'

export const GTA_EDIT_REQUEST = 'GTA_EDIT_REQUEST'
export const GTA_EDIT_SUCCESS = 'GTA_EDIT_SUCCESS'
export const GTA_EDIT_FAILURE = 'GTA_EDIT_FAILURE'

export const GTA_GET_ONE_REQUEST = 'GTA_GET_ONE_REQUEST'
export const GTA_GET_ONE_SUCCESS = 'GTA_GET_ONE_SUCCESS'
export const GTA_GET_ONE_FAILURE = 'GTA_GET_ONE_FAILURE'

export const typesGta = {
  gtaListRequest: '[ GTA ] GTA_LIST_REQUEST',
  gtaListSuccess: '[ GTA ] GTA_LIST_SUCESS',
  gtaListError: '[ GTA ] GTA_LIST_ERROR'
}

// ACTIONS
export const gtaGetAllRequest = payload => ({ type: GTA_GET_ALL_REQUEST, payload })
export const gtaListRequest = filters => ({ type: typesGta.gtaListRequest, filters })
export const gtaListSuccess = payload => ({ type: typesGta.gtaListSuccess, payload })
export const gtaListError = payload => ({ type: typesGta.gtaListError, payload })

// REDUCER
const initialState = {
  gtaList: {},
  loading: false,
  loader: false,
  error: '',
  all: [],
  one: {}
}

export function gta(state = initialState, action) {
  switch (action.type) {
    case typesGta.gtaListRequest:
    case GTA_CREATE_REQUEST:
    case GTA_GET_ONE_REQUEST:
    case GTA_EDIT_REQUEST:
    case GTA_REMOVE_REQUEST:
      return { ...state, error: null, loading: true }
    case GTA_GET_ALL_REQUEST:
      return { ...state, error: null, loading: true, loader: false }

    case typesGta.gtaListSuccess:
      return { ...state, loading: false, gtaList: action.payload }
    case GTA_CREATE_SUCCESS:
      return { ...state, error: null, loading: false, one: [action.payload] }

    case GTA_GET_ALL_SUCCESS:
      return { ...state, error: null, loading: false, all: action.payload, loader: true }
    case GTA_EDIT_SUCCESS:
    case GTA_GET_ONE_SUCCESS:
    case GTA_REMOVE_SUCCESS:
      return { ...state, error: null, loading: false, one: { ...action.payload } }

    case typesGta.gtaListError:
    case GTA_CREATE_FAILURE:
    case GTA_GET_ONE_FAILURE:
    case GTA_EDIT_FAILURE:
    case GTA_REMOVE_FAILURE:
      return { ...state, loading: false, error: action.payload }
    case GTA_GET_ALL_FAILURE:
      return { ...state, loading: false, error: action.payload, loader: true }
    default:
      return state
  }
}
