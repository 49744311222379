import React from 'react'
import PropTypes from 'prop-types'
import {
  ContainerItemList,
  ContentDescriptionItem,
  DescriptionItemList,
  ImgItemList,
  TitleItemList
} from './listDropDownStyle'

const ItemDropDownOurObjectives = ({ img, title, description }) => {
  return (
    <ContainerItemList>
      <ImgItemList src={img} />
      <ContentDescriptionItem>
        <TitleItemList>{title}</TitleItemList>
        <DescriptionItemList className='objectives-paragraph'>{description}</DescriptionItemList>
      </ContentDescriptionItem>
    </ContainerItemList>
  )
}

ItemDropDownOurObjectives.propTypes = {
  img: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string
}
export default ItemDropDownOurObjectives
