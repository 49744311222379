import React from 'react'
import { connect } from 'react-redux'
import RegisterCreate from './RegisterCreate'
import UsersRegisterForm from './RegisterForm'
export const Register = props => {
  return (
    <div>
      <div>
        <div className='users__main'>
          <div className='users__box-container animate__animated animate__fadeIn animate__faster'>
            <div>
              <RegisterCreate />
              <UsersRegisterForm />
            </div>
            <img
              className='register-mobile'
              src='https://source.unsplash.com/tXiMrX3Gc-g/300x600'
              alt=''
            />
          </div>
        </div>
      </div>
    </div>
  )
}
const mapStateToProps = state => ({
  users: state.users.all,
  usersError: state.users.error,
  usersLoading: state.users.loading
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(Register)
