import React from 'react'
import PropTypes from 'prop-types'
import HomeHead from '../Home/HomeHead'

export const PostLegacyDisclaimer = ({ title, content }) => {
  return (
    <div className='container container-light '>
      <div className='row animate__animated animate__fadeIn light post-paragraph'>
        {!content ? (
          <>
            <HomeHead title={title} type={2} />
            <div itemProp='articleBody post-paragraph'>
              <p className=''>
                <strong>Valores Corporativos Softtek, S.A. de C.V.</strong>,Lorem ipsum dolor
                sit amet, consectetur adipiscing elit. Vivamus iaculis sapien a felis porta, at
                faucibus velit sodales. Vestibulum vel neque nibh. In ac nibh quis mi ultricies
                tristique ac condimentum quam. Fusce aliquet varius nulla ac euismod. Nam nec
                feugiat velit. Sed imperdiet luctus maximus. Vivamus rutrum tortor ut arcu
                dictum iaculis. Ut hendrerit neque aliquet blandit malesuada. Sed in
                sollicitudin orci, non molestie augue. Quisque a mi sit amet risus interdum
                accumsan quis non erat. Nunc gravida eros ac mauris ultrices imperdiet. In
                felis massa, tempus nec pharetra ut, porttitor non velit. Phasellus in pharetra
                massa, id faucibus orci. Suspendisse eget nunc non tortor fermentum malesuada
                ac in dolor. Vestibulum a turpis eu ipsum dictum iaculis. Aliquam vel velit
                congue, hendrerit arcu ac, mattis urna.
                <a href='https://www.softtek.com/legal-disclaimer#terms'>terms of service.</a>
              </p>
              <br />
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus iaculis sapien a
              felis porta, at faucibus velit sodales. Vestibulum vel neque nibh. In ac nibh
              quis mi ultricies tristique ac condimentum quam. Fusce aliquet varius nulla ac
              euismod. Nam nec feugiat velit. Sed imperdiet luctus maximus. Vivamus rutrum
              tortor ut arcu dictum iaculis. Ut hendrerit neque aliquet blandit malesuada. Sed
              in sollicitudin orci, non molestie augue. Quisque a mi sit amet risus interdum
              accumsan quis non erat. Nunc gravida eros ac mauris ultrices imperdiet. In felis
              massa, tempus nec pharetra ut, porttitor non velit. Phasellus in pharetra massa,
              id faucibus orci. Suspendisse eget nunc non tortor fermentum malesuada ac in
              dolor. Vestibulum a turpis eu ipsum dictum iaculis. Aliquam vel velit congue,
              hendrerit arcu ac, mattis urna.
              <br />
              <br />
              <br />
            </div>
          </>
        ) : (
          <>
            <br />
            <HomeHead
              style={{ marginTop: '30px' }}
              title={'TÉRMINOS Y CONDICIONES'}
              type={2}
            />

            <div itemProp='articleBody post-paragraph' className='articleBody'>
              <p>
                La utilización de este sitio y de cualquiera de las aplicaciones del mismo
                constituye el pleno y expreso consentimiento por parte del usuario para
                observar y sujetarse respecto de cada uno de los términos y condiciones que
                aquí se contienen, así como respecto de las políticas de privacidad, políticas
                de seguridad y, en su caso, cualesquiera otros documentos que conformen parte o
                regulen la participación del usuario en este sitio. En el supuesto que
                cualquiera de los términos a los que habrá de sujetarse el usuario cuando
                acceda a este sitio sean contrarios a sus intereses, deberá abstenerse de hacer
                uso de este.
              </p>
              <p>
                <b>Obligaciones del usuario</b>
                <br />
                Al utilizar este sitio me obligo a cumplir con los términos y condiciones de su
                uso, establecidos en el presente documento (en adelante, los “términos y
                condiciones"), por lo que reconozco haber leído, entendido y estar de acuerdo
                en sujetarme a sus términos y condiciones. De manera que, al acceder al sitio,
                estoy de acuerdo en acatar lo anterior. La información y los servicios que
                ofrece www.pactomundial.org.mx (en adelante el Sitio) hacen referencia
                exclusivamente, a la normatividad que los rige en los Estados Unidos Mexicanos.
              </p>
              <p>
                Por el hecho de hacer uso de la información y hacer uso de los Servicios, el
                usuario expresa su aceptación y está de acuerdo con los términos y condiciones
                del uso del sitio, así como con las modificaciones que, en su caso, se
                realicen.
              </p>
              <p>
                Los usuarios no residentes en los Estados Unidos Mexicanos que deseen acceder
                al sitio deberán asegurarse de que el acceso y uso de este, de su contenido y/o
                de los servicios, les esté permitido de conformidad con su propia legislación.
              </p>
              <p>
                El usuario que utilice este sitio fuera de la República Mexicana se hará
                responsable del cumplimiento de todas las leyes de dicha jurisdicción.
              </p>
              <p>El uso del sitio es bajo la exclusiva responsabilidad del usuario.</p>
              <p>
                Todos los materiales descargables son propiedad de Red del Pacto Global de y en
                México, A.C. Se autoriza al Usuario a visualizar y descargar los materiales
                contenidos en el sitio solamente para su uso personal y no para un uso
                comercial.
              </p>
              <p>
                El Usuario deberá conservar en todas las copias de los materiales descargados,
                todos los avisos sobre derechos de autor y propiedad intelectual de los
                materiales, contenidos en los mismos;
              </p>
              <p>
                El Usuario no deberá modificar, reproducir o mostrar pública o comercialmente
                los materiales, ni podrá distribuir o utilizarlos con algún propósito público o
                comercial;
              </p>
              <p>
                El Usuario no deberá transferir los materiales a ningún tercero. Nos reservamos
                el derecho de suspender, interrumpir o dejar de operar el sitio y los
                servicios, en cualquier momento.
              </p>
              <p>
                Cualquier inconveniente o anomalía presentada en estos espacios deberá ser
                reportado al área de Servicios 5540009714.
                <br />
                <b>e-mail:</b>{' '}
                <a href='mailto:contacto@pactomundial.org.mx'>contacto@pactomundial.org.mx</a>
                <br /> <b>Horario:</b> de Lunes a Jueves de 9:00 a 17:00 hrs
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

PostLegacyDisclaimer.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired
}

PostLegacyDisclaimer.defaultProps = {
  title: '',
  content: ''
}

export default PostLegacyDisclaimer
