import React from 'react'
import { Formik, Form } from 'formik'
import { connect, useDispatch } from 'react-redux'
import { contactCreateRequest } from './contact.redux'
import LoadingBar from 'react-redux-loading-bar'
import {
  ActualLabel,
  ErrorMessageStyled,
  Flex,
  Input,
  Label,
  PrimaryButton
} from './contactStyleComponents.js'
const FooterContactForm = () => {
  const dispatch = useDispatch()
  const handleContact = values => dispatch(contactCreateRequest(values))
  return (
    <>
      <LoadingBar
        showFastActions
        maxProgress={100}
        progressIncrease={50}
        style={{ backgroundColor: '#7a9ec6', height: '5px', top: 0, left: 0, zIndex: 999999 }}
      />
      <div className='contact__form2'>
        <Formik
          initialValues={{ name: '', email: '', subject: '', message: '' }}
          validate={values => {
            const errors = {}
            for (const key in values) if (!values[key]) errors[key] = 'Requerido'
            if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email))
              errors.email = 'Ingresa un correo válido'
            return errors
          }}
          // Submit
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              handleContact(values)
              setSubmitting(false)
            }, 400)
          }}>
          {({ touched, errors, isSubmitting }) => (
            <Form className='form contact__form2'>
              <Flex wrap='wrap'>
                <ActualLabel
                  width
                  style={{
                    width: '220px !important',
                    minWidth: '220px !important',
                    maxWidth: '220px !important',
                    marginRight: '19px'
                  }}>
                  <Label>Nombre</Label>
                  <Input
                    type='text'
                    name='name'
                    autoCorrect='off'
                    autoComplete='name'
                    valid={touched.name && !errors.name}
                    error={touched.name && errors.name}
                  />
                  <ErrorMessageStyled name='name' />
                </ActualLabel>
                <ActualLabel
                  width
                  style={{
                    width: '220px !important',
                    minWidth: '220px !important',
                    maxWidth: '220px !important'
                  }}>
                  <Label>Correo</Label>
                  <Input
                    type='text'
                    name='email'
                    autoCorrect='off'
                    autoComplete='email'
                    valid={touched.email && !errors.email}
                    error={touched.email && errors.email}
                  />
                  <ErrorMessageStyled name='email' />
                </ActualLabel>
              </Flex>
              <Flex wrap='wrap'>
                <ActualLabel fullwidth>
                  <Label>Asunto</Label>
                  <Input
                    type='text'
                    name='subject'
                    autoCorrect='off'
                    autoComplete='subject'
                    valid={touched.subject && !errors.subject}
                    error={touched.subject && errors.subject}
                  />
                  <ErrorMessageStyled name='subject' />
                </ActualLabel>
              </Flex>
              <Flex wrap='wrap'>
                <ActualLabel fullwidth>
                  <Label>Mensaje</Label>
                  <Input
                    type='textarea'
                    name='message'
                    autoCorrect='off'
                    autoComplete='message'
                    valid={touched.message && !errors.message}
                    error={touched.message && errors.message}
                  />
                  <ErrorMessageStyled name='message' />
                </ActualLabel>
              </Flex>
              <PrimaryButton type='submit' disabled={isSubmitting}>
                {'Enviar'}
              </PrimaryButton>
            </Form>
          )}
        </Formik>
      </div>
    </>
  )
}

const mapStateToProps = ({ contact }) => ({
  loading: contact.loading,
  error: contact.error
})

const createContact = payload => ({
  type: 'CONTACT_CREATE_REQUEST',
  payload: payload.values
})

const eventDispatchCreateContact = {
  createContact
}

export default connect(
  (createContact, mapStateToProps),
  eventDispatchCreateContact
)(FooterContactForm)
