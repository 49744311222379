// Type's
export const RANKING_GET_ALL_REQUEST = 'RANKING_GET_ALL_REQUEST'
export const RANKING_GET_ALL_SUCCESS = 'RANKING_GET_ALL_SUCCESS'
export const RANKING_GET_ALL_FAILURE = 'RANKING_GET_ALL_FAILURE'
export const RANKING_GET_RANKING_LIST_REQUEST = 'RANKING_GET_RANKING_LIST_REQUEST'
export const RANKING_GET_RANKING_LIST_SUCCESS = 'RANKING_GET_RANKING_LIST_SUCCESS'
export const RANKING_GET_RANKING_LIST_FAILURE = 'RANKING_GET_LIST_FAILURE'
export const RANKING_GET_CATALOGS_REQUEST = 'RANKING_GET_CATALOGS_REQUEST'
export const RANKING_GET_CATALOGS_SUCCESS = 'RANKING_GET_CATALOGS_SUCCESS'
export const RANKING_GET_CATALOGS_FAILURE = 'RANKING_GET_CATALOGS_FAILURE'
export const rankingCardGetCatalogs = payload => ({
  type: RANKING_GET_CATALOGS_REQUEST,
  payload
})
export const rankingGetAllRequest = page => ({ type: RANKING_GET_ALL_REQUEST, page })
export const rankingGetRankingList = ({ values }) => ({
  type: RANKING_GET_RANKING_LIST_REQUEST,
  payload: values
})
const INITIAL_STATE = {
  one: {},
  all: [],
  loading: false,
  loader: false,
  error: null,
  list: [],
  ranking: {},
  catalogs: {}
}
export function rankings(state = INITIAL_STATE, action) {
  switch (action.type) {
    case RANKING_GET_ALL_REQUEST:
    case RANKING_GET_CATALOGS_REQUEST:
      return { ...state, error: null, loading: true }
    case RANKING_GET_RANKING_LIST_REQUEST:
      return { ...state, error: null, loading: true, loader: true }

    case RANKING_GET_ALL_SUCCESS:
      return { ...state, error: null, loading: false, all: { ...action.payload } }
    case RANKING_GET_RANKING_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        loader: false,
        ranking: { ...action.payload }
      }
    case RANKING_GET_CATALOGS_SUCCESS:
      return { ...state, error: null, loading: false, catalogs: { ...action.payload } }

    case RANKING_GET_ALL_FAILURE:
    case RANKING_GET_CATALOGS_FAILURE:
      return { ...state, loading: false, error: action.error }
    case RANKING_GET_RANKING_LIST_FAILURE:
      return { ...state, loading: false, error: action.error, loader: false }
    default:
      return state
  }
}
