import React, { useEffect } from 'react'
import HeaderRanking from './../Ranking/HeaderRanking'
import Layout from './../Layout/Layout'
import ListItemLoader from '../Cards/CardsItemLoader.js'
import { SplashScreen } from '../SplashScreen/SplashScreen.js'
import { connect, useDispatch } from 'react-redux'
import RankingSlider from '../Ranking/RankingSlider'
import { rankingGetRankingList } from 'components/Ranking/ranking.redux'

function Ranking({ loading, loader, filters }) {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(rankingGetRankingList({}))
  }, [dispatch])

  return (
    <div className='ranking-page'>
      <Layout>
        <HeaderRanking />
        {loader && <ListItemLoader />}
        <br />
        {loading ? <SplashScreen /> : <RankingSlider />}
      </Layout>
    </div>
  )
}
const mapStateToProps = ({ cards }) => ({
  cardsList: cards.cardsList,
  loading: cards.loading,
  loader: cards.loader,
  error: cards.error,
  filters: cards.filters
})
export default connect(mapStateToProps, null)(Ranking)
