import React from 'react'
import { connect } from 'react-redux'
import imgBanner from 'assets/img/banners/actions.webp'
import FilterBar from './../Helpers/FilterBar.js'
import { Card } from './Card.js'
import { cardsGetAllRequest } from './cards.redux.js'
import Footer from './../Layout/Footer'
import HeaderBanner from 'components/Header/HeaderBanner.js'
import HeaderSolutionMap from 'components/Header/HeaderSolutionMap.js'
import ButtonsBanner from 'components/Header/ButtonsBanner.js'
export const CardsAll = ({ data, areas, footer, header }) => {
  const { docs } = data
  return (
    <>
      <div
        className='container cards__row'
        style={{ marginLeft: '0px', backgroundColor: 'white' }}>
        {/* {header} */}
        <HeaderBanner
          title='TARJETAS GTA2030'
          img={imgBanner}
          paragraph='En este espacio podrás consultar propuestas de acciones de sostenibilidad empresarial que pueden ayudar a tu empresa a responder al reto global.'
          btn={<HeaderSolutionMap />}
        />
        <ButtonsBanner />
        <div className='div-filter-map' style={{ paddingLeft: '5rem' }}>
          <FilterBar />
        </div>
        <div className='row  '>
          <div className='row cards-mobile cards-list-goodpractices-row'>
            {docs?.map(key => {
              return <Card areas={areas} data={key} key={key._id} />
            })}
          </div>
          {footer && (
            <div style={{ padding: '0px 0px 0px 11px', marginTop: '-30px' }}>
              <Footer />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

const mapStateToProps = ({ cards }) => ({
  all: cards.all,
  loading: cards.loading,
  error: cards.error,
  filters: cards.filters
})

const mapDispatchToProps = {
  cardsGetAllRequest: cardsGetAllRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(CardsAll)
