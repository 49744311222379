import React from 'react'
import { Link } from 'react-router-dom'
import {
  ImageActionCard,
  JoinCardContainerPodium,
  JoinCardContent,
  JoinCardContentLikes,
  JoinCardFooterLeft,
  JoinCardFooterLeftImage,
  JoinCardFooterPodium,
  JoinCardFooterRightPodium,
  JoinCardFooterRightP,
  JoinCardFooterRightText,
  JoinCardHeaderPodium,
  JoinCardPodiumLikes,
  JoinCardPlace
} from './JoinStyledComponents'
import imgDefault from 'assets/img/defaults/defaultEdit.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import first from 'assets/img/podium/first.svg'
import second from 'assets/img/podium/second.svg'
import third from 'assets/img/podium/third.svg'
import { urlImgODS } from 'helpers/imageDefaultsODS'

function JoinActionPodiumCard({ data }) {
  const { _id, name, number, ods, promoterInstitution, image, count, position } = data
  const colorOds = ods.color
  const logoOds = ods.logo
  const iconThumbsUp = <FontAwesomeIcon style={{ marginRight: '5px' }} icon={faThumbsUp} />
  const numStrOds = ods.objective || '0'
  const numOds = numStrOds.split(' ')[0]

  return (
    <JoinCardContainerPodium>
      <Link to={`/good-practice/${_id}`}>
        {position === 0 && <JoinCardPlace src={first} alt='place' />}
        {position === 1 && <JoinCardPlace src={second} alt='place' />}
        {position === 2 && <JoinCardPlace src={third} alt='place' />}
        <JoinCardHeaderPodium>
          {image ? (
            <ImageActionCard src={image} alt='joinImage' />
          ) : (
            <ImageActionCard src={urlImgODS[numOds] || imgDefault} alt='joinImage' />
          )}
        </JoinCardHeaderPodium>
        <JoinCardContent color={colorOds}>{name}</JoinCardContent>
        <JoinCardFooterPodium>
          <JoinCardFooterLeft>
            <JoinCardFooterLeftImage src={logoOds} alt='gta-img' />
          </JoinCardFooterLeft>
          <JoinCardFooterRightPodium>
            <JoinCardFooterRightP>
              <JoinCardFooterRightText> {promoterInstitution}</JoinCardFooterRightText>
              <br />
              Acción implementada #{number}
            </JoinCardFooterRightP>
          </JoinCardFooterRightPodium>
        </JoinCardFooterPodium>
        <JoinCardPodiumLikes>
          <JoinCardContentLikes>
            {iconThumbsUp} {count} Likes
          </JoinCardContentLikes>
        </JoinCardPodiumLikes>
      </Link>
    </JoinCardContainerPodium>
  )
}

JoinActionPodiumCard.propTypes = {}

export default JoinActionPodiumCard
