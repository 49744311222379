import React, { useEffect } from 'react'
import { Formik, Form, Field } from 'formik'
import FormInput from './../Helpers/FormInput.js'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  countryListRequest,
  stateListRequest,
  registerListCompanyRequest,
  registersCreateRequest,
  registerListSectorRequest,
  registerGetCitiesRequest
} from './register.redux'
import { genders } from 'utils/auth.js'
import Swal from 'sweetalert2'

function UsersRegisterForm({
  sectorList,
  companyList,
  createRegister,
  countryListRequest,
  countryList,
  conectAPI,
  stateList,
  stateListRequest,
  registerListSectorRequest,
  registerListCompanyRequest,
  registerGetCitiesRequest,
  cities
}) {
  const handleRegister = values => {
    if (!values?.checked)
      return Swal.fire(
        'Alerta',
        'Debe aceptar los términos y condiciones antes de continuar.',
        'info'
      )
    createRegister({ values })
  }
  useEffect(() => {
    registerListSectorRequest()
    registerListCompanyRequest()
    countryListRequest()
  }, [registerListSectorRequest, registerListCompanyRequest, countryListRequest])
  const companies = companyList && companyList.docs?.map(c => c)
  const sectors = sectorList && sectorList.docs?.map(c => c)
  const getState = event => {
    stateListRequest(event.value)
  }
  const getCities = (country, event) => {
    registerGetCitiesRequest(country, event.value)
  }
  const getCompany = event => {
    registerListCompanyRequest(event.value)
  }
  const getSector = event => {
    registerListSectorRequest(event.value)
  }
  const changeCompany = (e, values) => {
    values.company = e.value
  }
  const changeCountry = (e, values) => {
    values.country = e.value
  }
  const changeState = (e, values) => {
    values.state = e.value
  }
  const changeCity = (e, values) => {
    values.city = e.value
  }
  const changeSector = (e, values) => {
    values.sector = e._id
  }

  const estateSpa = {
    México: 'Estado de México',
    'Mexico City': 'Ciudad de México'
  }

  return (
    <>
      <Formik
        initialValues={{
          name: '',
          lastName: '',
          email: '',
          telephone: '',
          gender: '',
          birthDay: '',
          country: '',
          state: '',
          company: '',
          sector: '',
          city: '',
          password: '',
          otherGender: '',
          passwordValidate: '',
          checked: false
        }}
        validate={values => {
          const errors = {}
          for (const key in values)
            if (!values[key] && key !== 'gender' && key !== 'otherGender' && key !== 'checked')
              errors[key] = 'Requerido'
          if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email))
            errors.email = 'Ingresa un correo válido'
          if (
            values.password &&
            values.passwordValidate &&
            values.password !== values.passwordValidate
          )
            errors.password = 'Las contraseñas no coinciden'
          return errors
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            handleRegister(values)
            setSubmitting(false)
          }, 400)
        }}>
        {({ values, errors, isSubmitting, handleChange }) => {
          return (
            <Form className='form'>
              <div className='general__information'>
                <div className='row gutters'>
                  <FormInput id='name' label='Nombre' type='small' />
                  <FormInput id='lastName' label='Apellidos' type='small' />
                  <FormInput id='birthDay' label='Fecha de nacimiento' type='smalldate' />

                  <FormInput
                    label='País'
                    type='selectresidency'
                    style={{}}
                    placeholder='Seleccione'
                    defaultValue={''}
                    name='country'
                    id='country'
                    onChange={e => {
                      changeCountry(e, values)
                      conectAPI && getState(e)
                    }}
                    options={
                      countryList &&
                      countryList?.data?.map(({ name, Iso3 }) => ({
                        value: name,
                        name: name,
                        label: name === 'Mexico' ? 'México' : name,
                        id: name,
                        key: Iso3
                      }))
                    }
                  />
                  {!conectAPI ? (
                    <FormInput id='state' label='Estado' type='small' />
                  ) : (
                    <FormInput
                      id='state'
                      label='Estado'
                      placeholder='Seleccione'
                      type='selectresidency'
                      value={values.state}
                      onChange={e => {
                        changeState(e, values)
                        getCities(values.country, e)
                      }}
                      options={
                        stateList &&
                        stateList?.data?.states.map(({ name }) => ({
                          value: name,
                          label: estateSpa[name] || name,
                          name: name,
                          id: name,
                          key: name
                        }))
                      }
                    />
                  )}
                  {!conectAPI ? (
                    <FormInput id='city' label='Ciudad' type='small' />
                  ) : (
                    <FormInput
                      id='city'
                      label='Localidad'
                      placeholder='Municipio o alcaldía'
                      type='selectresidency'
                      value={values.city}
                      onChange={e => {
                        changeCity(e, values)
                      }}
                      options={
                        cities &&
                        cities.data?.map(city => ({
                          value: city,
                          label: city,
                          id: city,
                          key: city
                        }))
                      }
                    />
                  )}
                  <br />
                  <br />
                  <br />
                  <FormInput id='email' type='small' label='Correo' />

                  <FormInput
                    id='telephone'
                    label={'Teléfono'}
                    type='small'
                    placeholder='Ejem: +1 (232) 1 21 12 32 '
                  />
                  <FormInput
                    id='gender'
                    label='Género'
                    other='otherGender'
                    type='radioButtonSmall'
                    radioButton={genders}
                  />

                  <FormInput
                    label='Sector'
                    type='selectresidency'
                    placeholder='Seleccione'
                    css={'form-inputs-sector-company'}
                    defaultValue={''}
                    name='sector'
                    id='sector'
                    onChange={e => {
                      changeSector(e, values)
                      getSector(e)
                    }}
                    options={
                      sectors &&
                      sectors.map(({ _id, name, code }) => ({
                        value: name,
                        label: name,
                        _id,
                        key: code
                      }))
                    }
                  />
                  <FormInput
                    label='Empresa u organización'
                    type='multiselectsmall'
                    css={'form-inputs-sector-company'}
                    tooltip='Si no encuentra la empresa que busca, puede escribirla manualmente dentro del campo de Empresa u organización y dar clic en "Agregar nueva empresa..."'
                    placeholder='Seleccione'
                    defaultValue={''}
                    name='company'
                    id='company'
                    onChange={e => {
                      changeCompany(e, values)
                      getCompany(e)
                    }}
                    options={
                      companies &&
                      companies.map(({ _id, name }) => ({
                        value: name,
                        label: name,
                        _id
                      }))
                    }
                  />
                </div>
              </div>
              <div className='sector__company-row'>
                <div className='row gutters'>
                  <FormInput id='password' label='Contraseña' type={'password'} />
                  <FormInput
                    id='passwordValidate'
                    label='Confirmar contraseña'
                    type={'password'}
                  />
                </div>
                <div className='register__create'>
                  <label>
                    <Field type='checkbox' name='checked' />
                    {'   '}
                    <span style={{ fontWeight: 'normal' }}>Acepto los </span>
                    <Link to={'/legal-disclaimer'} target='_blanks'>
                      <span style={{ fontWeight: 'bold' }}>Términos y condiciones </span>
                    </Link>
                    <span style={{ fontWeight: 'normal' }}>y el </span>
                    <Link to={'/privacy-notice'} target='_blank'>
                      <span style={{ fontWeight: 'bold' }}>Aviso de Privacidad</span>
                    </Link>
                  </label>
                  <button
                    type='submit'
                    className='btn btn-primary btn-block mt-3'
                    disabled={isSubmitting}>
                    {'Crear'}
                  </button>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

const mapStateToProps = ({ register }) => ({
  one: register.one,
  countryList: register.countryList,
  conectAPI: register.conectAPI,
  stateList: register.stateList,
  companyList: register.companyList,
  sectorList: register.sectorList,
  loading: register.loading,
  error: register.error,
  cities: register.cities
})

const mapDispatchToProps = {
  registerListCompanyRequest,
  createRegister: registersCreateRequest,
  countryListRequest,
  stateListRequest,
  registerListSectorRequest,
  registerGetCitiesRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersRegisterForm)
