import React from 'react'
import { connect } from 'react-redux'
import 'animate.css'
import { Container, Row, Col } from 'reactstrap'
import PropTypes from 'prop-types'
import Swal from 'sweetalert2'

import img1 from 'assets/img/lupa.svg'
import img2 from 'assets/img/points_home.svg'
import img3 from 'assets/img/compartir_acciones.svg'
import JoinCard from 'components/Join/JoinCard'
import JoinCardHome from 'components/Join/JoinCardHome'
import JoinCardButton from 'components/Join/JoinCardButton'

const HomeLinks = ({ deactivateIdeas, isLogin }) => {
  const handleLogin = () => {
    Swal.fire({
      title: 'Inicio de sesión requerido',
      text: 'Para poder compartir una acción es necesario que inicies sesión.',
      showCancelButton: true,
      confirmButtonText: 'Iniciar sesión',
      cancelButtonText: 'Cerrar'
    }).then(result => {
      if (result.isConfirmed) {
        window.location.href = '/login'
      }
    })
  }

  const HomeLinks1 = (
    <Container className='home-main-inks'>
      <Row xs='2' className='row-home-card'>
        <Col className='col-home-card' style={{ justifyContent: 'left' }}>
          <span className='font-weight-500 home-points-text2' style={{ marginTop: '25px' }}>
            BANCO DE ACCIONES
          </span>
        </Col>
        <Col className='col-home-card' style={{ justifyContent: 'right' }}>
          <img
            src={img1}
            style={{ marginTop: '-63px' }}
            className={'company-card-img'}
            alt=''
          />
        </Col>
      </Row>
    </Container>
  )
  const HomeLinks2 = (
    <p>
      <span className='font-weight-500 home-points-text'>TARJETAS GTA2030</span>
    </p>
  )
  const HomeLinks3 = (
    <Container className='home-main-inks'>
      <Row xs='2' className='row-home-card'>
        <Col className='col-home-card' style={{ justifyContent: 'left' }}>
          <img
            src={img3}
            style={{ marginTop: '5px' }}
            className={'company-card-img2'}
            alt=''
          />
        </Col>
        <Col
          className='col-home-card'
          style={{ justifyContent: 'right', marginLeft: '40px', marginTop: '-85px' }}>
          <span className='font-weight-500 home-points-text2 home-points-text2-margin'>
            COMPARTE ACCIONES
          </span>
        </Col>
      </Row>
    </Container>
  )

  return (
    <>
      <Container
        className='home-main-inks'
        style={{ paddingTop: deactivateIdeas ? '50px' : '0px' }}>
        <Row xs='1' sm='1' md='1' lg='2' xl='2' xxl='2' className='row-home-card'>
          <Col className='col-home-card' style={{ justifyContent: 'right' }}>
            <JoinCard img={img1} url='/good-practices-map' title={HomeLinks1} />
          </Col>
          <Col className='col-home-card' style={{ justifyContent: 'left' }}>
            {isLogin ? (
              <JoinCard img={img3} text='Súmate' url='/register-action' title={HomeLinks3} />
            ) : (
              <JoinCardButton
                img={img3}
                text='Súmate'
                onClick={handleLogin}
                title={HomeLinks3}
              />
            )}
          </Col>
        </Row>
        {/* {!deactivateIdeas && (
          <Row
            xs='1'
            sm='1'
            md='1'
            lg='1'
            xl='1'
            xxl='1'
            className='row-home-card'
            style={{ marginTop: '-30px' }}>
            <Col className='col-home-card'>
              <JoinCardHome img={img2} url='/metodology' title={HomeLinks2} />
            </Col>
          </Row>
        )} */}
      </Container>
    </>
  )
}

HomeLinks.propTypes = {
  deactivateIdeas: PropTypes.bool.isRequired
}

HomeLinks.defaultProps = {
  deactivateIdeas: false
}

const mapStateToProps = state => {
  return {
    isLogin: state.auth.isLogin
  }
}

export default connect(mapStateToProps)(HomeLinks)
