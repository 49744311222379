export const USER_LOGIN_GET_REQUEST = 'USER_LOGIN_GET_REQUEST'
export const USER_LOGIN_GET_SUCCESS = 'USER_LOGIN_GET_SUCCESS'
export const USER_LOGIN_GET_FAILURE = 'USER_LOGIN_GET_FAILURE'

export const USERS_CREATE_REQUEST = 'USERS_CREATE_REQUEST'
export const USERS_CREATE_SUCCESS = 'USERS_CREATE_SUCCESS'
export const USERS_CREATE_FAILURE = 'USERS_CREATE_FAILURE'

export const USERS_GET_ALL_REQUEST = 'USERS_GET_ALL_REQUEST'
export const USERS_GET_ALL_SUCCESS = 'USERS_GET_ALL_SUCCESS'
export const USERS_GET_ALL_FAILURE = 'USERS_GET_ALL_FAILURE'
export const USERS_REMOVE_FAILURE = 'USERS_REMOVE_FAILURE'

export const USERS_UPDATE_REQUEST = 'USERS_UPDATE_REQUEST'
export const USERS_UPDATE_SUCCESS = 'USERS_UPDATE_SUCCESS'
export const USERS_UPDATE_FAILURE = 'USERS_UPDATE_FAILURE'

export const USERS_GET_ONE_REQUEST = 'USERS_GET_ONE_REQUEST'
export const USERS_GET_ONE_SUCCESS = 'USERS_GET_ONE_SUCCESS'
export const USERS_GET_ONE_FAILURE = 'USERS_GET_ONE_FAILURE'

export const USERS_FILTER_CHANGE_REQUEST = 'USERS_FILTER_CHANGE_REQUEST'
export const USERS_FILTER_CHANGE_SUCCESS = 'USERS_FILTER_CHANGE_SUCCESS'
export const USERS_FILTER_CHANGE_FAILURE = 'USERS_FILTER_CHANGE_FAILURE'

export const USERS_NEW_USER_REQUEST = 'USERS_NEW_USER_REQUEST'

export const USER_CREATE_IMAGE_REQUEST = 'USER_CREATE_IMAGE_REQUEST'
export const USER_CREATE_IMAGE_SUCCESS = 'USER_CREATE_IMAGE_SUCCESS'
export const USER_CREATE_IMAGE_FAILURE = 'USER_CREATE_IMAGE_FAILURE'

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_FAILURE = 'USER_UPDATE_FAILURE'

export const typesUsers = {
  userListRequest: '[ USERS ] USER_LIST_REQUEST',
  userListSuccess: '[ USERS ] USER_LIST_SUCESS',
  userListError: '[ USERS ] USER_LIST_ERROR',
  userNewUserRequest: '[ USERS ] USERS_NEW_USER_REQUEST',
  usersUpdateRequest: '[ USERS ] USERS_UPDATE_REQUEST'
}

export const usersListRequest = filters => ({ type: typesUsers.userListRequest, filters })
export const usersListSuccess = payload => ({ type: typesUsers.userListSuccess, payload })
export const usersListError = payload => ({ type: typesUsers.userListError, payload })
export const usersGetOneRequest = payload => ({ type: USERS_GET_ONE_REQUEST, payload })
export const userUpdateRequest = ({ values }) => ({
  type: USER_UPDATE_REQUEST,
  payload: values
})
export const updatedItem = param => (list, item) => {
  const index = list.findIndex(i => i[param] === item[param])
  return [...list.slice(0, index), item, ...list.slice(index + 1)]
}
const initialState = {
  usersList: {},
  loading: false,
  loader: false,
  error: '',
  all: [],
  one: {},
  filters: {}
}
const INITIAL_STATE_UNIT = {
  loading: false,
  error: null
}
export function users(state = initialState, action) {
  switch (action.type) {
    case typesUsers.userListRequest:
      return { ...state, loading: true }
    case typesUsers.userListSuccess:
      return { ...state, loading: false, usersList: action.payload }
    case typesUsers.userListError:
      return { ...state, loading: false, error: action.payload }
    case typesUsers.userNewUserRequest:
      return { ...state, error: null, loading: false, all: [action.payload] }
    case USERS_CREATE_REQUEST:
      return { ...state, error: null, loading: true }
    case USERS_CREATE_SUCCESS:
      return { ...state, error: null, loading: false, one: [action.payload] }
    case USERS_CREATE_FAILURE:
      return { ...state, loading: false, error: action.error }
    case USERS_GET_ALL_SUCCESS:
      return { ...state, error: null, loading: false, all: { ...action.payload } }
    case USERS_GET_ALL_REQUEST:
      return { ...state, error: null, loading: true }
    case USERS_GET_ALL_FAILURE:
      return { ...state, loading: false, error: action.error }
    case USERS_REMOVE_FAILURE:
      return { ...state, loading: false, error: action.error }
    case USERS_UPDATE_SUCCESS:
      return {
        ...state,
        usersList: updatedItem('id')(state.list, action.payload),
        loader: false,
        loading: false,
        error: {}
      }
    case USERS_UPDATE_REQUEST:
      return { ...state, error: null, loading: true }
    case USERS_UPDATE_FAILURE:
      return { ...state, loading: false, error: action.error }
    case USERS_GET_ONE_SUCCESS:
      const {
        password,
        validated,
        login,
        recoveryTokenExpires,
        validationToken,
        ...newPayload
      } = action.payload
      return { ...state, error: null, loading: false, one: { ...newPayload } }
    case USERS_GET_ONE_REQUEST:
      return { ...state, error: null, loading: true }
    case USERS_GET_ONE_FAILURE:
      return { ...state, loading: false, error: action.error }
    case USERS_FILTER_CHANGE_SUCCESS:
      return { ...state, error: null, loading: false, filters: { ...action.payload } }
    case USERS_FILTER_CHANGE_REQUEST:
      return { ...state, error: null, loading: true }
    case USERS_FILTER_CHANGE_FAILURE:
      return { ...state, loading: false, error: action.error }
    case USER_UPDATE_SUCCESS:
      return { ...state, error: null, loading: false, one: { ...action.payload } }
    case USER_UPDATE_REQUEST:
      return { ...state, error: null, loading: true }
    case USER_UPDATE_FAILURE:
      return { ...state, loading: false, error: action.error }

    default:
      return state
  }
}
export function user(state = INITIAL_STATE_UNIT, action) {
  switch (action.type) {
    case USER_LOGIN_GET_SUCCESS:
      const {
        password,
        validated,
        recoverPasswordToken,
        recoverPasswordExpires,
        twoFactorToken,
        twoFactorExpires,
        city,
        updatedAt,
        ...newPayload
      } = action.payload
      return { ...state, error: null, loading: false, ...newPayload }
    default:
      return state
  }
}
