// TYPES
export const CARDS_CREATE_REQUEST = 'CARDS_CREATE_REQUEST'
export const CARDS_CREATE_SUCCESS = 'CARDS_CREATE_SUCCESS'
export const CARDS_CREATE_FAILURE = 'CARDS_CREATE_FAILURE'

export const CARDS_CREATE_DOC_REQUEST = 'CARDS_CREATE_DOC_REQUEST'
export const CARDS_CREATE_DOC_SUCCESS = 'CARDS_CREATE_DOC_SUCCESS'
export const CARDS_CREATE_DOC_FAILURE = 'CARDS_CREATE_DOC_FAILURE'

export const CARDS_GET_ALL_REQUEST = 'CARDS_GET_ALL_REQUEST'
export const CARDS_GET_ALL_SUCCESS = 'CARDS_GET_ALL_SUCCESS'
export const CARDS_GET_ALL_FAILURE = 'CARDS_GET_ALL_FAILURE'

export const CARDS_GET_MORE_REQUEST = 'CARDS_GET_MORE_REQUEST'
export const CARDS_GET_MORE_SUCCESS = 'CARDS_GET_MORE_SUCCESS'
export const CARDS_GET_MORE_FAILURE = 'CARDS_GET_MORE_FAILURE'

export const CARDS_REMOVE_REQUEST = 'CARDS_REMOVE_REQUEST'
export const CARDS_REMOVE_SUCCESS = 'CARDS_REMOVE_SUCCESS'
export const CARDS_REMOVE_FAILURE = 'CARDS_REMOVE_FAILURE'

export const CARDS_EDIT_REQUEST = 'CARDS_EDIT_REQUEST'
export const CARDS_EDIT_SUCCESS = 'CARDS_EDIT_SUCCESS'
export const CARDS_EDIT_FAILURE = 'CARDS_EDIT_FAILURE'

export const CARD_GET_ONE_REQUEST = 'CARD_GET_ONE_REQUEST'
export const CARD_GET_ONE_SUCCESS = 'CARD_GET_ONE_SUCCESS'
export const CARD_GET_ONE_FAILURE = 'CARD_GET_ONE_FAILURE'

export const CARDS_FILTER_CHANGE_REQUEST = 'CARDS_FILTER_CHANGE_REQUEST'
export const CARDS_FILTER_CHANGE_SUCCESS = 'CARDS_FILTER_CHANGE_SUCCESS'
export const CARDS_FILTER_CHANGE_FAILURE = 'CARDS_FILTER_CHANGE_FAILURE'

export const CARDS_GET_CATALOGS_REQUEST = 'CARDS_GET_CATALOGS_REQUEST'
export const CARDS_GET_CATALOGS_SUCCESS = 'CARDS_GET_CATALOGS_SUCCESS'
export const CARDS_GET_CATALOGS_FAILURE = 'CARDS_GET_CATALOGS_FAILURE'

export const CARDS_RANKING_FILTER_CHANGE_REQUEST = 'CARDS_RANKING_FILTER_CHANGE_REQUEST'
export const CARDS_RANKING_FILTER_CHANGE_SUCCESS = 'CARDS_RANKING_FILTER_CHANGE_SUCCESS'
export const CARDS_RANKING_FILTER_CHANGE_FAILURE = 'CARDS_RANKING_FILTER_CHANGE_FAILURE'

export const typesCards = {
  cardsListRequest: '[ CARDS ] CARDS_LIST_REQUEST',
  cardsListSuccess: '[ CARDS ] CARDS_LIST_SUCCESS',
  cardsListError: '[ CARDS ] CARDS_LIST_ERROR',

  loadMoreCardsRequest: '[ CARDS ] CARDS_LOAD_MORE_REQUEST',
  loadMoreCardsSuccess: '[ CARDS ] CARDS_LOAD_MORE_SUCESS',
  loadMoreCardsError: '[ CARDS ] CARDS_LOAD_MORE_ERROR',

  cardDetailRequest: '[ CARDS ] CARD_DETAIL_REQUEST',
  cardDetailSuccess: '[ CARDS ] CARD_DETAIL_SUCESS',
  cardDetailError: '[ CARDS ] CARD_DETAIL_ERROR',

  workAxisRequest: '[ WORKAXIS ] WORK_AXIS_REQUEST',
  workAxisSuccess: '[ WORKAXIS ] WORK_AXIS_SUCESS',
  workAxisError: '[ WORKAXIS ] WORK_AXIS_ERROR',

  cardsLikesRequest: '[ LIKES ] CARDS_LIKES_REQUEST',
  cardsLikesSuccess: '[ LIKES ] CARDS_LIKES_SUCESS',
  cardsLikesError: '[ LIKES ] CARDS_LIKES_ERROR',

  cardsLikeRequest: '[ LIKES ] CARDS_LIKE_REQUEST',
  cardsUnlikeRequest: '[ LIKES ] CARDS_UNLIKE_REQUEST',
  cardsLikeSuccess: '[ LIKES ] CARDS_LIKE_SUCESS',
  cardsLikeError: '[ LIKES ] CARDS_LIKE_ERROR'
}

// ACTIONS
export const cardsGetAllRequest = filters => ({ type: CARDS_GET_ALL_REQUEST, filters })
export const loadMoreCardsRequest = filters => ({
  type: CARDS_GET_MORE_REQUEST,
  filters
})
export const cardsFiltersChangeRequest = filters => ({
  type: CARDS_FILTER_CHANGE_REQUEST,
  filters
})
export const cardsRankingFiltersChangeRequest = filters => ({
  type: CARDS_RANKING_FILTER_CHANGE_REQUEST,
  filters
})

export const cardsListRequest = filters => ({ type: typesCards.cardsListRequest, filters })
export const cardsLikeRequest = payload => ({ type: typesCards.cardsLikeRequest, payload })

export const cardsListSuccess = payload => ({ type: typesCards.cardsListSuccess, payload })
export const cardsListError = payload => ({ type: typesCards.cardsListError, payload })

export const cardDetailRequest = card => ({ type: typesCards.cardDetailRequest, card })
export const cardDetailSuccess = payload => ({ type: typesCards.cardDetailSuccess, payload })
export const cardDetailError = payload => ({ type: typesCards.cardDetailError, payload })

export const workAxisRequest = () => ({ type: typesCards.workAxisRequest })
export const workAxisSuccess = payload => ({ type: typesCards.workAxisSuccess, payload })
export const workAxisError = payload => ({ type: typesCards.workAxisError, payload })

export const loadMoreCardsSuccess = payload => ({
  type: typesCards.loadMoreCardsSuccess,
  payload
})
export const loadMoreCardsError = payload => ({ type: typesCards.loadMoreCardsError, payload })

export const cardsLikesRequest = filters => ({
  type: typesCards.cardsLikesRequest,
  filters
})
export const cardsCreateDocRequest = payload => ({
  type: CARDS_CREATE_DOC_REQUEST,
  payload
})
export const cardsUnlikeRequest = payload => ({ type: typesCards.cardsUnlikeRequest, payload })

export const cardGetOneRequest = payload => ({ type: CARD_GET_ONE_REQUEST, payload })
export const cardGetOneSuccess = payload => ({ type: CARD_GET_ONE_SUCCESS, payload })
export const cardGetOneFailure = payload => ({ type: CARD_GET_ONE_FAILURE, payload })

export const cardGetCatalogs = filters => ({
  type: CARDS_GET_CATALOGS_REQUEST,
  filters
})

// REDUCER
const initialState = {
  cardsList: {},
  loading: false,
  loader: false,
  error: '',
  list: [],
  one: {},
  likes: {},
  cardSelected: '',
  filters: {},
  catalogs: {}
}

export function cards(state = initialState, action) {
  switch (action.type) {
    case typesCards.cardsListRequest:
    case typesCards.cardDetailRequest:
    case typesCards.workAxisRequest:
    case typesCards.cardsLikesRequest:
    case typesCards.cardsLikeRequest:
    case typesCards.cardsUnlikeRequest:
    case CARDS_CREATE_DOC_REQUEST:
      return { ...state, loading: true }

    case typesCards.cardsListSuccess:
      return { ...state, loading: false, error: null, cardsList: [...action.payload] }
    case typesCards.workAxisSuccess:
      return { ...state, loading: false, workAxisList: action.payload }

    case typesCards.cardsListError:
    case typesCards.loadMoreCardsError:
    case typesCards.workAxisError:
    case typesCards.cardsLikeError:
      return { ...state, loading: false, error: action.payload }
    // Detalle
    case typesCards.cardDetailError:
    case typesCards.cardsLikesError:
      return { ...state, loading: false, error: action.payload, one: {} }
    case typesCards.cardDetailSuccess:
      return { ...state, loading: false, one: action.payload }
    case typesCards.cardsLikesSuccess:
      return { ...state, loading: false, likes: action.payload }
    case typesCards.cardsLikeSuccess:
      return { ...state, loading: false, likes: [...action.payload] }

    case typesCards.loadMoreCardsRequest:
      return { ...state, loader: true, count: action.payload }

    case typesCards.loadMoreCardsSuccess:
      const { payload } = action
      const { cardsList } = state
      payload.data.docs = [...cardsList.docs, ...payload.data.docs]
      return {
        ...state,
        loader: false,
        all: payload.data
      }
    case CARDS_FILTER_CHANGE_REQUEST:
    case CARD_GET_ONE_REQUEST:
    case CARDS_EDIT_REQUEST:
    case CARDS_REMOVE_REQUEST:
    case CARDS_CREATE_REQUEST:
    case CARDS_RANKING_FILTER_CHANGE_REQUEST:
      return { ...state, error: null, loading: true }

    case CARDS_GET_MORE_REQUEST:
      return { ...state, error: null, loader: true }

    case CARDS_GET_ALL_REQUEST:
      return { ...state, error: null, loading: true, filters: {} }

    case CARD_GET_ONE_SUCCESS:
    case CARDS_EDIT_SUCCESS:
    case CARDS_REMOVE_SUCCESS:
      return { ...state, error: null, loading: false, one: { ...action.payload } }
    case CARDS_FILTER_CHANGE_SUCCESS:
    case CARDS_RANKING_FILTER_CHANGE_SUCCESS:
      return { ...state, error: null, loading: false, filters: { ...action.payload } }

    case CARDS_GET_ALL_SUCCESS:
      return { ...state, error: null, loading: false, list: action.payload }

    case CARDS_GET_MORE_SUCCESS:
      action.payload.docs = [...state.list?.docs, ...action.payload?.docs]
      return { ...state, loader: false, list: action.payload }

    case CARDS_CREATE_SUCCESS:
      return { ...state, error: null, loading: false, one: [action.payload] }
    case CARDS_CREATE_DOC_SUCCESS:
      return { ...state, error: null, loading: false }

    case CARDS_FILTER_CHANGE_FAILURE:
    case CARD_GET_ONE_FAILURE:
    case CARDS_EDIT_FAILURE:
    case CARDS_REMOVE_FAILURE:
    case CARDS_CREATE_FAILURE:
    case CARDS_CREATE_DOC_FAILURE:
    case CARDS_GET_ALL_FAILURE:
    case CARDS_RANKING_FILTER_CHANGE_FAILURE:
      return { ...state, loading: false, error: action.error }
    case CARDS_GET_MORE_FAILURE:
      return { ...state, loader: false, error: action.error }
    case CARDS_GET_CATALOGS_SUCCESS:
      return { ...state, error: null, loading: false, catalogs: { ...action.payload } }
    default:
      return state
  }
}
