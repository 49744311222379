import { put, call, takeLatest, delay, select } from 'redux-saga/effects'
import {
  ACTION_CREATE_REQUEST,
  ACTION_CREATE_SUCCESS,
  ACTION_CREATE_FAILURE,
  ACTION_GET_REQUEST,
  ACTION_GET_SUCCESS,
  ACTION_GET_FAILURE,
  ACTION_GET_ALL_REQUEST,
  ACTION_GET_ALL_SUCCESS,
  ACTION_GET_ALL_FAILURE,
  ACTION_GET_WORKAXIS_REQUEST,
  ACTION_GET_WORKAXIS_FAILURE,
  ACTION_GET_WORKAXIS_SUCCESS,
  ACTION_GET_TOTALS_REQUEST,
  ACTION_GET_TOTALS_SUCCESS,
  ACTION_GET_TOTALS_FAILURE,
  ACTION_JOIN_USER_REQUEST,
  ACTION_JOIN_USER_SUCCESS,
  ACTION_JOIN_USER_FAILURE,
  actionGetRequest,
  ACTION_FILTER_CHANGE_FAILURE,
  ACTION_FILTER_CHANGE_SUCCESS,
  ACTION_FILTER_CHANGE_REQUEST,
  actionGetAllRequest,
  ACTION_GET_MORE_REQUEST,
  ACTION_GET_MORE_SUCCESS,
  ACTION_GET_MORE_FAILURE,
  ACTION_GET_TOTALS_ODS_SUCCESS,
  ACTION_GET_TOTALS_ODS_FAILURE,
  ACTION_GET_TOTALS_ODS_REQUEST,
  ACTION_GET_TOTALS_AXES_SUCCESS,
  ACTION_GET_TOTALS_AXES_FAILURE,
  ACTION_GET_TOTALS_AXES_REQUEST,
  ACTION_GET_ONE_LIKE_REQUEST,
  ACTION_GET_ONE_LIKE_SUCCESS,
  ACTION_GET_ONE_LIKE_FAILURE,
  ACTION_GET_TOTALS_LIKES_SUCCESS,
  ACTION_GET_TOTALS_LIKES_FAILURE,
  ACTION_GET_TOTALS_LIKES_REQUEST,
  ACTION_GET_LIKED_REQUEST
} from './joinAction.redux'
import axios from 'axios'
import Swal from 'sweetalert2'
import { hideLoading, showLoading } from 'react-redux-loading-bar'
import { push } from 'connected-react-router'
import { getUserId } from 'redux/helpers/handlers'
import { encodeFiltersData } from 'redux/helpers/handlers'

export function* joinActionCreateSaga({ payload }) {
  try {
    yield put(showLoading())
    const user = yield select(u => getUserId(u))
    let formData = new FormData()
    formData.append('aceptVolunteers', payload.aceptVolunteers)
    formData.append('alliedInstitutions', payload.alliedInstitutions)
    formData.append('axisGlobalPact', payload.axisGlobalPact)
    formData.append('businessJoinSubType', payload.businessJoinSubType)
    formData.append('businessJoinType', payload.businessJoinType)
    formData.append('checked', payload.checked)
    formData.append('createdBy', user)
    formData.append('description', payload.description)
    formData.append('email', payload.email)
    formData.append('image', payload.imageFile)
    formData.append('logo', payload.logoFile)
    formData.append('implementationPeriod', payload.implementationPeriod)
    formData.append('implementationTime', payload.implementationTime)
    formData.append('indicator', payload.indicator)
    formData.append('information', payload.information)
    formData.append('involveVolunteers', payload.involveVolunteers)
    formData.append('lat', payload.lat)
    formData.append('lng', payload.lng)
    formData.append('name', payload.name)
    formData.append('number', payload.number)
    formData.append('numberOfVolunteers', payload.numberOfVolunteers)
    formData.append('objective', payload.objective)
    formData.append('ods', payload.ods)
    formData.append('otherAmbit', payload.otherAmbit)
    formData.append('promoterInstitution', payload.promoterInstitution)
    formData.append('shareName', payload.shareName)
    formData.append('status', payload.status)
    formData.append('ubication', payload.ubication)
    formData.append('user', payload.user)

    if (user === undefined) {
      return Swal.fire(
        'Aviso',
        'Debes iniciar sesión para poder crear una iniciativa',
        'warning'
      )
    }
    const insert = yield call(axios.post, '/api/goodPractices', formData)
    yield put({ type: ACTION_CREATE_SUCCESS, payload: insert })
    yield put(push('/'))
    Swal.fire(
      '¡Gracias por compartir una acción por los ODS!',
      'Recibirás un correo electrónico de confirmación con los siguientes pasos.',
      'success'
    )
    yield put(hideLoading())
  } catch (error) {
    yield put(hideLoading())
    yield put({ type: ACTION_CREATE_FAILURE, error: error.response })
  }
}

function* joinUserToActionSaga({ payload }) {
  try {
    yield put(showLoading())
    const user = yield select(u => getUserId(u))
    payload.user = user
    if (payload.user === undefined) {
      yield put(push('/login'))
      return Swal.fire(
        'Aviso',
        'Debes iniciar sesión para poder unirte a una iniciativa',
        'warning'
      )
    }
    yield call(axios.post, '/api/goodPractices/join', payload)
    yield delay(process.env.REACT_APP_DELAY)
    yield put(hideLoading())
    yield put({ type: ACTION_JOIN_USER_SUCCESS, payload: true })
    yield put(actionGetRequest({ _id: payload._id }))
    yield Swal.fire('Se ha unido a la iniciativa, gracias por participar', '', 'success')
  } catch (error) {
    Swal.fire('Error', error.message, 'error')
    yield put({ type: ACTION_JOIN_USER_FAILURE, error: error.response })
  }
}

export function* joinActionGetRegisterSaga({ payload }) {
  const _id = payload._id
  try {
    const { data } = yield call(axios.get, `/api/goodPractices/${_id}`)
    yield delay(process.env.REACT_APP_DELAY)
    yield put({ type: ACTION_GET_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: ACTION_GET_FAILURE, error: error.response })
  }
}

export function* joinActionGetOneLikeRequestSaga({ payload }) {
  try {
    if (payload.user === undefined)
      yield put({ type: ACTION_GET_ONE_LIKE_SUCCESS, payload: false })
    const { data } = yield call(axios.post, `/api/likes/searchone`, payload)
    const status = yield data?.data === null ? false : true
    yield put({
      type: ACTION_GET_ONE_LIKE_SUCCESS,
      payload: status
    })
  } catch (error) {
    yield put({ type: ACTION_GET_ONE_LIKE_FAILURE, error: error.response })
  }
}

export function* joinActionGetAllRegisterSaga({
  payload = { status: 2, active: true, limit: 20, page: 1 }
}) {
  try {
    const filterString = encodeFiltersData(payload)
    const { data } = yield call(axios.get, `/api/goodPractices/?${filterString}`)
    yield delay(process.env.REACT_APP_DELAY)
    yield put({ type: ACTION_GET_ALL_SUCCESS, payload: data?.data })
  } catch (error) {
    Swal.fire('Error', error, 'error')
    yield put({ type: ACTION_GET_ALL_FAILURE, error: error.response })
  }
}
export function* joinActionGetAllLikedSaga({
  payload = { status: 2, active: true, limit: 20, page: 1 }
}) {
  try {
    const filterString = encodeFiltersData(payload)
    const { data } = yield call(axios.get, `/api/goodPractices/likes/?${filterString}`)
    yield delay(process.env.REACT_APP_DELAY)
    yield put({ type: ACTION_GET_ALL_SUCCESS, payload: data?.data })
  } catch (error) {
    Swal.fire('Error', error, 'error')
    yield put({ type: ACTION_GET_ALL_FAILURE, error: error.response })
  }
}
export function* joinActionGetMoreRegisterSaga({
  payload = { status: 2, active: true, limit: 20, page: 1 }
}) {
  try {
    const filterString = encodeFiltersData(payload)
    const { data } = yield call(axios.get, `/api/goodPractices/?${filterString}`)
    yield delay(600)
    yield put({ type: ACTION_GET_MORE_SUCCESS, payload: data?.data })
  } catch (error) {
    Swal.fire('Error', error, 'error')
    yield put({ type: ACTION_GET_MORE_FAILURE, error: error.response })
  }
}

export function* joinActionGetWorkAxisSaga({ payload }) {
  try {
    const { data } = yield call(axios.get, `/api/workAxes/all?gta=${payload}`)
    yield delay(process.env.REACT_APP_DELAY)
    yield put({ type: ACTION_GET_WORKAXIS_SUCCESS, payload: data?.data })
  } catch (error) {
    Swal.fire('Error', error, 'error')
    yield put({ type: ACTION_GET_WORKAXIS_FAILURE, error: error.response })
  }
}
function* joinActionGetTotalsSaga({ payload }) {
  try {
    const { data } = yield call(axios.get, `/api/goodPractices/totals`)
    yield delay(800)
    yield put({ type: ACTION_GET_TOTALS_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: ACTION_GET_TOTALS_FAILURE, error: error.response })
  }
}

function* joinActionGetTotalsByOdsSaga({ payload }) {
  try {
    const { data } = yield call(axios.get, `/api/goodPractices/totalsOds`)
    yield delay(800)
    yield put({ type: ACTION_GET_TOTALS_ODS_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: ACTION_GET_TOTALS_ODS_FAILURE, error: error.response })
  }
}

function* joinActionGetTotalsByAxesSaga({ payload }) {
  try {
    const { data } = yield call(axios.get, `/api/goodPractices/totalsAxes`)
    yield delay(800)
    yield put({ type: ACTION_GET_TOTALS_AXES_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: ACTION_GET_TOTALS_AXES_FAILURE, error: error.response })
  }
}

function* joinActionGetTotalsByLikesSaga({ payload }) {
  try {
    const { data } = yield call(axios.get, `/api/likes/countByPractice`)
    yield delay(800)
    yield put({ type: ACTION_GET_TOTALS_LIKES_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: ACTION_GET_TOTALS_LIKES_FAILURE, error: error.response })
  }
}

export function* getActionFiltersSaga({ filters = {} }) {
  try {
    filters.status = 2
    filters.page = 1
    filters.active = true
    yield put({ type: ACTION_FILTER_CHANGE_SUCCESS, payload: filters })
    yield put(actionGetAllRequest(filters))
    // yield put({ type: typesSavedCards.savedCardsGetAllRequest, filters })
  } catch (error) {
    Swal.fire('Error', error, 'error')
    yield put({ type: ACTION_FILTER_CHANGE_FAILURE, error: error.response })
  }
}

export default function* joinActionsSagas() {
  yield takeLatest(ACTION_CREATE_REQUEST, joinActionCreateSaga)
  yield takeLatest(ACTION_GET_REQUEST, joinActionGetRegisterSaga)
  yield takeLatest(ACTION_GET_ONE_LIKE_REQUEST, joinActionGetOneLikeRequestSaga)
  yield takeLatest(ACTION_GET_TOTALS_REQUEST, joinActionGetTotalsSaga)
  yield takeLatest(ACTION_GET_TOTALS_ODS_REQUEST, joinActionGetTotalsByOdsSaga)
  yield takeLatest(ACTION_GET_TOTALS_AXES_REQUEST, joinActionGetTotalsByAxesSaga)
  yield takeLatest(ACTION_GET_TOTALS_LIKES_REQUEST, joinActionGetTotalsByLikesSaga)
  yield takeLatest(ACTION_FILTER_CHANGE_REQUEST, getActionFiltersSaga)
  yield takeLatest(ACTION_GET_ALL_REQUEST, joinActionGetAllRegisterSaga)
  yield takeLatest(ACTION_GET_LIKED_REQUEST, joinActionGetAllLikedSaga)
  yield takeLatest(ACTION_GET_MORE_REQUEST, joinActionGetMoreRegisterSaga)
  yield takeLatest(ACTION_GET_WORKAXIS_REQUEST, joinActionGetWorkAxisSaga)
  yield takeLatest(ACTION_JOIN_USER_REQUEST, joinUserToActionSaga)
}
