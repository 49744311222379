import React from 'react'
import { Form } from 'reactstrap'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { loginRequest } from './auth.redux.js'
import LoginRegister from './LoginRegister.js'
import LoginFormInputs from './LoginFormInputs.js'
import SocialMediaBar from 'components/Users/SocialMediaBar.js'

function AuthLoginForm({ loading, loginRequest }) {
  const iconQuestion = <FontAwesomeIcon icon={faQuestionCircle} />
  return (
    <div className='auth__form'>
      <h3 className='auth__title'>Iniciar Sesión</h3>
      <Formik
        initialValues={{ email: '', password: '' }}
        validate={values => {
          const errors = {}
          for (const key in values) if (!values[key]) errors[key] = 'Requerido'
          if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email))
            errors.email = 'Correo no válido'
          return errors
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            loginRequest(values)
            setSubmitting(false)
          }, 400)
        }}>
        {({ values, handleSubmit, handleChange }) => (
          <Form onSubmit={handleSubmit} className='form login__form'>
            <LoginFormInputs
              loading={loading}
              iconQuestion={iconQuestion}
              values={values}
              handleChange={handleChange}
            />
          </Form>
        )}
      </Formik>
      <SocialMediaBar css='social-media-bar-login' />
      <LoginRegister />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    error: state.auth.error,
    loading: state.auth.loading
  }
}

const mapDispatchToProps = {
  loginRequest: loginRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthLoginForm)
