import React from 'react'
import SavedCards from './../Cards/CardSaved'
import Layout from './../Layout/Layout'

const SavedCardsPage = () => (
  <Layout>
    <SavedCards />
  </Layout>
)
export default SavedCardsPage
