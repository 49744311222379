import React from 'react'
import PropTypes from 'prop-types'
import { Column, AboutNumber, LeftContainer, RightContainer, AboutItemTitle, AboutItemDescription } from './aboutStyleComponents'

const AboutItem = ({ img, title, description }) => {
  return (
    <Column md='6' xs='12'>
      <LeftContainer>
        <AboutNumber src={img}/>
      </LeftContainer>        
      <RightContainer>
        <AboutItemTitle>{title}</AboutItemTitle>
        <AboutItemDescription> {description}</AboutItemDescription>
      </RightContainer>
    </Column>
  )
}

AboutItem.propTypes = {
  img: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
}

AboutItem.defaultProps = {
  title: 'Título',
  img: require('./../../assets/img/banners/ball.svg')
}

export default AboutItem
