import { put, call, takeLatest, delay } from 'redux-saga/effects'
import {
  gtaListError,
  gtaListSuccess,
  GTA_CREATE_FAILURE,
  GTA_CREATE_REQUEST,
  GTA_CREATE_SUCCESS,
  GTA_EDIT_REQUEST,
  GTA_GET_ALL_FAILURE,
  GTA_GET_ALL_REQUEST,
  GTA_GET_ALL_SUCCESS,
  GTA_GET_ONE_FAILURE,
  GTA_GET_ONE_REQUEST,
  GTA_GET_ONE_SUCCESS,
  GTA_REMOVE_FAILURE,
  GTA_REMOVE_REQUEST,
  GTA_REMOVE_SUCCESS,
  typesGta
} from './gta.redux.js'
import axios from 'axios'
import Swal from 'sweetalert2'
import { push } from 'connected-react-router'
import { encodeFiltersData, manageError } from '../../redux/helpers/handlers.js'

function* gtaListRequest({ filters = { active: true, page: 1 } }) {
  try {
    const filterString = encodeFiltersData(filters)
    const { data } = yield call(axios.get, `/api/gtas/?${filterString}`)
    yield delay(process.env.REACT_APP_DELAY)
    yield put(gtaListSuccess(data.data))
  } catch (error) {
    Swal.fire('Error', error, 'error')
    yield put(gtaListError(error.message))
  }
}

export function* getAllGtaSaga({ payload = { active: true } }) {
  try {
    const filterString = encodeFiltersData(payload)
    const { data } = yield call(axios.get, `/api/gtas/all/?${filterString}`)
    // yield delay(process.env.REACT_APP_DELAY)
    yield put({ type: GTA_GET_ALL_SUCCESS, payload: data?.data })
  } catch (error) {
    // Swal.fire('Error', error, 'error')
    yield put({ type: GTA_GET_ALL_FAILURE, error: error.response })
  }
}

export function* createGtaSaga({ payload }) {
  try {
    const insert = yield call(axios.post, '/api/gta', payload)
    yield delay(process.env.REACT_APP_DELAY)
    yield put({ type: GTA_CREATE_SUCCESS, payload: insert })
    yield put(push('/admin/card'))
  } catch (error) {
    Swal.fire('Error', error, 'error')
    yield put({ type: GTA_CREATE_FAILURE, error: error.response })
  }
}

export function* removeGtaSaga({ id }) {
  try {
    const insert = yield call(axios.delete, '/api/gta/' + id)
    yield delay(process.env.REACT_APP_DELAY)
    yield put({ type: GTA_REMOVE_SUCCESS, payload: insert })
    yield put({ type: GTA_GET_ALL_REQUEST })
    yield put(push('/admin/card'))
  } catch (error) {
    Swal.fire('Error', error, 'error')
    yield put({ type: GTA_REMOVE_FAILURE, error: error.response })
  }
}
export function* getOneGtaSaga({ id = 1 }) {
  try {
    const { data } = yield call(axios.get, `/api/gta/${id}`)
    yield delay(process.env.REACT_APP_DELAY)
    yield put({ type: GTA_GET_ONE_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: GTA_GET_ONE_FAILURE, error: error.response })
  }
}
export function* editGtaSaga({ payload }) {
  try {
    const insert = yield call(axios.put, '/api/gta/' + payload._id, payload)
    yield put({ type: GTA_CREATE_SUCCESS, payload: insert })
    yield put(push('/admin/gta'))
  } catch (error) {
    Swal.fire('Error', error, 'error')
    yield put({ type: GTA_CREATE_FAILURE, error: error.response })
  }
}

export default function* GtaSagas() {
  yield takeLatest(typesGta.gtaListRequest, gtaListRequest)
  yield takeLatest(GTA_CREATE_REQUEST, createGtaSaga)
  yield takeLatest(GTA_GET_ALL_REQUEST, getAllGtaSaga)
  yield takeLatest(GTA_EDIT_REQUEST, editGtaSaga)
  yield takeLatest(GTA_GET_ONE_REQUEST, getOneGtaSaga)
  yield takeLatest(GTA_REMOVE_REQUEST, removeGtaSaga)
  yield takeLatest(GTA_REMOVE_FAILURE, manageError)
  yield takeLatest(GTA_GET_ALL_FAILURE, manageError)
  yield takeLatest(GTA_GET_ONE_FAILURE, manageError)
}
