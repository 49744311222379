//TYPES
export const typesSavedCards = {
  savedCardsGetAllRequest: '[SAVEDCARDS] SAVED_CARDS_GET_ALL_REQUEST',
  savedCardsGetAllSuccess: '[SAVEDCARDS] SAVED_CARDS_GET_ALL_SUCCESS',
  savedCardsGetAllFailure: '[SAVEDCARDS] SAVED_CARDS_GET_ALL_FAILURE',
  
  savedCardsGetOneRequest: '[SAVEDCARDS] SAVED_CARDS_GET_ONE_REQUEST',
  savedCardsGetOneSuccess: '[SAVEDCARDS] SAVED_CARDS_GET_ONE_SUCCESS',
  savedCardsGetOneFailure: '[SAVEDCARDS] SAVED_CARDS_GET_ONE_FAILURE',
  savedCardsGetOneRemove: '[SAVEDCARDS] SAVED_CARDS_GET_ONE_REMOVE',
  
  savedCardsCreateRequest: '[SAVEDCARDS] SAVED_CARDS_CREATE_REQUEST',
  savedCardsCreateSuccess: '[SAVEDCARDS] SAVED_CARDS_CREATE_SUCCESS',
  savedCardsCreateFailure: '[SAVEDCARDS] SAVED_CARDS_CREATE_FAILURE',

  savedCardsRemoveRequest: '[SAVEDCARDS] SAVED_CARDS_REMOVE_REQUEST',
  savedCardsRemoveSuccess: '[SAVEDCARDS] SAVED_CARDS_REMOVE_SUCCESS',
  savedCardsRemoveFailure: '[SAVEDCARDS] SAVED_CARDS_REMOVE_FAILURE'
}

// ACTIONS
export const savedCardsCreateRequest = (flashcard) => ({
  type: typesSavedCards.savedCardsCreateRequest,
  flashcard
})

export const savedCardsGetAllRequest = (filters) => ({
  type: typesSavedCards.savedCardsGetAllRequest,
  filters
})
export const savedCardsGetOneRequest = filters => ({
  type: typesSavedCards.savedCardsGetOneRequest,
  filters
})
export const savedCardsCreateSuccess = flashcard => ({
  type: typesSavedCards.savedCardsCreateSuccess,
  flashcard
})
export const savedCardsCreateFailure = flashcard => ({
  type: typesSavedCards.savedCardsCreateFailure,
  flashcard
})
export const savedCardsRemoveRequest = flashcard => ({
  type: typesSavedCards.savedCardsRemoveRequest,
  flashcard
})

const initialState = {
  all: [],
  one: [],
  error: '',
  loading: false,
  filters: {}
}

export function savedCards(state = initialState, action) {
  switch (action.type) {

    case typesSavedCards.savedCardsCreateRequest:
    case typesSavedCards.savedCardsGetAllRequest:
    case typesSavedCards.savedCardsGetOneRequest:
      return { ...state, loader: true, error: null }
      
    case typesSavedCards.savedCardsRemoveRequest:
    case typesSavedCards.savedCardsGetOneFailure:
      return { ...state, loading: true, error: action.payload, one: [] }
    case typesSavedCards.savedCardsGetOneRemove:
      return { ...state, loading: true, error: null, one: [] }
    case typesSavedCards.savedCardsGetAllFailure:
      return { ...state, loading: false, error: action.payload, all: {} }

    case typesSavedCards.savedCardsCreateSuccess:      
    case typesSavedCards.savedCardsGetOneSuccess:      
      return { ...state, error: null, loading: false, one: {...action.payload} }
    case typesSavedCards.savedCardsGetAllSuccess:      
      return { ...state, error: null, loading: false, all: [...action.payload] }    
    default:
      return state
  }
}
