import React from 'react'
import PropTypes from 'prop-types'
import ReactPlayer from 'react-player'
import styled from 'styled-components'
import AboutPoints from 'components/About/AboutPoints'
import { AboutTitle } from 'components/About/aboutStyleComponents'

const HomeVideo = props => {
  const ContentVideo = styled.section`
    justify-content: center;
    display: grid;
    padding: 77px 0 26px 0;
  `

  const DivPlayer = styled.div`
    border: 1px solid #a1a1a1;
  `

  return (
    <ContentVideo>
      <AboutTitle style={{ padding: '24px 0 19px 0' }}>¿Cómo puedes participar?</AboutTitle>
      <DivPlayer>
        <ReactPlayer url='https://youtu.be/E3mWv6H55_4' />
      </DivPlayer>
    </ContentVideo>
  )
}

HomeVideo.propTypes = {}

export default HomeVideo
