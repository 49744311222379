import React, { useEffect } from 'react'
import Layout from './../Layout/Layout'
import { connect } from 'react-redux'
import HomeTitle from '../Home/HomeTitle'
import FilterBar from '../Helpers/FilterBar.js'
import { SplashScreen } from '../SplashScreen/SplashScreen.js'
import CompanyCardPodium from '../Company/CompanyCardPodium'
import CompanyCardPodiumList from '../Company/CompanyCardPodiumList'
import { gtaGetAllRequest } from '../Gta/gta.redux.js'
import { CompanyContainer } from '../Company/CompanyContainer'
import { companyRankingGetAllRequest, companyGetAllRequest } from '../Company/company.redux'

function Ranking({
  companyRankingList,
  companyList,
  companyRankingGetAllRequest,
  companyGetAllRequest,
  gtaGetAllRequest
}) {
  useEffect(() => {
    gtaGetAllRequest()
    companyRankingGetAllRequest()
    companyGetAllRequest()
  }, [gtaGetAllRequest, companyRankingGetAllRequest, companyGetAllRequest])
  const companies = companyRankingList?.docs
  const companiesList = companyList?.docs
  return (
    <Layout>
      {!companiesList ? (
        <SplashScreen />
      ) : (
        <>
          <div
            className='container home-company-container justify-content-center'
            style={{ backgroundColor: '#131A27' }}>
            <div className='row '>
              <div className='col-5'></div>
              <div className='col-6 pr-5' style={{}}>
                <HomeTitle
                  title='Empresas comprometidas'
                  line={false}
                  subtitle='Conoce a las empresas que han tenido más interacción 
                  y compromiso con la creación de Flashcompany CLIMB'
                />
                <div className='col-1'></div>
              </div>
            </div>
            <div className='container'>
              <div className='row justify-content-center'>
                <FilterBar type='rankingCompany' theme='dark' />
              </div>
            </div>
            <div className='row justify-content-center'>
              {companies &&
                companies
                  .slice(0, 1)
                  .map(company => <CompanyCardPodium position={1} data={company} />)}
              {companies &&
                companies
                  .slice(1, 2)
                  .map(company => <CompanyCardPodium position={2} data={company} />)}
              {companies &&
                companies
                  .slice(2, 3)
                  .map(company => <CompanyCardPodium position={3} data={company} />)}
            </div>
            {companies && <CompanyCardPodiumList data={companies?.slice(3)} />}
            {companiesList && <CompanyContainer data={companiesList} />}
          </div>
        </>
      )}
    </Layout>
  )
}
const mapStateToProps = ({ gta, company }) => ({
  gtaList: gta.all,
  companyRankingList: company.companyRankingList,
  companyList: company.all
})

const mapDispatchToProps = {
  gtaGetAllRequest,
  companyRankingGetAllRequest,
  companyGetAllRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(Ranking)
