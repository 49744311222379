import React from 'react'
import { Login } from './../Auth/Login'
import Layout from './../Layout/Layout'
const PageLogin = () => {
  return (
    <Layout>
      <Login />
    </Layout>
  )
}
export default PageLogin
