import React from 'react'
import Layout from './../Layout/Layout'
import GlossaryHeader from '../Glossary/GlossaryHeader'
import GlossaryIcons from '../Glossary/GlossaryIcons'
import GlossaryDescription from '../Glossary/GlossaryDescription'
import GlossaryPermissions from '../Glossary/GlossaryPermissions'
function Glossary() {
  return (
    <div className='glossaryPage'>
      <Layout>
        <div style={{ overflowY: 'hidden !important' }}>
          <GlossaryHeader />
          <GlossaryIcons />
          <div className='glossary-line'></div>
          <GlossaryDescription />
          <div className='glossary-line'></div>
          <GlossaryPermissions />
        </div>
      </Layout>
    </div>
  )
}
export default Glossary
