import React from 'react'
import PropTypes from 'prop-types'
import { InfoBanner, BannerLogo, InfoParrafo, BannerLink } from './headerStyleComponents'
import { Columna } from 'components/About/aboutStyleComponents'
import logo from 'assets/img/about/logoGrupos.svg'

const InformationBanner = ({ img, paragraph }) => {
  return (
    <InfoBanner>
      <Columna md='8' sm='6'>
        {paragraph && <InfoParrafo>{paragraph}</InfoParrafo>}
      </Columna>
      <Columna style={{ height: '100%' }} md='4' sm='6'>
        <BannerLink href='https://www.pactomundial.org.mx/gta2030/' target='_blank'>
          {img && <BannerLogo src={logo} />}
        </BannerLink>
      </Columna>
    </InfoBanner>
  )
}

InformationBanner.propTypes = {
  img: PropTypes.string,
  paragraph: PropTypes.string
}

InformationBanner.defaultProps = {
  paragraph:
    'Estas propuestas fueron generadas en la iniciativa Grupos de Trabajo Agenda 2030',
  img: require('./../../assets/img/banners/ball.svg')
}

export default InformationBanner
