import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { cardsFiltersChangeRequest, cardGetCatalogs } from './../Cards/cards.redux.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Formik, Form } from 'formik'
import { Button, Col, Row } from 'reactstrap'
import Select from 'react-select'
import FormInput from './FormInput'

export const FilterBar = ({
  filters,
  catalogs,
  cardGetCatalogs,
  cardsFiltersChangeRequest
}) => {
  useEffect(() => {
    cardGetCatalogs()
  }, [cardGetCatalogs])

  const workAxes = catalogs.workAxes?.data
  const [workAxis, setWorkAxis] = useState([])

  function handleChangeGta(event) {
    const value = event.target.value
    const filters = workAxes.filter(item => item.gta === value)
    setWorkAxis(filters)
  }
  const changeBusinessType = (e, values) => {
    values.businessType = e.map(item => item.value)
  }

  const changeGta = event => {
    filters.gta = event.target.value
  }
  const changeAxis = event => {
    filters.workAxis = event.target.value
  }

  const handleFilter = filters => {
    filters.page = 1
    cardsFiltersChangeRequest(filters)
  }
  const changeClimbsType = (e, values) => {
    values.climb = e.map(item => item.value)
  }

  const changeObjectives = event => {
    filters.objectives = event.target.value
  }
  const changeAreasType = (e, values) => {
    values.areas = e.map(item => item.value)
  }
  const changeTimeInitiatives = event => {
    filters.timeInitiatives = event.target.value
  }
  const iconSearch = <FontAwesomeIcon icon={faSearch} className='filter-inline-search' />

  let initialValuesData = {
    gta: '',
    workAxis: '',
    areas: '',
    businessType: '',
    climb: ''
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValuesData}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            handleFilter(values)
            setSubmitting(false)
          }, 400)
        }}>
        {({ values, isSubmitting, handleChange }) => (
          <Form className='form '>
            <Row>
              <Col lg='3'>
                <FormInput
                  id='gta'
                  label={'Grupo de trabajo Agenda 2030'}
                  type='select'
                  onChange={e => {
                    handleChangeGta(e)
                    handleChange(e)
                    changeGta(e)
                  }}
                  dataOptions={
                    catalogs.gtas &&
                    catalogs.gtas.data.map(({ title, _id }) => ({
                      value: _id,
                      text: title,
                      id: _id,
                      key: _id
                    }))
                  }
                  value={values.gta}
                />
              </Col>
              {/* -- Eje de trabajo--------------------------------- */}
              <Col lg='3'>
                <FormInput
                  id='workAxis'
                  label={'Eje de trabajo'}
                  type='select'
                  onChange={e => {
                    changeAxis(e)
                    handleChange(e)
                  }}
                  dataOptions={workAxis.map(({ objective, _id }) => ({
                    value: _id,
                    text: objective,
                    id: _id,
                    key: _id
                  }))}
                  value={values.workAxis}
                />
              </Col>
              {/* -- Ámbito de implementación --------------------------------- */}
              <Col lg='3'>
                <div>
                  <div className='form-group' style={{ color: '#1e3250' }}>
                    <label className='form-label auth__label' style={{ color: '#1e3250' }}>
                      Ámbito de implementación
                    </label>
                    <Select
                      id='businessType'
                      name='businessType'
                      style={{ color: 'black' }}
                      placeholder='Seleccione'
                      isMulti
                      onChange={e => {
                        changeBusinessType(e, values)
                      }}
                      options={
                        catalogs.businessTypes &&
                        catalogs.businessTypes.data?.map(({ name, _id }) => ({
                          value: _id,
                          label: name,
                          id: _id,
                          _id,
                          key: _id
                        }))
                      }
                    />
                  </div>
                </div>
              </Col>
              {/* -- CLIMB --------------------------------- */}
              <Col lg='3' style={{ paddingRight: '40px' }}>
                <div>
                  <div className='form-group' style={{ color: '#1e3250' }}>
                    <label className='form-label auth__label' style={{ color: '#1e3250' }}>
                      CLIMB
                    </label>
                    <Select
                      id='climb'
                      name='climb'
                      style={{ color: 'black' }}
                      placeholder='Seleccione'
                      isMulti
                      onChange={e => {
                        changeClimbsType(e, values)
                      }}
                      options={
                        catalogs.climbs &&
                        catalogs.climbs.data?.map(({ name, _id }) => ({
                          value: _id,
                          label: name,
                          id: _id,
                          _id,
                          key: _id
                        }))
                      }
                    />
                  </div>
                </div>
              </Col>
              {/* --- ODS ----------------------------------------------------------------------------- */}
              <Col lg='3'>
                <FormInput
                  id='objectives'
                  label='ODS'
                  type='select'
                  onChange={e => {
                    changeObjectives(e)
                    handleChange(e)
                  }}
                  dataOptions={
                    catalogs.objectives &&
                    catalogs.objectives.data.map(({ title, _id }) => ({
                      value: _id,
                      text: title,
                      id: _id,
                      key: _id,
                      name: _id
                    }))
                  }
                  value={values.objectives}
                />
              </Col>
              {/* --- Tiempo de implementación ----------------------------------------------------------------------------- */}
              <Col lg='3'>
                <FormInput
                  id='timeInitiatives'
                  label='Tiempo de implementación'
                  type='select'
                  onChange={e => {
                    changeTimeInitiatives(e)
                    handleChange(e)
                  }}
                  dataOptions={
                    catalogs.timeInitiatives &&
                    catalogs.timeInitiatives.data.map(({ name, _id }) => ({
                      value: name,
                      text: name,
                      id: name,
                      key: name,
                      name: name
                    }))
                  }
                  value={values.timeInitiatives}
                />
              </Col>
              {/* -- Área responsable --------------------------------- */}
              <Col lg='3'>
                <div>
                  <div className='form-group' style={{ color: '#1e3250' }}>
                    <label className='form-label auth__label' style={{ color: '#1e3250' }}>
                      Área responsable
                    </label>
                    <Select
                      id='areas'
                      name='areas'
                      style={{ color: 'black' }}
                      placeholder='Seleccione'
                      isMulti
                      onChange={e => {
                        changeAreasType(e, values)
                      }}
                      options={
                        catalogs.areas &&
                        catalogs.areas.data?.map(({ name, _id }) => ({
                          value: _id,
                          label: name,
                          id: _id,
                          _id,
                          key: _id
                        }))
                      }
                    />
                  </div>
                </div>
              </Col>
              <Col lg='1'>
                <Button className='search' type='submit' disabled={isSubmitting}>
                  <span>{iconSearch}</span>
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  )
}

FilterBar.propTypes = {
  color: PropTypes.string.isRequired
}

FilterBar.defaultProps = {
  color: '#1e3250'
}

const mapStateToProps = state => {
  return {
    loading: state.cards.loading,
    error: state.cards.error,
    filters: state.cards.filters,
    catalogs: state.cards.catalogs
  }
}

const mapDispatchToProps = {
  cardsFiltersChangeRequest: cardsFiltersChangeRequest,
  cardGetCatalogs: cardGetCatalogs
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterBar)
