import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import CountUp from 'react-countup'
import { Link } from 'react-router-dom'
import moment from 'moment'

function HomeCounter({ totalImplemented }) {
  const today = moment().format('DD/MM/YYYY')
  return (
    <Link
      className='link-to-join animate__animated animate__fadeIn animate__faster'
      to='/good-practices-map'>
      <Container className='home-main-counter'>
        <Row xs='1' sm='1' md='1' lg='2' xl='2' xxl='2' className='home-row-counter'>
          <Col className='center-col without-padding without-margin'>
            <p className='home-count-number-text-title'>CONTADOR</p>
            <h3 className='home-count-h3-number'>
              <CountUp
                className='home-count-number'
                start={0}
                end={totalImplemented}
                duration={0.5}
              />
            </h3>
            <p className='home-count-number-text-subtitle2'>Actualizado el {today}</p>
          </Col>
          <Col className='center-col'>
            <p className='home-count-number-text'>
              {' '}
              <span style={{ fontSize: '40px' }}>Acciones</span>
              <br />
              en nuestro banco{' '}
            </p>
          </Col>
        </Row>
      </Container>
    </Link>
  )
}

export default HomeCounter
