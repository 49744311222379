import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Bar } from 'react-chartjs-2'
import { actionGetTotalsByAxes } from 'components/Join/joinAction.redux'
import { cardGetCatalogs } from 'components/Cards/cards.redux'
import { chartData, mergeArray, mergeById, splitName } from 'components/Helpers/exceptionUtils'
import {
  ContainerGraphOds,
  ContainerODS,
  SectionGraphOds,
  TitleGraphOds
} from './GraphOdsStyle'
import 'chartjs-plugin-datalabels'
import 'chartjs-plugin-labels'

const GraphAxis = ({
  actionGetTotalsByAxes,
  totalImplementedByAxes,
  cardGetCatalogs,
  title,
  axesLabels
}) => {
  useEffect(() => {
    cardGetCatalogs()
    actionGetTotalsByAxes()
  }, [cardGetCatalogs, actionGetTotalsByAxes])

  const dataAxesLabels = axesLabels?.data
  const labels = dataAxesLabels?.map(l => l.name)
  const images = dataAxesLabels?.map(i => i.logo)

  const countLabels = dataAxesLabels?.map(i => {
    return {
      id: i._id,
      count: 0
    }
  })

  const countsPractice = totalImplementedByAxes?.map(v => {
    return {
      id: v._id,
      count: v.count
    }
  })

  const counts = countLabels && mergeArray(countLabels, countsPractice)
  const data = counts?.map(c => c.count)
  const chart = labels && chartData('En Cifras', labels, data)

  const arrValuesChart = chart?.datasets[0]?.data
  const maxValueArray = arrValuesChart?.reduce((a, b) => Math.max(a, b))

  const barOptions = {
    scales: {
      yAxes: [
        {
          ticks: {
            display: false,
            min: 0,
            max: maxValueArray + maxValueArray * 0.43,
            stepSize: 25
          }
        }
      ]
    },
    legend: { display: false },
    responsive: true,
    plugins: {
      datalabels: {
        display: true,
        color: 'white',
        font: { size: '16' },
        formatter: function (value) {
          if (value == 0) {
            value = ''
            return value
          }
        }
      },
      labels: {
        render: 'image',
        images: images && [
          ...images.map((image, i) => {
            return {
              src: image,
              height: 80,
              width: 80
            }
          })
        ]
      }
    }
  }

  return (
    <ContainerODS>
      <ContainerGraphOds>
        <TitleGraphOds>{title}</TitleGraphOds>
      </ContainerGraphOds>
      {images && (
        <SectionGraphOds>
          <Bar data={chart} width={90} height={25} options={barOptions} />
        </SectionGraphOds>
      )}
    </ContainerODS>
  )
}

GraphAxis.propTypes = {
  totalImplementedByAxes: PropTypes.array.isRequired
}

GraphAxis.defaultProps = {
  totalImplementedByAxes: []
}

const mapStateToProps = ({ joinActions, cards }) => ({
  axesLabels: cards?.catalogs.axesGlobalPact,
  totalImplementedByAxes: joinActions?.totalsAxes
})

const mapDispatchToProps = {
  cardGetCatalogs: cardGetCatalogs,
  actionGetTotalsByAxes: actionGetTotalsByAxes
}

export default connect(mapStateToProps, mapDispatchToProps)(GraphAxis)
