import React from 'react'
import { useLinkedIn } from 'react-linkedin-login-oauth2'
import { connect } from 'react-redux'

import linkedInLogo from './../../assets/img/logos/linkedin.svg'
import { loginLinkedinRequest } from './auth.redux'

const LoginLinkedin = ({ loginLinkedinRequest }) => {
  const { linkedInLogin } = useLinkedIn({
    clientId: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
    scope: 'r_liteprofile r_emailaddress',
    state: process.env.REACT_APP_LINKEDIN_STATE,
    redirectUri: `${window.location.origin}/linkedin`, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
    onSuccess: code => {
      loginLinkedinRequest(code)
    }
  })
  return (
    <img
      onClick={linkedInLogin}
      src={linkedInLogo}
      alt=''
      className='social-media-logo-google'
    />
  )
}

const mapDispatchToProps = {
  loginLinkedinRequest
}
export default connect(null, mapDispatchToProps)(LoginLinkedin)
