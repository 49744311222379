import React from 'react'
import PropTypes from 'prop-types'
import {
  ContainerItemList,
  ContentDescriptionItem,
  ContentImage,
  DescriptionItemList,
  ImgItemList,
  LineDotted,
  TitleItemList
} from './listFAQStyle'
import Col from '../../../node_modules/reactstrap/es/Col'
import { Row } from 'components/StyledComponents/Grid'

const ItemDropDownFAQ = ({ title, description }) => {
  return (
    <ContainerItemList>
      <ContentImage>
        <ImgItemList src={require(`./../../assets/img/FAQ-check.svg`)} />
        <LineDotted />
      </ContentImage>
      {/* <Col md='6'/>
          <Col md='6'/> */}
      <ContentDescriptionItem>
        <TitleItemList>{title}</TitleItemList>
        <DescriptionItemList>{description}</DescriptionItemList>
      </ContentDescriptionItem>
    </ContainerItemList>
  )
}

ItemDropDownFAQ.propTypes = {
  img: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string
}
export default ItemDropDownFAQ
