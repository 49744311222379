import React from 'react'
import PropTypes from 'prop-types'
import { Container } from './contactStyleComponents'

const ContactPage = ({ img, title }) => {
  return (
    <Container
      style={{
        backgroundImage: `url(${img})`
      }}></Container>
  )
}

ContactPage.propTypes = {
  // title: PropTypes.string.isRequired,
  // img: PropTypes.string
}

ContactPage.defaultProps = {
  // title: 'Título',
  // img: require('./../../assets/img/banners/ball.svg')
}

export default ContactPage
