import React from 'react'
import PropTypes from 'prop-types'
import Layout from '../Layout/Layout'
import HeaderBanner from 'components/Header/HeaderBanner'
import UnderconstructionPage from 'components/UnderconstructionPage/UnderconstructionPage'
import FooterContact from 'components/Contact/FooterContact'
import HowParticipate from 'components/HowParticipate/HowParticipate'
import ButtonsContainer from 'components/Buttons/ButtonsContainer'
import ButtonCard from 'components/Buttons/ButtonCard'
import { DarkContainer, HowParticipateContainer, ParagraphSpace } from 'components/HowParticipate/howParticipateStyled'

export const PageHowParticipate = props => {
  return (
    <div className='gta__page'>
      <Layout>
        <HeaderBanner title='¿CÓMO PARTICIPAR?' />
        <DarkContainer paddingTop='5%'>
        <ParagraphSpace>
          La plataforma LOGRA es de acceso gratuito para cualquier persona que visite el sitio
          web, pudiendo ver la totalidad de las acciones publicadas en ella.
          <br />
          <br />
          Sin embargo, para poder participar activamente compartiendo acciones deberás
          registrarte en la plataforma.
        </ParagraphSpace>
      </DarkContainer>
        <HowParticipateContainer>
          <HowParticipate />
          {/* Botónes */}
          <ButtonsContainer marginBottom='90px'>
            <ButtonCard url='/about' title={'ACERCA DE'} />
            <ButtonCard url='/our-objectives' title={'NUESTROS OBJETIVOS'} />
            <ButtonCard url='/page-faq' title={'PREGUNTAS FRECUENTES'} />
          </ButtonsContainer>
        </HowParticipateContainer>
        <FooterContact title='¿Tienes alguna duda?' subtitle='Contáctanos' />
      </Layout>
    </div>
  )
}

PageHowParticipate.propTypes = {
  props: PropTypes
}

export default PageHowParticipate
