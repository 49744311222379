import React from 'react'
import { Container, Row } from 'reactstrap'
import styled from 'styled-components'
import logo from './../../assets/img/LOGO-blanco.png'

const ImgLogra = styled.img`
  display: block;
  height: 113px;
  margin: 26px 0 9px 0;
`
const HomeTitleColabora = () => {
  return (
    <Container className=' animate__animated animate__fadeIn animate__faster'>
      <Row className='home-row-ods-img'>
        <div className='home-ods-svg-div2'>
          <ImgLogra src={logo} />
        </div>
        <Row className='home-row-ods-label'>
          <p className='home-subtitle-home2' style={{ fontWeight: 'bold' }}>
            Acciones de sostenibilidad empresarial
          </p>
        </Row>
        <Row className='home-row-ods-label'>
          <p className='home-subtitle-home2'>
            Un espacio para compartir y conocer buenas prácticas
            <br />
            en desarrollo sostenible, mostrar tu mejora
            <br />
            continua y progreso e inspirar a otras empresas.
          </p>
        </Row>
      </Row>
    </Container>
  )
}

export default HomeTitleColabora
