import { put, call, takeLatest, delay } from 'redux-saga/effects'
import axios from 'axios'
import {
  RANKING_GET_RANKING_LIST_REQUEST,
  RANKING_GET_RANKING_LIST_FAILURE,
  RANKING_GET_RANKING_LIST_SUCCESS,
  RANKING_GET_CATALOGS_REQUEST,
  RANKING_GET_CATALOGS_FAILURE,
  RANKING_GET_CATALOGS_SUCCESS
} from './ranking.redux'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import { manageError } from '../../redux/helpers/handlers.js'

export function* getRankingListSaga({ payload }) {
  try {
    yield put(showLoading())
    const urlRanking = payload?.gta
      ? `/api/priorizations/ranking?gta=${payload.gta}`
      : `/api/priorizations/ranking`
    const insert = yield call(axios.get, urlRanking, payload)
    yield delay(1500)
    yield put({ type: RANKING_GET_RANKING_LIST_SUCCESS, payload: insert })
    yield put(hideLoading())
  } catch (error) {
    yield put({ type: RANKING_GET_RANKING_LIST_FAILURE, error: error.response })
  }
}
function* getCatalogsSaga() {
  try {
    const { data: workAxes } = yield call(axios.get, '/api/workAxes/all')
    const { data: gtas } = yield call(axios.get, '/api/gtas/all')

    const payload = { workAxes, gtas }
    yield put({ type: RANKING_GET_CATALOGS_SUCCESS, payload: payload })
  } catch (error) {
    yield put({ type: RANKING_GET_CATALOGS_FAILURE, error: error.response })
  }
}
export default function* rankingSagas() {
  yield takeLatest(RANKING_GET_RANKING_LIST_REQUEST, getRankingListSaga)
  yield takeLatest(RANKING_GET_CATALOGS_REQUEST, getCatalogsSaga)
  yield takeLatest(RANKING_GET_CATALOGS_FAILURE, manageError)
}
