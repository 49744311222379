import React from 'react'
import JoinFilterBar from './JoinFilterBar.js'
import JoinActionCard from './JoinActionCard.js'
import HeaderGoodPractice from 'components/Header/HeaderGoodPractice.js'
import Footer from './../Layout/Footer'
export const JoinGoodPracticesMap = ({ data, filters, actionGetMoreRequest }) => {
  const { docs } = data

  const handleScroll = event => {
    const { target } = event
    const finalPage = target?.clientHeight + 100
    if (Math.round(target.scrollHeight - target.scrollTop) <= finalPage && data.hasNextPage) {
      filters.page = data.nextPage
      data.totalPages >= filters.page &&
        actionGetMoreRequest({ status: 2, active: true, limit: 30, ...filters })
    }
  }
  return (
    <>
      <div className='container cards__row' onScroll={handleScroll}>
        <HeaderGoodPractice>
          <JoinFilterBar />
        </HeaderGoodPractice>
        <div className='row '>
          <div className='row cards-mobile cards-list-goodpractices-row'>
            {docs?.map(key => {
              return <JoinActionCard data={key} key={key._id} />
            })}
          </div>
          <div className='footer-map'>
            <Footer />
          </div>
        </div>
      </div>
    </>
  )
}

export default JoinGoodPracticesMap
