import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

// Create a <Title> react component that renders an <h1> which is
// centered, palevioletred and sized at 1.5em
export const TitleItemList = styled.h1`
  font-size: 140%;
  font-weight: bold;
  text-align: left;
  color: #0a121d;
  /* max-width: 574px; */
`

export const WhiteText = styled.text`
  color: white;
  font-size: 24px;
  @media screen and (max-width: 575px) {
    ${props =>
      props.joinActions &&
      css`
        font-size: 20px !important;
      `}
  }
`

export const ContentDescriptionQuote = styled.section`
  color: #000;
  border-left: 5px solid #689dc6;
  padding-left: 18px;
`

export const ImgItemList = styled.img`
  height: 50px;
  width: 50px;
  display: flex;
  /* margin: 2% auto 50px 0; */
`
export const ContentImage = styled.section`
  width: 50px;
  height: auto;
  display: flex;
`

export const TitleListItem = styled.h5`
  color: #699cc6;
  font-weight: bold;
`
export const ParraphListItem = styled.p`
  color: #0a121d;
  font-size: 19px;
  line-height: 24px;
`

export const LineDotted = styled.div`
  height: auto;
  display: flex;
  width: 26px;
  /* background: green; */
  margin-left: -27px;
  margin-top: 57px;
  margin-bottom: 34px;
  border-left: 3px dotted #749bc2;
`

export const Container = styled.section`
  padding: 2% 5% 5em 5%;
  background-color: #f4f4f4;
  @media only screen and (max-width: 640px) {
    padding: 0 0 25px 20px; 
  }
`
export const ContainerMet = styled.section`
  padding: 2% 5% 5em 5%;
  background-color: #f4f4f4;
  @media only screen and (max-width: 640px) {
    padding: 25px 20px; 
  }
`

export const ContainerCenter = styled.section`
  ${props =>
      props.center &&
      css`
        align-items: center;
        justify-items: center;
        display: grid;
      `}
  @media only screen and (max-width: 640px) {
    align-items: center;
    justify-items: center;
    display: grid;

  }
`

export const DescriptionItemList = styled.p`
  font-size: 140%;
  font-weight: light;
  text-align: left;
  color: #0a121d;
`

export const LinkItemList = styled(Link)`
  color: #699cc6;
  font-weight: bold;
  text-decoration: underline;
`

export const ButtonLightBlue = styled(Link)`
  color: #699cc6;
  font-size: 22px;
  border: 1px solid #699cc6;
  padding: 5px 16px 5px 19px;
  border-radius: 24px;
  transition: background 0.2s ease;
  &:hover {
    background: #ecf6ff;
  }
`

export const OlItemList = styled.ol``
export const LiItemList = styled.li``

// Create a <Wrapper> react component that renders a <section> with
// some padding and a papayawhip background
export const ContainerList = styled.section`
  padding: 5em 8em 5em 8em;
`
export const QuoteSmall = styled.text`
  font-style: italic;
  font-size: 14px;
`

export const ContainerQuoteList = styled.section`
  padding: 2em 8em 2em 8em;
  margin: 10px 0px;
  @media screen and (max-width: 575px) {
    padding: 2em 1em 2em 0;
  }
`

export const ContentDescriptionItem = styled.section`
  padding: 7px 2em 2em 1em;
`

export const BoldText = styled.text`
  font-weight: 400;
`

export const ContainerItemList = styled.section`
  flex: 1;
  display: flex;
  background: #f4f4f4;
`
