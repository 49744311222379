import React from 'react'
import HeaderBanner from 'components/Header/HeaderBanner'
import { listContentFAQ } from 'components/FAQ/ContentFAQ'
import ListDropDown from 'components/ListDropDown/ListDropDown'
import Layout from 'components/Layout/Layout'
import ButtonsContainer from 'components/Buttons/ButtonsContainer'
import ButtonCard from 'components/Buttons/ButtonCard'
import FooterContact from 'components/Contact/FooterContact'
import { Container } from 'components/FAQ/listFAQStyle'

const PageFAQ = props => {
  return (
    <Layout>
      <HeaderBanner title='PREGUNTAS FRECUENTES' />
      <Container>
        <ListDropDown list={listContentFAQ} listType={'faq'} />
        {/* Botónes */}
        <ButtonsContainer>
          <ButtonCard url='/about' title={'ACERCA DE'} />
          <ButtonCard url='/our-objectives' title={'NUESTROS OBJETIVOS'} />
          <ButtonCard url='/how-participate' title={'¿CÓMO PARTICIPAR?'} />
        </ButtonsContainer>
      </Container>
      <FooterContact title='¿Tienes alguna duda?' subtitle='Contáctanos' />
    </Layout>
  )
}

PageFAQ.propTypes = {}

export default PageFAQ
