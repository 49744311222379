import React from 'react'
import { Container, Circle, Rectangle } from 'components/HowParticipate/howParticipateStyled'
import { Paragraph } from 'components/Contact/contactStyleComponents'
import { Row, Col } from 'reactstrap'

const HowParticipateRectangle = ({ number, text, top, topCircle }) => {
  return (
    <>
      <Container>
        <Row className='circle-dot' >
          <Col lg='1' style={{ zIndex: 1 }}>
            <Circle topCircle={topCircle}>{number}</Circle>
          </Col>
          <Col lg='11'>
            <Rectangle top={top}>
              <Paragraph>{text}</Paragraph>
            </Rectangle>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default HowParticipateRectangle
