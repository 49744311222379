import React, { useState } from 'react'
import { Row, Col, Collapse, Button, CardBody, Card, Container } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp, faDownload } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'

export const PriorizationCard = ({ data, functionHandler, args }) => {
  const { _id, title, color: background, document, logoColor, goal } = data
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)
  const handlerGuide = () => functionHandler({ gta: _id, document })

  const ButtonODS = styled(Button)`
    background: #fff;
    color: #666;
    width: 100%;
    border-radius: 15px solid;
    background: #fff;
    color: #000;
    border-color: #ececec;
    border-radius: 35px;
    text-align: left;
    padding: 6px 15px;
    transition: all 0.2s ease;
    &:hover {
      background: #fff;
      color: #000;
      border-color: #dddddd;
      box-shadow: 2px 1px 13px -8px #cccccceb;
    }
    @media only screen and (max-width: 640px) {
    padding: 5px 10px;
  }
  `

  const CardODS = styled(Card)`
    background: #f5f5f5;
  `

  const ChevronDown = styled(FontAwesomeIcon)`
    float: right;
    margin: 6px 2px 0 0;
    transition: display 0.2s ease;
    display: ${({ status }) => (!status ? 'inline-block' : 'none')};
  `

  const ChevronUp = styled(FontAwesomeIcon)`
    float: right;
    margin: 6px 2px 0 0;
    transition: display 0.2s ease;
    display: ${({ status }) => (status ? 'inline-block' : 'none')};
  `

  const IconDownload = styled(FontAwesomeIcon)`
    margin: 0 6px 0px 3px;
  `

  const DivDownloadLabel = styled.div`
    cursor: pointer;
    width: fit-content;
    padding: 5px 12px;
    color: #689ec6;
    border: 1px solid #689ec6;
    margin: 12px 0 0 0;
    border-radius: 23px;
    transition: all 0.2s ease;
    &:hover {
      background: #689ec6;
      color: #fff;
    }
  `

  const iconDownload = <IconDownload icon={faDownload} />

  return document ? (
    <Container className='guides-option'>
      <Row>
        <Col>
          <div>
            <ButtonODS onClick={toggle} background='green'>
              {title}
              <ChevronDown icon={faChevronDown} status={isOpen} />
              <ChevronUp icon={faChevronUp} status={isOpen} />
            </ButtonODS>
            <Collapse isOpen={isOpen} {...args}>
              <CardODS>
                <CardBody>
                  <Row>
                    <Col style={{ maxWidth: '100px' }}>
                      <img src={logoColor} height={'80px'} alt='logoColor' />
                    </Col>
                    <Col>
                      {goal}
                      <DivDownloadLabel onClick={handlerGuide}>
                        Descargar publicación {iconDownload}
                      </DivDownloadLabel>
                    </Col>
                  </Row>
                </CardBody>
              </CardODS>
            </Collapse>
          </div>
        </Col>
      </Row>
    </Container>
  ) : (
    ''
  )
}

export default PriorizationCard
