import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { SplashScreen } from '../SplashScreen/SplashScreen'
import Layout from '../Layout/Layout'
import { JoinUserToAction } from '../Join/JoinUserToAction'
import { actionGetOneLikeRequest, actionGetRequest } from 'components/Join/joinAction.redux'

export const PageRegisterAction = ({
  match,
  joinActions,
  actionGetRequest,
  actionGetOneLikeRequest,
  user
}) => {
  const { _id } = match?.params
  useEffect(() => {
    actionGetRequest({ _id })
    actionGetOneLikeRequest({
      user,
      goodPractice: _id
    })
  }, [actionGetRequest, actionGetOneLikeRequest, user, _id])

  return (
    <div className='gta__page'>
      <Layout>
        {joinActions.loading ? <SplashScreen /> : !joinActions.loading && <JoinUserToAction />}
      </Layout>
    </div>
  )
}

const mapStateToProps = ({ user, joinActions }) => ({
  user: user._id,
  joinActions
})
const mapDispatchToProps = { actionGetRequest, actionGetOneLikeRequest }

export default connect(mapStateToProps, mapDispatchToProps)(PageRegisterAction)
