import React from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import { connect } from 'react-redux'
import CompanyGtaRankingList from './CompanyGtaRankingList.js'
import paginationFactory from 'react-bootstrap-table2-paginator'

function CompanyCardPodiumList({ data, gtas }) {
  let indexRow = 4
  let bandera = 0

  const styleColumnIni = {
    background: 'transparent',
    border: 'none',
    color: '#4c69ae',
    fontSize: '22px',
    fontWeight: '500',
    padding: '18px 28px '
  }

  const stylePoints = {
    background: 'transparent',
    border: 'none',
    paddingLeft: '10px',
    color: '#4c69ae',
    fontSize: '22px',
    fontWeight: '500',
    textAlign: 'left'
  }

  const styleColumn = {
    background: 'transparent',
    border: 'none',
    color: 'white'
  }

  const styleColumnCenter = {
    background: 'transparent',
    border: 'none',
    textAlign: 'right',
    color: 'white'
  }

  const styleCompany = {
    fontSize: '25px',
    fontWeight: 'normal',
    textAlign: 'left',
    color: '#131a27'
  }

  const columns = [
    {
      dataField: 'position',
      id: 'tableRankposition',
      text: 'Posición',
      style: styleColumnIni,
      headerStyle: styleColumn,
      formatter: (cell, row) => {
        indexRow = indexRow + 2
        const divPosition = <div>{indexRow - 2}</div>
        indexRow = bandera === 0 ? indexRow - 1 : indexRow - 2
        bandera = bandera === 0 ? 1 : 0
        return divPosition
      }
    },
    {
      dataField: 'logo',
      text: 'Empresa',
      headerStyle: styleColumn,
      formatter: (cell, row) =>
        (
          <img
            src={require(`./../../assets/img/${cell}`)}
            alt={row.name}
            className='imgRankingList'
          />
        ) || <></>
    },
    {
      dataField: 'company',
      text: '',
      style: styleCompany,
      headerStyle: styleColumn
    },
    {
      dataField: 'companyType',
      text: 'Tipo de empresa',
      headerStyle: styleColumn
    },
    {
      dataField: 'points',
      text: 'Puntos',
      style: stylePoints,
      headerStyle: styleColumn
    },
    {
      dataField: 'gtaNum',
      style: function callback(cell, row, rowIndex, colIndex) {
        const gta = gtas?.find(element => element?._id === cell) || '#fff'
        return {
          background: gta?.color,
          width: '180px'
        }
      },
      formatter: (cell, row) => {
        const gta = gtas?.find(element => element?._id === cell)
        return <CompanyGtaRankingList gta={gta} />
      },
      text: 'GTA2030',
      headerStyle: styleColumnCenter
    }
  ]

  return (
    <div className='container company-container-rank-list justify-content-center'>
      <div className='row justify-content-center'>
        <BootstrapTable
          id='rankCompany'
          keyField='id'
          data={data}
          columns={columns}
          pagination={paginationFactory()}
        />
      </div>
    </div>
  )
}

CompanyCardPodiumList.propTypes = {}

const mapStateToProps = ({ gta }) => ({
  gtas: gta.all.docs
})

const mapDispatchToProps = {
  // cardsFiltersChangeRequest: cardsFiltersChangeRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyCardPodiumList)
