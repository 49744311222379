import React from 'react'
import { Formik, Form } from 'formik'
import FormInput from './../Helpers/FormInput.js'
import './_auth.scss'
import { connect } from 'react-redux'
import { recoverRequestRequest } from './auth.redux.js'

const RecoverPasswordForm = ({ loading, recoverRequestRequest }) => {
  return (
    <div className='recover__password'>
      <h3 className='auth__title'>Recuperar contraseña</h3>
      <p className='recover__description'>
        Ingresa el correo electrónico asociado a la cuenta. Te enviaremos las instrucciones
        para que puedas restablecer tu contraseña. Es posible que se encuentre en la carpeta
        Spam o correo no deseado.
      </p>
      <Formik
        initialValues={{ email: '' }}
        validate={values => {
          const errors = {}
          for (const key in values) if (!values[key]) errors[key] = 'Requerido'
          if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email))
            errors.email = 'Correo no válido'
          return errors
        }}
        // Submit
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            recoverRequestRequest(values)
            setSubmitting(false)
          }, 400)
        }}>
        {({ handleSubmit, values, handleChange }) => (
          <Form onSubmit={handleSubmit} className='form recoverPwd__form'>
            <div className='row gutters recoverPwd__input'>
              <div className='recoverPwd__icon'>
                <FormInput
                  id='email'
                  type=''
                  label='Correo electrónico'
                  onChange={handleChange}
                  value={values.email}
                />
              </div>
            </div>

            <div className='row gutters'>
              <button
                type='submit'
                className='btn btn-primary btn-block mt-3'
                disabled={loading}>
                Enviar{' '}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}
const mapStateToProps = state => {
  return {
    error: state.auth.error,
    loading: state.auth.loading
  }
}

const mapDispatchToProps = {
  recoverRequestRequest: recoverRequestRequest
}
export default connect(mapStateToProps, mapDispatchToProps)(RecoverPasswordForm)
