import { SplashScreen } from 'components/SplashScreen/SplashScreen'
import { usersGetOneRequest } from 'components/Users/users.redux'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import ProfileForm from '../Users/ProfileForm'
import Layout from './../Layout/Layout'
const PageProfileEdit = ({ one, usersGetOneRequest }) => {
  const { _id } = useParams()

  useEffect(() => {
    !one.active && usersGetOneRequest({ _id })
  }, [usersGetOneRequest, _id])
  const isEmpty =
    !one.city ||
    !one.company ||
    !one.country ||
    !one.gender ||
    !one.name ||
    !one.lastName ||
    !one.state ||
    !one.profileUser.telephone
  return (
    <div className='profile-edit-form'>
      {isEmpty && (
        <>
          <span className='FLASHCARDS-CLIMB-REGISTER'>
            {'Complete su información para poder usar la plataforma'}
          </span>
          <p className='FLASHCARDS-CLIMB-REGISTER-OBS'>
            La información proporcionada en el Sitio está destinada exclusivamente a fines
            informativos de orden general.
          </p>
        </>
      )}

      {!!one.active ? (
        isEmpty ? (
          <ProfileForm isEmpty={isEmpty} profileuser={one} />
        ) : (
          <Layout>
            <ProfileForm isEmpty={isEmpty} profileuser={one} />
          </Layout>
        )
      ) : (
        <SplashScreen />
      )}
    </div>
  )
}
const mapStateToProps = state => ({
  one: state.users.one
})
const mapDispatchToProps = {
  usersGetOneRequest
}
export default connect(mapStateToProps, mapDispatchToProps)(PageProfileEdit)
