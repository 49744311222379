import { Form, Formik } from 'formik'
import React, { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'
import { cardGetCatalogs } from './../Cards/cards.redux.js'
import FormInput from '../Helpers/FormInput.js'
const RankingFilterBar = ({ handleChange, filters, catalogs, cardGetCatalogs, ...props }) => {
  useEffect(() => {
    cardGetCatalogs()
  }, [cardGetCatalogs])
  const iconSearchWhite = (
    <FontAwesomeIcon
      icon={faSearch}
      style={{ color: 'white', margin: '31px 0 0 7px' }}
      className='filter-inline-search'
    />
  )
  let initialValues = {
    gta: ''
  }
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validate={values => {
          const errors = {}
          for (const key in values) if (!values[key]) errors[key] = 'Requerido'
          return errors
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            handleChange(values)
            setSubmitting(false)
          }, 600)
        }}>
        {({ values, isSubmitting, setFieldValue, handleChange }) => (
          <Form className='form ranking-form'>
            <div className='row justify-content-center'>
              <div className='col-md-3 mb-1'>
                <FormInput
                  colorLabel='filter-rank'
                  id='gta'
                  label={'Grupo de Trabajo'}
                  type='select'
                  onChange={handleChange}
                  dataOptions={
                    catalogs.gtas &&
                    catalogs.gtas.data.map(({ title, _id }) => ({
                      value: _id,
                      text: title,
                      id: _id,
                      key: _id
                    }))
                  }
                  value={values.gta}
                />
              </div>
              <button className='col-md-1 mb-1 search' type='submit' disabled={isSubmitting}>
                {iconSearchWhite}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}
const mapStateToProps = state => {
  return {
    catalogs: state.cards.catalogs
  }
}
const mapDispatchToProps = {
  cardGetCatalogs: cardGetCatalogs
}
export default connect(mapStateToProps, mapDispatchToProps)(RankingFilterBar)
