import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { gtaGetAllRequest } from '../Gta/gta.redux.js'
import AboutPoints from 'components/About/AboutPoints'
import { SplashScreen } from '../SplashScreen/SplashScreen.js'
import Layout from '../Layout/Layout'
import HeaderBanner from 'components/Header/HeaderBanner.js'
import FooterContact from 'components/Contact/FooterContact.js'
import AboutSection from 'components/About/AboutSection.js'


const PageAbout = ({ loading, gtaGetAllRequest }) => {
  useEffect(() => {
    gtaGetAllRequest()
  }, [gtaGetAllRequest])
  return (
    <div className='gta__page'>
      <Layout>
        {/* {loading ? (
          <SplashScreen />
        ) : (
          <>
            <HeaderGta />
          </>
        )} */}
        <HeaderBanner title='ACERCA DE' />
        <AboutSection/>
        <AboutPoints title='LOGRA cumple dos funciones:'/>
        {/* Botónes */}
        <FooterContact title='¿Tienes alguna duda?' subtitle='Contáctanos' />
      </Layout>
    </div>
  )
}
const mapStateToProps = ({ gta }) => ({
  gtaList: gta.all,
  loading: gta.loading
})

const mapDispatchToProps = {
  gtaGetAllRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(PageAbout)
