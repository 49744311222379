import React from 'react'
import PropTypes from 'prop-types'
import { ContainerList } from './listDropDownStyle'
import ItemDropDownOurObjectives from './ItemDropDownOurObjectives'
import ItemDropDownFAQ from 'components/FAQ/FAQListDropDrown'

const ListDropDown = ({ list, listType }) => {
  return (
    <ContainerList>
      {listType === 'faq' &&
        list.map(({ title, description }) => (
          <ItemDropDownFAQ title={title} description={description} />
        ))}
      {listType === 'objectives' &&
        list.map(({ img, title, description }) => (
          <ItemDropDownOurObjectives img={img} title={title} description={description} />
        ))}
    </ContainerList>
  )
}

ListDropDown.propTypes = {
  list: PropTypes.array.isRequired,
  listType: PropTypes.string.isRequired
}

ListDropDown.defaultProps = {
  list: [],
  listType: 'objectives'
}

export default ListDropDown
