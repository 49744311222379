import { WhiteText } from 'components/FAQ/listFAQStyle'
import QuoteContent from 'components/FAQ/QuoteContent'
import { SplashScreen } from 'components/SplashScreen/SplashScreen'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Container } from 'reactstrap'

import JoinActionForm from './JoinActionForm'
import { ContainerJoin, ContainerJoinTitle } from './JoinStyledComponents'

export const JoinActionRegister = props => {
  const [showSplash, setShowSplash] = useState(false)
  return (
    <>
      {showSplash && <SplashScreen />}

      <Container>
        {/* Banner Title */}
        <ContainerJoinTitle>
          <QuoteContent>
            <WhiteText joinActions>
              Registra la siguiente información para hacer pública tu buena práctica
            </WhiteText>
          </QuoteContent>
        </ContainerJoinTitle>
        {/* Register form */}
        <ContainerJoin>
          <JoinActionForm setShowSplash={setShowSplash} />
        </ContainerJoin>
      </Container>
    </>
  )
}
const mapStateToProps = state => ({
  users: state.users.all,
  usersError: state.users.error,
  usersLoading: state.users.loading
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(JoinActionRegister)
