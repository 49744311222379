import React from 'react'
import { ContainerQuoteList, ContentDescriptionQuote } from './listFAQStyle'

const QuoteContent = ({ children }) => {
  return (
    <ContainerQuoteList>
      <ContentDescriptionQuote>{children}</ContentDescriptionQuote>
    </ContainerQuoteList>
  )
}

export default QuoteContent
