import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { gtaGetAllRequest } from './../Gta/gta.redux'
import { SplashScreen } from '../SplashScreen/SplashScreen.js'
import { Container } from 'reactstrap'
import Layout from 'components/Layout/Layout.js'
import PriorizationCard from 'components/Cards/PriorizationCard'
import { cardsCreateDocRequest } from 'components/Cards/cards.redux'
import HeaderBanner from 'components/Header/HeaderBanner'
import InformationBanner from 'components/Header/InformationBanner'

export const ImplementationGuides = ({
  all,
  loader,
  gtaGetAllRequest,
  cardsCreateDocRequest
}) => {
  useEffect(() => {
    gtaGetAllRequest()
  }, [gtaGetAllRequest])
  return (
    <>
      <Layout className='solution-map-scroll'>
        <HeaderBanner title='PUBLICACIONES' />
        <InformationBanner
          paragraph={
            <p style={{ textAlign: 'justify', paddingRight: '31px', fontSize: '21px' }}>
              En este espacio podrás consultar documentos generados por la iniciativa Grupos de
              Trabajo Agenda 2030, así como publicaciones relevantes relacionadas con los ODS
              de la Agenda 2030.
              <br />
              <br />
              La iniciativa Grupos de Trabajo Agenda 2030 es un trabajo conjunto entre Pacto
              Mundial México y el Consejo Coordinador Empresarial.
            </p>
          }
        />
        {!loader ? (
          <SplashScreen />
        ) : (
          <Container className='guides'>
            <h5 className='guides-title'>Grupos de Trabajo Agenda 2030</h5>
            {all?.map(gta => (
              <PriorizationCard data={gta} functionHandler={cardsCreateDocRequest} />
            ))}
          </Container>
        )}
      </Layout>
    </>
  )
}

const mapStateToProps = ({ gta }) => ({
  all: gta.all,
  loader: gta.loader
})

const mapDispatchToProps = {
  gtaGetAllRequest,
  cardsCreateDocRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(ImplementationGuides)
