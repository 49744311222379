import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const NavbarContainer = styled.nav`
  width: 100%;
  height: ${props => (props.extendNavbar ? '100vh' : '80px')};
  background-color: #131a27;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  padding-top: 20px;
  z-index: 10;

  @media (min-width: 700px) {
    height: 80px;
  }
`

export const LeftContainer = styled.div`
  flex: 5%;
  display: flex;
  align-items: center;
`

export const FirstContainer = styled.div`
  flex: 50%;
  display: flex;

  /* // everything bigger than that */
  @media only (min-width: 768px) and (max-width: 992px) {
    margin-right: 2%;
  }

  /* // everything bigger than that */
  @media only (min-width: 1900px) {
    margin-right: 10%;
  }
`

export const SecondContainer = styled.div`
  flex: 10%;
  display: flex;
`

export const ThirdContainer = styled.div`
  flex: 20%;
  display: flex;
`

export const RightContainer = styled.div`
  flex: 30%;
  display: flex;
  justify-content: center;

  /* // everything bigger than that */
  @media only (min-width: 768px) and (max-width: 992px) {
    margin-right: 1%;
  }

  /* // everything bigger than that */
  @media only (min-width: 1900px) {
    margin-right: 3%;
  }
`

export const NavbarInnerContainer = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
`

export const NavbarLinkContainer = styled.div`
  display: flex;
`

export const NavbarLinkLogo = styled(Link)`
  color: white;
  font-size: small;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  margin: 10px 9px;
`

export const NavbarLinkProfile = styled(Link)`
  color: white;
  font-size: small;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  margin: 5px 5px;

  @media (max-width: 700px) {
    position: absolute;
    top: 28px;
    right: 70px;
  }
`

export const NavbarLink = styled(Link)`
  color: white;
  font-size: small;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  margin: 10px 7px;

  @media (max-width: 700px) {
    display: none;
  }
`

export const NavbarLabel = styled.label`
  color: white;
  font-size: small;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  margin: 10px 7px;
  cursor: pointer;

  &:hover {
    color: #709dc8;
  }

  @media (max-width: 700px) {
    display: none;
  }
`

export const LinkMenu = styled.li`
  list-style: none;
`

export const NavItem1 = styled.ul`
  list-style: none;
  padding-left: 0;
  margin: 0;
`

export const NavItem = styled.li`
  list-style: none;
`

export const NavbarLinkDropdown = styled(Link)`
  color: white;
  font-size: small;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  margin: 1px;

  &:hover {
    color: #131a27;
    font-weight: 400;
  }

  @media (max-width: 700px) {
    display: none;
  }
`

export const NavbarLinkExtended = styled(Link)`
  color: white;
  font-size: small;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  margin: 10px;
`

export const Logo = styled.img`
  max-width: 145px;
  height: auto;
  margin-bottom: 40px;
`

export const OpenLinksButton = styled.button`
  width: 70px;
  height: 50px;
  background: none;
  border: none;
  color: white;
  font-size: 45px;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 10px;

  @media (min-width: 700px) {
    display: none;
  }
`

export const NavbarExtendedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 700px) {
    display: none;
  }
`

export const NavbarItemDropdown = styled.div`
  color: white;
  font-size: small;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  margin: 10px;
  @media (max-width: 700px) {
    display: none;
  }
`

export const NavbarDropdown = styled.div`
  position: relative;
  display: inline-block;
  &:hover {
    display: block;
    > div {
      display: block;
    }
  }
  @media (max-width: 700px) {
    display: none;
  }
`

export const NavbarDropdownContent = styled.div`
  display: none;
  position: absolute;
  top: 28px;
  background-color: #608fb8;
  min-width: 188px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0, 2);
  border-top: 5px solid #a3c3d9;
  padding: 5px 10px;
  z-index: 10;
  left: 10px;
`
