import PageCardDetail from '../Pages/PageCardDetail'
import PriorizationDetail from './PriorizationDetail'

const cardRoutes = [
  {
    path: '/card/:_id',
    component: PageCardDetail
  },
  {
    path: '/priorization/:id',
    children: { PriorizationDetail }
  }
]

export default cardRoutes
