import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { faBars, faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from 'react-redux'
import MenuFullPage from './MenuFullPage'
import profileDefault from 'assets/img/profileavatar.svg'
import { loginLogoutRequest } from './../Auth/auth.redux.js'
import UnderConstruction from 'components/Helpers/UnderConstruction'

function Navbar({ loginLogoutRequest, isLogin, userId, profile }) {
  const handleLogout = () => {
    loginLogoutRequest()
  }

  const iconMenu = <FontAwesomeIcon icon={faBars} />

  return (
    <>
      <nav
        id='pageHeader'
        className='navbar navbar-expand-lg nav-agenda'
        style={{ position: 'fixed' }}>
        <NavLink className='navbar-brand' to='/'>
          <div>{/* <img src={logoNav} className='logo-nav' alt='logoNav' /> */}</div>
        </NavLink>

        <MenuFullPage icon={iconMenu} className />
        <button
          className='navbar-toggler'
          type='button'
          data-toggle='collapse'
          data-target='#navbarCollapse'
          aria-controls='navbarCollapse'
          aria-expanded='false'
          aria-label='Toggle navigation'>
          <span className='navbar-toggler-icon' />
        </button>

        <div className='collapse navbar-collapse' id='navbarCollapse'>
          <div className='nav-grid'>
            <ul className='navbar-nav mr-auto'>
              <li className='nav-item'>
                <NavLink className='nav-link nav-link-light' to='/good-practices-map'>
                  BANCO DE ACCIONES
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink className='nav-link nav-link-light' to='/register-action'>
                  COMPARTE ACCIONES
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink className='nav-link nav-link-light' to='/metodology'>
                  TARJETAS GTA2030
                </NavLink>
              </li>
            </ul>
          </div>

          <div className='nav-grid'>
            <ul className='navbar-nav'>
              <li className='nav-item dropdown'>
                <a
                  className='nav-link dropdown-toggle'
                  href={() => false}
                  id='navbarDropdown'
                  role='button'
                  data-toggle='dropdown'
                  aria-haspopup='true'
                  aria-expanded='false'>
                  ACERCA DE
                </a>
                <div
                  className='dropdown-menu dropdown-agenda'
                  aria-labelledby='navbarDropdown'
                  style={{ paddingBottom: '10px', paddingRight: '10px' }}>
                  <Link className='dropdown-item' to='/about'>
                    ACERCA DE
                  </Link>
                  <Link className='dropdown-item' to='/solution-map'>
                    NUESTROS OBJETIVOS
                  </Link>
                  <Link className='dropdown-item' to='/priorization'>
                    ¿CÓMO PARTICIPAR?
                  </Link>
                  <Link className='dropdown-item' to='/implementation'>
                    FAQS
                  </Link>
                  {/* <Link
                      className='dropdown-item dropdown-subitem'
                      to='/implementation-guides'>
                      Guías de implementación
                    </Link>
                    <Link className='dropdown-item dropdown-subitem' to='/implementation'>
                      Implementación
                    </Link> */}
                </div>
              </li>
            </ul>
          </div>

          <div className='nav-grid'>
            <ul className='navbar-nav mr-auto'>
              <li className='nav-item'>
                <NavLink className='nav-link' to='/lol'>
                  EN CIFRAS
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink className='nav-link' to='/lo'>
                  PUBLICACIONES
                </NavLink>
              </li>
            </ul>
          </div>

          <div className='nav-grid'>
            <ul className='navbar-nav mr-auto'>
              {/* <li className='nav-item'>
              <NavLink className='nav-link' to='/glossary'>
                Glosario
              </NavLink>
            </li> */}
              <li className='nav-item'>
                <NavLink className='nav-link' to='/contact'>
                  CONTACTO
                </NavLink>
              </li>
              {!isLogin && (
                <li className='nav-item'>
                  <NavLink className='nav-link' to='/register'>
                    REGISTRO
                  </NavLink>
                </li>
              )}
              {isLogin && (
                <>
                  <li className='nav-item'>
                    <NavLink className='nav-link' to={`/profile/${userId}`}>
                      PERFIL
                    </NavLink>
                  </li>
                  <li className='nav-item'>
                    <img
                      className='img-profile rounded-circle'
                      src={profile || profileDefault}
                      alt='profile'
                    />
                  </li>
                </>
              )}

              {!isLogin ? (
                <li className='nav-item'>
                  <NavLink className='nav-link' to='/login'>
                    INICIAR SESIÓN
                  </NavLink>
                </li>
              ) : (
                <li className='nav-item'>
                  <NavLink className='nav-link' onClick={handleLogout} to='/!'>
                    CERRAR SESIÓN
                  </NavLink>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </>
  )
}

const mapStateToProps = state => {
  return {
    isLogin: state.auth.isLogin,
    userId: state.user._id,
    profile: state.user.thumbnail
  }
}
const mapDispatchToProps = {
  loginLogoutRequest: loginLogoutRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar)
