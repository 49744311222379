// TYPES
export const COMPANY_CREATE_REQUEST = 'COMPANY_CREATE_REQUEST'
export const COMPANY_CREATE_SUCCESS = 'COMPANY_CREATE_SUCCESS'
export const COMPANY_CREATE_FAILURE = 'COMPANY_CREATE_FAILURE'

export const COMPANY_GET_ALL_REQUEST = 'COMPANY_GET_ALL_REQUEST'
export const COMPANY_GET_ALL_SUCCESS = 'COMPANY_GET_ALL_SUCCESS'
export const COMPANY_GET_ALL_FAILURE = 'COMPANY_GET_ALL_FAILURE'

export const COMPANY_REMOVE_REQUEST = 'COMPANY_REMOVE_REQUEST'
export const COMPANY_REMOVE_SUCCESS = 'COMPANY_REMOVE_SUCCESS'
export const COMPANY_REMOVE_FAILURE = 'COMPANY_REMOVE_FAILURE'

export const COMPANY_EDIT_REQUEST = 'COMPANY_EDIT_REQUEST'
export const COMPANY_EDIT_SUCCESS = 'COMPANY_EDIT_SUCCESS'
export const COMPANY_EDIT_FAILURE = 'COMPANY_EDIT_FAILURE'

export const COMPANY_GET_ONE_REQUEST = 'COMPANY_GET_ONE_REQUEST'
export const COMPANY_GET_ONE_SUCCESS = 'COMPANY_GET_ONE_SUCCESS'
export const COMPANY_GET_ONE_FAILURE = 'COMPANY_GET_ONE_FAILURE'

export const typesCompany = {
  companyListRequest: '[ COMPANY ] COMPANY_LIST_REQUEST',
  companyListSuccess: '[ COMPANY ] COMPANY_LIST_SUCESS',
  companyListError: '[ COMPANY ] COMPANY_LIST_ERROR',

  companyRankingListRequest: '[ COMPANY RANKING ] COMPANY_RANKING_LIST_REQUEST',
  companyRankingListSuccess: '[ COMPANY RANKING ] COMPANY_RANKING_LIST_SUCESS',
  companyRankingListError: '[ COMPANY RANKING ] COMPANY_RANKING_LIST_ERROR',

  companyFilterChangeRequest: '[ COMPANY RANKING ] COMPANY_FILTER_CHANGE_REQUEST',
  companyFilterChangeSuccess: '[ COMPANY RANKING ] COMPANY_FILTER_CHANGE_SUCCESS',
  companyFilterChangeError: '[ COMPANY RANKING ] COMPANY_FILTER_CHANGE_ERROR'
}

// ACTIONS
export const companyRankingGetAllRequest = filters => ({
  type: typesCompany.companyRankingListRequest,
  filters
})

export const companyRankingListError = payload => ({
  type: typesCompany.companyRankingListError,
  payload
})
export const companyRankingListSuccess = payload => ({
  type: typesCompany.companyRankingListSuccess,
  payload
})

export const companyGetAllRequest = page => ({ type: COMPANY_GET_ALL_REQUEST, page })

export const companyListRequest = filters => ({
  type: typesCompany.companyListRequest,
  filters
})
export const companyListSuccess = payload => ({
  type: typesCompany.companyListSuccess,
  payload
})
export const companyListError = payload => ({ type: typesCompany.companyListError, payload })

export const companyFiltersChangeRequest = filters => ({
  type: typesCompany.companyFilterChangeRequest,
  filters
})

const initialState = {
  companyList: {},
  loading: false,
  loader: false,
  error: '',
  filters: {}
}

export function company(state = initialState, action) {
  switch (action.type) {
    case typesCompany.companyListRequest:
    case typesCompany.companyRankingListRequest:
    case COMPANY_CREATE_REQUEST:
    case COMPANY_GET_ALL_REQUEST:
    case typesCompany.companyFilterChangeRequest:
    case COMPANY_REMOVE_REQUEST:
    case COMPANY_EDIT_REQUEST:
    case COMPANY_GET_ONE_REQUEST:
      return { ...state, error: null, loading: true }

    case typesCompany.companyListSuccess:
      return { ...state, loading: false, companyList: action.payload }
    case typesCompany.companyRankingListSuccess:
      return { ...state, loading: false, companyRankingList: action.payload, filters: {} }
    case COMPANY_CREATE_SUCCESS:
      return { ...state, error: null, loading: false, one: [action.payload] }
    case COMPANY_GET_ALL_SUCCESS:
      return { ...state, error: null, loading: false, all: { ...action.payload } }
    case COMPANY_REMOVE_SUCCESS:
    case COMPANY_EDIT_SUCCESS:
    case COMPANY_GET_ONE_SUCCESS:
      return { ...state, error: null, loading: false, one: { ...action.payload } }
    case typesCompany.companyFilterChangeSuccess:
      return { ...state, error: null, loading: false, filters: { ...action.payload } }

    case typesCompany.companyListError:
    case COMPANY_GET_ALL_FAILURE:
    case typesCompany.companyRankingListError:
    case COMPANY_CREATE_FAILURE:
    case COMPANY_REMOVE_FAILURE:
    case COMPANY_GET_ONE_FAILURE:
    case typesCompany.companyFilterChangeError:
    case COMPANY_EDIT_FAILURE:
      return { ...state, loading: false, error: action.payload }

    default:
      return state
  }
}
