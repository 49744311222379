import React from 'react'
import styled from 'styled-components'

export const Main = styled.div`
  background-color: #f2f2f2;
  display: flex;
  flex-flow: column wrap;
`
export const TopBlueSection = styled.section`
  flex: 1;
  width: 100%;
  height: auto;
  padding: 80px 304px 60px;
  justify-content: center;
  background-color: #0a121d;
  font-family: Roboto;
  font-size: 38px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
`

export const Container = styled.section`
  align-self: center;
  min-height: 666px;
  padding: 9% 10% 15%;
`

export const Row = styled.div`
  &::after {
    content: '';
    clear: both;
    display: table;
  }
`

function getWidth(span) {
  if (!span) return
  let width = (span / 12) * 100
  return `width: ${width}%`
}

const Column = styled.div`
  float: left;
  background-color: #f2f2f2;
  color: #0a121d;
  font-size: 38px;
  border: 1px solid #000000;
  // extra small screens
  ${({ xs }) => (xs ? getWidth(xs) : 'width: 100%')}
  // small screens
  @media only screen and (min-width: 768px) {
    ${({ sm }) => sm && getWidth(sm)}
  }
  // medium screens
  @media only screen and (min-width: 992px) {
    ${({ md }) => md && getWidth(md)}
  }
  // everything bigger than that
  @media only screen and (min-width: 992px) {
    ${({ lg }) => lg && getWidth(lg)}
  }
`

function Grid(props) {
  return (
    <Row>
      <Column md='8' sm='6' xs='12'>
        md: 8 - sm: 6 - xs: 12
      </Column>
      <Column md='4' xs='6'>
        md: 4 - xs: 6
      </Column>
      <Column md='6' sm='6' xs='6'>
        md: 6 - sm: 6 - xs: 6
      </Column>
      <Column md='6' sm='6' xs='6'>
        md: 6 - sm: 6 - xs: 6
      </Column>
      <Column md='12' sm='12' xs='12'>
        md: 12 - sm: 12 - xs: 12
      </Column>
      <Column md='12' sm='12' xs='12'>
        md: 12 - sm: 12 - xs: 12
      </Column>
    </Row>
  )
}

export default Grid
