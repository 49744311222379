import React from 'react'
import FormInput from './../Helpers/FormInput.js'

export default function LoginFormEmailPassword(props) {
  return (
    <div>
      <div className='row gutters login__input'>
        <div className='login__questioni'>{props.iconQuestion}</div>
        <FormInput
          id='email'
          type='fullwidth'
          onChange={props.handleChange}
          label='Correo'
          value={props.values.email}
        />
      </div>
      <div className='row gutters login__input'>
        <div className='login__questioni'>{props.iconQuestion}</div>
        <FormInput
          id='password'
          type='password'
          onChange={props.handleChange}
          label='Ingrese contraseña'
          values={props.values.password}
        />
      </div>
    </div>
  )
}
