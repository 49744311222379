import styled from 'styled-components'
import { Row } from 'reactstrap'

export const FooterContent = styled.footer`
  background: #131a27;
  margin-top: auto;
  padding-bottom: 20px;
  max-width: 100vw;
`

export const FooterSection = styled(Row)`
  padding: 1rem 0;
  display: flex;
  margin: 0px;
  justify-content: space-around;
`

export const FooterSectionLogo = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    width: 100%;
    padding-left: 8px;
  }
`
