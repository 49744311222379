import React from 'react'
import PropTypes from 'prop-types'
import { BannerButton, ButtonLink, BannerInfoButton } from './headerStyleComponents'

const ButtonsBanner = ({ btn, paragraph}) => {
  return (
    <BannerButton>   
      <BannerInfoButton>{paragraph}</BannerInfoButton>
      <ButtonLink href='/register-action' className='btn btn-block map-modal-info-btn'>{btn}</ButtonLink>
    </BannerButton>
  )
}

ButtonsBanner.propTypes = {
  btn: PropTypes.string,
  paragraph: PropTypes.string
}

ButtonsBanner.defaultProps = {
  paragraph: '¿Viste alguna idea que te haya inspirado?',
  btn:'Impleméntala y compártela en el BANCO DE ACCCIONES'
}

export default ButtonsBanner
