import React, { useEffect } from 'react'
import { faFileAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { actionGetTotals } from 'components/Join/joinAction.redux'
import { connect } from 'react-redux'

import HomeCounter from './HomeCounter'
import HomeLinks from './HomeLinks'
import HomeTitleColabora from './HomeTitleColabora'
import HomeDraft from './HomeDraft'
import HomeVideo from './HomeVideo'
import HomeTitleColabora2024 from './HomeTitleColabora2024'

const HomeContainer = ({ totalImplemented, actionGetTotals }) => {
  useEffect(() => {
    actionGetTotals()
  }, [actionGetTotals])

  const iconBookmarkActive = <FontAwesomeIcon size='1x' color={'#e5e5e5'} icon={faFileAlt} />
  return (
    <div className='home-div-principal'>
      <div className='home-div-principal-img'>
        {/* <HomeTitleColabora /> */}
        <HomeTitleColabora2024 />
        {/* <div style={{ height: '640px' }} /> */}
        <HomeCounter totalImplemented={totalImplemented}></HomeCounter>
        <HomeLinks iconBookmarkActive={iconBookmarkActive}></HomeLinks>
        <HomeVideo />
      </div>
    </div>
  )
}

HomeContainer.propTypes = {}

const mapStateToProps = ({ joinActions }) => ({
  totalImplemented: joinActions.totals.totalAction
})

const mapDispatchToProps = {
  actionGetTotals: actionGetTotals
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer)
