import React from 'react'
import glossarymap from './../../assets/img/glossary_map.svg'

const GlossaryPermissions = () => {
  return (
    <div className='glossary-grid' style={{ marginBottom: '200px' }}>
      <div className='glossary-contain'>
        <div className='row'>
          <div className='col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 glossary-left_description'>
            <h3 className='glossary-subtitle'>Permisos de la plataforma</h3>
            <div className='glossary-subtitle_bg' style={{ backgroundColor: '#7c518e' }}>
              <p className='glossary-subs_txt'>Administrador general(super usuario)</p>
            </div>
            <p className='glossary-description'>- Acceso Total</p>

            <div className='glossary-subtitle_bg' style={{ backgroundColor: '#41908e' }}>
              <p className='glossary-subs_txt'>Administradores</p>
            </div>
            <p className='glossary-description'>
              - Acceso a la información de cuestionarios <br /> - Acceso a los ejes de trabajo,
              nuevos usuarios y autorización de flashcards.
            </p>

            <div className='glossary-subtitle_bg' style={{ backgroundColor: '#db6d35' }}>
              <p className='glossary-subs_txt'>Participantes</p>
            </div>
            <p className='glossary-description'>
              - Acceso a consultar la información <br /> - Acceso a la captura de
              cuestionarios, mapeo de soluciones, priorización e implementación.
            </p>

            <div className='glossary-subtitle_bg' style={{ backgroundColor: '#d84747' }}>
              <p className='glossary-subs_txt'>Público en general</p>
            </div>
            <p className='glossary-description'>- Acceso a la consulta de información</p>

            <div className='glossary-subtitle_bg' style={{ backgroundColor: '#6baf6d' }}>
              <p className='glossary-subs_txt'>Público en general registrado</p>
            </div>
            <p className='glossary-description'>
              - Acceso a la consulta de información
              <br />
              - Acceso a guardar tarjetas
              <br />- Acceso a hacer comentarios y likes
            </p>
          </div>
          <div className='glossary_map'>
            <img className='glossary_map-img img-fluid' src={glossarymap} alt='' />
          </div>
          {/* <div className='col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12'></div> */}
        </div>
      </div>
    </div>
  )
}

export default GlossaryPermissions
