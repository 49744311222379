import React from 'react'
import {
  Container,
  DarkContainer,
  DivLine,
  Title,
  Paragraph,
  ParagraphSpace,
  ParagraphNote
} from 'components/HowParticipate/howParticipateStyled'
import HowParticipateRectangle from './HowParticipateRectangle'
import { Link } from 'react-router-dom'
import { Row, Col } from 'reactstrap'
const HowParticipate = () => {
  return (
    <>
      <Title>Comparte tus acciones en 4 sencillos pasos:</Title>
      <HowParticipateRectangle
        number='1'
        text={[
          <Link to='/register' style={{ color: '#749bc2' }}>
            <u style={{ fontWeight: 'bold' }}>Regístrate</u>
          </Link>,
          <strong style={{ color: '#1e3250', fontWeight: 'bold' }}> en la plataforma </strong>
        ]}
        topCircle='78%'
      />
      <HowParticipateRectangle
        number='2'
        topCircle='93%'
        text={[
          'Lee atentamente nuestro ',
          <Link to='/page-selection' style={{ color: '#749bc2' }}>
            <u style={{ fontWeight: 'bold' }}>Procedimiento de Selección de Acciones</u>
          </Link>,
          ' para conocer los requisitos necesarios para que tu buena práctica sea validada por Pacto Global México y sea publicada.'
        ]}
      />
      <HowParticipateRectangle
        number='3'
        topCircle='113%'
        text={[
          <strong style={{ color: '#1e3250', fontWeight: 'bold' }}>
            Comparte tu acción{' '}
          </strong>,
          'completando el formulario, adjuntando una fotografía y el logo de tu organización en caso de que quieras personalizarla.'
        ]}
      />
      <HowParticipateRectangle
        number='4'
        topCircle='133%'
        text={[
          'Espera a que el equipo de Pacto Global México',
          <strong style={{ color: '#1e3250', fontWeight: 'bold' }}> valide tu acción </strong>,
          'y sea publicada en la plataforma. Recibirás un correo de confirmación.'
        ]}
      />
      <Container
        marginBottom='5%'
        marginTop='5%'
        marginLeft='10%'
        width='80%'
        textAlign='center'>
        <Paragraph>
          El papel del sector privado es primordial. La integración de los principios y
          criterios de sostenibilidad en sus operaciones y prácticas corporativas no solo es un
          imperativo ético, sino que también es un buen negocio a mediano y largo plazo. Es
          urgente proporcionar nuevos modelos de productividad y gestión que contribuyan a la
          mejora económica, social y medioambiental de nuestro planeta.
        </Paragraph>
      </Container>
      <Container marginTop='4%' width='80%' color='#699cc6' textAlign='center'>
        <Paragraph style={{ fontWeight: '900', marginBottom: '100px' }}>
          ¡Toma la iniciativa y comparte tus acciones en sostenibilidad!
        </Paragraph>
      </Container>
      {/* <Container marginBottom='5%' width='65%'>
        <Row>
          <Col lg='1' style={{ marginLeft: '50px' }}>
            <DivLine />
          </Col>
          <Col lg='11' className='note-participate'>
            <ParagraphNote>
              Todas las Buenas Prácticas compartidas en LOGRA, acceden al proceso de Premiación
              organizado por Pacto Global México.
            </ParagraphNote>
          </Col>
        </Row>
      </Container> */}
    </>
  )
}

export default HowParticipate
