import React from 'react'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { connect } from 'react-redux'

import facebookLogo from './../../assets/img/logos/facebook.svg'
import { registersCreateRequest } from 'components/Users/register.redux'

const LoginFacebook = ({ registersCreateRequest }) => {
  const responseFacebook = response => {
    if (response) {
      const { userID, name, picture, email } = response
      var arrayName = name.split(' ')
      const values = {
        email,
        name: arrayName[0],
        lastName: arrayName[1] + ' ',
        providerId: userID,
        thumbnail: picture?.data?.url,
        validated: true,
        provider: 'facebook'
      }
      registersCreateRequest({ values })
    }
  }

  return (
    <FacebookLogin
      appId={process.env.REACT_APP_FACEBOOK_APP_ID}
      autoLoad={false}
      fields='name,email,picture'
      callback={responseFacebook}
      render={renderProps => (
        <img
          onClick={renderProps.onClick}
          disabled={renderProps.disabled}
          src={facebookLogo}
          className='social-media-logo-google'
          alt=''
        />
      )}
    />
  )
}

const mapDispatchToProps = {
  registersCreateRequest
}

export default connect(null, mapDispatchToProps)(LoginFacebook)
