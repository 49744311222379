import React from 'react'
import CardDetail from '../Cards/CardDetail'
import Layout from './../Layout/Layout'
export default function PageCardDetail(props) {
  return (
    <div className='priorization-page-detail'>
      <Layout>
        <CardDetail {...props} />
      </Layout>
    </div>
  )
}
