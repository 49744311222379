import { Upload } from 'antd'
import ImgCrop from 'antd-img-crop'
import { Field, Form, Formik } from 'formik'
import React from 'react'
import { Button } from 'reactstrap'
import { connect } from 'react-redux'
import { userUpdateRequest } from './users.redux'
const ProfilePicture = ({ profileuser, ...props }) => {
  const handleEditProfile = values => {
    props.userUpdateRequest({ values })
  }
  const onPreview = async file => {
    let src = file.url
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader()
        reader.readAsDataURL(file.originFileObj)
        reader.onload = () => resolve(reader.result)
      })
    }
    const image = new Image()
    image.src = src
    const imgWindow = window.open(src)
    if (imgWindow) {
      imgWindow.document.write(image.outerHTML)
    } else {
      window.location.href = src
    }
  }
  let initialValuesData = {
    _id: profileuser?._id,
    name: profileuser?.name,
    lastName: profileuser?.lastName,
    email: profileuser?.email,
    gender: profileuser?.gender,
    company: profileuser?.company?.name,
    sector: profileuser?.sector?._id,
    state: profileuser?.state,
    country: profileuser?.country,
    city: profileuser?.city,
    telephone: profileuser?.telephone,
    thumbnail: '',
    imageFile: {}
  }
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValuesData}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            handleEditProfile(values)
            setSubmitting(false)
          }, 600)
        }}>
        {({ values, setFieldValue }) => (
          <Form encType='multipart/form-data'>
            <ImgCrop rotate onUploadFail='null'>
              <Field
                component={Upload}
                error={false}
                listType='picture-card'
                fileList={values.avatars}
                value={values.thumbnail}
                onChange={({ fileList: newFileList }) => {
                  if (!newFileList[0]) {
                    setFieldValue('imageFile', '', values)
                    setFieldValue('thumbnail', '', values)
                  } else {
                    setFieldValue('imageFile', newFileList[0].originFileObj, values)

                    setFieldValue('thumbnail', newFileList[0].name, values)
                  }
                }}
                onPreview={onPreview}>
                {values.thumbnail.length < 1 && '+'}
              </Field>
            </ImgCrop>
            {values.thumbnail.length > 0 ? <Button type='submit'>Actualizar</Button> : null}
          </Form>
        )}
      </Formik>
    </>
  )
}
const mapDispatchToProps = {
  userUpdateRequest: userUpdateRequest
}

export default connect(null, mapDispatchToProps)(ProfilePicture)
