import React from 'react'
import ContactForm from './ContactForm'

export const Contact = () => {
  return (
    <div className='col-lg-12'>
      <div className='user__contact-form'>
        <ContactForm />
      </div>
    </div>
  )
}

export default Contact
