import React from 'react'
import LayoutOne from 'layouts/LayoutOne/LayoutOne'
import HeaderBanner from 'components/Header/HeaderBanner'
import HomeDraft from 'components/Home/HomeDraft'

const PageOne = props => {
  return (
    <LayoutOne>
      <HeaderBanner title='Página 1' />
      <HomeDraft />
    </LayoutOne>
  )
}

PageOne.propTypes = {}

export default PageOne
