import React, { useEffect, useState } from 'react'
import FormInput from '../Helpers/FormInput'
import { Container, Row, Col } from 'reactstrap'
import { Field, Form, Formik } from 'formik'
import { Link } from 'react-router-dom'
import { JoinValidationSchema } from './JoinValidationSchema'
import { cardsFiltersChangeRequest, cardGetCatalogs } from './../Cards/cards.redux.js'
import { actionCreateRequest } from './joinAction.redux'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'

import DragAndDropUploadFile, {
  ContentCenter,
  FormLabel,
  FormLabelWhite,
  RowForm,
  validateImage
} from 'components/DragAndDropUploadFile/DragAndDropUploadFile'

const JoinActionForm = ({
  actionCreateRequest,
  cardGetCatalogs,
  catalogs,
  user,
  setShowSplash,
  loading
}) => {
  useEffect(() => {
    cardGetCatalogs()
  }, [cardGetCatalogs])

  useEffect(() => {
    !loading && setShowSplash(false)
  }, [loading])

  const [ambitState, setAmbitState] = useState(false)

  const setUploadFileLogo = (event, values) => {
    if (validateImage(event.target.files[0])) {
      values.logoFile = event.target.files[0]
      return true
    }
    return false
  }

  const setUploadFileImage = (event, values) => {
    if (validateImage(event.target.files[0])) {
      values.imageFile = event.target.files[0]
      return true
    }
    return false
  }

  const handleCreate = values => {
    values.user = user
    setAmbitState(false)
    if (!values?.otherAmbit && values?.businessJoinType?.length === 0) {
      setAmbitState(true)
      setShowSplash(false)
      return Swal.fire(
        'Alerta',
        'Debe ingresar un ámbito de la implementación antes de poder generar una iniciativa.',
        'info'
      )
    }
    if (!values?.implementationTime) {
      setShowSplash(false)
      values.implementationTime = undefined
    }
    if (!values?.checked) {
      setShowSplash(false)
      return Swal.fire(
        'Alerta',
        'Debe aceptar los términos y condiciones antes de continuar.',
        'info'
      )
    }
    if (!values?.user) {
      setShowSplash(false)
      return Swal.fire(
        'Alerta',
        'Debe iniciar sesión antes de poder generar una iniciativa.',
        'info'
      )
    }

    if (values?.ubication === '') {
      setShowSplash(false)
      return Swal.fire(
        'Alerta',
        'Debe ingresar una ubicación antes de poder generar una iniciativa.',
        'info'
      )
    }
    values.aceptVolunteers = values.aceptVolunteers === 'Sí' ? true : false
    values.shareName = values.shareName === 'Sí' ? true : false
    values.involveVolunteers = values.involveVolunteers === 'Sí' ? true : false
    values.numberOfVolunteers = values.numberOfVolunteers || '0'
    actionCreateRequest({ values })
  }
  const businessJoinTypes = catalogs.businessJoinTypes?.data

  const [lat, setLat] = useState(null)
  const [lng, setLng] = useState(null)
  return (
    <>
      <Formik
        initialValues={{
          ods: '',
          axisGlobalPact: '',
          status: 0,
          numberOfVolunteers: '0',
          aceptVolunteers: '',
          implementationPeriod: '',
          name: '',
          number: 1,
          objective: '',
          ubication: '',
          lat,
          lng,
          logo: '',
          logoFile: {},
          image: '',
          imageFile: {},
          email: '',
          description: '',
          promoterInstitution: '',
          alliedInstitutions: '',
          information: '',
          businessJoinType: [],
          businessJoinSubType: '',
          implementationTime: '',
          indicator: '',
          shareName: '',
          involveVolunteers: '',
          user,
          checked: false
        }}
        validationSchema={JoinValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setShowSplash(true)
          values.ubication = document.getElementById('ubication').value
          values.lat = lat
          values.lng = lng
          setTimeout(() => {
            handleCreate(values)
            setSubmitting(false)
          }, 0)
        }}>
        {({ values, isSubmitting, handleChange }) => {
          const handleChangeRadio = e => (values.numberOfVolunteers = 0)
          const handleChangeShareName = e => (values.shareName = e.target.value)
          const handleInvolveVolunteers = e => (values.involveVolunteers = e.target.value)

          const handleChangeUbication = e => {
            handleChange(e.street)
            setLat(e.lat)
            setLng(e.lng)
          }
          return (
            <Form className='form join-action_form'>
              <div className='row '>
                <FormInput
                  id='name'
                  label='Nombre de la acción'
                  css='col-12 mb-3'
                  type='inputForm'
                  onChange={handleChange}
                  value={values.name}
                />
                <FormInput
                  id='objective'
                  label='Objetivos de la acción'
                  css='col-12 mb-3'
                  onChange={handleChange}
                  type='textarea'
                  value={values.objective}
                />
                <RowForm className='impacted-ods_row'>
                  <Col className='impacted-ods_join'>
                    <FormInput
                      id='ods'
                      label={'Principal ODS impactado '}
                      type='selectForm'
                      css='join-label-select-register join-label_responsive'
                      onChange={handleChange}
                      dataOptions={
                        catalogs.objectives &&
                        catalogs.objectives.data.map(({ title, _id }) => ({
                          value: _id,
                          text: title,
                          id: _id,
                          key: _id
                        }))
                      }
                      value={values.ods}
                    />
                  </Col>
                  <Col className='impacted-ods_join'>
                    <FormInput
                      id='axisGlobalPact'
                      label={'Principal eje de Pacto Global de las Naciones Unidas'}
                      type='selectForm'
                      css='join-label-select-register'
                      onChange={handleChange}
                      dataOptions={
                        catalogs.axesGlobalPact &&
                        catalogs.axesGlobalPact.data.map(({ name, _id }) => ({
                          value: _id,
                          text: name,
                          id: _id,
                          key: _id
                        }))
                      }
                      value={values.axisGlobalPact}
                    />
                  </Col>
                </RowForm>
                <FormInput
                  id='description'
                  label='Describe aquí tu acción'
                  value={values.description}
                  onChange={handleChange}
                  css='col-12 mb-4'
                  type='textarea'
                />
                <FormInput
                  id='businessJoinType'
                  onChange={e => {
                    setAmbitState(false)
                    handleChange(e)
                  }}
                  label='Ámbito de implementación'
                  type='checkbox'
                  child={
                    <FormInput
                      id='otherAmbit'
                      css='join-other-input'
                      placeholder='Otro'
                      type='inputForm'
                      onChange={e => {
                        setAmbitState(false)
                        handleChange(e)
                      }}
                      value={values.otherAmbit}
                    />
                  }
                  radioButton={businessJoinTypes}
                />
                {ambitState && (
                  <label
                    style={{
                      margin: '-48px 0 42px 5px',
                      fontWeight: '500',
                      color: 'red'
                    }}>
                    Debe indicar al menos un ámbito de implementación
                  </label>
                )}
                <FormInput
                  id='ubication'
                  onChange={handleChange}
                  label='Ubicación donde se implementó la acción'
                  css='col-12 mb-3'
                  type='autocomplete'
                  onChanges={handleChangeUbication}
                  value={values.ubication}
                />
                <FormInput
                  id='promoterInstitution'
                  label='Nombre de la empresa que implementó la acción'
                  css='col-12 mb-3'
                  type='inputForm'
                  onChange={handleChange}
                  value={values.promoterInstitution}
                />
                <FormInput
                  id='businessJoinSubType'
                  label='Tamaño de empresa que implementa'
                  type='selectForm'
                  css='join-label-select-register'
                  onChange={handleChange}
                  dataOptions={
                    catalogs.businessJoinSubtypes &&
                    catalogs.businessJoinSubtypes.data.map(({ name, _id }) => ({
                      value: _id,
                      text: name,
                      id: _id,
                      key: _id
                    }))
                  }
                  value={values.businessJoinSubType}
                />
                <FormInput
                  id='alliedInstitutions'
                  label='Otras organizaciones participantes'
                  css='col-12 mb-3'
                  type='textarea'
                  onChange={handleChange}
                  value={values.alliedInstitutions}
                />
                <FormInput
                  id='implementationPeriod'
                  label='Fecha de implementación de la acción'
                  css='col-12 mb-3'
                  type='inputForm'
                  onChange={handleChange}
                  value={values.implementationPeriod}
                />
                <FormInput
                  id='implementationTime'
                  label='Duración de la iniciativa'
                  css='join-label-select-register'
                  type='selectForm'
                  onChange={handleChange}
                  dataOptions={
                    catalogs.timeInitiatives &&
                    catalogs.timeInitiatives.data.map(({ name, _id }) => ({
                      value: _id,
                      text: name,
                      id: _id,
                      key: _id
                    }))
                  }
                  value={values.implementationTime}
                />
                <FormInput
                  id='indicator'
                  label='¿Qué indicador utilizas para medir el impacto de esta acción?'
                  css='col-12 mb-3'
                  type='inputForm'
                  onChange={handleChange}
                  value={values.indicator}
                />
                <FormInput
                  id='information'
                  label='¿Dónde se puede encontrar más información sobre esta acción?'
                  css='col-12 mb-3'
                  type='textarea'
                  onChange={handleChange}
                  value={values.information}
                />
                <FormInput
                  id='email'
                  label='Correo de contacto para compartir con las personas interesadas en esta iniciativa (al compartirlo, será visible públicamente)'
                  css='col-12 mb-3'
                  type='inputForm'
                  onChange={handleChange}
                  value={values.email}
                />
                {/* -- Involucra voluntarios -- */}

                <RowForm className='accept-join_check'>
                  <Col>
                    <FormInput
                      id='involveVolunteers'
                      css='col-12 mb-6'
                      label='¿Tu acción involucra a personas voluntarias?'
                      onChange={handleInvolveVolunteers}
                      type='yesNo'
                    />
                  </Col>
                  {values.involveVolunteers === 'Sí' && (
                    <Col>
                      <FormInput
                        id='numberOfVolunteers'
                        label='¿Cuántos voluntarios participan actualmente en la acción?'
                        css='col-12 mb-6'
                        type='number'
                        onChange={handleChange}
                        value={values.numberOfVolunteers}
                      />
                    </Col>
                  )}
                </RowForm>

                {/* Sumar voluntarios */}
                <Row className='accept-join_check'>
                  <FormInput
                    id='aceptVolunteers'
                    css='col-12 mb-3'
                    label='¿Quieres sumar voluntarios? (Si aceptas, se activará un registro de voluntarios)'
                    onChange={handleChangeRadio}
                    type='yesNo'
                  />
                </Row>

                {/* Imputs de carga */}
                <RowForm className='row-join_files'>
                  <Col>
                    <FormLabel style={{ minHeight: '48px' }}>
                      ¿Quieres acompañar tu buena práctica con tu logotipo?
                    </FormLabel>
                    <DragAndDropUploadFile
                      idImage='logo'
                      isImageUploaded={values.logo === '' ? false : true}
                      value={values.logo}
                      handleChange={() => (values.logo = '')}
                      onChange={e => {
                        setUploadFileLogo(e, values) && handleChange(e)
                      }}
                    />
                  </Col>
                  <Col>
                    <FormLabel style={{ minHeight: '48px' }}>
                      ¿Quieres acompañar tu buena práctica con una fotografía?
                    </FormLabel>
                    <DragAndDropUploadFile
                      idImage='image'
                      leyend={true}
                      isImageUploaded={values.image === '' ? false : true}
                      value={values.image}
                      handleChange={() => (values.image = '')}
                      onChange={e => {
                        setUploadFileImage(e, values) && handleChange(e)
                      }}
                    />
                  </Col>
                </RowForm>

                <RowForm className='join-action-checkboxes_form'>
                  <Col>
                    <FormInput
                      id='shareName'
                      css='col-12 mb-3'
                      label='¿Quieres que se comparta públicamente tu nombre como parte de esta acción? '
                      onChange={handleChangeShareName}
                      type='yesNo'
                    />
                  </Col>
                  {values.shareName === 'Sí' && (
                    <Col>
                      {/* -- PERSONA QUE EMITE LA INICIATIVA -- */}
                      <FormLabel> </FormLabel>

                      <FormLabelWhite style={{ marginTop: '66px' }} value={values.user}>
                        {user}
                      </FormLabelWhite>
                    </Col>
                  )}
                </RowForm>
              </div>

              <ContentCenter className='join-actions_terns' joinActions>
                <FormLabel joinActions>
                  <Field type='checkbox' name='checked' />
                  {'   '}
                  <span style={{ fontWeight: 'normal' }}>Acepto los </span>
                  <Link to={'/legal-disclaimer'} target='_blanks'>
                    <span style={{ fontWeight: 'bold' }}>Términos y condiciones </span>
                  </Link>
                  <span style={{ fontWeight: 'normal' }}>y el </span>
                  <Link to={'/privacy-notice'} target='_blank'>
                    <span style={{ fontWeight: 'bold' }}>Aviso de Privacidad</span>
                  </Link>
                </FormLabel>
              </ContentCenter>

              <Row>
                <button
                  type='submit'
                  className='btn btn-primary btn-block mt-3'
                  disabled={isSubmitting}>
                  {'Compartir acción'}
                </button>
              </Row>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

const mapStateToProps = ({ cards, user, joinActions }) => ({
  catalogs: cards.catalogs,
  user: user.name,
  loading: joinActions.loading
})

const mapDispatchToProps = {
  actionCreateRequest,
  cardGetCatalogs,
  cardsFiltersChangeRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(JoinActionForm)
