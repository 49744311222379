/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import CardDetailFront from './CardDetailFront.js'
import { priorizationGetAverageRequest, priorizationGetOneRequest } from './Priorization.redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { cardGetOneRequest } from './cards.redux.js'
import { getRating } from './../../utils/priorization.js'

const PriorizationDetail = ({
  cardGetOneRequest,
  priorizationGetAverageRequest,
  ...props
}) => {
  const { _id } = props.match.params
  useEffect(() => {
    cardGetOneRequest({ _id })
    priorizationGetAverageRequest()
  }, [priorizationGetAverageRequest, cardGetOneRequest, _id])

  const icon_Star = (
    <FontAwesomeIcon style={{ fontSize: '3px' }} color='#ffe200' icon={faStar} />
  )
  const card = props.card || {}
  const priorizationAvg = props.average || {}
  const filterAverage = priorizationAvg?.docs
    ?.map(a => a)
    .filter(f => f._id.flashcardId === card._id)
  const { gta, number } = card
  const { className } = props
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)
  const bgColor = gta?.color
  const getLength = filterAverage?.map(a => a).length
  return (
    <div>
      <a
        color='danger'
        href={() => false}
        onClick={toggle}
        style={{ color: '#000', cursor: 'pointer', whiteSpace: 'nowrap' }}>
        {getLength === 0 ? null : 'Ver más'}
      </a>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader style={{ color: bgColor }}>
          <p>{gta?.title}</p>
          <p>
            Flashcards #{gta?.nomenclature}
            {number}
          </p>
        </ModalHeader>
        <ModalBody>
          <div className='row card-priorization-detail'>
            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 priorization-detail-card'>
              <CardDetailFront data={card} />
            </div>
            {filterAverage?.map(p => (
              <>
                <div className='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 priorization-rating'>
                  <div>
                    <p
                      className='impacto-title-priorization'
                      style={{ color: '#131a27', fontSize: '18px', fontWeight: 500 }}>
                      Impacto
                    </p>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p>Cobertura</p>
                    <div className='icon_stars-priorization'>
                      {getRating(p.coverage, icon_Star)}
                    </div>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p>Impacto Externo</p>
                    <div className='icon_stars-priorization'>
                      {getRating(p.impact, icon_Star)}
                    </div>
                  </div>
                  <hr style={{ width: '485px', marginTop: '40px' }} />
                  <div>
                    <p
                      className='implementation-title-priorization'
                      style={{
                        color: '#131a27',
                        fontSize: '18px',
                        fontWeight: 500,
                        marginTop: '50px'
                      }}>
                      Facilidad de implementación
                    </p>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p>Presupuesto</p>
                    <div className='icon_stars-priorization'>
                      {getRating(p.budget, icon_Star)}
                    </div>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p>Infraestructura</p>
                    <div className='icon_stars-priorization'>
                      {getRating(p.infraestructure, icon_Star)}
                    </div>
                  </div>
                </div>
                <div className='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 priorization-rating'>
                  <div>
                    <p>
                      <br />
                    </p>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginTop: '20px'
                    }}>
                    <p>Replicabilidad</p>
                    <div className='icon_stars-priorization'>
                      {getRating(p.replicabilty, icon_Star)}
                    </div>
                  </div>
                  <br />
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginTop: '-25px'
                    }}>
                    <p>Beneficio Interno</p>
                    <div className='icon_stars-priorization'>
                      {getRating(p.benefit, icon_Star)}
                    </div>
                  </div>
                  <div>
                    <p
                      style={{
                        color: '#131a27',
                        fontSize: '18px',
                        fontWeight: 500,
                        marginTop: '25px'
                      }}>
                      <br />
                    </p>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginTop: '108px'
                    }}>
                    <p>Recursos Humanos</p>
                    <div className='icon_stars-priorization'>
                      {getRating(p.humanResources, icon_Star)}
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
        </ModalBody>
        {filterAverage?.map(p => (
          <div className='modal-footer_priorization'>
            <ModalFooter style={{ backgroundColor: bgColor }}>
              <div className='row'>
                <div className='col-4'>
                  <span>Impacto</span>
                  {getRating(p.impactTotal, icon_Star)}
                </div>
                <div className='col-4'>
                  <span>Implementación</span>
                  {getRating(p.implementationTotal, icon_Star)}
                </div>
                <div className='col-4'>
                  <span>Total</span>
                  {getRating(p.total, icon_Star)}
                </div>
              </div>
            </ModalFooter>
          </div>
        ))}
      </Modal>
    </div>
  )
}

const mapStateToProps = state => ({
  priorizations: state.priorizations,
  average: state.priorizations.average,
  one: state.priorizations.one,
  card: state.cards.one
})

const mapDispatchToProps = {
  cardGetOneRequest: cardGetOneRequest,
  priorizationGetOneRequest: priorizationGetOneRequest,
  priorizationGetAverageRequest: priorizationGetAverageRequest
}

export default connect(mapStateToProps, mapDispatchToProps)(PriorizationDetail)
