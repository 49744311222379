import React from 'react'
import PropTypes from 'prop-types'
import { Banner, Boton, Description, Parrafo, SecondTitle, SubTitle, Title } from './headerStyleComponents'

const HeaderBanner = ({ img, goodPractices, title, subtitle, second, description, paragraph, btn}) => {
  return (
    <Banner
      style={{
        backgroundImage: `url(${img})`
      }}>
      <Title goodPractices={goodPractices}>{title}</Title>
      {second && <SecondTitle>{second}</SecondTitle>}
      {subtitle && <SubTitle>{subtitle}</SubTitle>}
      {description && <Description>{description}</Description>}
      {paragraph && <Parrafo>{paragraph}</Parrafo>}
      {btn && <Boton>{btn}</Boton>} 
    </Banner>
  )
}

HeaderBanner.propTypes = {
  img: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  second: PropTypes.string,
  description: PropTypes.string,
  paragraph: PropTypes.string
}

HeaderBanner.defaultProps = {
  title: 'Título',
  img: require('./../../assets/img/banners/ball.svg')
}

export default HeaderBanner
