import ReactDOM from 'react-dom'
import React from 'react'
import SnackBar from './SnackBar'
import * as fs from 'fs'

const triggerSnackbar = (title, message, messageType) => {
  const validMessageTypes = ['error', 'info', 'warning', 'success']
  if (!validMessageTypes.includes(messageType)) {
    throw Error('Tipo de mensaje inválido')
  }
  ReactDOM.render(
    <SnackBar messageType={messageType} timer={4000} title={title} message={message} />,
    document.getElementById('snackbar-fixed-container')
  )
}

export const showErrorMessage = (title, message) => {
  triggerSnackbar(title, message, 'error')
}

export const showInfoMessage = (title, message) => {
  triggerSnackbar(title, message, 'info')
}

export const showSuccessMessage = (title, message) => {
  triggerSnackbar(title, message, 'success')
}

export const showWarningMessage = (title, message) => {
  triggerSnackbar(title, message, 'warning')
}

export const randomNumber = (min, max) => Math.floor(Math.random() * (max - min + 1) + min)
export const randomByte = () => randomNumber(0, 255)
export const randomPercent = () => (randomNumber(50, 100) * 0.01).toFixed(2)
export const randomRgba = () => `rgba(${[ randomByte(), randomByte(), randomByte(), randomPercent() ].join(',')})`

export const splitName = (sentence) => {
  const totalSentence = sentence.split(' ')
  const number = totalSentence[0]
  return number
}

export const mergeArray = (first, second) => {
  return [...first, ...second].reduce((acc, val, i, arr) => {
     const { id, count } = val;
     const ind = acc.findIndex(el => el.id === id);
     if(ind !== -1){
        acc[ind].count += count;
     }else{
        acc.push({
           id,
           count
        });
     }
     return acc;
  }, []);
}


export function chartData(label, labels, dataset, colors) {
	return {
		labels: [ ...labels],
		datasets: [
			{
				label,
				data: [ ...dataset ],
				backgroundColor: (colors ? [...colors] : '#131a27') 
			}
		]
	}
}
