import React from 'react'
import RestorePasswordForm from './RestorePasswordForm.js'

export const RestorePassword = () => {
  return (
    <div>
      <div className='auth__recover'>
        <div
          className='auth__box-restore-container animate__animated animate__fadeIn animate__faster'
          style={{ maxWidth: '502px' }}>
          <RestorePasswordForm />
          <img
            className='recover__sideimg'
            src='https://source.unsplash.com/DUmFLtMeAbQ/214x392'
            alt=''
          />
        </div>
      </div>
    </div>
  )
}

export default RestorePassword
