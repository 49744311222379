import React from 'react'
import Layout from '../Layout/Layout'
import HeaderBanner from 'components/Header/HeaderBanner'
import { Container } from 'components/Header/headerStyleComponents'
import ListDropDown from 'components/ListDropDown/ListDropDown'
import { listObjectives } from 'components/ListDropDown/List'

import FooterContact from 'components/Contact/FooterContact'
import ButtonCard from 'components/Buttons/ButtonCard'
import ButtonsContainer from 'components/Buttons/ButtonsContainer'

const PageOurObjectives = () => {
  return (
    <Layout>
      {/* Banner de título */}
      <HeaderBanner title='NUESTROS OBJETIVOS' />
      <Container style={{ paddingBottom: '100px' }}>
        {/* Lista de objetivos */}
        <ListDropDown list={listObjectives} />
        {/* Botónes */}
        <ButtonsContainer>
          <ButtonCard url='/about' title={'ACERCA DE'} />
          <ButtonCard url='/how-participate' title={'¿CÓMO PARTICIPAR?'} />
          <ButtonCard url='/page-faq' title={'PREGUNTAS FRECUENTES'} />
        </ButtonsContainer>
      </Container>
      {/* Formulario de contacto */}
      <FooterContact title='¿Tienes alguna duda?' subtitle='Contáctanos' />
    </Layout>
  )
}
export default PageOurObjectives
