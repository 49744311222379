import React from 'react'
import AuthLoginForm from './LoginForm.js'

export const Login = () => {
  return (
    <div>
      <div className='auth__main'>
        <div className='auth__box-container animate__animated animate__fadeIn animate__faster'>
          <AuthLoginForm mode={undefined} />
          <img
            className='login__sideimg'
            src='https://source.unsplash.com/DUmFLtMeAbQ/170x402'
            alt=''
          />
        </div>
      </div>
    </div>
  )
}
