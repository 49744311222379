import styled from 'styled-components'

// Create a <Title> react component that renders an <h1> which is
// centered, palevioletred and sized at 1.5em
export const TitleGraphOds = styled.h1`
  font-size: 22px;
  font-weight: medium;
  color: #75a0cc;
  margin-top: 20px;

  @media (max-width: 768px) {
    margin-top: 35px;
  }
`
export const SubtitleGraphOds = styled.p`
  font-size: 18px;
  font-weight: medium;
  color: #75a0cc;
`
export const SectionGraphOds = styled.div`
  @media (max-width: 768px) {
    margin-top: 22px;
    margin-bottom: 11px;
    height: auto;
  }
`

export const ImgGraphOds = styled.img`
  height: auto;
  width: 80px;
  margin: 15px 0 auto 0;
`

export const DescriptionGraphOds = styled.p`
  font-size: 18px;
  font-weight: regular;
  text-align: left;
  color: #0a121d;
`

export const ContainerGraphOds = styled.div`
  flex: 1;
  background: #f4f4f4;
  border-top: 3px solid #75a0cc;
`

export const ContainerODS = styled.section`
  padding: 0em 10% 2em 10%;
  background: #f4f4f4;

  @media screen and (min-width: 1400px) {
    padding: 0em 15% 2em 15%;
  }

  @media screen and (max-width: 768px) {
    padding: 2px 25px 26px 27px;
    width: 100vw;
    margin-left: 12px;
  }
`

export const RowCounter = styled.div`
  background: #d4e8f7;
  color: #131a27;
  border-radius: 5px;
  margin-bottom: -80px;
  display: flex;
  width: 60%;
  position: relative;
  left: 400px;
  top: -80px;

  @media only screen and (max-width: 600px) {
    width: 100%;
    left: 0;
    top: 0;
    margin-bottom: 10px;
  }
`

export const NumberCounter = styled.h3`
  color: #131a27;
  font-size: 110px;
  text-align: center;
  font-weight: bold;
  margin: 18px;

  @media only screen and (max-width: 600px) {
    margin: 0;
    padding-left: 10px;
  }
`

export const SpaceText = styled.p`
  color: #131a27;
  text-align: center;
  font-size: 25px;
  margin: 35px;

  @media only screen and (max-width: 600px) {
    font-size: 22px;
    line-height: 26px;
    padding-top: 12px;
  }
`

export const TitleText = styled.span`
  color: #131a27;
  font-weight: bold;
  font-size: 40px;

  @media only screen and (max-width: 600px) {
    font-size: 35px;
  }
`

//Styles Podium

export const ContainerFirst = styled.section`
  padding: 2em 10% 2em 10%;
  background: #f4f4f4;
  @media only screen and (max-width: 600px) {
    padding: 2em 0;
    padding-left: 11px;
    padding-right: 11px;
  }
  @media screen and (min-width: 1400px) {
    padding: 0em 15% 2em 15%;
  }
`

export const ContainerPodium = styled.div`
  background: #f4f4f4;
`

export const FirstPlaceContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  height: ${({ first }) => first || 100};

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`
export const SecondFloorContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  top: -284px;
  margin-bottom: -250px;

  @media only screen and (max-width: 600px) {
    display: grid;
    justify-content: center;
    position: relative;
    top: 0;
    margin-bottom: 0px;
  }
`

export const ThirdPlaceContainer = styled.div`
  position: relative;
  top: 73px;

  @media only screen and (max-width: 600px) {
    top: 0;
  }
`

export const ValidatePodium = styled.h3`
  margin: 10px 0;
  text-align: center;
  color: #75a0cc;
  @media screen and (min-width: 1400px) {
    margin: 30px 0;
  }
`
