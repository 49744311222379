import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import LoadingBar from 'react-redux-loading-bar'
import SocialMediaBar from './SocialMediaBar'

function RegisterCreate(props) {
  return (
    <>
      <LoadingBar
        showFastActions
        maxProgress={100}
        progressIncrease={50}
        style={{ backgroundColor: '#7a9ec6', height: '5px', top: 0, left: 0, zIndex: 999999 }}
      />
      <div className='users__title'>
        <div>
          <h3>Crea una cuenta</h3>
        </div>
      </div>
      <span className='users__subtitle'>
        ¿Ya tienes una cuenta?{' '}
        <Link className='link-to-log-in' to='/login'>
          Iniciar sesión
        </Link>
      </span>
      <SocialMediaBar />
    </>
  )
}
const mapStateToProps = ({ register }) => ({ register })
export default connect(mapStateToProps, null)(RegisterCreate)
