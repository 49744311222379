import React from 'react'

function CompanyGtaRankingList({ gta }) {
  return (
    <div className='company-card-list-top-bar'>
      <div className='company-header-front'>
        <div className='flashcard-header-gta'>
          <img
            className='company-list-header-gta-img'
            width='100'
            src={
              gta?.imageGta
                ? require(`./../../assets/img/gtas/blanco/GTA2030_IconosColor_${gta?._id}.svg`)
                : require('./../../assets/img/gtas/blanco/GTA2030_IconosColor_1.svg')
            }
            alt='icon gta2030'
          />
        </div>
        <div className='company-list-header-number'>
          <div className='company-list-header-gta-title'>Grupo de trabajo Agenda 2030</div>
          <div className='flashcard-header-gta-name'>{gta?.frontTitle}</div>
        </div>
      </div>
    </div>
  )
}

export default CompanyGtaRankingList
