import React from 'react'
import triangle from './../../assets/img/ejesDeTrabajo/workAxis1.svg'
import circle from './../../assets/img/ejesDeTrabajo/workAxis2.svg'
import square from './../../assets/img/ejesDeTrabajo/workAxis3.svg'
import figure from './../../assets/img/ejesDeTrabajo/workAxis4.svg'
import hexagon from './../../assets/img/ejesDeTrabajo/workAxis5.svg'

function GlossaryIcons() {
  return (
    <div className='glossary-grid'>
      <div className='glossary-contain'>
        <div className='row'>
          <div className='col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 glossary-left_description'>
            <h3 className='glossary-subtitle'>Figuras por Eje de Trabajo</h3>
            <p className='glossary-description'>
              Las figuras son un elemento gráfico añadido para identificar los ejes de trabajo
              respectivos de cada Grupo de Trabajo. En la parte inferior de cada tarjeta se
              encuentra el nombre del eje de trabajo correspondiente a la tarjeta, con la
              figura se pueden identificar en una vista previa.
            </p>
          </div>
          <div className='col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12'>
            <div className='glossary-workaxis text-center'>
              <div className='glossary-img_title'>
                <img src={triangle} alt='' />
                <p>Eje 1</p>
              </div>
              <div className='glossary-img_title'>
                <img src={circle} alt='' />
                <p>Eje 2</p>
              </div>
              <div className='glossary-img_title'>
                <img src={square} alt='' />
                <p>Eje 3</p>
              </div>
              <div className='glossary-img_title'>
                <img src={figure} alt='' />
                <p>Eje 4</p>
              </div>
              <div className='glossary-img_title'>
                <img src={hexagon} alt='' />
                <p>Eje 5</p>
              </div>
            </div>
          </div>
          <div className='col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12'></div>
        </div>
      </div>
    </div>
  )
}

export default GlossaryIcons
