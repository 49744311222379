import React from 'react'
import { LiItemList, LinkItemList, OlItemList, ParraphListItem } from './listFAQStyle'

export const listSelection = [
  {
    title: 'Criterios de aprobación de Acciones para ser publicadas en la plataforma LOGRA',
    description: (
      <>
        <ParraphListItem>
          <OlItemList>
            <LiItemList>
              {/* 1 */}
              La acción debe ser implementada por una empresa de cualquier tamaño, ya sea
              mexicana o trasnacional, en México.
            </LiItemList>
            <LiItemList>
              {/* 2 */}
              La acción debe contribuir en la consecución de los{' '}
              <a
                style={{ textDecoration: 'underline' }}
                href='https://www.un.org/sustainabledevelopment/es/objetivos-de-desarrollo-sostenible/'
                target='_blank'>
                Objetivos de Desarrollo Sostenible de la Agenda 2030 de las Naciones Unidas.{' '}
              </a>
            </LiItemList>
            <LiItemList>
              {/* 3 */}
              La acción debe contar con resultados tangibles y medibles. Se sugiere incluir
              vínculos y links relacionados a la acción y resultados obtenidos.
            </LiItemList>
            <LiItemList>
              {/* 4 */}
              La acción debe estar alineada al menos a un eje de Pacto Global.
            </LiItemList>
            <LiItemList>
              {/* 5 */}
              La acción compartida deberá estar escrita en idioma español, con buena redacción
              y ortografía, a fin de que el contenido se transmita claramente.
            </LiItemList>
            <LiItemList style={{ fontWeight: '500' }}>
              {/* 6 */}
              La acción debe estar alineada a la estrategia de sustentabilidad y a los temas
              materiales de la empresa, contar con indicadores que permitan medir los cambios y
              justificar el impacto positivo de la acción, y mostrar resultados tangibles que
              demuestren liderazgo hacia la sostenibilidad.
            </LiItemList>
          </OlItemList>
        </ParraphListItem>
      </>
    )
  },
  {
    title: 'Proceso de Aprobación',
    description: (
      <>
        <ParraphListItem>
          <OlItemList>
            <LiItemList>
              Pacto Global México revisará el contenido de la acción compartida en un plazo
              aproximado de 5 días hábiles.
            </LiItemList>
            <LiItemList>
              En caso de ser aprobada, la acción será publicada, es decir, estará visible
              públicamente en la plataforma a partir de 5 a 10 días hábiles.
            </LiItemList>
            <LiItemList>
              Pacto Global México contactará a la persona que emitió la ficha técnica de la
              acción compartida únicamente en caso de requerir alguna aclaración sobre esta,
              previo a su publicación en la plataforma.
            </LiItemList>
          </OlItemList>
        </ParraphListItem>
      </>
    )
  },
  {
    title: (
      <>
        <ParraphListItem>
          <a
            style={{ textDecoration: 'underline' }}
            href='https://www.pactomundial.org.mx/ods/'
            target='_blank'>
            {' '}
            Ver Los Objetivos de Desarrollo Sostenible y el Sector Privado{' '}
          </a>
        </ParraphListItem>
      </>
    )
  }
]
