import React from 'react'
import PropTypes from 'prop-types'
import { DataParrafJoin, TitleParrafJoin } from './JoinStyledComponents'

const JoinParraf = ({ title, text, colorParraf, children }) => {
  return (
    <>
      <TitleParrafJoin color={colorParraf}>{title}</TitleParrafJoin>
      {children}
      <DataParrafJoin>{text}</DataParrafJoin>
    </>
  )
}

JoinParraf.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
}

JoinParraf.defaultProps = {
  title: '',
  text: undefined
}

export default JoinParraf
