import { put, call, takeLatest, delay } from 'redux-saga/effects'

import {} from './cards.redux.js'
import axios from 'axios'
import Swal from 'sweetalert2'
import {
  priorizationsListError,
  priorizationsListSuccess,
  PRIORIZATION_GET_AVERAGE_FAILURE,
  PRIORIZATION_GET_AVERAGE_REQUEST,
  PRIORIZATION_GET_AVERAGE_SUCCESS,
  PRIORIZATION_GET_ONE_FAILURE,
  PRIORIZATION_GET_ONE_REQUEST,
  PRIORIZATION_GET_ONE_SUCCESS,
  typesPriorizations
} from './Priorization.redux'
import { encodeFiltersData, manageError } from '../../redux/helpers/handlers.js'

function* priorizationListSaga({ filters = {} }) {
  try {
    const filterString = encodeFiltersData(filters)
    const { data } = filterString
      ? yield call(axios.get, `/api/priorizations?${filterString}`)
      : yield call(axios.get, `/api/priorizations`)
    yield delay(process.env.REACT_APP_DELAY)
    yield put(priorizationsListSuccess(data.data))
  } catch (error) {
    Swal.fire('Error', error, 'error')
    yield put(priorizationsListError(error.message))
  }
}

export function* getOnePriorizationSaga({ payload }) {
  const _id = payload._id
  try {
    const { data } = yield call(axios.get, `/api/priorizations/${_id}`)
    yield delay(process.env.REACT_APP_DELAY)
    yield put({ type: PRIORIZATION_GET_ONE_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: PRIORIZATION_GET_ONE_FAILURE, error: error.response })
  }
}

export function* getAveragePriorizationSaga() {
  try {
    const { data } = yield call(axios.get, `/api/priorizations/getPriorizationAverageValues`)
    yield delay(process.env.REACT_APP_DELAY)
    yield put({ type: PRIORIZATION_GET_AVERAGE_SUCCESS, payload: data?.data })
  } catch (error) {
    yield put({ type: PRIORIZATION_GET_AVERAGE_FAILURE, error: error.response })
  }
}

export default function* priorizationsSagas() {
  yield takeLatest(typesPriorizations.priorizationsListRequest, priorizationListSaga)
  yield takeLatest(PRIORIZATION_GET_ONE_REQUEST, getOnePriorizationSaga)
  yield takeLatest(PRIORIZATION_GET_AVERAGE_REQUEST, getAveragePriorizationSaga)
  yield takeLatest(PRIORIZATION_GET_ONE_FAILURE, manageError)
}
