import React from 'react'
import { Provider } from 'react-redux'
import configureStore from './redux/configureStore'
import { Helmet } from 'react-helmet'
import { LoadScript } from '@react-google-maps/api'

import Router from './components/Router'
import favicon from './assets/img/favicon.ico'
import rotate from './assets/img/screen-rotate.png'
import { GoogleOAuthProvider } from '@react-oauth/google';

const store = configureStore()

if (process.env.REACT_APP_ENV !== 'production') {
  window.store = store
  window.state = store.getState()
}

const ldJson = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  url: 'https://probonos.dev/',
  logo: { favicon }
}

const API_KEY = 'AIzaSyBRNyoPFLDczARsnD5h1vPAzOA0KAtVCME'

function App() {
  return (
    <Provider store={store}>
      <GoogleOAuthProvider
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <div id='landscape'>
          <div>
            <img src={rotate} alt='appRotate' />
            <p>
              ¡Gira el móvil! <br /> Por favor
            </p>
          </div>
        </div>
        <Helmet>
          <script type='application/ld+json'>{JSON.stringify(ldJson)}</script>
        </Helmet>
        {/* loadingElement Se usa para que el LoadScript no muestre el mensaje de Loading... hasta que se cargue el mapa */}
        <LoadScript googleMapsApiKey={API_KEY} libraries={['places']} loadingElement={<div />}>
          <Router />
        </LoadScript>
      </GoogleOAuthProvider>
    </Provider>
  )
}

export default App
