import React from 'react'
import PropTypes from 'prop-types'

function HomeHead({ title, type, marginTop }) {
  return (
    <div className='row' style={{ marginTop: marginTop }}>
      <div className={type === 1 ? 'col-6' : 'col-12'}></div>
      <div className={type === 1 ? 'col-6' : 'col-12'}>
        <span className={type === 1 ? 'home-label-h1' : 'home-label-h2'}>{title}</span>
      </div>
    </div>
  )
}

HomeHead.propTypes = {
  title: PropTypes.string.isRequired,
  marginTop: PropTypes.string.isRequired,
  type: PropTypes.number.isRequired
}

HomeHead.defaultProps = {
  type: 1,
  marginTop: '50px'
}

export default HomeHead
